define('web-app-frontend/components/project-file-upload', ['exports', 'ember', 'web-app-frontend/config/environment', 'web-app-frontend/components/file-upload'], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendComponentsFileUpload) {
  // Any methods not found in this file will be inhereted from imported FileUploadComponent.

  exports['default'] = _webAppFrontendComponentsFileUpload['default'].extend({
    disableFileEditability: 'disableFileEditability',
    setClientStateOnProject: 'setClientStateOnProject',

    // Observer
    filesDidChange: (function () {
      if (this.userPresent() && !this.filesEmpty()) {
        this.prepareToUploadProjectDrawings();
      } else {
        return this.get('component').sendAction('openModal', 'modals/sign-in-or-register-modal');
      }
    }).observes('files'),

    // Methods
    buildURL: function buildURL() {
      var uploadUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + this.get('project').get('id') + '/drawings?Api-Key=SCWEB';

      return uploadUrl;
    },

    resetParentComponet: function resetParentComponet() {
      // to remove messages from previous upload attempts
      this.get('component').setProperties({ 'showSpinner': true,
        'showComplete': false,
        'showError': false
      });
    },

    buildFormData: function buildFormData() {
      var formData = new FormData();
      var files = this.get('files');
      formData.append('file', files[0]);
      return formData;
    },

    prepareToUploadProjectDrawings: function prepareToUploadProjectDrawings() {
      if (this.fileIsCorrectFormat() && this.fileIsNotTooLarge()) {
        var formData = this.buildFormData();
        var uploadUrl = this.buildURL();
        var oauthToken = this.fetchOauthToken();

        this.unloadProjectFiles();
        this.resetParentComponet();
        this.setFileNameInView();

        this.postFileDrawingToEndPoint(uploadUrl, oauthToken, formData);
      } else {
        this.get('component').sendAction('openModal', 'modals/alert-modal', { text: 'File Error.  Files must be in .zip format & smaller than 100mb.' });
      }
    },

    unloadProjectFiles: function unloadProjectFiles() {
      var store = this.get('component.targetObject.store');
      store.unloadAll('project-file');
    },

    postFileDrawingToEndPoint: function postFileDrawingToEndPoint(uploadUrl, oauthToken, formData) {
      var self = this;

      var quoteSubmitButton = self.get('component').get('quoteSubmitButton');
      quoteSubmitButton.send('cannotSubmit');
      self.get('component').$('#saveCloseBtn').addClass('disabledLink');

      $.ajax({
        url: uploadUrl,
        type: 'POST',
        headers: oauthToken,
        data: formData,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false }). // Set content type to false as jQuery will tell the server its a query string request
      done(function (data) {
        console.log('SOMETHING WENT RIGHT', data);
        var createRecordDeferred = new _ember['default'].RSVP.defer('create project-file');
        self.createProjectFileRecord(data, createRecordDeferred);
        self.setCompleteState();
        createRecordDeferred.promise.then(function (projectFile) {
          if (self.fileType !== 'customQuote') {
            self.send('setClientStateOnProject');
            self.send('setSectionEditability');
            //Reload timeline
            self.get('component').set('reloadTimeLine', true);
          }
          self.sendAction('setProjectFile', projectFile);
        });
      }).fail(function (data) {
        console.log('SOMETHING WENT WRONG', data);
        self.setErrorState();
      }).always(function () {
        console.log('Enabling submit button');
        quoteSubmitButton.send('canSubmit');
        self.get('component').$('#saveCloseBtn').removeClass('disabledLink');
      });
    },

    createProjectFileRecord: function createProjectFileRecord(data, deferred) {
      var store = this.get('component.targetObject.store');
      var projectGUID = data.projectFileUploadModel.projectGUID;
      var projectFile = store.createRecord('projectFile', { id: projectGUID });
      var serializer = store.serializerFor('project-file');
      var typeClass = store.modelFor('project-file');
      var json = serializer.normalizeSaveResponse(store, typeClass, data, projectGUID);
      store.push(json);
      deferred.resolve(projectFile);
    },

    fileIsCorrectFormat: function fileIsCorrectFormat() {
      var files = this.get('files');
      var extension = this.getFileExtension(files[0].name);
      switch (extension.toLowerCase()) {
        case 'zip':
          return true;
      }
      return false;
    },

    actions: {
      setClientStateOnProject: function setClientStateOnProject() {
        //set client state both on success of file upload, and on transition to next form section via
        //green button, so that if page is refreshed, it will be indicated that user already provided
        //their file
        if (this.get('project.clientState') !== 'complete') {
          console.log('set client state in project file upload');
          this.sendAction('setClientStateOnProject', 'contacts');
        }
      },

      setSectionEditability: function setSectionEditability() {
        var projectStatus = this.get('project.status');
        if (projectStatus === 'Order Placed') {
          this.sendAction('disableFileEditability');
        }
      }
    }
  });
});