define('web-app-frontend/components/assembly-turn-time', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['relative', 'ml5', 'turn-time-toggle'],
    classNameBindings: ['isActive:extended'],
    attributeBindings: ['formName'],
    isActive: false,
    saveAndValidateForm: 'saveAndValidateForm',

    regroupTurnTimes: (function () {
      if (this.model) {
        this.assignTurnTimes();
      }
    }).observes('model.triggerTurnTimeReload').on('init'),

    assignTurnTimes: function assignTurnTimes() {
      var component = this;
      var turnTimes = this.model.get('turnTimes');

      var shortRun = [];
      var fullProto = [];

      turnTimes.forEach(function (turnTime) {
        if (turnTime.get('assemblyServiceName') === 'Full Proto') {
          fullProto.pushObject(turnTime);
        } else {
          shortRun.pushObject(turnTime);
        }
      });

      var sortByTurnDays = function sortByTurnDays(a, b) {
        if (parseInt(a.get('turnDays')) < parseInt(b.get('turnDays'))) {
          return -1;
        }

        if (parseInt(a.get('turnDays')) > parseInt(b.get('turnDays'))) {
          return 1;
        }

        return 0;
      };

      fullProto.sort(sortByTurnDays);

      shortRun.sort(sortByTurnDays);

      var turnTimeArray = [{ isFullProto: true, data: fullProto }, { isFullProto: false, data: shortRun }];
      _ember['default'].beginPropertyChanges();
      component.set('turnTimes', turnTimeArray);
      _ember['default'].endPropertyChanges();
    },

    actions: {
      toggle: function toggle() {
        if (!this.get('isActive')) {
          // making sure DOM is clear of other turn-time components
          this.set('parentView.parentView.turnTime', false);
          this.set('parentView.parentView.turnTime', true);
        }
        this.set('parentView.parentView.turnTime', true);
        this.toggleProperty('isActive');
      },

      closeAndSave: function closeAndSave() {
        var self = this;
        var assemblyDeferred = new _ember['default'].RSVP.defer('validateAssemblyDeferred for save and close spinner control');

        this.set('showSpinner', true);

        this.sendAction('saveAndValidateForm', assemblyDeferred);
        this.model.toggleProperty('triggerPriceDifference');
        this.model.set('userHasSelectedTurnTime', true);
        this.send('toggle');

        assemblyDeferred.promise.then(function () {
          self.set('showSpinner', false);
        })['catch'](function () {
          self.set('showSpinner', false);
        });
      }
    },

    click: function click(e) {
      // stop bubbling to parent view
      e.stopPropagation();
    },

    itChanged: (function () {
      if (!this.get('parentView.parentView.turnTime')) {
        this.set('isActive', false);
      }
    }).observes('parentView.parentView.turnTime').on('init')
  });
});