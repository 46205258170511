define('web-app-frontend/app', ['exports', 'ember', 'web-app-frontend/resolver', 'ember-load-initializers', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendResolver, _emberLoadInitializers, _webAppFrontendConfigEnvironment) {

  var App = _ember['default'].Application.extend({
    modulePrefix: _webAppFrontendConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _webAppFrontendConfigEnvironment['default'].podModulePrefix,
    Resolver: _webAppFrontendResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _webAppFrontendConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});