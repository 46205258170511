define('web-app-frontend/serializers/quote-assembly-pricing', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    //overrides the root key for an outgoing request. local modelName is "quoteAssemblyPricing." server expects "assemblyPricing"
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return "assemblyPricing";
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var currentQuoteAssemblyPricing = store.peekRecord('quote-assembly-pricing', id);

      var turnTimes = payload.assemblyPricing.data || [];
      var self = this;

      //bc no ids are returned from api, instead of updating existing turn time records by ids, we're just destroying old and creating new ones
      this.destroyAllHasMany(currentQuoteAssemblyPricing, 'turnTimes');

      turnTimes.forEach(function (turnTime) {
        turnTime.id = self.generateIdForRecord();
      });

      var assemblyPricing = currentQuoteAssemblyPricing.toJSON();

      var cacheCopy = currentQuoteAssemblyPricing.findMatchingRecord();
      assemblyPricing.id = cacheCopy.get('requestID');
      assemblyPricing.isCache = cacheCopy.get('isCache');
      assemblyPricing.isFulfilled = cacheCopy.get('isFulfilled');
      assemblyPricing.hashed = cacheCopy.get('hashed');
      assemblyPricing.userSelectedTurnTime = cacheCopy.get('userSelectedTurnTime');
      assemblyPricing.userAlteredAttributes = cacheCopy.get('userAlteredAttributes');

      assemblyPricing.project = id;
      assemblyPricing.turnTimes = turnTimes.map(function (turnTime) {
        return turnTime.id;
      });

      payload = { quoteAssemblyPricing: assemblyPricing, assemblyTurnTimes: turnTimes };
      return this._super(store, typeClass, payload, id);
    },

    destroyAllHasMany: function destroyAllHasMany(model, relationship) {
      if (model) {
        var children = model.get(relationship);
        var list = children.toArray();

        list.forEach(function (child) {
          children.removeObject(child);
          child.unloadRecord();
        });
      }
    },

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }
  });

  // Assembly attributes that are specific to a particular turntime are mapped in the quote-assembly-pricing model's
  // rubberBandTurnTimes, selectTurnTime & updateWithTurnTime functions.
});