define("web-app-frontend/components/job-file-upload", ["exports", "ember", "web-app-frontend/config/environment", "web-app-frontend/components/file-upload"], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendComponentsFileUpload) {
  // Any methods not found in this file will be inhereted from imported FileUploadComponent.

  exports["default"] = _webAppFrontendComponentsFileUpload["default"].extend({
    session: _ember["default"].inject.service(),

    // Observer
    filesDidChange: (function () {
      if (!this.filesEmpty()) {
        this.prepareToUploadJobFiles();
      }
    }).observes('files'),

    // Methods
    buildURL: function buildURL() {
      var uploadUrl = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/system/jobfiles?Api-Key=SCWEB';

      return uploadUrl;
    },

    resetParentComponet: function resetParentComponet() {
      // to remove messages from previous upload attempts
      this.get('component').setProperties({ 'showSpinner': true,
        'showComplete': false,
        'showError': false
      });
    },

    buildFormData: function buildFormData() {
      var formData = new FormData();
      var files = this.get('files');
      formData.append('file', files[0]);
      return formData;
    },

    fetchOauthAndRequestToken: function fetchOauthAndRequestToken() {
      var oAuthTokenString = '';
      if (this.userPresent()) {
        oAuthTokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': oAuthTokenString, 'SCValidationToken': this.get('jobFile.validationToken') };
    },

    prepareToUploadJobFiles: function prepareToUploadJobFiles() {
      if (this.fileIsCorrectFormat() && this.fileIsNotTooLarge()) {
        var formData = this.buildFormData();
        var uploadUrl = this.buildURL();
        var headers = this.fetchOauthAndRequestToken();

        this.resetParentComponet();
        this.setFileNameInView();

        this.postJobFileToEndPoint(uploadUrl, headers, formData);
      } else {
        this.get('component').send('openModal', 'modals/alert-modal', { text: 'File Error.  Files must be in .zip format & smaller than 100mb.' });
      }
    },

    postJobFileToEndPoint: function postJobFileToEndPoint(uploadUrl, headers, formData) {
      var self = this;

      $.ajax({
        url: uploadUrl,
        type: 'POST',
        headers: headers,
        data: formData,
        cache: false,
        dataType: 'text json',
        processData: false, // Don't process the files
        contentType: false }). // Set content type to false as jQuery will tell the server its a query string request
      done(function (data) {
        console.log('SOMETHING WENT RIGHT', data);
        self.setCompleteState();
        console.log(data.jobFile);
        console.log(data.jobFile.fileName);

        self.set('jobFile.fileName', data.jobFile.fileName);
      }).fail(function (data) {
        console.log('SOMETHING WENT WRONG', data);
        self.setErrorState();
      });
    },

    fileIsCorrectFormat: function fileIsCorrectFormat() {
      var files = this.get('files');
      var extension = this.getFileExtension(files[0].name);
      switch (extension.toLowerCase()) {
        case 'zip':
          return true;
      }
      return false;
    },

    actions: {}
  });
});