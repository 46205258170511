define('web-app-frontend/routes/resource-center/terms-and-conditions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'Terms and Conditions',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'All orders we accept are subject to our terms and conditions unless otherwise specifically agreed to in writing by Screaming Circuits.'
      }
    }]
  });
});