define('web-app-frontend/controllers/application', ['exports', 'ember', 'web-app-frontend/config/environment', 'web-app-frontend/utils/hubspot-utils'], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsHubspotUtils) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    userController: _ember['default'].inject.controller('user'),
    billingController: _ember['default'].inject.controller('user/account/billing'),
    taxController: _ember['default'].inject.controller('quoting/tax-shipping-estimate-modal'),
    pricingSelectionController: _ember['default'].inject.controller('quoting/confirm-pricing-selection-modal'),
    signinController: _ember['default'].inject.controller('modals/sign-in-or-register-modal'),
    quotingProjectNameController: _ember['default'].inject.controller('quoting/project-name-modal'),
    projectController: _ember['default'].inject.controller('project'),
    contactController: _ember['default'].inject.controller('user/account/contact-modal'),
    quotingController: _ember['default'].inject.controller('quoting'),
    orderProjectNameController: _ember['default'].inject.controller('order/project-name-modal'),

    authenticator: 'authenticator:custom',
    session: _ember['default'].inject.service(),

    signInHover: null,
    mobileNav: false,
    publicPath: 'web-app-frontend/public/',
    registerRedirectPath: null,
    registerRedirectProject: null,
    queryParams: ['wwwToken'],
    wwwToken: null,
    evaluatedTempToken: false,
    showInspectletOptin: false,
    isAPIDown: false,

    environment: _webAppFrontendConfigEnvironment['default'].environment,
    environmentIsProduction: _webAppFrontendConfigEnvironment['default'].environmentIsProduction,

    currentTransition: '',

    isMobile: (function () {
      if ($(window).width() < 992) {
        return true;
      } else {
        return false;
      }
    }).property(),

    browserUnsupported: (function () {
      return _ember['default'].$('html').is('.unsupported');
    }).property(),

    browserOld: (function () {
      return _ember['default'].$('html').is('.old-browser');
    }).property(),

    hideSignIn: (function () {
      return this.get('browserOld') || this.get('isCookieDisabled') || this.get('isAPIDown');
    }).property('browserOld', 'isCookieDisabled', 'isAPIDown'),

    isCookieDisabled: (function () {
      var cookieEnabled = navigator.cookieEnabled ? true : false;
      if (typeof navigator.cookieEnabled === "undefined" && !cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled = document.cookie.indexOf("testcookie") !== -1 ? true : false;
      }
      return !cookieEnabled;
    }).property(),

    showAPIDown: (function () {
      var currentRoute = this.get('currentPath');
      if (this.get('isAPIDown') === true && currentRoute.match(/user|quote|quoting|order|contact|project-sign-in|layout/)) {
        this.send('alertAPIDown');
      }
    }).observes('isAPIDown'),

    resolveDeferred: function resolveDeferred(deferred, returnValue) {
      if (deferred) {
        deferred.resolve(returnValue);
      }
    },

    updateChangedValues: function updateChangedValues(copyToRecord, copyFromRecord, deferred) {
      var keysToCopy = copyToRecord.get('constructor.attributes._keys.list');

      keysToCopy.forEach(function (key) {
        if (typeof copyFromRecord.get(key) !== 'undefined') {
          copyToRecord.set(key, copyFromRecord.get(key));
        }
      });

      if (deferred) {
        this.resolveDeferred(deferred, copyToRecord);
      } else {
        return copyToRecord;
      }
    },

    actions: {
      updateModel: function updateModel(model, controllerContext, deferred) {
        var self = this;
        var submitBtnComponent;
        if (controllerContext) {
          submitBtnComponent = controllerContext.get('submitButton');
        } else {
          submitBtnComponent = this.get('submitButton');
        }

        // Defines attributes that will display input errors
        // also sets 'commitOnSuccess' to true
        model.setupForCommit();

        model.save().then(function () {
          //deferred is only being used with project-name-modal
          self.send('closeModal', deferred);
          submitBtnComponent.send('hasSucceeded');
        })['catch'](function (error) {
          console.log('UPDATE MODEL FAILED +++++', error);
          submitBtnComponent.send('showError');
        })['finally'](function () {
          submitBtnComponent.send('hideSpinner');
        });
      },

      validateModel: function validateModel(modelContext) {
        var model;
        if (modelContext) {
          model = modelContext;
        } else {
          model = this.model;
        }

        model.set('commitOnSuccess', false);
        model.alterAttributes();

        model.save().then(function () {
          // success
        })['catch'](function () {
          //Handled errors, need this empty function to keep Ember from bubbling them further.
        });
        //returning false here because we handled action, else Ember won't know the action was handled in this controller
        //and will allow it to bubble up when the model is saved successfully.
        return false;
      },

      toggleSignIn: function toggleSignIn(param) {
        if (param !== undefined) {
          this.set('signInTriggered', false);
          this.set('errorMessage', null);
          if (param === 'createAccount') {
            this.transitionToRoute('register');
          }
        } else {
          this.toggleProperty('signInTriggered');
        }
      },

      signInAndAuthenticate: function signInAndAuthenticate(id, password, deferred) {
        this.set('identification', id);
        this.set('password', password);
        this.send('authenticate', deferred);
      },

      authenticate: function authenticate(deferred) {
        var self = this;
        //this._super().then(function () {
        this.get('session').authenticate('authenticator:custom', this.get('identification'), this.get('password')).then(function () {
          console.log('Authentication successful');
          if (deferred) {
            var userGuid = JSON.parse(localStorage['ember_simple_auth-session']).authenticated.UserGUID;
            self.store.findRecord('user', userGuid).then(function (user) {

              var userEmail = user.get('emailAddress');
              (0, _webAppFrontendUtilsHubspotUtils.identifyHubspotUser)(userEmail);
              (0, _webAppFrontendUtilsHubspotUtils.callHubspotEventAPI)(_webAppFrontendConfigEnvironment['default'].hubspotLoginEventId);
            });

            deferred.resolve();
          }
        })['catch'](function (error) {
          console.log('Authentication failed', error);
          var message = error.error_description;
          self.set('errorMessage', message);
          self.get('signinController').set('errorMessage', message);
          if (deferred) {
            deferred.reject();
          }
        });
      },

      signInHovered: function signInHovered(param) {
        this.set('signInHover', param);
      },

      forgotPassword: function forgotPassword() {
        var store = this.get('store');
        var resetPasswordObject = store.createRecord('user-password-forgot');
        this.send('openModal', 'user.account.forgot-password-modal', resetPasswordObject);
      },

      toggleMobileNav: function toggleMobileNav(param) {
        if (param !== undefined) {
          this.set('mobileNav', param);
        } else {
          this.toggleProperty('mobileNav');
        }
      },

      alertAPIDown: function alertAPIDown() {
        //this.send('openModal', 'modals/alert-modal', { text: 'Great!!! APIs are blocked or down.' });
        var confirmDeferred = new _ember['default'].RSVP.defer('API down confirmation');
        this.send('openModal', 'modals/apidown-modal', { deferredObject: confirmDeferred });
        confirmDeferred.promise.then(function () {
          this.transitionToRoute('/index');
        });
      },

      alertOldBrowser: function alertOldBrowser() {
        this.send('openModal', 'modals/alert-modal', { text: 'You are on an old browser and cannot proceed! Please use an updated browser such as Internet Explorer 11, Chrome, Firefox, or Safari.' });
      },

      alertCookieDisabled: function alertCookieDisabled() {
        this.send('openModal', 'modals/alert-modal', { text: 'Cookies are required for quoting and account management. <br/>Please enable cookies and refresh the page.' });
      },

      //moved from route where the following error occurred upon sending of the action from the application adapter.
      //Uncaught Error: Can't trigger action 'authorizationFailed' because your app hasn't
      //finished transitioning into its first route. To trigger an action on destination routes during a transition,
      //you can call `.send()` on the `Transition` object passed to the `model/beforeModel/afterModel` hooks.
      authorizationFailed: function authorizationFailed() {
        console.log("****************************** controllers/application.js/authorizationFailed *******************************");
        if (this.get('session.isAuthenticated')) {
          this.get('session').invalidate();
        }
      }
    }
  });
});

//import LoginControllerMixin from 'ember-simple-auth/mixins/login-controller-mixin';