define('web-app-frontend/models/project-file', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    commitOnSuccess: _emberData['default'].attr('boolean'),
    existingFileDrawingsZIP: _emberData['default'].attr('string'),
    fileName: _emberData['default'].attr('string'),
    filesMissingError: _emberData['default'].attr('boolean', { defaultValue: false }),
    filesNow: _emberData['default'].attr('string', { defaultValue: 'File' }),
    fileUploadDate: _emberData['default'].attr('string'),
    newFileDrawingsZIPName: _emberData['default'].attr('string'),
    orderNumber: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'),
    status: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    canUpdateFiles: _emberData['default'].attr('boolean'),
    isOriginalFiles: _emberData['default'].attr('boolean'),
    filesReceivedOffline: _emberData['default'].attr('boolean'),

    showFile: _ember['default'].computed.equal('filesNow', 'File'),
    showDate: _ember['default'].computed.equal('filesNow', 'Date'),
    showOriginal: _ember['default'].computed.equal('filesNow', 'Original'),

    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),

    displayUploadDate: (function () {
      return moment(this.get('fileUploadDate')).format('MMMM DD, YYYY');
    }).property('fileUploadDate'),

    formatDateForSave: function formatDateForSave() {
      //from the format used for the datepicker
      var serverFriendlyDate = moment(this.get('fileUploadDate')).format();
      this.set('fileUploadDate', serverFriendlyDate);
    },

    uploadDateHasPassed: function uploadDateHasPassed() {
      return moment().diff(moment(this.get('fileUploadDate')), 'days') > 0;
    }
  });
});