define('web-app-frontend/serializers/project-file', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      var record = payload.projectFileUploadModel;
      record.id = record.projectGUID;
      record.project = record.projectGUID;
      if (record.fileUploadDate) {
        record.fileUploadDate = moment(record.fileUploadDate).format();
      }
      payload = { 'projectFile': record };
      return this._super(store, typeClass, payload);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var record = payload.projectFileUploadModel;
      record.id = record.projectGUID;
      record.project = record.projectGUID;
      if (record.fileUploadDate) {
        record.fileUploadDate = moment(record.fileUploadDate).format();
      }
      payload = { projectFile: record };
      return this._super(store, typeClass, payload);
    }
  });
});