define('web-app-frontend/routes/notifications', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        titleToken: 'Notifications',

        headTags: [{
            type: 'meta',
            tagId: 'meta-description',
            attrs: {
                name: 'description',
                content: 'Find out what\'s happening at Screaming Circuits right now.'
            }
        }]

    });
});