define('web-app-frontend/components/marketing-section-links', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    openModal: 'openModal',

    isServices: (function () {
      return this.get('indexName') === 'services';
    }).property('isServices'),

    isProcess: (function () {
      return this.get('indexName') === 'process';
    }).property('isProcess'),

    isTips: (function () {
      return this.get('indexName') === 'tips';
    }).property('isTips'),

    isTutorials: (function () {
      return this.get('indexName') === 'tutorials';
    }).property('isTutorials'),

    actions: {
      openModal: function openModal(modal, model) {
        this.sendAction('openModal', modal, model);
      }
    }
  });
});