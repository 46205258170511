define('web-app-frontend/models/timeline-node', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    commitOnSuccess: _emberData['default'].attr('boolean'),
    completionDate: _emberData['default'].attr('string'),
    date: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    estimatedDateComplete: _emberData['default'].attr('string'),
    inProgress: _emberData['default'].attr('boolean', { defaultValue: false }),
    month: _emberData['default'].attr('string'),
    notes: _emberData['default'].attr('string'),
    orderState: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'),
    sequence: _emberData['default'].attr('number'),
    subTitle: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    timelineContent: _emberData['default'].attr('string'),
    toolTip: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),

    //Associations
    project: _emberData['default'].belongsTo('project', { async: true })
  });
});