define('web-app-frontend/controllers/user/activity', ['exports', 'ember', 'web-app-frontend/controllers/mixins/activities'], function (exports, _ember, _webAppFrontendControllersMixinsActivities) {
  exports['default'] = _ember['default'].Controller.extend(_webAppFrontendControllersMixinsActivities['default'], {

    firstVisit: (function () {
      if (this.get('activities.length') <= 1) {
        return true;
      } else {
        return false;
      }
    }).property('activities'),

    actions: {
      transitionToQuoteState: function transitionToQuoteState(routeName, projectGUID) {
        if (routeName === 'quote') {
          this.transitionToRoute('quote.edit', projectGUID);
        } else if (routeName === 'order') {
          this.transitionToRoute('order.edit', projectGUID);
        }
      }
    }
  });
});