define('web-app-frontend/components/form-field-state', ['exports', 'ember', 'web-app-frontend/controllers/mixins/stateandprovince'], function (exports, _ember, _webAppFrontendControllersMixinsStateandprovince) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendControllersMixinsStateandprovince['default'], {
    setSectionErrorState: 'setSectionErrorState',
    showDropdown: true,
    activeList: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.setActiveList();
    },

    setActiveList: (function () {
      if (this.get('country') === "USA") {
        this.set('showDropdown', true);
        this.set("activeList", this.stateList);
        this.resetState();
      } else if (this.get('country') === "Canada") {
        this.set('showDropdown', true);
        this.set("activeList", this.provinceList);
        this.resetState();
      } else {
        this.set('showDropdown', false);
        this.set("activeList", null);
      }
    }).observes('country'),

    resetState: function resetState() {
      var state = this.get('model.state');
      var activelist = this.get('activeList');
      var filteredState = activelist.filter(function (obj) {
        return obj.value === state;
      });

      if (filteredState.length === 0) {
        this.set('model.state', '');
        this.set('value', '');
      }
    },

    actions: {

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      }

    }
  });
});