define('web-app-frontend/controllers/user/account/billing', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    creditController: _ember['default'].inject.controller('user/account/credit-card-modal'),
    session: _ember['default'].inject.service(),

    creditCardOnFile: false,
    availableCards: [],
    showSpinner: false,
    showNoCardsMessage: false,

    createNewCreditCard: function createNewCreditCard(store) {
      return store.createRecord('userCreditDetail', { userGUID: this.get('session.data.authenticated.UserGUID'),
        creditCardExpirationMonth: moment().month() + 1,
        creditCardExpirationYear: moment().year() });
    },

    fetchOauthToken: function fetchOauthToken() {
      var tokenString = '';
      if (this.get('session.isAuthenticated')) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    deleteCreditCard: function deleteCreditCard(paymentId) {
      var self = this;
      var oauthToken = self.fetchOauthToken();
      var userGUID = self.get('session.data.authenticated.UserGUID');

      var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/user/' + userGUID + '/creditcard/' + paymentId + '?Api-Key=' + _webAppFrontendConfigEnvironment['default'].EmberENV.client_id + '&SessionGUID=' + $.cookie('sessionGUID');

      return $.ajax({
        url: url,
        type: 'DELETE',
        data: JSON.stringify({}),
        contentType: 'application/json; charset=UTF-8',
        cache: false,
        headers: oauthToken,
        dataType: 'json'
      });
    },

    actions: {
      openCreditCardModal: function openCreditCardModal(creditCard) {
        var isEdit = true;
        if (!creditCard) {
          var store = this.get("store");
          var currentCards = this.get("store").peekAll('userCreditDetail');

          if (currentCards.content.length > 0) {
            store.unloadAll('userCreditDetail');
          }
          creditCard = this.createNewCreditCard(store);
          isEdit = false;
        }
        this.set('creditCard', creditCard);
        this.get('creditController').set('isEdit', isEdit);
        this.send('openModal', 'user.account.credit-card-modal', creditCard);
      },

      removeCreditCard: function removeCreditCard(paymentId) {
        var controller = this;
        var userGUID = controller.get('session.data.authenticated.UserGUID');

        var deferred = new _ember['default'].RSVP.defer('remove credit card confirmation');
        this.send('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to remove this credit card?',
          customSubmitText: 'Yes, continue to remove',
          deferredObject: deferred });
        deferred.promise.then(function () {
          controller.deleteCreditCard(paymentId).done(function () {

            var usercreditDetails = controller.store.peekRecord('userCreditDetail', userGUID);
            var cards = usercreditDetails.get('availableCards').filter(function (x) {
              return x.paymentId !== paymentId;
            });

            usercreditDetails.set('availableCards', cards);
            controller.set('availableCards', cards);

            if (cards.length === 0) {
              controller.set('showNoCardsMessage', true);
              controller.set('creditCardOnFile', false);
            }
          }).fail(function (err) {
            console.error(err);
          });
        })['catch'](function () {
          return false;
        });
      }
    }
  });
});