define('web-app-frontend/routes/passthrough-sign-in', ['exports', 'ember', 'web-app-frontend/mixins/analytics', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _ember, _webAppFrontendMixinsAnalytics, _emberSimpleAuthMixinsUnauthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsAnalytics['default'], _emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {
    titleToken: 'Passthrough Sign-in',
    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Create an account or sign-in to experience the quickest route from PCB Assembly quote to order to delivery'
      }
    }],

    renderTemplate: function renderTemplate() {
      this.send('openModal', 'modals.sign-in-or-register-modal');
    }

    //TODO: Will need to handle the 'cancel' button on the signin modal, and any scenarios that close it.

  });
});