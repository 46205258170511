define("web-app-frontend/components/bom-exporter", ["exports", "ember", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports["default"] = _ember["default"].Component.extend({
    session: _ember["default"].inject.service(),
    showLink: false,

    showBOMExporter: (function () {
      var self = this;

      var canShowLink = self.get("partsIncludedBoolean") && !self.get("userSkippedOnlineQuoting");

      if (self.get("customPartsQuote")) {
        self.set("showLink", canShowLink && self.get('partsCount') !== 0);
      } else {
        self.set("showLink", canShowLink);
      }
    }).observes('project').on('init'),

    actions: {
      openBOMExporter: function openBOMExporter(projectGUID) {
        var windowHandle = window.open();
        var self = this;
        var tokenString = '';
        tokenString = 'Bearer ' + self.get('session.data.authenticated.access_token');
        var oauthToken = { 'Authorization': tokenString };
        var statusUrl = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/downloadbom?Api-Key=SCWEB';
        $.ajax({
          url: statusUrl,
          type: 'GET',
          dataType: 'text',
          headers: oauthToken
        }).done(function (data) {
          console.log("download BOM status request success.");
          console.log(statusUrl);
          windowHandle.location.href = statusUrl;
          setTimeout(function () {
            windowHandle.close();
          }, 10000);
        }).fail(function (data) {
          //1st, 2nd and 3rd params are data, textStatus and errorThrown if needed
          console.log('Download BOM creation failed');
          console.log(statusUrl);
          windowHandle.close();

          if (data.status === 401) {
            var applicationController = _ember["default"].getOwner(self).lookup('controller:application');
            applicationController.send('authorizationFailed');
          }
        });
      }
    }
  });
});