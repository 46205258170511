define('web-app-frontend/serializers/layout-quote-token', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      var layoutQuoteToken = {};
      layoutQuoteToken.token = payload.model;
      layoutQuoteToken.id = id;
      var data = { layoutQuoteToken: layoutQuoteToken };

      return this._super(store, typeClass, data);
    }
  });
});