define('web-app-frontend/adapters/layout-quote-token', ['exports', 'ember', 'ember-data', 'web-app-frontend/config/environment', 'web-app-frontend/utils/error-formatting'], function (exports, _ember, _emberData, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    host: _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl,
    namespace: 'api',

    ajax: function ajax(url, type, hash) {
      url = url + "?Api-Key=SCWEB";
      return this._super(url, type, hash);
    },

    buildURL: function buildURL(endpoint) {
      var url = "system/" + endpoint;
      return this._super(url);
    },

    findRecord: function findRecord() {
      //store, type, id
      return this.ajax(this.buildURL("validationtoken"), "GET");
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.model.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.model.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    },

    //overwrite bc by default this method pluralizes AND camelizes type name for a GET request,
    //and endpoint uses singular 'validationToken'
    pathForType: function pathForType(type) {
      return _ember['default'].String.camelize(type);
    }
  });
});