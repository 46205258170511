define('web-app-frontend/adapters/quote-assembly', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/assembly';
      // super will pass to pathForType callback.
      return this._super(url);
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      record.set('projectGUID', id);
      var payload = { orderAssembly: record };

      return this.ajax(this.buildURL(type.modelName, id), "POST", { data: payload });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      record.set('projectGUID', id);
      var payload = { orderAssembly: record };

      return this.ajax(this.buildURL(type.modelName, id), "PUT", { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.orderAssembly.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.orderAssembly.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});