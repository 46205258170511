define('web-app-frontend/components/faq-section-title', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['faq-section-title'],

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    // gives an id to attach to for out of page transitions into faq
    // without a separate action in the application/route
    faqIdTitle: (function () {
      return 'faq-' + this.get('title').split(" ")[0].toLowerCase();
    }).property('faqIdTitle')
  });
});