define('web-app-frontend/serializers/project', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,
    attrs: {
      assemblyDetails: { embedded: 'always' },
      fabricationDetails: { embedded: 'always' },
      bomDetails: { embedded: 'always' }
    },

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, type, payload) {
      var data = {};
      payload = payload.projectCreationModel;
      var id = payload.projectGUID;
      payload.id = id;
      data = { project: payload };

      return this._super(store, type, data, id);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, type, payload) {
      var data = {};
      var id = payload.projectDetails.projectGUID;
      var projectDetails = payload.projectDetails;
      var quoteAssembly, quoteFabrication, bom, bomLineData;

      projectDetails.id = id;
      if (!_ember['default'].isEmpty(projectDetails.fileName)) {
        projectDetails.projectFile = id;
      }

      if (projectDetails.assemblyServiceDetails) {
        quoteAssembly = projectDetails.assemblyServiceDetails;
        quoteAssembly.turnTimeCode = quoteAssembly.turnTime;
        quoteAssembly.userSelectedTurnTime = quoteAssembly.turnTime;
        quoteAssembly.id = id;
        quoteAssembly.projectGUID = id;
        quoteAssembly.customAssemblyQuote = projectDetails.customAssemblyQuote;
        quoteAssembly.customAssemblyNotes = projectDetails.customAssemblyNotes;
        projectDetails.assemblyTurnTime = quoteAssembly.turnDaysDisplay;

        // these belongs to records require the object to be within an array when passed to _super.
        projectDetails.assemblyDetails = id;
        delete projectDetails.assemblyServiceDetails;

        quoteAssembly = [quoteAssembly];
      } else {
        projectDetails.assemblyTurnTime = null;
        quoteAssembly = [];
      }

      if (projectDetails.fabricationServiceDetails) {
        quoteFabrication = projectDetails.fabricationServiceDetails;
        quoteFabrication.id = id;
        quoteFabrication.projectGUID = id;
        quoteFabrication.userSelectedTurnTime = quoteFabrication.turnTime;
        // quoteFabrication.quoteTotal = projectDetails.fabricationServiceDetails.fabricationTotal;
        // quoteFabrication.quoteSubTotal = projectDetails.fabricationServiceDetails.fabricationSubTotal;
        // quoteFabrication.quoteDiscount = projectDetails.fabricationServiceDetails.fabricationDiscount;
        projectDetails.fabricationDetails = id;
        projectDetails.fabTurnTime = quoteFabrication.turnTime;
        delete projectDetails.fabricationServiceDetails;

        quoteFabrication = [quoteFabrication];
      } else {
        projectDetails.fabTurnTime = null;
        quoteFabrication = [];
      }

      if (projectDetails.partsDetails) {
        bom = projectDetails.partsDetails;
        bomLineData = bom.allBomLines.data;
        delete projectDetails.partsDetails.bomLines;
        delete projectDetails.partsDetails.allBomLines;

        bomLineData.forEach(function (line) {
          line.id = line.bmlId;
          line.project = id;

          // move reference data into top level
          Object.keys(line.resultReferenceData).forEach(function (key) {
            var value = line.resultReferenceData[key];
            line[key] = value;
          });
          // remove nested reference data
          delete line.resultReferenceData;
        });
        bom.bomLines = bomLineData;
        bom.id = id;
        bom = [bom];
        projectDetails.bomDetails = id;

        projectDetails.quotedPartsTotal = bom.partsTotal;
        projectDetails.quotedPartsPerBoardTotal = bom.partCostPerBoard;
      } else {
        projectDetails.quotedPartsTotal = 0.0;
        projectDetails.quotedPartsPerBoardTotal = 0.0;
        // needed for deleteBOM before bom has been saved
        bom = [];
      }

      data = { project: projectDetails,
        quoteAssembly: quoteAssembly,
        quoteFabrication: quoteFabrication,
        bom: bom
      };

      return this._super(store, type, data, id);
    },

    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, type, payload, id) {
      var record;
      if (payload.projectUpdateModel) {
        record = payload.projectUpdateModel;
      } else {
        record = payload.projectDetails;
      }
      record.id = record.projectGUID;
      // delete payload.projectUpdateModel;

      var data = { project: record };
      return this._super(store, type, data, id);
    },

    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, type, payload) {
      var data = {};
      var id = payload.projectDetails.projectGUID;
      var projectDetails = payload.projectDetails;
      var quoteAssembly, quoteFabrication, bom, bomLineData;

      projectDetails.id = id;
      projectDetails.projectFile = id;

      if (projectDetails.assemblyServiceDetails) {
        quoteAssembly = projectDetails.assemblyServiceDetails;
        quoteAssembly.turnTimeCode = quoteAssembly.turnTime;
        quoteAssembly.userSelectedTurnTime = quoteAssembly.turnTime;
        quoteAssembly.id = id;
        quoteAssembly.projectGUID = id;
        quoteAssembly.customAssemblyQuote = projectDetails.customAssemblyQuote;
        quoteAssembly.customAssemblyNotes = projectDetails.customAssemblyNotes;
        projectDetails.assemblyTurnTime = quoteAssembly.turnDaysDisplay;

        // these belongs to records require the object to be within an array when passed to _super.
        projectDetails.assemblyDetails = id;
        delete projectDetails.assemblyServiceDetails;

        quoteAssembly = [quoteAssembly];
      } else {
        projectDetails.assemblyTurnTime = null;
        quoteAssembly = [];
      }

      if (projectDetails.fabricationServiceDetails) {
        quoteFabrication = projectDetails.fabricationServiceDetails;
        quoteFabrication.id = id;
        quoteFabrication.projectGUID = id;
        quoteFabrication.userSelectedTurnTime = quoteFabrication.turnTime;
        // quoteFabrication.quoteTotal = projectDetails.fabricationServiceDetails.fabricationTotal;
        // quoteFabrication.quoteSubTotal = projectDetails.fabricationServiceDetails.fabricationSubTotal;
        // quoteFabrication.quoteDiscount = projectDetails.fabricationServiceDetails.fabricationDiscount;
        projectDetails.fabricationDetails = id;
        projectDetails.fabTurnTime = quoteFabrication.turnTime;
        delete projectDetails.fabricationServiceDetails;

        quoteFabrication = [quoteFabrication];
      } else {
        projectDetails.fabTurnTime = null;
        quoteFabrication = [];
      }

      if (projectDetails.partsDetails) {
        bom = projectDetails.partsDetails;
        bomLineData = bom.allBomLines.data;
        delete projectDetails.partsDetails.bomLines;
        delete projectDetails.partsDetails.allBomLines;

        bomLineData.forEach(function (line) {
          line.id = line.bmlId;
          line.project = id;

          // move reference data into top level
          Object.keys(line.resultReferenceData).forEach(function (key) {
            var value = line.resultReferenceData[key];
            line[key] = value;
          });
          // remove nested reference data
          delete line.resultReferenceData;
        });
        bom.bomLines = bomLineData;
        bom.id = id;
        bom = [bom];
        projectDetails.bomDetails = id;

        projectDetails.quotedPartsTotal = bom.partsTotal;
        projectDetails.quotedPartsPerBoardTotal = bom.partCostPerBoard;
      } else {
        projectDetails.quotedPartsTotal = 0.0;
        projectDetails.quotedPartsPerBoardTotal = 0.0;
        // needed for deleteBOM before bom has been saved
        bom = [];
      }

      data = { project: projectDetails,
        quoteAssembly: quoteAssembly,
        quoteFabrication: quoteFabrication,
        bom: bom
      };

      return this._super(store, type, data, id);
    }

  });
});