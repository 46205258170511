define('web-app-frontend/controllers/resource-center/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    faqController: _ember['default'].inject.controller('resource-center/faq'),
    resourceController: _ember['default'].inject.controller('resource-center'),

    actions: {
      // for FAQ section as it grabs the component ember object not by DOM id
      transitionAndScrollTitle: function transitionAndScrollTitle(title) {
        this.transitionToRoute('resource-center.faq');
        _ember['default'].run.later(this, function () {
          this.get('faqController').send('scrollToTitle', title);
        }, 500);
      }
    }
  });
});