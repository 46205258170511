define("web-app-frontend/controllers/quoting/tax-shipping-estimate-modal", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    applicationController: _ember["default"].inject.controller('application'),
    shippingOptions: [{ label: "Ground (3-6 days)", value: 5 }, { label: "2 Day Air", value: 2 }, { label: "Next Day Air", value: 1 }, { label: "Will Call", value: 0 }],

    todayDate: moment().toDate(),
    showSpinner: false,

    createNewTaxShippingEstimate: function createNewTaxShippingEstimate(project) {
      var taxShippingEstimate = this.store.createRecord('taxShippingEstimate', {
        id: project.id,
        assemblyTurnTimeCode: project.get('assemblyDetails.turnTimeCode'),
        fabTurnTimeCode: project.get('fabricationDetails.turnTime'),
        fabServiceType: project.get('fabricationDetails.serviceType'),
        projectTotal: project.get('orderTotal'),
        isFormalQuote: project.get('isFormalQuote')
      });
      return taxShippingEstimate;
    },

    createWithCustomTurnTime: function createWithCustomTurnTime(turnTime) {
      return this.store.createRecord('tax-shipping-estimate', {
        id: turnTime.get('projectGUID'),
        projectGUID: turnTime.get('projectGUID'),
        assemblyTurnTimeCode: turnTime.get('assemblyTurnTimeCode'),
        fabServiceType: turnTime.get('fabServiceName'),
        fabTurnTimeCode: turnTime.get('fabTurnTimeCode'),
        projectTotal: turnTime.get('projectTotal'),
        isFormalQuote: turnTime.get('customFabQuote') || turnTime.get('project.customAssemblyQuote') || turnTime.get('project.isFormalQuote') && turnTime.get('project.customPartsQuote')
      });
    },

    //set both default on calendar and on taxShippingEstimate record
    setDefaultDates: (function () {
      if (this.model && this.get('dateValue')) {
        // converting pikaday unix timestamp string to usable number
        var chosenDate = moment.utc(this.get('dateValue')).format();
        this.model.set('projectStartDate', chosenDate);
      } else {
        this.set('dateValue', this.todayDate);
      }
    }).observes('dateValue', 'model').on('init'),

    actions: {
      calculate: function calculate() {
        var self = this;
        if (self.get('showSpinner') === false) {
          var taxShippingEstimate = this.model;
          taxShippingEstimate.setupForCommit();
          this.set('showSpinner', true);
          $('#shippingEstimatorCloseBtn').addClass("disabled");

          taxShippingEstimate.save().then(function () {
            // scroll to date and project total on calculate success
            if ($(window.outerWidth < 768)) {
              $('.estimate-tax-ship .modal').animate({ scrollTop: $('.tax-ship-modal-target').offset().top - 20 }, 200);
            }
            self.set('showSpinner', false);
            $('#shippingEstimatorCloseBtn').removeClass("disabled");
          })["catch"](function (errors) {
            console.log('POST to ProjectTaxAndShipping failed', errors);
            self.set('showSpinner', false);
            $('#shippingEstimatorCloseBtn').removeClass("disabled");
          });
        }
      },

      closeTaxEstimateModal: function closeTaxEstimateModal() {

        if (!$('#shippingEstimatorCloseBtn').hasClass('disabled')) {
          //reset default date, else it will remain at prior selection when modal is reopened
          this.set('dateValue', this.todayDate);
          this.store.unloadAll('tax-shipping-estimate');
          var applicationController = this.get('applicationController');
          applicationController.send('closeModal');
        }
      }
    }
  });
});