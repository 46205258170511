define('web-app-frontend/serializers/order-contact', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      var orderContact = payload.projectEmergencyContactModel;
      orderContact.id = orderContact.projectGUID;

      //set fresh objects to the default commitOnSuccess value here instead of letting the defaultValue do so.
      //This ensures that our conditionals using hasDirtyAttributes will not fire when DS changes this true value to false.
      orderContact.commitOnSuccess = false;

      if (!orderContact.dayEmergencyContactPreference) {
        orderContact.dayEmergencyContactPreference = 'Phone';
      }
      if (!orderContact.nightEmergencyContactPreference) {
        orderContact.nightEmergencyContactPreference = 'Phone';
      }

      var data = { orderContact: orderContact };

      return this._super(store, typeClass, data);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var orderContact = payload.projectEmergencyContactModel;
      orderContact.id = orderContact.projectGUID;
      var data = { orderContact: orderContact };
      return this._super(store, typeClass, data);
    }
  });
});