define('web-app-frontend/adapters/project-turn-time', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/turntimes';
      // super will pass to pathForType callback.
      return this._super(url);
    },

    query: function query(store, type, _query) {
      return this.ajax(this.buildURL(type.modelName, _query['id']), "GET");
    },

    updateRecord: function updateRecord(store, type, snapshot, id) {
      var record = snapshot.record;
      id = record.get('projectGUID');
      var model = { "projectGUID": record.get('projectGUID'),
        "assemblyTurnTimeCode": record.get('assemblyTurnTimeCode'),
        "fabTurnTimeCode": record.get('fabTurnTimeCode'),
        "fabService": record.get('fabServiceName'),
        "fabSubTotal": record.get('fabSubTotal'),
        "assemblySubTotal": record.get('assemblySubTotal'),
        "upgradeFabRouting": record.get('upgradeFabRouting'),
        "commitOnSuccess": true,
        "customFabQuote": record.get('customFabQuote')
      };
      var payload = { projectTurnTimeSelectionModel: model };

      return this.ajax(this.buildURL(type.modelName, id), 'PUT', { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      errors = [];
      response = payload;
      if (status === 500) {
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError("base", "An error has occurred. Please give us a call at 1-866-784-5887 for assistance.", errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    }
  });
});