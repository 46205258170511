define('web-app-frontend/adapters/user-credit-detail', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL(type, id) {
      var url = "user/" + id + "/creditcard";
      return this._super(url);
    },

    deleteRecord: function deleteRecord(store, type, record) {
      var id = record.attr('userGUID');
      var promise = this.ajax(this.buildURL(type.modelName, id), 'DELETE').then(function (response) {
        console.log('Delete Record Response', response);
      });

      return promise;
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), 'GET');
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var userGUID = snapshot.attr('userGUID');
      var payload = { userCreditUpdate: record };

      return this.ajax(this.buildURL(type.modelName, userGUID), "PUT", { data: payload });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var userGUID = snapshot.attr('userGUID');
      var record = snapshot.record;
      var payload = { userCreditUpdate: record };

      return this.ajax(this.buildURL(type.modelName, userGUID), "POST", { data: payload });
    },

    ajax: function ajax(url, type, hash) {
      if (type !== 'GET') {
        url = url + "?Api-Key=SCWEB";
        return this._super(url, type, hash);
      } else {
        return this._super(url, type, hash);
      }
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      errors = [];
      if (status === 400) {
        response = payload;

        if (response.userCreditUpdate.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.userCreditUpdate.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else if (status === 500) {
        // due to removal of credit card number validation needed for submit, now it
        // returns in 500 and we create error manually.
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError("creditCardNumber", "Credit Card Number is Invalid", errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});