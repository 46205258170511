define('web-app-frontend/adapters/user-register-model', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting', 'ember'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting, _ember) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },

    headers: _ember['default'].computed(function () {
      return {
        'SCValidationToken': this.get('validationToken')
      };
    }).property('validationToken').volatile(),

    pathForType: function pathForType() {
      return 'user';
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = {};
      payload = { userRegisterModel: record };
      this.set('validationToken', record.get('validationToken'));
      return this.ajax(this.buildURL(type.modelName), "POST", { data: payload });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = {};
      payload = { userRegisterModel: record };
      return this.ajax(this.buildURL(type.modelName), "POST", { data: payload });
    },

    ajax: function ajax(url, type, hash) {
      url = url + "?Api-Key=SCWEB";
      return this._super(url, type, hash);
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      errors = {};
      response = payload;
      if (payload.userRegisterModel) {
        if (payload.userRegisterModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.userRegisterModel.errors);
          return new _emberData['default'].InvalidError(errors);
        } else {
          return payload;
        }
      } else {
        return payload;
      }
    }
  });
});