define('web-app-frontend/routes/resource-center/process/delivery-guarantee', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'Delivery Guarantee',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'We guarantee to ship at least 50% of your finished printed circuit board assemblies by the day your order is due, with the balance as soon as possible.'
      }
    }],

    beforeModel: function beforeModel() {
      this.transitionTo('resource-center.terms-and-conditions');
    }
  });
});