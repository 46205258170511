define('web-app-frontend/components/soft-alert', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['suggestUpgrade:soft-alert:hide'],
    suggestUpgrade: false,
    scrollToFullFeature: 'scrollToFullFeature',
    hideErrorDisplay: 'hideErrorDisplay',
    showErrorDisplay: 'showErrorDisplay',

    isUpgradeAlert: _ember['default'].computed.equal('alertType', 'upgradeAlert'),

    didInsertElement: function didInsertElement() {
      this.$().popover({
        selector: '[rel="popover"]',
        trigger: 'click',
        placement: 'bottom',
        html: true,
        content: function content() {
          return $(this).parent().next('.popover-wrapper').html();
        }
      });
    },

    softAlertIsPresent: function softAlertIsPresent() {
      var currentErrors = this.model.get('errors');
      var attribute = this.get('attribute');
      return currentErrors.has(attribute) && currentErrors.has('upgradeAlert') || attribute === 'height' && currentErrors.has('assemblyServiceAlert');
    },

    checkForUpgrade: (function () {
      var model = this.model;
      if (model && this.softAlertIsPresent()) {
        var currentErrors = model.get('errors');
        if (currentErrors.has('assemblyServiceAlert') && this.attribute === 'height') {
          this.set('alertType', 'assemblyServiceAlert');
        } else if (currentErrors.has('upgradeAlert')) {
          this.set('alertType', 'upgradeAlert');
        }
        this.createSoftAlert();
      } else {
        this.set('suggestUpgrade', false);
        this.send('showErrorDisplay');
      }
    }).observes('model.errors.length'),

    createSoftAlert: function createSoftAlert() {
      var softAlertMessage = '';
      if (this.get('alertType') !== 'assemblyServiceAlert') {
        this.get('model.errors').get(this.attribute).forEach(function (error) {
          softAlertMessage += error.message;
        });
      } else {
        softAlertMessage += this.get('model.errors.assemblyServiceAlert')[0].message;
      }
      this.set('suggestUpgrade', true);
      this.set('softAlertMessage', softAlertMessage);
      this.send('hideErrorDisplay');
    },

    softAlertHeadline: (function () {
      if (this.get('alertType') === 'upgradeAlert') {
        return 'Upgrade to Full Feature Required';
      } else if (this.get('alertType') === 'assemblyServiceAlert') {
        return 'Notice about Service Type';
      }
    }).property('alertType'),

    actions: {
      hideErrorDisplay: function hideErrorDisplay() {
        this.sendAction('hideErrorDisplay');
      },

      showErrorDisplay: function showErrorDisplay() {
        this.sendAction('showErrorDisplay');
      },

      // Sends this action to quote-fab-pricing, doesn't do anything on assembly.
      scrollToFullFeature: function scrollToFullFeature() {
        this.sendAction('scrollToFullFeature');
        this.$('[rel="popover"]').popover('hide');
      }
    }
  });
});