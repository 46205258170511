define('web-app-frontend/components/mobile-nav', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['mobile-nav padded text-center'],
    classNameBindings: ['mobileNav:extended'],
    toggleMobileNav: 'toggleMobileNav',
    startNewQuote: 'startNewQuote',
    openModal: 'openModal',

    click: function click() {
      // keeps from bubbling to application view to close mobile nav
      return false;
    },

    actions: {
      toggleMobileNav: function toggleMobileNav(navBoolean) {
        this.sendAction('toggleMobileNav', navBoolean);
      },

      goToLink: function goToLink(link) {
        window.open(link, '_blank');
      },

      startNewQuote: function startNewQuote() {
        this.sendAction('startNewQuote');
      },

      openModal: function openModal(template, modal) {
        this.sendAction('openModal', template, modal);
      }
    }
  });
});