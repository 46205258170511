define('web-app-frontend/components/site-search', ['exports', 'ember', 'web-app-frontend/config/environment', 'web-app-frontend/mixins/analytics', 'web-app-frontend/utils/quote-state-evaluator'], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendMixinsAnalytics, _webAppFrontendUtilsQuoteStateEvaluator) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendMixinsAnalytics['default'], {
    transitionToRoute: 'transitionToRoute',
    store: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    searchQueryRedirect: {
      'project box': '/user'
      //Add additional direct search results here, as comma separated key value pairs
    },

    searchQueryController: [{ label: "project box", searchText: "project box", isInternal: true, isPattern: false, link: "user", newWindow: false }, { label: "order number", searchText: "^\\d{4}-\\d{4}$", isInternal: true, isPattern: true, link: "user", newWindow: false,
      linkDetails: {
        data: { modelName: "project", searchproperty: "orderNumber" }
      }
    }],

    classNames: ['ib'],
    classNameBindings: ['fullWidth:full-width:nav-search'],
    openModal: 'openModal',

    click: function click() {
      // keeps from bubbling to application view to close mobile nav
      return false;
    },

    showSearchResults: function showSearchResults(data, baseSearchUrl) {
      var searchTerm = this.get('searchQuery');
      var title = 'Results for "' + searchTerm + '"';
      var hasNextPage = _ember['default'].isPresent(data.queries.nextPage);
      var hasPreviousPage = _ember['default'].isPresent(data.queries.previousPage);

      this.sendAction('openModal', 'modals/search-results', { searchResults: data.items,
        title: title,
        hasPreviousPage: hasPreviousPage,
        hasNextPage: hasNextPage,
        baseSearchUrl: baseSearchUrl,
        data: data
      });
    },

    actions: {

      postSearchToEndpoint: function postSearchToEndpoint() {
        var store = this.get('store');
        var self = this;
        var linkToRedirect;
        var promises = [];

        var searchQuery = this.get('searchQuery');

        var searchScope = this.get('filteredSearch') ? 'Search: ' + this.get('filterKey') : 'Search: Global';
        this.sendGoogleTagEvent({ action: searchScope, label: searchQuery }); //Analytics Mixin

        // Updated code to look for search patterns
        var searchTextRedirects = this.searchQueryController.filterBy('isPattern', false);
        var redirectDetails = null;
        if (searchTextRedirects.length > 0) {
          searchTextRedirects.any(function (item, index) {
            if (item.searchText.toLowerCase() === searchQuery.toLowerCase()) {
              console.log('found searched item' + item.searchText.toLowerCase());
              console.log("item index " + index);
              redirectDetails = item;
              linkToRedirect = redirectDetails.link;
              return true;
            }
          });
        }
        if (_ember['default'].isEmpty(redirectDetails)) {
          var searchPatternRedirects = this.searchQueryController.filterBy('isPattern', true);
          if (searchPatternRedirects.length > 0) {
            searchPatternRedirects.any(function (item, index) {
              var regEx = new RegExp(item.searchText, 'i');
              if (regEx.test(searchQuery.toLowerCase())) {
                console.log('found searched pattern' + item.searchText.toLowerCase() + "at " + index);
                redirectDetails = item;
                linkToRedirect = redirectDetails.link;
                //Use link details to populate the link here
                if (!_ember['default'].isEmpty(redirectDetails.linkDetails) && self.get('session.isAuthenticated')) {
                  var json = JSON.parse('{"' + redirectDetails.linkDetails.data.searchproperty + '": "' + searchQuery + '"}');
                  var findDataDeferred = new _ember['default'].RSVP.defer('Find data deferred');
                  promises.push(findDataDeferred.promise);
                  store.queryRecord(redirectDetails.linkDetails.data.modelName, json).then(function (data) {
                    console.log('found model based on data');
                    //Prepare link for the order number search
                    if (redirectDetails.label === "order number") {
                      if ((0, _webAppFrontendUtilsQuoteStateEvaluator['default'])(data.get('clientState'), data.get('isUserEditable')) === 'quote') {
                        linkToRedirect = null;
                        //self.sendAction('transitionToRoute','quote.edit', data.get('projectGUID'));
                        //Router has been injected in all components using initializer
                        self.get('router').transitionTo('quote.edit', data.get('projectGUID'));
                      } else {
                        linkToRedirect = null;
                        //self.sendAction('transitionToRoute','order.edit', data.get('projectGUID'));
                        //Router has been injected in all components using initializer
                        self.get('router').transitionTo('order.edit', data.get('projectGUID'));
                      }
                    }
                    findDataDeferred.resolve();
                  })['catch'](function (errors) {
                    console.log('data errors', errors);
                    findDataDeferred.resolve();
                  });
                }
                return true;
              }
            });
          }
        }

        // Updated code to look for search patterns
        _ember['default'].RSVP.allSettled(promises).then(function () {
          if (!_ember['default'].isEmpty(redirectDetails)) {
            if (!_ember['default'].isEmpty(linkToRedirect)) {
              //if linkToRedirect is empty means we are using transitionToRoute action above for redirect
              if (redirectDetails.newWindow) {
                window.open(linkToRedirect);
              } else {
                if (redirectDetails.isInternal) {
                  self.get('router').transitionTo(linkToRedirect);
                } else {
                  window.location.replace(linkToRedirect);
                }
              }
            }
          } else {
            if (!_ember['default'].isBlank(searchQuery)) {
              $('html, body').animate({ scrollTop: $('.header').offset().top - 10 }, 200);
              var google = _webAppFrontendConfigEnvironment['default'].googleCustomSearch;
              var baseURL = google.baseURL + '?&key=' + google.apiKey + '&cx=' + google.searchEngineID;

              var baseSearchUrl = baseURL + '&q=' + searchQuery;

              // Whatever it takes to scope resource centre search to only those pages that apply.
              if (self.get('filteredSearch')) {
                baseSearchUrl = baseSearchUrl + '+more:pagemap:metatags-' + self.get('filterKey') + ':' + self.get('filterValue');
                // instead of q=‘search term’, you’ll have q=‘search term+more:pagemap:keywordarea:true'
              }

              $.ajax({
                url: baseSearchUrl,
                type: 'GET',
                dataType: 'json'
              }).done(function (data) {
                self.showSearchResults(data, baseSearchUrl);
                //Remove user's query from the search nav bar.
                self.set('searchQuery', '');
              });
            }
          }
        });
      }
    }
  });
});