define('web-app-frontend/mixins/error-handler', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    handleApplicationError: function handleApplicationError(functionName, error, alertUser, alertMessage) {
      var messageForUser = alertMessage || 'Oops. Something went wrong. Please try again later';
      console.log("In mixin error handler");
      console.log(error);
      this._logError(functionName, error);
      if (alertUser) {
        this.send('alertError', messageForUser);
      }
    },

    _logError: function _logError(functionName, error) {
      //Either log error using local API or use third party API to log production errors
      if (window.trackJs) {
        console.log('sending error to error log service');
        window.trackJs.console.log("Function Name : " + functionName);
        window.trackJs.track(error);
      }
    }
  });
});