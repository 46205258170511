define('web-app-frontend/components/user-password-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    passwordsMatch: false,
    isSubmitted: false,

    actions: {
      changePassword: function changePassword(password) {
        var submitBtnComponent = this.get('submitButton');
        var self = this;
        // Defines attributes that will display input errors
        // also sets 'commitOnSuccess' to true
        password.setupForCommit();

        password.save().then(function (record) {
          // when successful
          submitBtnComponent.send('hasSucceeded');
          self.set('isSubmitted', true);

          record.setProperties({ passwordOld: '', passwordNew: '', passwordConf: '', commitOnSuccess: false });
        })['catch'](function () {
          // show Error...
          submitBtnComponent.send('showError');
        })['finally'](function () {
          submitBtnComponent.send('hideSpinner');
        });
      },

      validatePassword: function validatePassword() {
        var password = this.get('password');

        password.set('commitOnSuccess', false);
        password.alterAttributes();

        password.save().then(function () {
          // success
        })['catch'](function () {
          //Handled errors, need this empty function to keep Ember from bubbling them further.
        });
        //returning false here because we handled action, else Ember won't know the action was handled in this controller
        //and will allow it to bubble up when the model is saved successfully.
        return false;
      },

      matchPassword: function matchPassword() {
        var password = this.get('password');

        if (password.get('passwordConf') === password.get('passwordNew') && password.get('passwordConf') !== '') {
          this.set('passwordsMatch', true);
          this.send('validatePassword');
        } else {
          this.set('passwordsMatch', false);
        }
      }
    }
  });
});