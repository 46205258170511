define('web-app-frontend/controllers/quoting/project-name-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),

    actions: {
      closeProjectNameModal: function closeProjectNameModal() {
        //project-name-modal initiated by Save For Later link defers transition to quote index page until user
        //works through modal, but deferred will be undefined if modal is initiated by transition to ordering form

        //quick n easy rollback
        this.model.set('projectName', '');
        this.model.set('revision', '');
        this.send('closeModal', this.deferred);
      },

      updateModel: function updateModel(model) {
        this.get('applicationController').send('updateModel', model, this, this.deferred);
      },

      validateModel: function validateModel() {
        this.get('applicationController').send('validateModel', this.model);
      }
    }
  });
});