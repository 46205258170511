define('web-app-frontend/routes/quote/edit', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    headTags: [{
      type: 'meta',
      tagId: 'meta-prerender-status-code',
      attrs: {
        name: 'prerender-status-code',
        content: '401'
      }
    }],

    controllerName: 'quoting',
    titleToken: 'Quote In Progress',
    session: _ember['default'].inject.service(),

    activate: function activate() {
      this._super();
      _ember['default'].$('body').addClass('quoting');
      window.scrollTo(0, 0);
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('quoting');
    },

    beforeModel: function beforeModel(transition) {
      this.get('session').set('attemptedTransition', transition);
    },

    model: function model() {
      var self = this;
      var id = this.modelFor('project').id;
      return this.store.findRecord('project', id)['catch'](function (errors) {
        if (errors.errors) {
          if (errors.errors[0].status === '401') {
            self.transitionTo('passthrough-sign-in');
          } else if (errors.errors[0].status === '403') {
            self.transitionTo('/404');
          } else if (errors.errors[0].status === '404') {
            self.transitionTo('/404');
          }
        }
      });
    },

    setupController: function setupController(controller, model) {
      // to trigger default behavior
      this._super(controller, model);
      var self = this;
      var projectGUID = model.get('id');

      // Since we have the projectGUID contained in the URL, we can remove this cookie and still be able to maintain state.
      // primarily used for redirects to edit on refresh of quote/new and during the register redirect if a user
      // has created their quote prior to registeration.
      $.removeCookie('currentProjectGUID', { path: '/' });

      //Clear Sunstone flag
      controller.set('sunstoneUnavailable', false);

      self.store.findRecord('project', projectGUID).then(function (project) {
        var currentProjectDeferred = new _ember['default'].RSVP.defer('make sure project is current');
        if (project.get('guidForDuplication')) {
          project.reload().then(function () {
            currentProjectDeferred.resolve();
          });
        } else {
          currentProjectDeferred.resolve();
        }

        currentProjectDeferred.promise.then(function () {
          controller.set('project', project);

          //not delayed enough... Set manual timeout?
          _ember['default'].run.schedule('afterRender', this, function () {
            controller.printOnTransition();
          });

          self.unloadStaleRecords();
          self.findAndSetAssembly(controller, projectGUID);
          self.findAndSetFabrication(controller, projectGUID);
          self.findAndSetBom(controller, projectGUID);

          //the project file endpoint will return a 401 if the user is not logged in only fetch if authenticated.
          if (self.get('session.isAuthenticated')) {
            self.findAndSetProjectFile(controller, projectGUID);
          }
          self.setPageState(controller, project);
          self.checkForLockedQuote(controller, project);
          self.checkIfCustomQuoteApproved(controller, project);
        });
      })['catch'](function () {
        console.log('quote/edit findProject failed');
      });
    },

    checkIfCustomQuoteApproved: function checkIfCustomQuoteApproved(controller, project) {
      if (project.get('hasCustomQuoteApproved')) {
        controller.set('showCustomQuote', false);
        controller.set('quoteLocked', true);
      }
    },

    unloadStaleRecords: function unloadStaleRecords() {
      var store = this.store;
      //unload all record types that caching has been applied to
      var cachedAssemblyQuotes = store.peekAll('quote-assembly-pricing').filterBy('isCache', true);
      cachedAssemblyQuotes.forEach(function (record) {
        record.unloadRecord();
      });
      store.unloadAll('assembly-turn-time');
      store.unloadAll('assembly-price-difference');

      var cachedFabQuotes = store.peekAll('quote-fabrication-pricing').filterBy('isCache', true);
      cachedFabQuotes.forEach(function (record) {
        record.unloadRecord();
      });
      store.unloadAll('fabrication-turn-time');
      store.unloadAll('fabrication-price-difference');

      store.unloadAll('design-violation');
    },

    checkForLockedQuote: function checkForLockedQuote(controller, project) {
      if (project.get('hasCustomQuotePendingApproval')) {
        controller.set('showAssembly', false);
        controller.set('showFabrication', false);
        controller.set('showParts', false);
        controller.set('showCustomQuote', false);
        controller.set('customQuoteTouched', true);
        controller.set('quoteLocked', true);
      }
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('assemblyPricing', null);
        controller.set('fabricationPricing', null);
        controller.set('bom', null);
      }
    },

    openLastActiveSection: function openLastActiveSection(controller, project) {
      var quoteSections = ['projectInformation', 'parts', 'assembly', 'fabrication', 'customQuote', 'pricing'];
      var sectionLastTouched = project.get('clientState') || 'projectInformation';
      if (quoteSections.contains(sectionLastTouched) && sectionLastTouched !== 'pricing') {
        controller.set(sectionLastTouched + 'Touched', true);
        controller.set('show' + sectionLastTouched.capitalize(), true);
        controller.set('allCollapsed', false);
      } else {
        if (!project.get('isCustomQuote')) {
          _ember['default'].run.schedule('afterRender', this, function () {
            controller.send('scrollToSnippet', 'pricing');
          });
        } else {
          controller.set('customQuoteTouched', true);
          controller.set('showCustomQuote', true);
          _ember['default'].run.schedule('afterRender', this, function () {
            controller.send('scrollToSnippet', 'customQuote');
          });
        }
      }
    },

    setPageState: function setPageState(controller, project) {
      var assemblyStatus = project.get('assemblyIncluded');
      var fabStatus = project.get('fabIncluded');
      var partsStatus = project.get('partsIncluded');
      var statuses = { 'assembly': assemblyStatus, 'fabrication': fabStatus, 'parts': partsStatus };
      controller.set('assemblyError', false);
      controller.set('fabricationError', false);
      controller.set('showFooterError', false);

      Object.keys(statuses).forEach(function (key) {
        var status = statuses[key];

        switch (status) {
          case "True":
            controller.set(key + 'Touched', true);
            controller.set(key + 'Edit', true);
            break;
          case "False":
            controller.set(key + 'Touched', true);
            controller.set('show' + key.capitalize(), false);
            break;
          case "Unknown":
            controller.set(key + 'Touched', false);
            break;
          default:
            controller.set(key + 'Touched', false);
        }
      });

      // allCollapsed true by default, so footer will not jump around. If openLastActiveSection is used, allCollapsed will be set to false.
      controller.set('allCollapsed', true);
      controller.set('projectInformationTouched', true);
      controller.set('assemblyTouched', assemblyStatus === "True" && partsStatus === "True");
      controller.set('showAssembly', false);
      controller.set('quoteLocked', false);

      this.openLastActiveSection(controller, project);
    },

    renderTemplate: function renderTemplate() {
      this.render('quoting', {
        controller: 'quoting'
      });

      this.render('quoting.footer', {
        outlet: 'footer',
        into: 'application'
      });
    },

    findAndSetAssembly: function findAndSetAssembly(controller, projectGUID) {
      var applicationController = controller.get('applicationController');
      var assemblyPricingController = controller.get('assemblyPricingController');
      var assemblyPromise = this.store.findRecord('quoteAssembly', projectGUID);
      var newAssemblyPricingObject = assemblyPricingController.createNewQuoteAssemblyPricingObject(projectGUID);

      assemblyPromise.then(function (record) {
        controller.set('assemblyPricing', applicationController.updateChangedValues(newAssemblyPricingObject, record));
      })['catch'](function () {
        console.log('failed');
        controller.set('assemblyPricing', newAssemblyPricingObject);
      });
    },

    findAndSetFabrication: function findAndSetFabrication(controller, projectGUID) {
      var store = this.store;
      var applicationController = controller.get('applicationController');
      var fabricationPricingController = controller.get('fabricationPricingController');
      var newFabricationPricingObject = fabricationPricingController.createNewQuoteFabricationPricingObject(projectGUID);

      store.findRecord('quoteFabrication', projectGUID, { reload: true }).then(function (record) {
        controller.set('fabricationPricing', applicationController.updateChangedValues(newFabricationPricingObject, record));
      })['catch'](function (errors) {
        console.log('fabPromise.catch', errors);
        controller.set('fabricationPricing', newFabricationPricingObject);
      }).then(function () {
        newFabricationPricingObject.set('quoteAssemblyPricing', store.peekRecord('quoteAssemblyPricing', projectGUID));
      });
    },

    findAndSetBom: function findAndSetBom(controller, projectGUID) {
      var store = this.store;
      // store.unloadAll('bom');
      // store.unloadAll('bom-line');
      store.findRecord('bom', projectGUID, { reload: true }).then(function (bom) {
        controller.set('bom', bom);
        controller.set('partsEdit', true);
      })['catch'](function (errors) {
        console.log('BOM NOT FOUND!', errors);
        controller.set('bom', null);
      });
    },

    findAndSetProjectFile: function findAndSetProjectFile(controller, projectGUID) {
      var store = this.store;
      store.findRecord('project-file', projectGUID).then(function (file) {
        controller.set('projectFile', file);
      })['catch'](function (errors) {
        console.log('No Project File has been added for custom quote', errors);
      });
    }
  });
});