define("web-app-frontend/helpers/keep-together", ["exports", "ember"], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

  //Used to keep sets of like information together on the same line by replacing white space around
  //a joining word with non-breaking spaces. For example, ("Shipped 2 of 7", "of") will be returned as
  //"Shipped 2&nbsp;of&nbsp;97"  
  exports["default"] = _ember["default"].Helper.helper(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var text = _ref2[0];
    var joiner = _ref2[1];

    if (text && joiner) {
      var exp = new RegExp("\\s" + joiner + "\\s", "g");
      return new _ember["default"].Handlebars.SafeString(text.replace(exp, '&nbsp;' + joiner + '&nbsp;'));
    }
  });
});