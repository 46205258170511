define('web-app-frontend/components/bom-file-upload', ['exports', 'web-app-frontend/config/environment', 'web-app-frontend/components/file-upload'], function (exports, _webAppFrontendConfigEnvironment, _webAppFrontendComponentsFileUpload) {
  exports['default'] = _webAppFrontendComponentsFileUpload['default'].extend({
    // OBSERVERS
    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    filesDidChange: (function () {
      // File Upload Begins Here.
      this.prepareToUploadProjectBom();
      console.log('fake input focus');
      $("#fake-file-input").first().focus();
    }).observes('files'),

    // METHODS
    bomParseStatus: function bomParseStatus(projectGUID, bomGUID, progress) {
      var self = this;
      //start ajax call to GET /api/project/{projectguid}/bomupload/{bomguid} to compare totNumOfLines === countComplete
      //If that's true, return true as the parsing is done, else add self to set interval again to recheck in x seconds.
      //On error, TODO
      var oauthToken = this.fetchOauthToken();

      var statusUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/bomupload/' + bomGUID + '?Api-Key=SCWEB&lastBOMStatusReceived=0';
      statusUrl = statusUrl + '&SessionGUID=' + $.cookie('sessionGUID');

      $.ajax({
        url: statusUrl,
        type: 'GET',
        dataType: 'json',
        headers: oauthToken
      }).done(function (data) {
        progress.set('maxLines', data.bomProcessStatus.totNumOfLines);
        progress.set('linesRead', data.bomProcessStatus.countComplete);

        if (self.finishedParsing(data)) {
          //POST /api/project/bom/{bomguid} to associate the parsed Bom to the project,
          //then the response there will have the bomLines in the response
          self.postParsedBomToAssociateWithProject(projectGUID, bomGUID);
        } else {
          window.setTimeout(self.bomParseStatus(projectGUID, bomGUID, progress), 500);
        }
      }).fail(function (data) {
        //1st, 2nd and 3rd params are data, textStatus and errorThrown if needed
        self.showParsingSpinner(false);
        self.showErrorAlert(data);
      });
    },

    showErrorAlert: function showErrorAlert(data) {
      var message = data.responseJSON.model;
      this.openBOMModal(this.get('component'), "quoting.bom-error-alert", { message: message });
    },

    fileHasMultipleWorksheets: function fileHasMultipleWorksheets(data) {
      return data.partsFileUploadModel.validWorksheetNames !== null && data.partsFileUploadModel.validWorksheetNames.data !== null;
    },

    fileIsCorrectFormat: function fileIsCorrectFormat() {
      var files = this.get('files');
      var extension = this.getFileExtension(files[0].name);
      switch (extension.toLowerCase()) {
        case 'xls':
        case 'xlsx':
          return true;
      }
      return false;
    },

    finishedParsing: function finishedParsing(data) {
      return data.bomProcessStatus.totNumOfLines === data.bomProcessStatus.countComplete;
    },

    postBomToServer: function postBomToServer(uploadUrl, oauthToken, formData, store) {
      var self = this;
      var parentComponent = this.get('component');
      var projectGUID = this.get('project').get('id');
      var isKittedBom = this.get('isUserSourcingBom');
      var progress = store.createRecord('bom-progress', { id: 1, linesRead: 0, maxLines: 0 });

      $.ajax({
        url: uploadUrl,
        type: 'POST',
        headers: oauthToken,
        data: formData,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false }). // Set content type to false as jQuery will tell the server its a query string request
      done(function (data) {
        //check data, if data.partsFileUploadModel == null, then start checking progress of file parse,
        //else ask user which worksheet name to use for parse and PUT /api/project/{projectguid}/bomupload and then
        //start checking progress at GET /api/project/{projectguid}/bomupload/{bomguid}
        //When totNomOfLines === countComplete hit the url POST /api/project/bom/{bomguid} to associate the uploaded BOM
        //to the project.
        var bomGUID = data.partsFileUploadModel.bomGUID;
        var fileBOMName = data.partsFileUploadModel.fileBOMName;
        if (self.fileHasMultipleWorksheets(data)) {
          //With multiple worksheets we need to ask which one to has the parts on it.
          self.showWorkSheetSelectionModal(projectGUID, bomGUID, fileBOMName, progress, data, isKittedBom);
        } else {
          self.showBomLoadingModal(parentComponent, progress);
          //else we need to PUT /api/project/{projectguid}/bomupload
          self.showParsingSpinner(true);
          window.setTimeout(self.bomParseStatus(projectGUID, bomGUID, progress), 500);
        }
      }).fail(function (data) {
        //available params are data, textStatus and errorThrown if needed
        self.showBomLoadingModal(parentComponent, progress);
        window.setTimeout(self.bomParseStatus(projectGUID, data.partsFileUploadModel.bomGUID, progress), 500);
      });
    },

    prepareToUploadProjectBom: function prepareToUploadProjectBom() {
      if (this.fileIsCorrectFormat() && this.fileIsNotTooLarge()) {
        var parentComponent = this.get('component');
        var store = parentComponent.get('targetObject.store');
        var oauthToken = this.fetchOauthToken();
        var uploadUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + this.get('project').get('id') + '/bomupload?Api-Key=SCWEB';
        uploadUrl = uploadUrl + "&isKittedBom=" + this.get('isUserSourcingBom') + '&SessionGUID=' + $.cookie('sessionGUID');

        var files = this.get('files');
        var formData = new FormData();
        formData.append('file', files[0]);

        this.showUploadingSpinner(true);

        // Send filename to parent component to update text
        this.sendAction('updateUploadText', files[0].name);
        this.unloadStaleBomRecords(store);
        this.postBomToServer(uploadUrl, oauthToken, formData, store);
      } else {
        this.get('component').sendAction('openModal', 'modals/alert-modal', { text: 'File Error.  Files must be in either .xls or .xlsx formats & no larger than 100mb.' });
      }
    },

    showBomLoadingModal: function showBomLoadingModal(parentComponent, progress) {
      this.openBOMModal(parentComponent, "quoting.bom-loading-modal", progress);
    },

    showUploadingSpinner: function showUploadingSpinner(status) {
      this.hideSpinners();
      this.get('component').set('showSpinner', status);
    },

    showParsingSpinner: function showParsingSpinner(status) {
      this.hideSpinners();
      this.get('component').set('showParsingSpinner', status);
    },

    hideSpinners: function hideSpinners() {
      this.get('component').set('showParsingSpinner', false);
      this.get('component').set('showSpinner', false);
    },

    showWorkSheetSelectionModal: function showWorkSheetSelectionModal(projectGUID, bomGUID, fileBOMName, progress, data, isKittedBom) {
      this.openBOMModal(this.get('component'), 'quoting.worksheet-selection', {
        worksheetNames: data.partsFileUploadModel.validWorksheetNames.data,
        projectGUID: projectGUID,
        bomGUID: bomGUID,
        fileBOMName: fileBOMName,
        progress: progress,
        fileUpload: this,
        isKittedBom: isKittedBom
      });
    },

    unloadStaleBomRecords: function unloadStaleBomRecords(store) {
      //Get rid of stale progress records or records in need of replace.  Pretty heavy handed...
      store.unloadAll('bom-progress');
      store.unloadAll('bom-line');
      store.unloadAll('bom');
    },

    postParsedBomToAssociateWithProject: function postParsedBomToAssociateWithProject(projectGUID, bomGUID) {
      var self = this;
      var parentComponent = this.get('component');
      var oauthToken = this.fetchOauthToken();
      var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/bom/' + bomGUID + '?Api-Key=SCWEB' + '&isKittedBom=' + this.get('isUserSourcingBom');

      //start ajax call to POST /api/project/bom/{bomguid} to associate the parsed Bom to the project
      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        headers: oauthToken
      }).done(function (data) {
        self.hideSpinners();
        self.closeBOMModal(parentComponent, "quoting.bom-loading-modal");
        self.buildBomAndLineModels(data);
      }).fail(function () {
        //1st, 2nd and 3rd params are data, textStatus and errorThrown if needed
        self.hideSpinners();
      });
    },

    getSourcedPartsTotal: function getSourcedPartsTotal(bomLines, attribute) {
      var attrArray = bomLines.filterBy('sourcingAction', 'Purchase').mapBy(attribute);
      if (attrArray.length > 0) {
        return attrArray.reduce(function (a, b) {
          return a + b;
        });
      } else {
        return 0;
      }
    },

    buildBomAndLineModels: function buildBomAndLineModels(data) {

      //place where we read data from backend repsonse and store it in ember data
      var self = this;
      var project = this.get('project');
      var parentComponent = this.get('component');
      var store = parentComponent.get('targetObject.store');

      //create BomLines models from response, open the bom modal
      var bomLines;
      if (data.projectBOMModel.allBomLines) {
        bomLines = data.projectBOMModel.allBomLines.data;
      } else {
        bomLines = [];
      }

      var lowSuccessWarning = data.projectBOMModel.lowSuccessWarning;
      var allBomLines = [];
      var bom;

      var itemNumbersPresent = !bomLines.mapBy('itemNumber').contains(0);

      bomLines.forEach(function (line) {
        allBomLines.push(self.createBomLine(store, line));
      });

      var storedBomLines = allBomLines.filterBy('bmlIsSelected', true);

      bom = this.createBomRecord(store, allBomLines, project, data);

      this.openBOMModal(parentComponent, "quoting.bom-modal", { storedBomLines: storedBomLines,
        bom: bom,
        project: project,
        newBom: true,
        itemNumbersPresent: itemNumbersPresent,
        lowSuccessWarning: lowSuccessWarning
      });
    },

    createBomLine: function createBomLine(store, line) {
      var project = this.get('project');
      var bomLine = store.createRecord('bomLine', line);
      bomLine.setProperties({ id: line.bmlId,
        manufactureNumber: line.resultReferenceData.manufactureNumber,
        manufactureName: line.resultReferenceData.manufactureName,
        purchasedPartNumberOverride: line.resultReferenceData.purchasedPartNumberOverride,
        partDescription: line.resultReferenceData.partDescription,
        partImageURL: line.resultReferenceData.partImageURL,
        partImageURLssl: line.resultReferenceData.partImageURLssl,
        vendorPartNumber: line.resultReferenceData.vendorPartNumber,
        sourceVendor: line.resultReferenceData.sourceVendor,
        quantityValidation: line.resultReferenceData.quantityValidation,
        project: project
      });
      return bomLine;
    },

    createBomRecord: function createBomRecord(store, storedBomLines, project, data) {
      // remove bomLine reference from data object.
      delete data.projectBOMModel.bomLines;
      var bom = store.createRecord('bom', data.projectBOMModel);
      bom.set('id', project.get('id'));
      bom.set('project', project);
      bom.get('bomLines').addObjects(storedBomLines);
      bom.setProperties({ partsTotal: this.getSourcedPartsTotal(storedBomLines, 'quotedLineTotalPrice'),
        partCostPerBoard: this.getSourcedPartsTotal(storedBomLines, 'calculatedLinePerBoardPrice'),
        partsCount: this.getSourcedPartsTotal(storedBomLines, 'placementsPerBoard'),
        quotedBoardCount: storedBomLines.get('firstObject.quotedBoardCount')
      });
      return bom;
    }
  });
});