define('web-app-frontend/serializers/user-billing-address', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, typeClass, payload, id) {
      var billingAddress = payload.soldToAddress;
      billingAddress.id = id;
      payload = { userBillingAddress: billingAddress };
      return this._super(store, typeClass, payload, id);
    },

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, typeClass, payload, id) {
      var baseAddress = store.peekRecord('user-billing-address', id);
      var cloneAddress = baseAddress.findMatchingRecord();
      payload.soldToAddress.id = cloneAddress.get('requestID');

      return this._super(store, typeClass, payload);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      var billingAddress = payload.soldToAddress;
      // delete billingAddress.type;
      billingAddress.id = id;
      billingAddress.userGUID = id;
      payload = { userBillingAddress: billingAddress };

      return this._super(store, typeClass, payload);
    }

  });
});