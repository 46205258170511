define('web-app-frontend/routes/about/history', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'about',
    titleToken: 'History',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Screaming Circuits has been providing prototype and on-demand electronics manufacturing services since 2003.'
      }
    }],

    setupController: function setupController(controller) {
      controller.set('indexName', 'history');
    }
  });
});