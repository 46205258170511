define("web-app-frontend/components/order-kit", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    toggleComponent: "toggleComponent",
    collapse: "collapse",
    close: "close",
    setClientStateOnProject: "setClientStateOnProject",
    setSectionErrorState: "setSectionErrorState",
    openModal: "openModal",

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get("attrs.register-as")) {
        this.get("attrs.register-as.update")(this);
      }
    }).on("didInitAttrs"),

    isInitialized: false,
    openConfirmAttritionModal: (function () {
      if (this.get("isInitialized") && this.model.get("acceptAttritionPolicy")) {
        this.sendAction("openModal", "order.confirm-attrition-modal", this.model);
      }
      this.set("isInitialized", true);
    }).observes("model.acceptAttritionPolicy"),

    actions: {
      openModal: function openModal(controller, model) {
        this.sendAction("openModal", controller, model);
      },

      addBox: function addBox() {
        var kitBox = this.get("targetObject.store").createRecord("kitBox");
        this.get("model.kitBoxes").pushObject(kitBox);
        return kitBox;
      },

      performAction: function performAction(action, actionParam) {
        this.sendAction("setClientStateOnProject", actionParam);
        this.sendAction(action, actionParam);
      },

      saveAndPerformAction: function saveAndPerformAction(action, actionParam) {
        var orderKit = this.model;
        var self = this;
        var quoteSubmitButton = this.get("quoteSubmitButton");

        orderKit.setupForCommit();
        orderKit.get("kitBoxes").forEach(function (box) {
          box.setupForCommit();
        });
        orderKit.formatDateForSave();
        orderKit.save().then(function () {
          self.send("performAction", action, actionParam);
          //Trigger timeline reload
          self.set("reloadTimeLine", true);
        })["catch"](function (errors) {
          console.log("saveAndPerformAction Failed", errors);
        })["finally"](function () {
          quoteSubmitButton.send("canSubmit");
          orderKit.set("commitOnSuccess", false);
        });
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction("setSectionErrorState", sectionErrorStateName, state);
      },

      validateOrderKit: function validateOrderKit() {
        var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        var self = this;
        var orderKit = this.model;
        orderKit.generateHash();

        var matchingRecord = orderKit.findMatchingRecord();
        if (matchingRecord) {
          orderKit.get("kitBoxes").forEach(function (box) {
            console.log(box.get("id"));
          });
          orderKit.mapMatchToBaseRecord(matchingRecord, true);
        } else {
          var cacheCopy = orderKit.createCacheCopy(true);
          cacheCopy.alterAttributes(all);
          cacheCopy.formatDateForSave();
          cacheCopy.save().then(function () {
            self.send("setSectionErrorState", "kitError", false);
          })["catch"](function (errors) {
            self.send("setSectionErrorState", "kitError", true);
            console.log("validateOrderKit failed", errors);
          })["finally"](function () {
            orderKit.mapFromCopyOnRequestFulfillment(cacheCopy, true);
          });
        }
        return false;
      }
    }
  });
});