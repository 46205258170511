define('web-app-frontend/models/fabrication-turn-time', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    additionalBoardQuantity: _emberData['default'].attr('number'),
    assemblyServiceName: _emberData['default'].attr('string'),
    copperWeight: _emberData['default'].attr('string'),
    countOfHoles: _emberData['default'].attr('number'),
    customCutoffHour: _emberData['default'].attr('number'),
    dateCode: _emberData['default'].attr('boolean'),
    edgeLengthOfGoldFingers: _emberData['default'].attr('number'),
    electricalTest: _emberData['default'].attr('boolean', { defaultValue: true }),
    estimatedShipDate: _emberData['default'].attr('string'),
    expedite: _emberData['default'].attr('string'),
    hasDesignViolations: _emberData['default'].attr('boolean', { defaultValue: true }),
    height: _emberData['default'].attr('number'),
    isReorder: _emberData['default'].attr('boolean'),
    isITAR: _emberData['default'].attr('boolean'),
    invalidatedQuoteTotal: _emberData['default'].attr('number'),
    lastQuoteDate: _emberData['default'].attr('string'),
    layers: _emberData['default'].attr('string'),
    lotCode: _emberData['default'].attr('boolean'),
    minTraceAndSpace: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    quoteTotal: _emberData['default'].attr('number'),
    referralServicePartnerCode: _emberData['default'].attr('string'),
    routing: _emberData['default'].attr('string'),
    serviceType: _emberData['default'].attr('string'),
    silkscreenColor: _emberData['default'].attr('string'),
    silkscreenSides: _emberData['default'].attr('string'),
    smallestHoleSize: _emberData['default'].attr('string'),
    slotsOrCutoutsPerBoard: _emberData['default'].attr('number'),
    solderMaskColor: _emberData['default'].attr('string'),
    surfaceFinish: _emberData['default'].attr('string'),
    thickness: _emberData['default'].attr('string'),
    turnTime: _emberData['default'].attr('string'),
    turnDaysDisplay: _emberData['default'].attr('string'),
    ulCertification: _emberData['default'].attr('boolean'),
    width: _emberData['default'].attr('number'),
    validationStatus: _emberData['default'].attr('string'),
    perBoardPrice: _emberData['default'].attr('number', { defaultValue: 0 }),

    engineering: _emberData['default'].attr('string'),
    material: _emberData['default'].attr('string'),
    solderMaskSides: _emberData['default'].attr('string'),
    silkScreenClipping: _emberData['default'].attr('boolean'),
    inspection: _emberData['default'].attr('string'),
    goldFingerSides: _emberData['default'].attr('number'),
    bevel: _emberData['default'].attr('string'),
    crossSectionRpt: _emberData['default'].attr('boolean'),
    platedSlots: _emberData['default'].attr('number'),
    platedCutouts: _emberData['default'].attr('number'),
    materialOther: _emberData['default'].attr('string'),
    counterSink: _emberData['default'].attr('number'),
    counterBore: _emberData['default'].attr('number'),
    viaPlug: _emberData['default'].attr('boolean'),
    fileConvert: _emberData['default'].attr('boolean'),
    platedEdges: _emberData['default'].attr('boolean'),
    blindVias: _emberData['default'].attr('boolean'),
    castelHoles: _emberData['default'].attr('boolean'),
    contrldImp: _emberData['default'].attr('boolean'),
    contrldImpList: _emberData['default'].attr('string'),
    serialize: _emberData['default'].attr('boolean'),
    fai: _emberData['default'].attr('string'),
    cov19Critical: _emberData['default'].attr('boolean'),
    logo: _emberData['default'].attr('boolean'),
    panelApprove: _emberData['default'].attr('boolean'),
    contDepthMill: _emberData['default'].attr('boolean'),
    dpasPriority: _emberData['default'].attr('boolean'),
    layersOther: _emberData['default'].attr('number'),
    thicknessOther: _emberData['default'].attr('number'),
    copperWeightOther: _emberData['default'].attr('string'),
    innerTrace: _emberData['default'].attr('string'),
    innerTraceOther: _emberData['default'].attr('string'),
    outerTraceOther: _emberData['default'].attr('string'),
    smallestDrillOther: _emberData['default'].attr('string'),
    inspectionOther: _emberData['default'].attr('string'),
    finishOther: _emberData['default'].attr('string'),
    stdMLConstruction: _emberData['default'].attr('boolean'),
    customFabNotes: _emberData['default'].attr('string'),
    customFabQuote: _emberData['default'].attr('boolean', { defaultValue: false }),
    fabNotes: _emberData['default'].attr('string'),
    cageCode: _emberData['default'].attr('boolean'),
    coC: _emberData['default'].attr('boolean'),

    // Associations
    designViolations: _emberData['default'].hasMany('design-violation', { polymorphic: true, async: false }),

    turnQuote: _emberData['default'].belongsTo('quote-fabrication-pricing', { async: true, inverse: 'fabTurnTimes' }),

    violationFree: function violationFree() {
      return this.get('hasDesignViolations') === false;
    }
  });
});