define('web-app-frontend/models/order-kit', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    //'boxes' attr only exists to receive errors back from the API, for it could not return errors on individual kit-boxes
    boxes: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    kitArrivalDate: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'), //to use as 'id' when making requests on cached records
    acceptAttritionPolicy: _emberData['default'].attr('boolean'),

    // Associations
    kitBoxes: _emberData['default'].hasMany('kit-box', { async: false }),

    keysToSerialize: ["kitArrivalDate", "acceptAttritionPolicy"],

    boxError: (function () {
      return this.get('errors.boxes.firstObject.message');
    }).property('errors.boxes.firstObject'),

    displayArrivalDate: (function () {
      var kitArrivalDate = this.get('kitArrivalDate');
      if (kitArrivalDate) {
        return moment(this.get('kitArrivalDate')).format('MMMM DD, YYYY');
      } else {
        return "No date selected.";
      }
    }).property('kitArrivalDate'),

    formatDateForSave: function formatDateForSave() {
      //from the format used for the datepicker
      var serverFriendlyDate = moment(this.get('kitArrivalDate')).format('MM/DD/YYYY');
      this.set('kitArrivalDate', serverFriendlyDate);
    },

    firstValidDate: function firstValidDate() {
      var nextMonday;
      var today = moment().toDate(); //today
      var numericDayOfWeek = moment().day(); //0-6
      var isSat = numericDayOfWeek === 6;
      var isSun = numericDayOfWeek === 0;
      var isWeekend = isSat || isSun;
      if (isWeekend) {
        nextMonday = isSat ? moment().weekday(-1).weekday(8) : moment().weekday(1);
      }
      return isWeekend ? moment(nextMonday).format() : moment(today).format();
    },

    generateHash: function generateHash() {
      var boxHash;
      var kitHash = this._super();

      this.get('kitBoxes').forEach(function (box) {
        boxHash = box.generateHash();
        kitHash += '_' + boxHash;
      });
      this.set('hashed', kitHash);
    },

    uploadDateHasPassed: function uploadDateHasPassed() {
      return moment().diff(moment(this.get('kitArrivalDate')), 'days') > 0;
    }
  });
});