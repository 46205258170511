define('web-app-frontend/adapters/assembly-price-difference', ['exports', 'web-app-frontend/adapters/quoting-parent'], function (exports, _webAppFrontendAdaptersQuotingParent) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    generateIdForRecord: function generateIdForRecord(store) {
      var id = store.peekAll('assembly-price-difference').get('length') + 1;
      return id;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/assembly/pricing';
      return this._super(url);
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('projectGUID');
      var payload = { assemblyPricing: record };
      return this.ajax(this.buildURL(type.modelName, id), "POST", { data: payload });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      return this.createRecord(store, type, snapshot);
    }

  });
});
// we are kind of stuck with the ajaxSuccess handler here until we re-work error handling...
/*
  ajaxSuccess: function(jqXHR, jsonPayload) {
    return this._super(jqXHR, jsonPayload);
  }
*/