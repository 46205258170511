define("web-app-frontend/templates/components/robot-sidebar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/robot-sidebar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bg-lightest-grey");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "padded");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        var el4 = dom.createTextNode("Helpful Resources");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("		\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h6");
        dom.setAttribute(el3, "class", "mt20");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "dark-gray");
        var el4 = dom.createTextNode("Learn more about what we can do for you.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h6");
        dom.setAttribute(el3, "class", "mt20");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "dark-gray");
        var el4 = dom.createTextNode("Learn how we work - Our process ensures a consistent, quality product.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h6");
        dom.setAttribute(el3, "class", "mt20");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "dark-gray");
        var el4 = dom.createTextNode("What we need from you so that we can build your boards the way you want them.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h6");
        dom.setAttribute(el3, "class", "mt20");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "dark-gray");
        var el4 = dom.createTextNode("Answers about the order process, our requirements and what we’ll need to fulfill your order.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "mt40");
        var el4 = dom.createTextNode("Downloads");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "mt10");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://i.screamingcircuits.com/docs/SC_Kit_Checklist.pdf");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createTextNode("Pre-Order Checklist");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "mt10");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://i.screamingcircuits.com/docs/SC_Sample_BOM.xls");
        dom.setAttribute(el4, "target", "_blank");
        var el5 = dom.createTextNode("Sample Bill of Materials");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n		\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element0, 3, 3);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [13]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [17]), 0, 0);
        morphs[5] = dom.createMorphAt(element0, 28, 28);
        return morphs;
      },
      statements: [["inline", "link-to", ["More at the Resource Center >", "resource-center"], ["class", "small"], ["loc", [null, [13, 2], [13, 77]]]], ["inline", "link-to", ["Services and Capabilities", "resource-center.services.index"], [], ["loc", [null, [15, 19], [15, 91]]]], ["inline", "link-to", ["Our Process", "resource-center.process.index"], [], ["loc", [null, [18, 19], [18, 76]]]], ["inline", "link-to", ["Terms and Requirements", "resource-center.terms-and-conditions"], [], ["loc", [null, [21, 19], [21, 94]]]], ["inline", "link-to", ["FAQ", "resource-center.faq"], [], ["loc", [null, [24, 19], [24, 58]]]], ["content", "yield", ["loc", [null, [36, 4], [36, 13]]]]],
      locals: [],
      templates: []
    };
  })());
});