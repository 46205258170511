define('web-app-frontend/models/project-billing', ['exports', 'ember', 'ember-data', 'web-app-frontend/mixins/credit-card'], function (exports, _ember, _emberData, _webAppFrontendMixinsCreditCard) {
  exports['default'] = _emberData['default'].Model.extend(_webAppFrontendMixinsCreditCard['default'], {
    cardOnFile: _emberData['default'].attr('boolean', { defaultValue: false }),
    cardOnFileNumberObfuscated: _emberData['default'].attr('string'),
    cardOnFileType: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    country: _emberData['default'].attr('string', { defaultValue: 'USA' }),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    creditCardExpirationMonth: _emberData['default'].attr('number'),
    creditCardExpirationYear: _emberData['default'].attr('number'),
    creditCardNumberObfuscated: _emberData['default'].attr('string'),
    rememberThisCard: _emberData['default'].attr('boolean', { defaultValue: true }),
    showRememberThisCard: false,
    creditCardNumberPlain: _emberData['default'].attr('string'),
    creditCardType: _emberData['default'].attr('string'),
    nameOnCard: _emberData['default'].attr('string'),
    newCard: _emberData['default'].attr('boolean', { defaultValue: false }),
    state: _emberData['default'].attr('string'),
    streetAddress1: _emberData['default'].attr('string'),
    streetAddress2: _emberData['default'].attr('string'),
    streetAddress3: _emberData['default'].attr('string'),
    paymentType: _emberData['default'].attr('string'),
    postalCode: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'),
    province: _emberData['default'].attr('string'),
    purchaseOrderNumber: _emberData['default'].attr('string'),
    rememberPayment: _emberData['default'].attr('boolean', { defaultValue: false }),
    isCurrentCreditCardOnUserProfile: _emberData['default'].attr('boolean', { defaultValue: false }),
    availableCards: _emberData['default'].attr('array'),
    selectedPaymentId: _emberData['default'].attr('string'),
    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),

    isCreditCardPayment: _ember['default'].computed.equal('paymentType', 'CreditCard'),
    isCustomPayment: _ember['default'].computed.equal('paymentType', 'Other'),

    setShowRememberThisCard: (function () {
      this.set('showRememberThisCard', this.get('isCurrentCreditCardOnUserProfile') && !this.get('newCard'));
    }).observes('isCurrentCreditCardOnUserProfile', 'newCard'),

    clearCardDetails: function clearCardDetails() {
      this.set('nameOnCard', '');
      this.set('creditCardNumberPlain', '');
      this.set('creditCardType', '');
      this.set('creditCardExpirationMonth', moment().month() + 1);
      this.set('creditCardExpirationYear', moment().year());
    },

    //card expiration errors for the credit card on file with the user account
    hasExpDateError: (function () {
      return !this.get('newCard') && (this.get('errors').has('creditCardExpirationMonth') || this.get('errors').has('creditCardExpirationYear'));
    }).property('newCard', 'errors.length'),

    toggleUseCreditCardOnFile: (function () {
      if (this.get('newCard')) {
        this.clearCardDetails();
      } else if (!this.get('newCard') && this.get('hasDirtyAttributes')) {
        //reload won't take if record is in dirty state
        this.rollbackAttributes();
        this.reload().then(function (model) {
          // do something with the reloaded model
          model.set('paymentType', 'CreditCard');
        });
        //this.set('paymentType', 'CreditCard');
      }
    }).observes('newCard'),

    togglePaymentType: (function () {
      if (this.get('hasDirtyAttributes') && this.get('paymentType') === 'Other') {
        //reload won't take if record is in dirty state
        this.rollbackAttributes();
        this.reload().then(function (model) {
          // do something with the reloaded model
          model.set('paymentType', 'Other');
        });
        //this.set('paymentType', 'Other');
      }
    }).observes('paymentType')
  });
});