define('web-app-frontend/components/project-title', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['project-title', 'ib'],
    hover: false,
    openModal: 'openModal',

    mouseEnter: function mouseEnter() {
      if (_ember['default'].$(window).width() > 960) {
        this.set('hover', true);
      }
    },
    mouseLeave: function mouseLeave() {
      if (_ember['default'].$(window).width() > 960) {
        this.set('hover', false);
      }
    },

    actions: {
      openModal: function openModal(template, model) {
        this.sendAction('openModal', template, model);
      }
    }
  });
});