define('web-app-frontend/adapters/user-billing-address', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL(typeClass, userGUID) {
      var url = "user/" + userGUID + "/soldtoaddress";
      return this._super(url);
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var userGUID = snapshot.attr('userGUID');
      var payload = { soldToAddress: snapshot.record };

      return this.ajax(this.buildURL(type.modelName, userGUID), "PUT", { data: payload });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var userGUID = snapshot.attr('userGUID');
      var payload = { soldToAddress: snapshot.record };

      return this.ajax(this.buildURL(type.modelName, userGUID), "POST", { data: payload });
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    },

    ajax: function ajax(url, type, hash) {
      if (type !== 'GET') {
        url = url + "?Api-Key=SCWEB";
        return this._super(url, type, hash);
      } else {
        return this._super(url, type, hash);
      }
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.soldToAddress.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.soldToAddress.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});