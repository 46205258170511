define("web-app-frontend/templates/components/turn-time-selection", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 2
            }
          },
          "moduleName": "web-app-frontend/templates/components/turn-time-selection.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "small");
          var el2 = dom.createElement("span");
          dom.setAttribute(el2, "class", "bold");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(":");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [0]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["inline", "format-turn-time", [["get", "turnTime", ["loc", [null, [3, 59], [3, 67]]]]], [], ["loc", [null, [3, 40], [3, 69]]]], ["inline", "format-value", [["get", "turnTimeRecord.quoteTotal", ["loc", [null, [3, 93], [3, 118]]]]], ["currency", true, "zeroAsNull", true, "defaultWhenNull", ["subexpr", "@mut", [["get", "defaultValueForQuoteTotal", ["loc", [null, [3, 165], [3, 190]]]]], [], []], "currencySign", true], ["loc", [null, [3, 78], [3, 210]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/turn-time-selection.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element1, 'class');
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["radio ", ["subexpr", "if", [["get", "isUnavailableTurnTime", ["loc", [null, [1, 23], [1, 44]]]], "disabled"], [], ["loc", [null, [1, 18], [1, 57]]]]]]], ["block", "radio-button", [], ["name", ["subexpr", "@mut", [["get", "quoteFormSection", ["loc", [null, [2, 23], [2, 39]]]]], [], []], "value", ["subexpr", "@mut", [["get", "turnTime", ["loc", [null, [2, 46], [2, 54]]]]], [], []], "groupValue", ["subexpr", "@mut", [["get", "groupValue", ["loc", [null, [2, 66], [2, 76]]]]], [], []], "changed", "closeAndSave", "disabled", ["subexpr", "@mut", [["get", "isUnavailableTurnTime", ["loc", [null, [2, 109], [2, 130]]]]], [], []]], 0, null, ["loc", [null, [2, 2], [4, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});