define('web-app-frontend/serializers/quote', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeQueryResponse: function normalizeQueryResponse(store, typeClass, payload) {
      var quotes = payload.userQuote.data;
      var hasMoreData = payload.userQuote.hasMoreData;

      quotes.forEach(function (quote) {
        quote.projectGUID = quote.id;
        quote.project = quote.id;
      });

      payload = { quote: quotes, meta: { hasMoreData: hasMoreData } };
      return this._super(store, typeClass, payload);
    },

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, typeClass, payload) {
      //this normalize method is being called from the userSession serializer, so typeClass must be explicity set
      typeClass = store.modelFor('quote');
      var quote = payload;
      quote.projectGUID = quote.id;
      payload = { quote: payload };
      return this._super(store, typeClass, payload);
    }
  });
});