define('web-app-frontend/controllers/user/account/credit-card-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    billingController: _ember['default'].inject.controller('user/account/billing'),

    actions: {
      updateCreditCard: function updateCreditCard(creditCard) {
        var self = this;
        var billingController = this.get('billingController');
        var submitBtnComponent = this.get('submitButton');
        if (!this.get('isEdit')) {
          //number isn't editable in edit mode
          creditCard.setCreditCardType();
        }
        creditCard.setupForCommit();

        //in the case of the CreditCard endpoint, once the form fields are all validated via focus out,
        //the success response causes the record to be placed in the 'loaded.saved' state, and then submitting the data a
        //final time with commitOnSuccess set to true occurs via a PUT, not a POST. PUT is used differently than POST for
        //a user credit card (requires an ID), so in order to ensure a POST for this request, the save must be initiated
        //from an uncommitted state
        if (!creditCard.id) {
          creditCard.transitionTo('loaded.created.uncommitted');
        }
        creditCard.save().then(function () {
          billingController.set('creditCardOnFile', true);
          submitBtnComponent.send('hasSucceeded');
          self.send('closeModal');
        })['catch'](function (errors) {
          console.log(errors);
          submitBtnComponent.send('showError');
        });
      },

      validateModel: function validateModel() {
        console.log('validating!');
        var creditCard = this.model;
        if (!this.get('isEdit')) {
          //number isn't editable in edit mode
          creditCard.setCreditCardType();
        }
        creditCard.set('commitOnSuccess', false);
        creditCard.alterAttributes();

        creditCard.save().then(function () {
          // success
        })['catch'](function () {
          //Handled errors, need this empty function to keep Ember from bubbling them further.
        });
        //returning false here because we handled action, else Ember won't know the action was handled in this controller
        //and will allow it to bubble up when the model is saved successfully.
        return false;
      }
    }
  });
});