define('web-app-frontend/components/account-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    swapBackground: false,
    openModal: 'openModal',
    transitionAndPrint: 'transitionAndPrint',
    duplicateProject: 'duplicateProject',
    showSpinner: false,

    printType: (function () {
      if (this.get('model.type') === 'UserOrder') {
        return 'Order';
      } else if (this.get('model.type') === 'UserQuote') {
        return 'Quote';
      }
    }).property('model.type'),

    formattedCreationDate: (function () {
      return moment(this.get('model.creationDate')).format('MMMM Do');
    }).property('model.creationDate'),

    onHold: (function () {
      return this.get('model.project.onHold');
    }).property('model.project.onHold'),

    actions: {
      duplicateProject: function duplicateProject(projectGUID) {
        this.set('showSpinner', true);
        this.sendAction('duplicateProject', projectGUID);
      },

      deleteProject: function deleteProject(model) {
        var deferred = new _ember['default'].RSVP.defer('delete project deferred');
        var parentComponent = this.get('component');
        var store = parentComponent.get('targetObject.store');
        var self = this;

        this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to delete this quote?',
          subtext: 'Deleting will permanently archive this project',
          deferredObject: deferred });
        deferred.promise.then(function () {
          var projectPromise = store.findRecord('project', model.get('id'));
          self.removeFromParent();
          projectPromise.then(function (project) {
            project.destroyRecord().then(function () {
              store.peekRecord(model.get('constructor.modelName'), model.get('id')).unloadRecord();
            });
          });
        });
      },

      transitionAndPrint: function transitionAndPrint(model) {
        this.sendAction('transitionAndPrint', model);
      },

      openModal: function openModal(template, model, deferred) {
        this.sendAction('openModal', template, model, deferred);
      }
    }

  });
});