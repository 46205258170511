define("web-app-frontend/adapters/user-password-forgot", ["exports", "web-app-frontend/adapters/quoting-parent"], function (exports, _webAppFrontendAdaptersQuotingParent) {
  exports["default"] = _webAppFrontendAdaptersQuotingParent["default"].extend({
    buildURL: function buildURL() {
      var url = 'password';
      // super will pass to pathForType callback.
      return this._super(url);
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;

      return this.ajax(this.buildURL(), "POST", {}, { EmailAddress: encodeURIComponent(record.get('emailAddress')) });
    }
  });
});