define('web-app-frontend/models/assembly-turn-time', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyDiscount: _emberData['default'].attr('number'),
    assemblyNotes: _emberData['default'].attr('string'),
    assemblyServiceCode: _emberData['default'].attr('string'),
    assemblyServiceName: _emberData['default'].attr('string'),
    assemblySubtotal: _emberData['default'].attr('number'),
    assemblySubtotalDisplay: _emberData['default'].attr('string', { defaultValue: '$0' }),
    assemblyTotal: _emberData['default'].attr('number'),
    assemblyCostPerBoard: _emberData['default'].attr('number'),
    bgaCount: _emberData['default'].attr('number'),
    customAssemblyNotes: _emberData['default'].attr('string'),
    customAssemblyQuote: _emberData['default'].attr('number'),
    designViolations: _emberData['default'].attr(),
    finePitchCount: _emberData['default'].attr('number'),
    hasBGA: _emberData['default'].attr('boolean'),
    hasClass3: _emberData['default'].attr('boolean'),
    hasDoubleSidedSMT: _emberData['default'].attr('boolean'),
    hasDoubleSidedThruHole: _emberData['default'].attr('boolean'),
    hasLeadFree: _emberData['default'].attr('boolean'),
    invalidatedQuoteTotal: _emberData['default'].attr('number'),
    isClone: _emberData['default'].attr('boolean'),
    isITAR: _emberData['default'].attr('boolean'),
    isFlexBoard: _emberData['default'].attr('boolean'),
    isReorder: _emberData['default'].attr('boolean'),
    quantity: _emberData['default'].attr('number'),
    smtCount: _emberData['default'].attr('number'),
    totalPlacements: _emberData['default'].attr('number'),
    thruHoleCount: _emberData['default'].attr('number'),
    turnDays: _emberData['default'].attr('string'),
    turnDaysDisplay: _emberData['default'].attr('string'),
    turnTimeCode: _emberData['default'].attr('string'),
    uniquePartCount: _emberData['default'].attr('number'),
    productLifecycle: _emberData['default'].attr('string'),

    // hasDesignViolations       : DS.attr('boolean'),

    parent: _emberData['default'].belongsTo('quote-assembly-pricing', { async: true }),

    isInvalid: (function () {
      //This property is intended to  disable selection of turntime in dropdown when
      //model has a designViolation || no available turntimes are present with a price < $15K
      return this.get('quoteAssemblyPricing.errors.length') || this.get('designViolations.length');
    }).property('quoteAssemblyPricing.errors.length', 'designViolations')

  });
});