define('web-app-frontend/serializers/bom', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      bomLines: { embedded: 'always' }
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      var self = this;
      var bomData = payload.projectBOMModel;
      var bomLineData = bomData.allBomLines.data;
      var bomLineIds = [];
      var bomLines = [];
      bomData.id = id;
      bomData.project = id;
      bomData.partsCount = self.calculatePartsCount(bomLineData);
      bomData.totalPrice = self.calculateTotalPrice(bomLineData);
      bomData.quotedBoardCount = bomLineData[0].quotedBoardCount;
      bomLineData.forEach(function (line) {
        var lineId = line.bmlId;
        bomLineIds.push(lineId);
        line.id = lineId;
        line.project = id;
        line.bom = id;
        // move reference data into top level
        Object.keys(line.resultReferenceData).forEach(function (key) {
          var value = line.resultReferenceData[key];
          line[key] = value;
        });
        // remove nested reference data
        delete line.resultReferenceData;
        bomLines.push(line);
        // store.pushPayload('bom-line', line);
      });
      bomData.bomLines = bomLineIds;
      payload = { bom: bomData, bomLine: bomLines };
      return this._super(store, typeClass, payload, id);
    },

    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, typeClass, payload) {
      var id = payload.projectBOMModel.projGUID;
      var self = this;
      var bomData = payload.projectBOMModel;
      var bomLineData = bomData.allBomLines.data;
      var bomLineIds = [];
      var bomLines = [];
      bomData.id = id;
      bomData.project = id;
      bomData.partsCount = self.calculatePartsCount(bomLineData);
      bomData.totalPrice = self.calculateTotalPrice(bomLineData);
      bomData.quotedBoardCount = bomLineData[0].quotedBoardCount;
      bomLineData.forEach(function (line) {
        var lineId = line.bmlId;
        bomLineIds.push(lineId);
        line.id = lineId;
        line.project = id;
        line.bom = id;
        // move reference data into top level
        Object.keys(line.resultReferenceData).forEach(function (key) {
          var value = line.resultReferenceData[key];
          line[key] = value;
        });
        // remove nested reference data
        delete line.resultReferenceData;
        bomLines.push(line);
        // store.pushPayload('bom-line', line);
      });
      bomData.bomLines = bomLineIds;
      payload = { bom: bomData, bomLine: bomLines };
      return this._super(store, typeClass, payload, id);
    },

    calculatePartsCount: function calculatePartsCount(lines) {
      var partsCount = 0;
      lines.forEach(function (line) {
        if (line.sourcingAction === 'Purchase') {
          partsCount += line.placementsPerBoard;
        }
      });
      return partsCount;
    },

    calculateTotalPrice: function calculateTotalPrice(lines) {
      // only add if Purchase
      var totalPrice = 0.0;
      lines.forEach(function (line) {
        if (line.sourcingAction === 'Purchase') {
          totalPrice += line.quotedLineTotalPrice;
        }
      });
      return totalPrice;
    }

  });
});