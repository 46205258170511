define('web-app-frontend/adapters/user-password-reset', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL(type, id) {
      var url = "user/" + id + "/password?Api-Key=SCWEB";
      return this._super(url);
    },

    createRecord: function createRecord(store, type, snapshot) {
      var userID = snapshot.attr('userGUID');
      var payload = { userPasswordReset: snapshot.record };

      return this.ajax(this.buildURL(type.modelName, userID), 'POST', { data: payload });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var userID = snapshot.attr('userGUID');
      var payload = { userPasswordReset: snapshot.record };

      return this.ajax(this.buildURL(type.modelName, userID), 'POST', { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.userPasswordReset.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.userPasswordReset.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});