define('web-app-frontend/routes/resource-center/privacy-policy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'Privacy Policy',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'At Screaming Circuits, we respect and value your privacy. See how we protect it for you.'
      }
    }]

  });
});