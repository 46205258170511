define('web-app-frontend/components/top-soft-alert', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    alertShown: true,

    actions: {
      hideAlert: function hideAlert() {
        this.set('alertShown', false);
      }
    }

  });
});