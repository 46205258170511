define('web-app-frontend/controllers/resource-center/layout', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSubmitted: false,

    actions: {
      calculateLayoutQuote: function calculateLayoutQuote(layoutQuote) {
        var submitButton = this.get('submitButton');
        layoutQuote.setupForCommit();
        layoutQuote.save().then(function () {
          var quoteState = layoutQuote.get('quoteState');
          //below line is to manage the mysterious disappearance of quoteState from the local record
          //after a successful POST with quoteState 'Quoted' in the response.
          if (quoteState === '') {
            layoutQuote.set('quoteState', 'Quoted');
          }
          submitButton.send('hasSucceeded');
          if (quoteState === 'Quoted') {
            //there is a second part to the form for this model, so we don't want errors showing up right away
            layoutQuote.resetForSecondHalf();
          }
        })['catch'](function (errors) {
          submitButton.send('showError');
          layoutQuote.set('commitOnSuccess', false);
          console.log('save layout-quote failed', errors);
        })['finally'](function () {
          submitButton.send('hideSpinner');
        });
      },

      goBack: function goBack() {
        //reset quoteState client side only so first form section is displayed
        var layoutQuote = this.model;
        layoutQuote.set('quoteState', '');
        layoutQuote.resetForSecondHalf();
      },

      validateLayoutQuote: function validateLayoutQuote() {
        var layoutQuote = this.model;
        layoutQuote.alterAttributes();
        layoutQuote.save().then(function () {})['catch'](function (errors) {
          console.log('validate layout-quote failed', errors);
        });
        return false;
      }
    }
  });
});