define('web-app-frontend/initializers/custom-error', ['exports', 'ember', 'web-app-frontend/mixins/error-handler'], function (exports, _ember, _webAppFrontendMixinsErrorHandler) {
  exports.initialize = initialize;

  //This iniitalizer is used for following two tasks
  //1: To wire up global error events
  //2: Reopen route and controller base class to extent error handler functionality

  function initialize(application) {
    //Reopen base classes to make error handling available in application
    _ember['default'].Route.reopen(_webAppFrontendMixinsErrorHandler['default']);
    _ember['default'].Controller.reopen(_webAppFrontendMixinsErrorHandler['default']);

    _ember['default'].onerror = function (err) {
      console.log('In global error handler');
      application.__container__.lookup("controller:application").handleApplicationError('Global', err, false);
    };

    _ember['default'].RSVP.on('error', function (err) {
      console.log('In promise on error');
      console.log(err);

      // Once this issue https://github.com/emberjs/ember.js/issues/12505 is
      // resolved we can remove this code.
      if (!_ember['default'].isEmpty(err)) {
        if (err.name === 'TransitionAborted') {
          return false;
        }
      }
      return true;
    });
  }

  exports['default'] = {
    name: 'custom-error',
    initialize: initialize
  };
});