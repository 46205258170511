define('web-app-frontend/models/user-email-address', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attributes
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    emailAddress: _emberData['default'].attr('string'),
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    serverId: _emberData['default'].attr('number'),
    userGUID: _emberData['default'].attr('string'),
    committedToServer: _emberData['default'].attr('boolean', { defaultValue: false }),

    isEmpty: (function () {
      var firstName = this.get('firstName');
      var lastName = this.get('lastName');
      var emailAddress = this.get('emailAddress');

      if (!firstName && !lastName && !emailAddress) {
        return true;
      } else {
        return false;
      }
    }).property('firstName', 'lastName', 'emailAddress'),

    fullName: (function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }).property('firstName', 'lastName')

  });
});