define('web-app-frontend/controllers/order/project-file', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    generateBlankProjectFile: function generateBlankProjectFile(projectGUID) {
      var store = this.store;
      return store.createRecord('project-file', { projectGUID: projectGUID,
        project: store.peekRecord('project', projectGUID),
        fileUploadDate: moment().format()
      });
    }
  });
});