define('web-app-frontend/controllers/quoting/bom-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    quotingController: _ember['default'].inject.controller('quoting'),

    needToResolveErrors: false,
    moreInfoDesired: false,
    userInteraction: false,

    sortValue: 'default',
    filterValue: 'all',
    viewFilterTypes: null,
    markAllValue: 'default',
    manualDataRefresh: false,

    sortFilters: [{ text: "Select One", value: 'default' }, { text: "BOM line number", value: 'lineNumber' }, { text: "Part Name", value: 'displayPartDescriptionShort' }, { text: "Mount Type", value: 'mountType' }],

    markAllTypes: [{ text: "Select One", value: 'default' }, { text: 'Do Not Place', value: 'DNP' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Quote for me', value: 'Quote' }],

    watchSortInteraction: (function () {
      if (this.sortValue !== null && this.sortValue !== 'default' && !this.userInteraction || !this.model.newBom) {
        this.set('userInteraction', true);
      }
    }).observes('sortValue'),

    watchForFilterInteraction: (function () {
      if (!this.userInteraction) {
        this.set('userInteraction', true);
      }
    }).observes('filterValue'),

    watchModelForUserInteraction: (function () {
      if (!this.model.newBom) {
        this.set('userInteraction', true);
      }
    }).observes('model'),

    dynamicallyInsertItemNumberSortFilter: (function () {
      if (this.get('model.itemNumbersPresent') && this.get('sortFilters').get('lastObject').value !== "itemNumber") {
        this.get('sortFilters').pushObject({ text: "Item Number", value: 'itemNumber' });
      }
    }).observes('model'),

    buildMarkAllTypes: (function () {
      switch (this.get('filterValue')) {
        case 'Purchase':
        case 'Quote':
          return this.set('markAllTypes', [{ text: "Select One", value: 'default' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Do Not Place', value: 'DNP' }]);
        case 'DNP':
          return this.set('markAllTypes', [{ text: "Select One", value: 'default' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Quote for me', value: 'Quote' }]);
        case 'CustProvided':
          return this.set('markAllTypes', [{ text: "Select One", value: 'default' }, { text: 'Quote for me', value: 'Quote' }, { text: 'Do Not Place', value: 'DNP' }]);
        default:
          return this.set('markAllTypes', [{ text: "Select One", value: 'default' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Quote for me', value: 'Quote' }, { text: 'Do Not Place', value: 'DNP' }]);
      }
    }).observes('filterValue'),

    recalcFilterCounts: (function () {
      var bomLines = this.model.bom.get('bomLines').filterBy('bmlIsSelected', true);
      var totalBomLines = bomLines.get('length');
      var partsWithIssues = bomLines.filterBy('shouldDisplayErrorIcon', true).length;
      var partsSourced = bomLines.filterBy('sourcingAction', 'Purchase').length;
      var partsNotPlaced = bomLines.filterBy('sourcingAction', 'DNP').length;
      var partsProvided = bomLines.filterBy('sourcingAction', 'CustProvided').length;
      var partsToBeQuoted = bomLines.filterBy('sourcingAction', 'Quote').length;

      var filterOptions = [{ text: 'All (' + totalBomLines + ')', value: 'all' }, { text: 'Parts Sourced (' + partsSourced + ')', value: 'Purchase' }, { text: 'Issues (' + partsWithIssues + ')', value: 'error' }, { text: 'Do Not Place (' + partsNotPlaced + ')', value: 'DNP' }, { text: 'I Will Provide (' + partsProvided + ')', value: 'CustProvided' }, { text: 'Quote Requested (' + partsToBeQuoted + ')', value: 'Quote' }];

      return this.set('viewFilterTypes', filterOptions);
    }).observes('model.storedBomLines.@each.sourcingAction'),

    //This observer will disable the default option for Sort & Mark all dropdowns.
    disableDefaultSelectOptions: (function () {
      _ember['default'].run.later(this, function () {
        $('select option[value="default"]').prop('disabled', true);
      }, 5);
    }).observes('markAllTypes', 'sortValue'),

    //This will be responsible for triggering the mark all select input.
    notViewingAll: (function () {
      return this.filterValue !== 'all';
    }).property('filterValue'),

    //This will trigger on opening of Bom Modal due to the change of filterValue from null -> default.
    sortBomLines: (function () {
      var sortValue = this.sortValue;
      if (!sortValue) {
        return;
      }
      var bomLines = this.model.storedBomLines;
      var filterdBomLines;
      //On default "Source / Action", group the similar rows based on error type and sort lines without error based on "Line #"
      if (sortValue === 'default') {
        var linesWithErrors = bomLines.filterBy('sourcingAction', 'PromptCustomer').sortBy('lineNumber');

        var linesWithNotFoundErrors = linesWithErrors.filterBy('sourceVendor', 'Unknown').rejectBy('sourcingAction', 'DNP').rejectBy('sourcingAction', 'CustProvided').sortBy('lineNumber');

        var linesWithRefDesErrors = linesWithErrors.filterBy('quantityValidation', 'Mismatch').rejectBy('sourcingAction', 'DNP').rejectBy('sourcingAction', 'CustProvided').rejectBy('sourceVendor', 'Unknown').sortBy('lineNumber');

        var linesWithNoStockErrors = linesWithErrors.filterBy('inventoryHealth', 'NoStock').rejectBy('sourceVendor', 'Unknown').rejectBy('quantityValidation', 'Mismatch').rejectBy('sourcingAction', 'DNP').rejectBy('sourcingAction', 'CustProvided').sortBy('lineNumber');

        var linesWithOutErrors = bomLines.rejectBy('sourcingAction', 'PromptCustomer').sortBy('lineNumber');

        filterdBomLines = [].concat.apply([], [linesWithNotFoundErrors, linesWithRefDesErrors, linesWithNoStockErrors, linesWithOutErrors]);
      } else {
        filterdBomLines = bomLines.sortBy(sortValue);
      }
      this.set('model.storedBomLines', filterdBomLines);
    }).observes('sortValue', 'model'),

    filterBomSelection: (function () {
      var bomLines = this.model.bom.get('bomLines').filterBy('bmlIsSelected', true);
      var param = this.filterValue;
      var scopedBomLines;
      if (param === 'all') {
        scopedBomLines = bomLines.sortBy(this.sortValue);
      } else if (param === 'error') {
        scopedBomLines = bomLines.filterBy('shouldDisplayErrorIcon', true).sortBy(this.sortValue);
      } else {
        scopedBomLines = bomLines.filterBy('sourcingAction', param).sortBy(this.sortValue);
      }
      this.set('model.storedBomLines', scopedBomLines);
    }).observes('filterValue', 'manualDataRefresh'),

    enableBulkActionButton: (function () {
      if (this.markAllValue !== 'default') {
        this.set('bulkActionSelected', true);
      } else {
        this.set('bulkActionSelected', false);
      }
    }).observes('markAllValue'),

    manageDisplayedPartCount: (function () {
      if (this.filterValue === 'all') {
        this.set('selectedFilterPartCount', this.model.storedBomLines.get('length'));
      } else if (this.filterValue === 'error') {
        this.set('selectedFilterPartCount', this.invalidPartsCount);
      } else {
        this.set('selectedFilterPartCount', this.model.storedBomLines.filterBy('sourcingAction', this.filterValue).length);
      }
    }).observes('model.storedBomLines.@each.sourcingAction'),

    setInvalidPartsCount: (function () {
      //Observer to monitor count of parts in error AND to resolve submit error
      // prompting user to resolve issues before submitting.
      var bomLines = this.get('model.bom.bomLines').filterBy('bmlIsSelected', true);
      var partCount = bomLines.filterBy('shouldDisplayErrorIcon', true).get('length');

      this.set('invalidPartsCount', partCount);

      if (partCount === 0) {
        this.send('toggleNeedToResolveErrors', false);
      }
    }).observes('model.storedBomLines.@each.shouldDisplayErrorIcon'),

    watchForCustomQuoteSelection: (function () {
      var partsNeedingQuoting = this.model.bom.get('bomLines').filterBy('bmlIsSelected', true).filterBy('sourcingAction', 'Quote').get('length');
      if (partsNeedingQuoting > 0 && (this.filterValue === 'all' || this.filterValue === 'Quote')) {
        this.set('shouldDisplayCustomQuoteAlert', true);
      } else {
        this.set('shouldDisplayCustomQuoteAlert', false);
      }

      //PN: Removing custom quote functionality alert by setting flag to false
      this.set('shouldDisplayCustomQuoteAlert', false);

      this.set('customQuotableCount', partsNeedingQuoting);
      // we need to watch the bomLines relationship explicitly due to changing of storedBomLines when filtering types.
    }).observes('model.bom.bomLines.@each.sourcingAction', 'filterValue'),

    resetFileNameField: function resetFileNameField() {
      $.find('.faux-button-upload span.fileName')[0].innerHTML = 'No file chosen';
    },

    actions: {
      moreBomErrorInfo: function moreBomErrorInfo() {
        this.toggleProperty('moreInfoDesired');
      },

      refreshData: function refreshData() {
        console.log('in refreshData');
        this.toggleProperty('manualDataRefresh');
      },

      yetToBeImplemented: function yetToBeImplemented() {
        alert('this feature has yet to be implemented');
      },

      closeBomModal: function closeBomModal() {
        var model = this.model;
        var shouldRemoveBomRelatedData = false;
        this.set('sortValue', 'default');
        this.set('filterValue', 'all');
        this.model.bom.set('customerNotifiedOfCustomQuote', false);

        var self = this;
        this.model.storedBomLines.forEach(function (line) {
          // The dirtyType conditional was put into place due to the rollback trying to reset new records ID attributes.
          if (line.get('hasDirtyAttributes') && line.get('dirtyType') !== 'created') {
            line.rollbackAttributes();
            line.save();
            // Don't unload records unless they are from a new BOM.
          } else if (model.newBom) {
              shouldRemoveBomRelatedData = true;
            }
        });
        // Unload bom related data if bom is new
        if (shouldRemoveBomRelatedData) {
          var store = self.store;
          store.unloadAll('bom-progress');
          store.unloadAll('bom-line');
          store.unloadAll('bom');
        }

        this.send('closeModal');
      },

      resetModalVariables: function resetModalVariables() {
        this.set('sortValue', 'default');
        this.set('filterValue', 'all');
        this.set('userInteraction', false);
      },

      triggerSummary: function triggerSummary(bom) {
        this.get('quotingController').set('bom', bom);
        this.get('quotingController').send('toggleRefreshTurnTimes');
      },

      toggleNeedToResolveErrors: function toggleNeedToResolveErrors(value) {
        this.set('needToResolveErrors', value);
        if (value === true) {
          this.set('filterValue', 'error');
        }
      },

      markAllBomLines: function markAllBomLines() {
        var self = this;
        var bomLines = this.get('model.storedBomLines');
        bomLines.forEach(function (line) {
          // to ensure we don't set unavailable actions on lines, sourcing unavailable parts etc.
          if (self.markAllValue === 'Quote' && line.get('sourcingActions').findBy('value', 'Purchase')) {
            line.set('sourcingAction', 'Purchase');
          } else if (line.get('sourcingActions').findBy('value', self.markAllValue)) {
            line.setProperties({ sourcingAction: self.markAllValue });
          }
        });
        this.set('markAllValue', 'default');
        this.set('bulkActionSelected', false);
      }
    }

  });
});