define("web-app-frontend/templates/components/blogs-and-publications", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 22,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/blogs-and-publications.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row mt60 mb60");
        var el2 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-offset-1 col-md-5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3, "class", "open-sans mb20");
        var el4 = dom.createTextNode("Sponsorship Programs");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n     ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        dom.setAttribute(el3, "class", "lh28 mt40");
        var el4 = dom.createTextNode("Screaming Circuits helps university-based or non-profit teams push the technological envelope by providing PCB assembly these groups can’t perform themselves.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-offset-1 col-md-5");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h3");
        dom.setAttribute(el3, "class", "open-sans mb20");
        var el4 = dom.createTextNode("Tech Help @Circuit Talk");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "mt10 lh28");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://www.screamingcircuits.com/circuittalk/fab-and-assembly-drawings/");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "rel", "noopener");
        var el5 = dom.createTextNode("Fab and Assembly Drawings");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "mt10 lh28");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://www.screamingcircuits.com/circuittalk/cost-reduction-in-electronics-design/");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "rel", "noopener");
        var el5 = dom.createTextNode("Cost Reduction in Electronics Design");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "mt10 lh28");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://www.screamingcircuits.com/circuittalk/living-with-todays-supply-chain/");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "rel", "noopener");
        var el5 = dom.createTextNode("Living With Today's Supply Chain");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "mt10 lh28");
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "https://www.screamingcircuits.com/circuittalk/creating-a-good-parts-kit/");
        dom.setAttribute(el4, "target", "_blank");
        dom.setAttribute(el4, "rel", "noopener");
        var el5 = dom.createTextNode("Creating a good Parts Kit");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "https://www.screamingcircuits.com/circuittalk/");
        dom.setAttribute(el3, "target", "_blank");
        dom.setAttribute(el3, "rel", "noopener");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn-blue btn mt20 mb40 text-case-inherit padded-5-40");
        var el5 = dom.createTextNode("More ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 5, 5);
        return morphs;
      },
      statements: [["inline", "link-to", ["Learn more about our sponsorship program", "about.sponsorship"], ["class", "btn btn-outline btn-small text-case-inherit mt40 mb40"], ["loc", [null, [8, 6], [8, 142]]]]],
      locals: [],
      templates: []
    };
  })());
});