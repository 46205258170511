define('web-app-frontend/initializers/global-app', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  var originalBuildInstance;

  //This iniitalizer is used for following two tasks
  //1: To inject global app instance on the window object, so that it will be availble throughout the application
  //2: Recreate registry after app.reset(), this is a work around for ember bug https://github.com/emberjs/ember.js/issues/10310

  function initialize(application) {
    originalBuildInstance = originalBuildInstance || _ember['default'].Application.prototype.buildInstance;
    _ember['default'].Application.prototype.buildInstance = function () {
      this.buildRegistry();
      return originalBuildInstance.apply(this);
    };

    window.SCEmberApp = application;
  }

  exports['default'] = {
    name: 'global-app',
    initialize: initialize
  };
});