define('web-app-frontend/helpers/format-value', ['exports', 'ember'], function (exports, _ember) {
	var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

	//By default return a string representation of a numeric value rounded to a whole number, or '0' if empty.
	//passed value can be a decimal, or a string representation of a decimal (with no formatting)
	//Optional parameters may be passed to override defaults as desired:
	// decimalPlaces to determine the number of decimal points desired after 0 (Default: 0)
	// currenyFormatting adds commas at every thousands point (Default: False), sets default decimalPlaces to 2, but this can be overriden
	// defaultWhenNull determines what is sent back if the value is null or empty (Default: '0')
	// zeroAsNull determines if the 0 value can be treated as null (Default: false)
	// currencySign determines if the dollar sign ($) can be returned or not (Default: false)

	//Examples:
	//{{format-value assembly.perBoardPrice decimalPlaces=2 }}
	//24
	//"13245"
	//"0"

	//{{format-value assembly.perBoardPrice decimalPlaces=2 }}
	//24.00
	//"13245.20"
	//"0"

	//{{format-value assembly.perBoardPrice currency=true defaultWhenNull='--'}}
	//24.00
	//"13,245.20"
	//"--"

	//{{format-value assembly.perBoardPrice currency=true defaultWhenNull='N/A' zeroAsNull= true currencySign=true}}
	//$24.00
	//"$13,245.20"
	//"N/A" output for 0 value

	exports['default'] = _ember['default'].Helper.helper(function (_ref, options) {
		var _ref2 = _slicedToArray(_ref, 1);

		var value = _ref2[0];

		var config = {
			decimalPlaces: 0,
			currency: false,
			defaultWhenNull: '0',
			zeroAsNull: false,
			currencySign: false
		};
		if (typeof options.currency === 'boolean') {
			config.currency = options.currency;
			config.decimalPlaces = config.currency ? 2 : config.decimalPlaces; //Default to 2 decimal places for currency
			config.defaultWhenNull = '0.00';
		}
		if (typeof options.decimalPlaces === 'number') {
			config.decimalPlaces = options.decimalPlaces;
		}
		if (typeof options.defaultWhenNull === 'string') {
			config.defaultWhenNull = options.defaultWhenNull;
		}
		if (typeof options.zeroAsNull === 'boolean') {
			config.zeroAsNull = options.zeroAsNull;
		}
		if (typeof options.currencySign === 'boolean') {
			config.currencySign = options.currencySign;
		}
		if (value && Number(value) !== 'NaN' && !(config.zeroAsNull && value == 0)) {
			// jshint ignore:line
			var trimmedValue = Number(value).toFixed(config.decimalPlaces).toString();
			var returnValue = config.currency ? trimmedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : trimmedValue;
			if (config.currencySign) {
				return "$" + returnValue;
			} else {
				return returnValue;
			}
		}
		return config.defaultWhenNull;
	});
});