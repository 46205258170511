define('web-app-frontend/controllers/resource-center/services/volume-production', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSubmitted: false,

    actions: {
      submit: function submit() {
        var self = this;
        var submitBtnComponent = this.get('submitButton');

        this.model.setupForCommit();
        this.model.save().then(function () {
          submitBtnComponent.send('hasSucceeded');
          self.set('isSubmitted', true);
          // targetting based off of window height to not get any jumping
          if ($(window).height() < 1050) {
            $('html, body').animate({ scrollTop: $('#contact-form').offset().top - 20 }, 100);
          }
        })['catch'](function () {
          console.log('save choice-production-contact fail');
          submitBtnComponent.send('showError');
        })['finally'](function () {
          submitBtnComponent.send('hideSpinner');
        });
      },

      validateContact: function validateContact() {
        this.model.alterAttributes();
        this.model.save().then(function () {
          console.log('validate choice-production-contact success');
        })['catch'](function () {
          console.log('validate choice-production-contact fail');
        });
      }
    }
  });
});