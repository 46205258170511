define('web-app-frontend/serializers/order', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeQueryResponse: function normalizeQueryResponse(store, typeClass, payload) {
      var orders = payload.userOrder.data;
      var hasMoreData = payload.userOrder.hasMoreData;
      var totalCount = payload.userOrder.totalCount;

      orders.forEach(function (order) {
        order.project = order.id;
      });

      payload = { order: orders, meta: { hasMoreData: hasMoreData, totalCount: totalCount } };
      return this._super(store, typeClass, payload);
    }
  });
});