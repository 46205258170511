define('web-app-frontend/controllers/user/quotes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    quotingController: _ember['default'].inject.controller('quoting'),

    filterQuotes: [{ text: 'Last Updated', value: 'modificationDate', sortAscending: false }, { text: 'Created Date', value: 'creationDate', sortAscending: false }, { text: 'Order Number', value: 'orderNumber', sortAscending: true }, { text: 'Project Name', value: 'projectName', sortAscending: true }],
    queryParams: ['PageNumber', 'sortField', 'sortAsc'],
    sortField: 'modificationDate',
    PageNumber: 1,
    shouldPaginate: false,
    sortAsc: false,

    setPageNumber: function setPageNumber(hasNextPage) {
      if (hasNextPage) {
        this.incrementProperty('PageNumber');
      }
      this.set('shouldPaginate', hasNextPage);
    },

    quotes: (function () {
      return this.get('model').filterBy('formalQuote', false);
    }).property('selectedSort'),

    formalQuoteRequests: (function () {
      return this.get('model').filterBy('formalQuote', true);
    }).property('model.@each.formalQuote'),

    sortQuotes: function sortQuotes() {
      var filteredQuotes = this.model.filterBy('formalQuote', false);
      this.set('quotes', filteredQuotes);
    },

    refreshModelBeforeSorting: (function () {
      this.set('sortField', this.get('selectedSort.value'));
      this.set('sortAsc', this.get('selectedSort.sortAscending'));
    }).observes('selectedSort'),

    getNextResults: function getNextResults(deferred) {
      var self = this;
      this.store.query('quote', { PageNumber: this.get('PageNumber'),
        SortField: this.get('sortField'),
        SortAscending: this.get('sortAsc') }).then(function (rawPayload) {
        var hasMoreData = rawPayload.get('meta.hasMoreData');
        self.setPageNumber(hasMoreData);
        self.get('model').addObjects(rawPayload.content);
        if (deferred) {
          deferred.resolve();
        }
        self.sortQuotes();
      })['catch'](function (errors) {
        console.log('Load More failed', errors);
        if (deferred) {
          deferred.reject(errors);
        }
      });
    },

    actions: {
      duplicateProject: function duplicateProject(projectGUID) {
        var quotingController = this.get('quotingController');
        quotingController.send('duplicateProject', projectGUID);
      },

      transitionAndPrint: function transitionAndPrint(model) {
        var quotingController = this.get('quotingController');
        quotingController.set('printTransition', true);
        quotingController.transitionToRoute('quote.edit', model.get('id'));
      },

      loadMore: function loadMore(deferred) {
        this.getNextResults(deferred);
      }
    }
  });
});