define('web-app-frontend/serializers/project-promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      project: { embedded: 'always' }
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var promotion = {};
      var project = payload.projectSummary;
      project.id = project.projectGUID;
      project.type = "Project";
      delete payload.projectSummary;

      promotion.id = project.projectGUID;
      promotion.promotionCode = project.promoCode;
      promotion.project = project;

      payload = { projectPromotion: promotion, project: project };
      return this._super(store, typeClass, payload);
    }
  });
});