define('web-app-frontend/components/custom-quote-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    inputActive: false,
    submitted: false,

    validateQuoteFabricationPricing: 'validateQuoteFabricationPricing',
    validateQuoteAssemblyPricing: 'validateQuoteAssemblyPricing',
    customQuoteEditing: 'customQuoteEditing',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    validateByRecordType: function validateByRecordType() {
      var validateDeferred = arguments.length <= 0 || arguments[0] === undefined ? null : arguments[0];

      if (this.get('model.constructor.modelName') === 'quote-assembly-pricing') {
        this.sendAction('validateQuoteAssemblyPricing', validateDeferred);
      } else if (this.get('model.constructor.modelName') === 'quote-fabrication-pricing') {
        this.sendAction('validateQuoteFabricationPricing', validateDeferred);
      } else if (validateDeferred) {
        validateDeferred.reject('That model type has no place here.');
      }
    },

    actions: {
      cancel: function cancel() {
        if (this.get('model')) {
          //can't use rollbackAttribute on bc with caching implementation, base pricing record is never saved (cachedCopies are),
          //which means the base record is always in the 'isNew' state. rollbackAttributes when record is in 'isNew' state
          //puts it in deleted state, from which 'set' cannot be called.

          var textField = this.get('textField'); //customAssemblyNotes or customFabNotes
          var rollbackState = this.get('textFieldRollback');
          this.get('model').set(textField, rollbackState);
        }
        this.send('toggleInput');
        this.sendAction('customQuoteEditing', false);
      },

      reset: function reset() {
        this.get('model').set(this.get('textField'), null);
        this.send('toggleInput');
        this.sendAction('customQuoteEditing', false);
      },

      toggleInput: function toggleInput(scrollTo) {
        this.sendAction('customQuoteEditing', true);
        this.toggleProperty('inputActive');
        if (this.get('inputActive')) {
          this.set('textFieldRollback', this.get('textValue'));
        } else {
          this.set('textFieldRollback', '');
        }
        if (scrollTo) {
          $('html, body').animate({ scrollTop: this.$().offset().top - 20 }, 1000);
        }
      },

      submitCustomQuote: function submitCustomQuote() {
        if (!_ember['default'].isBlank(this.get('textValue'))) {

          // no need to validate, quote should be submittable if form is in error
          this.get('model').setupForCommit();

          // sets model pricing component custom quote to true
          this.get('model').set(this.get('booleanField'), true);

          //remove any currently displayed errors from the form field.
          this.get('model.errors').clear();

          // TODO submit form - if success we can toggle the customQuote property in the quoting controller (or show based off of the api response)
          this.send('toggleInput');
          this.sendAction('customQuoteEditing', false);

          this.set('submitted', true);
        } else {
          this.send('reset');
        }
      },

      remove: function remove() {
        var self = this;
        this.get('model').set(this.get('booleanField'), false);
        this.get('model').set(this.get('textField'), '');
        var validateDeferred = new _ember['default'].RSVP.defer('removeAndValidateDeferred');
        this.validateByRecordType();

        validateDeferred.promise.then(function () {
          console.log('custom quote successfully removed');
          self.set('submitted', false);
        })['catch'](function (errors) {
          console.log('removal errors', errors);
        });
      }
    }
  });
});