define('web-app-frontend/routes/services', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'PCB Assembly Services Descriptions',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Screaming Circuits was founded in 2002 with the mission of improving the prototype and short-run process through easy ordering and quick turnaround.'
      }
    }, {
      type: 'meta',
      tagId: 'og:title',
      attrs: {
        name: 'og:title',
        content: 'PCB Assembly | Prototype and On-Demand Electronics Manufacturing Service Descriptions'
      }
    }, {
      type: 'meta',
      tagId: 'twitter:title',
      attrs: {
        name: 'twitter:title',
        content: 'PCB Assembly | Prototype and On-Demand Electronics Manufacturing Service Descriptions'
      }
    }],

    activate: function activate() {
      this._super.apply(this, arguments);
      _ember['default'].$('body').addClass('absolute-header');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('absolute-header');
    }
  });
});