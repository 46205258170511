define('web-app-frontend/components/error-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['hideForSoftAlert:hide:show'],
    errorMessages: [],

    setFieldErrorState: 'setFieldErrorState',
    setSectionErrorState: 'setSectionErrorState',

    updateErrorMessageTrigger: (function () {
      _ember['default'].run.once(this, 'updateErrorMessage'); //pattern to run updateErrorMessage only once per loop.
    }).observes('model.errors.length'),

    updateErrorMessage: function updateErrorMessage() {
      var attribute = this.attribute;
      var self = this;
      this.set('errorMessages', []);

      if (this.model) {
        if (this.model.get('userAlteredAttributes').indexOf(attribute) > -1) {
          if (this.model.get('errors').get(attribute)) {
            var updatedErrors = this.model.get('errors').get(attribute).map(function (error) {
              return error.message;
            });
            self.set('errorMessages', updatedErrors);
            //fieldErrorState is for highlighting the ember input box/field orange, not for the actual message display
            this.sendAction('setFieldErrorState', true);
            if (this.get('sectionErrorStateName') !== undefined) {
              this.sendAction('setSectionErrorState', this.get('sectionErrorStateName'), true);
            }
          } else {
            this.sendAction('setFieldErrorState', false);
          }
        }
        if (this.model.get('errors.length') === 0 && this.get('sectionErrorStateName') !== undefined) {
          this.sendAction('setSectionErrorState', this.get('sectionErrorStateName'), false);
        }
      }
    }
  });
});