define("web-app-frontend/templates/components/form-inline-inputs", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/form-inline-inputs.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-12");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        dom.setAttribute(el3, "class", "bold ml10 mr10");
        var el4 = dom.createTextNode("x");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "error-spacing");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        return morphs;
      },
      statements: [["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "firstValue", ["loc", [null, [4, 18], [4, 28]]]]], [], []], "placeholder", ["subexpr", "@mut", [["get", "placeholder1", ["loc", [null, [4, 41], [4, 53]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [4, 59], [4, 63]]]]], [], []], "class", "ib inline-inputs"], ["loc", [null, [4, 4], [4, 90]]]], ["inline", "input", [], ["value", ["subexpr", "@mut", [["get", "secondValue", ["loc", [null, [6, 18], [6, 29]]]]], [], []], "placeholder", ["subexpr", "@mut", [["get", "placeholder2", ["loc", [null, [6, 42], [6, 54]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [6, 60], [6, 64]]]]], [], []], "class", "ib inline-inputs"], ["loc", [null, [6, 4], [6, 91]]]], ["inline", "error-display", [], ["tagName", "span", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [10, 39], [10, 44]]]]], [], []], "type", "input", "attribute", ["subexpr", "@mut", [["get", "firstAttributeWithErrors", ["loc", [null, [10, 68], [10, 92]]]]], [], []]], ["loc", [null, [10, 2], [10, 94]]]]],
      locals: [],
      templates: []
    };
  })());
});