define('web-app-frontend/serializers/quote-assembly', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var data = { quoteAssembly: payload.orderAssembly };
      data.quoteAssembly.id = payload.orderAssembly.projectGUID;

      return this._super(store, typeClass, data);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      if (!payload.orderAssembly.turnTimeCode) {
        payload.orderAssembly.turnTimeCode = 'FP5';
      }
      var quoteAssembly = payload.orderAssembly;

      if (payload.projectSummary) {
        var assemblyDetails = payload.projectSummary.assemblyServiceSummary;
        quoteAssembly.turnDaysDisplay = assemblyDetails.turnTime;
      }

      quoteAssembly.id = payload.orderAssembly.projectGUID;
      quoteAssembly.pricingObject = quoteAssembly.id;
      quoteAssembly.userSelectedTurnTime = quoteAssembly.turnTime;
      var data = { quoteAssembly: quoteAssembly };

      return this._super(store, typeClass, data);
    }

  });
});