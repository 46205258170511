define('web-app-frontend/components/turn-time-selection', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    closeAndSave: 'closeAndSave',
    classNameBindings: ['serviceType'],
    isUnavailableTurnTimeBinding: 'unavailable',
    defaultValueForQuoteTotal: 'N/A',

    fabTurnTimes: _ember['default'].computed.alias('model.fabTurnTimes'),

    setPlaceholderRecord: function setPlaceholderRecord() {
      var store = this.get('parentView.parentView.targetObject.store');
      var emptyRecord = store.createRecord('fabricationTurnTime', { serviceType: this.get('model.serviceType'), invalidSelection: true, turnTime: this.get('turnTime') });
      this.model.get('fabTurnTimes').pushObject(emptyRecord);
      this.set('turnTimeRecord', emptyRecord);
    },

    gatherTurnTimes: (function () {
      if (this.model) {
        var match = this.get('fabTurnTimes').filterBy('turnTime', this.turnTime).get('firstObject');
        if (match) {
          this.set('turnTimeRecord', match);

          if ((match.get('validationStatus') === 'Valid' || match.get('validationStatus') === 'Unknown') && match.get('quoteTotal') === 0) {
            this.set('defaultValueForQuoteTotal', 'Custom');
          } else {
            this.set('defaultValueForQuoteTotal', 'N/A');
          }
        }

        if (!match) {
          this.setPlaceholderRecord();
        }

        this.setInvalidSelection();
      }
    }).observes('model.updateTurnTimeSelectionComponent'),

    setInvalidSelection: function setInvalidSelection() {
      var turnTimeRecord = this.get('turnTimeRecord');

      if (turnTimeRecord) {
        var unavailable = turnTimeRecord.get('hasDesignViolations');
        this.set('unavailable', unavailable);
      }
    },

    serviceTypesDontMatch: function serviceTypesDontMatch(turnTimeRecord, fabrication) {
      var turnTimeServiceType = turnTimeRecord.get('serviceType');
      var fabricationServiceType = fabrication.get('serviceType');
      if (turnTimeServiceType === fabricationServiceType) {
        return false;
      } else {
        return true;
      }
    },

    changeRadioButtonSelection: function changeRadioButtonSelection(turnTimeRecord) {
      $('.' + turnTimeRecord.get('serviceType')).find("input[type=radio][value='" + turnTimeRecord.get('turnTime') + "']").prop('checked', true);
    },

    //change/click event not firing without a groupValue, though it's not otherwise used, and
    //model.turnTime would be the group value if there weren't ambiguous/same string possibilities
    setGroupValue: function setGroupValue() {
      var groupValue = this.get('turnTime') + this.get('model.serviceType');
      this.set('groupValue', groupValue);
      this.model.set('serviceType', this.get('turnTimeRecord.serviceType'));
      this.model.set('turnTime', this.get('turnTime'));
    },

    actions: {
      closeAndSave: function closeAndSave() {
        var turnTimeRecord = this.get('turnTimeRecord');
        if (turnTimeRecord.violationFree()) {
          this.setGroupValue();
          this.changeRadioButtonSelection(turnTimeRecord);
          this.model.set('userSelectedTurnTime', this.get('turnTime'));
          this.sendAction('closeAndSave');
        }
      }
    }
  });
});