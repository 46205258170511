define("web-app-frontend/controllers/modals/payment-modal", ["exports", "ember", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendConfigEnvironment) {
    var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; })();

    exports["default"] = _ember["default"].Controller.extend({
        closeModal: 'closeModal',
        showSpinnerForCloseBtn: false,

        session: _ember["default"].inject.service(),

        fetchOauthToken: function fetchOauthToken() {
            var tokenString = '';
            if (this.get('session.isAuthenticated')) {
                tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
            }
            return { 'Authorization': tokenString };
        },

        actions: {
            closePaymentModal: function closePaymentModal() {

                var self = this;
                self.set('showSpinnerForCloseBtn', true);
                var oauthToken = self.fetchOauthToken();
                var userGUID = self.get('session.data.authenticated.UserGUID');
                var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/user/' + userGUID + '/creditcard?Api-Key=SCWEB&SessionGUID=' + $.cookie('sessionGUID') + '&projectGuid=' + self.get('model.project.id') + '&rememberPaymentMethod=' + self.get('model.orderBilling.rememberThisCard');

                var existingCards = self.get('model.orderBilling.availableCards').map(function (x) {
                    return x.paymentId;
                });

                //updating the availablecards list on server
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: JSON.stringify({}),
                    contentType: 'application/json; charset=UTF-8',
                    cache: false,
                    headers: oauthToken,
                    dataType: 'json'
                }).done(function (data) {
                    console.log(data);
                    //refreshing the project-billing model
                    self.store.findRecord('project-billing', self.get('model.project.id'), { reload: true }).then(function (newData) {
                        try {
                            //selecting the newly added/latest card

                            var _newData$get$sortBy$reverse = newData.get('availableCards').sortBy('creationDate').reverse();

                            var _newData$get$sortBy$reverse2 = _slicedToArray(_newData$get$sortBy$reverse, 1);

                            var latestCard = _newData$get$sortBy$reverse2[0];

                            if (latestCard && !existingCards.includes(latestCard.paymentId)) {
                                self.set('model.orderBilling.selectedPaymentId', latestCard.paymentId);
                                self.set('model.orderBilling.creditCardExpirationMonth', latestCard.cardExpirationMonth);
                                self.set('model.orderBilling.creditCardExpirationYear', latestCard.cardExpirationYear);
                                self.set('model.orderBilling.creditCardNumberObfuscated', latestCard.cardNumberObfuscated);
                                self.set('model.orderBilling.creditCardNumberPlain', latestCard.cardNumberObfuscated);
                                self.set('model.orderBilling.creditCardType', latestCard.cardType);
                                self.set('model.orderBilling.nameOnCard', latestCard.nameOnCard);
                                self.set('model.orderBilling.streetAddress1', latestCard.streetAddress);
                                self.set('model.orderBilling.postalCode', latestCard.postalCode);
                            }
                        } catch (err) {
                            console.error(err);
                        }

                        self.set('showSpinnerForCloseBtn', false);
                        //closing popup
                        self.send('closeModal');
                    });
                }).fail(function () {
                    console.error('Failed to update the AvailbleCreditCards');
                    self.set('showSpinnerForCloseBtn', false);
                    window.location.reload();
                });
            }
        }

    });
});