define('web-app-frontend/adapters/project-file', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({

    buildURL: function buildURL(modelName, id) {
      var url = 'project/' + id + '/drawings';
      // super will pass to pathForType callback.
      return this._super(url);
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    },

    createRecord: function createRecord(store, typeClass, snapshot) {
      var id = snapshot.record.get('projectGUID');
      var date = snapshot.attr('fileUploadDate');
      date = new Date(date).toUTCString();
      return this.ajax(this.buildURL(typeClass.modelName, id), 'POST', {}, { FileUploadDate: date });
    },

    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var id = snapshot.record.get('id');
      var date = snapshot.attr('fileUploadDate');
      date = new Date(date).toUTCString();
      return this.ajax(this.buildURL(typeClass.modelName, id), 'POST', {}, { FileUploadDate: date });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.projectFileUploadModel && response.projectFileUploadModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.projectFileUploadModel.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});