define('web-app-frontend/components/time-line', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    calendarHidden: false,
    calendarShifted: false,
    transitionToQuote: 'transitionToQuote',
    passEstimatedShipDate: 'passEstimatedShipDate',
    resetReloadTimeLine: 'resetReloadTimeLine',

    currentMonth: null,
    //Used as lock to run loadTimeLine function one at a time
    timelineLock: false,

    setCurrentMonth: (function () {
      var monthNum = moment().month();
      this.set('currentMonth', moment.months(monthNum));
    }).on('init'),

    loadTimeLine: function loadTimeLine() {
      var self = this;
      if (this.model) {
        if (this.get('timelineLock') !== true) {
          console.log('reloading the timeline');
          this.set('timelineLock', true);
          var project = this.model;
          var store = this.get('targetObject.store');
          var nodes;
          store.unloadAll('timeline-node');

          store.queryRecord('timeline-node', { id: project.get('id') }).then(function () {
            // need to peekAll due to queryRecord only returning first record from promise.
            nodes = store.peekAll('timeline-node').filterBy('projectGUID', project.get('id'));
            // Un comment to test styling of 4 nodes instead of 2 returned.
            // var copy = nodes.copy();
            // copy.forEach(function(n){ nodes.push(n) });
            self.set('timelineNodes', nodes);

            //Send the Estimated Ship Date down to the controller...				
            var descriptionNode = nodes.filterBy('description', 'Order Shipment');
            var estShipDate = !_ember['default'].isEmpty(descriptionNode) ? descriptionNode.get('firstObject').get('estimatedDateComplete') : null;
            if (!_ember['default'].isEmpty(estShipDate)) {
              self.sendAction('passEstimatedShipDate', estShipDate);
            }
          })['catch'](function (error) {
            console.log('loadTimeLine', error);
          })['finally'](function () {
            self.set('timelineLock', false);
          });
        } else {
          console.log('Adding loadTimeLine to queue');
          _ember['default'].run.later(function () {
            self.loadTimeLine();
          }, 900);
        }
      }
    },

    findTimeLineNodes: (function () {
      this.loadTimeLine();
    }).observes('model').on('init'),

    reloadTimeLineNodes: (function () {
      if (this.get('reloadTimeLine')) {
        this.loadTimeLine();
        this.set('reloadTimeLine', false);
        this.sendAction('resetReloadTimeLine');
      }
    }).observes('reloadTimeLine'),

    observesNodes: (function () {
      var nodes = this.get('timelineNodes');
      if (nodes) {
        this.set('widthOfNodes', Math.round(100 / nodes.length) - 1);
        this.setNodeLengths();
      }
    }).observes('timelineNodes'),

    expectedCompletionDate: (function () {
      if (this.timelineNodes) {
        var nodes = this.get('timelineNodes');
        //filter assembly completion time line details
        var assemblyCompletionTimeline = nodes.filter(function (x) {
          return x.get('orderState') === 'Assemble Boards' || x.get('orderState') === 'Boards Assembled';
        });
        //get assembly completion date
        var assemblyCompletionDate = assemblyCompletionTimeline.get('firstObject.estimatedDateComplete');
        return moment(assemblyCompletionDate).format('M/D/YYYY');
      }
    }).property('timelineNodes'),

    currentStatusDescription: (function () {
      if (this.timelineNodes) {
        var nodes = this.get('timelineNodes');
        return nodes.filterBy('inProgress', true).get('firstObject.toolTip');
      }
    }).property('timelineNodes'),

    didInsertElement: function didInsertElement() {
      var self = this;
      _ember['default'].$(window).on('resize', function () {
        self.set('calendarHidden', false);
        self.setNodeLengths();
      });
    },

    willDestroyElement: function willDestroyElement() {
      // removes event listener for resize if component is not on page
      _ember['default'].$(window).off('resize');
    },

    isNew: (function () {
      return this.get('type') === 'new';
    }).property('isNew'),

    isInProgress: (function () {
      return this.get('type') === 'inProgress';
    }).property('isInProgress'),

    setNodeLengths: function setNodeLengths() {
      if (window.outerWidth < 768) {
        this.set('nodeWidth', 100);
      } else {
        this.set('nodeWidth', this.get('widthOfNodes'));
      }
    },

    actions: {
      shiftCalendar: function shiftCalendar(shiftContent) {
        if (shiftContent) {
          this.set('calendarShifted', true);
        } else if ($('.timeline-content').length === 1) {
          this.set('calendarShifted', shiftContent);
        } else {
          if ($('.timeline-content').find('.sm-hide').length >= 1) {
            this.set('calendarShifted', false);
          }
        }
      },

      toggleCalendar: function toggleCalendar() {
        this.toggleProperty('calendarHidden');
        this.set('calendarShifted', false);
      },

      transitionToQuote: function transitionToQuote() {
        this.sendAction('transitionToQuote');
      }
    }
  });
});