define('web-app-frontend/components/orange-bar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      var self = this;
      _ember['default'].$(window).on('scroll', function () {
        self.checkScroll();
      });

      // stops animation from bugging when user resizes window
      _ember['default'].$(window).on('resize', function () {
        self.$('.orange-bar').finish();
      });
    },

    willDestroyElement: function willDestroyElement() {
      // removes event listener for resize if component is not on page
      _ember['default'].$(window).off('scroll resize');
    },

    checkScroll: function checkScroll() {
      var orangeBar = this.$('.orange-bar');
      var scrollBottom = $(window).scrollTop() + $(window).height();
      if (orangeBar.offset().top <= scrollBottom) {
        if (orangeBar.width() < $(window).width()) {
          orangeBar.animate({
            width: '100%'
          }, 4000);
        } else {
          orangeBar.finish();
          return false;
        }
      }
    }
  });
});