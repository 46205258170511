define('web-app-frontend/services/trackjs', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Provides an incomplete proxy to TrackJS. This is mostly because we can't
   * seem to rely upon `window.trackJs` being initialized... Or at least I had
   * enough grief in trying to get this to work that this proxy seemed like the
   * easiest solution for now.
   */
  exports['default'] = _ember['default'].Service.extend({
    track: function track() {
      return window.trackJs && window.trackJs.track.apply(window.trackJs, arguments);
    },

    configure: function configure() {
      return window.trackJs && window.trackJs.configure.apply(window.trackJs, arguments);
    },

    attempt: function attempt() {
      return window.trackJs && window.trackJs.attempt.apply(window.trackJs, arguments);
    },

    watch: function watch() {
      return window.trackJs && window.trackJs.watch.apply(window.trackJs, arguments);
    },

    watchAll: function watchAll() {
      return window.trackJs && window.trackJs.watchAll.apply(window.trackJs, arguments);
    },

    console: {
      error: function error() {
        return window.trackJs && window.trackJs.console.error.apply(window.trackJs, arguments);
      },

      info: function info() {
        return window.trackJs && window.trackJs.console.info.apply(window.trackJs, arguments);
      },

      log: function log() {
        return window.trackJs && window.trackJs.console.log.apply(window.trackJs, arguments);
      }
    }
  });
});