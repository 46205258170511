define('web-app-frontend/models/quote-fabrication-pricing', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    additionalBoardQuantity: _emberData['default'].attr('number'),
    assemblyServiceName: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    copperWeight: _emberData['default'].attr('string', { defaultValue: '1 oz.' }),
    countOfHoles: _emberData['default'].attr('number'),
    customFabNotes: _emberData['default'].attr('string'),
    customFabQuote: _emberData['default'].attr('boolean', { defaultValue: false }),
    fabNotes: _emberData['default'].attr('string'),
    dateCode: _emberData['default'].attr('boolean', { defaultValue: false }),
    edgeLengthOfGoldFingers: _emberData['default'].attr('number', { defaultValue: 0 }),
    electricalTest: _emberData['default'].attr('boolean', { defaultValue: true }),
    estimatedShipDate: _emberData['default'].attr('string'),
    expedite: _emberData['default'].attr('string'),
    height: _emberData['default'].attr('number'),
    invalidatedQuoteTotal: _emberData['default'].attr('number'),
    isClone: _emberData['default'].attr('boolean', { defaultValue: false }),
    lastQuoteDate: _emberData['default'].attr('string'),
    layers: _emberData['default'].attr('string', { defaultValue: '2' }),
    lotCode: _emberData['default'].attr('boolean', { defaultValue: false }),
    minTraceAndSpace: _emberData['default'].attr('string'),
    perBoardPrice: _emberData['default'].attr('number', { defaultValue: 0 }),
    projectGUID: _emberData['default'].attr('string'),
    referralServicePartnerCode: _emberData['default'].attr('string'),
    routing: _emberData['default'].attr('string', { defaultValue: 'Individual' }),
    serviceType: _emberData['default'].attr('string', { defaultValue: '' }),
    silkscreenColor: _emberData['default'].attr('string', { defaultValue: 'White' }),
    silkscreenSides: _emberData['default'].attr('string'),
    slotsOrCutoutsPerBoard: _emberData['default'].attr('number'),
    smallestHoleSize: _emberData['default'].attr('string'),
    solderMaskColor: _emberData['default'].attr('string', { defaultValue: 'Green' }),
    surfaceFinish: _emberData['default'].attr('string', { defaultValue: 'Silver' }),
    thickness: _emberData['default'].attr('string', { defaultValue: '0.062 in' }),
    turnTime: _emberData['default'].attr('string', { defaultValue: '1 WEEK' }),
    turnDaysDisplay: _emberData['default'].attr('string', { defaultValue: '5 Day' }),
    userSelectedTurnTime: _emberData['default'].attr('string'),
    ulCertification: _emberData['default'].attr('boolean', { defaultValue: false }),
    quoteTotal: _emberData['default'].attr('number'),
    quoteDiscount: _emberData['default'].attr('number'),
    quoteTotalWithDiscount: _emberData['default'].attr('number'),
    validationStatus: _emberData['default'].attr('string'),
    width: _emberData['default'].attr('number'),

    hasValidTurnTime: _emberData['default'].attr('boolean', { defaultValue: false }),
    triggerPriceDifference: _emberData['default'].attr('boolean', { defaultValue: false }),
    skipCheckPriceDifferences: _emberData['default'].attr('boolean', { defaultValue: false }),

    priceDifferenceReferenceID: _emberData['default'].attr('string'),
    engineering: _emberData['default'].attr('string', { defaultValue: 'Limited Review' }),
    material: _emberData['default'].attr('string', { defaultValue: 'FR4 150 Tg' }),
    solderMaskSides: _emberData['default'].attr('string', { defaultValue: 'Both' }),
    silkScreenClipping: _emberData['default'].attr('boolean', { defaultValue: true }),
    inspection: _emberData['default'].attr('string', { defaultValue: 'IPC A 600 Class 2' }),
    goldFingerSides: _emberData['default'].attr('number', { defaultValue: 1 }),
    bevel: _emberData['default'].attr('string', { defaultValue: 'None' }),
    crossSectionRpt: _emberData['default'].attr('boolean', { defaultValue: false }),
    platedSlots: _emberData['default'].attr('number'),
    platedCutouts: _emberData['default'].attr('number'),
    materialOther: _emberData['default'].attr('string'),
    counterSink: _emberData['default'].attr('number'),
    counterBore: _emberData['default'].attr('number'),
    viaPlug: _emberData['default'].attr('boolean', { defaultValue: false }),
    fileConvert: _emberData['default'].attr('boolean', { defaultValue: false }),
    platedEdges: _emberData['default'].attr('boolean', { defaultValue: false }),
    blindVias: _emberData['default'].attr('boolean', { defaultValue: false }),
    castelHoles: _emberData['default'].attr('boolean', { defaultValue: false }),
    contrldImp: _emberData['default'].attr('boolean', { defaultValue: false }),
    contrldImpList: _emberData['default'].attr('string', { defaultValue: 'None' }),
    serialize: _emberData['default'].attr('boolean', { defaultValue: false }),
    fai: _emberData['default'].attr('string', { defaultValue: 'None' }),
    cov19Critical: _emberData['default'].attr('boolean', { defaultValue: false }),
    logo: _emberData['default'].attr('boolean', { defaultValue: false }),
    panelApprove: _emberData['default'].attr('boolean', { defaultValue: false }),
    contDepthMill: _emberData['default'].attr('boolean', { defaultValue: false }),
    dpasPriority: _emberData['default'].attr('boolean', { defaultValue: false }),
    layersOther: _emberData['default'].attr('number'),
    thicknessOther: _emberData['default'].attr('string'),
    copperWeightOther: _emberData['default'].attr('string'),
    innerTrace: _emberData['default'].attr('string'),
    innerTraceOther: _emberData['default'].attr('string'),
    outerTraceOther: _emberData['default'].attr('string'),
    smallestDrillOther: _emberData['default'].attr('string'),
    inspectionOther: _emberData['default'].attr('string'),
    finishOther: _emberData['default'].attr('string'),
    stdMLConstruction: _emberData['default'].attr('boolean', { defaultValue: true }),
    cageCode: _emberData['default'].attr('boolean', { defaultValue: false }),
    coC: _emberData['default'].attr('boolean', { defaultValue: false }),
    isOfflineFabrication: _emberData['default'].attr('boolean', { defaultValue: false }),

    // Associations
    fabTurnTimes: _emberData['default'].hasMany('fabrication-turn-time', { async: true }),

    quoteAssemblyPricing: _emberData['default'].belongsTo('quote-assembly-pricing', { async: true }),
    project: _emberData['default'].belongsTo('project', { async: true }),

    keysToSerialize: ["additionalBoardQuantity", "copperWeight", "countOfHoles", "dateCode", "edgeLengthOfGoldFingers", "electricalTest", "height", "layers", "slotsOrCutoutsPerBoard", "lotCode", "minTraceAndSpace", "projectGUID", "routing", "serviceType", "smallestHoleSize", "solderMaskColor", "surfaceFinish", "thickness", "turnTime", "ulCertification", "width", "customFabQuote", "customFabNotes", 'quoteAssemblyPricing.quantity', 'userSelectedTurnTime', "silkscreenColor", "silkscreenSides", "engineering", "material", "solderMaskSides", "silkScreenClipping", "inspection", "goldFingerSides", "bevel", "crossSectionRpt", "platedSlots", "platedCutouts", "materialOther", "counterSink", "counterBore", "viaPlug", "fileConvert", "platedEdges", "blindVias", "castelHoles", "contrldImp", "contrldImpList", "serialize", "fai", "cov19Critical", "logo", "dpasPriority", "panelApprove", "contDepthMill", "layersOther", "thicknessOther", "copperWeightOther", "innerTrace", "innerTraceOther", "outerTraceOther", "smallestDrillOther", "inspectionOther", "finishOther", "stdMLConstruction", "fabNotes", "coC", "quoteAssemblyPricing.isITAR", "quoteAssemblyPricing.assemblyServiceName", "quoteAssemblyPricing.isFlexBoard", "cageCode"],

    addFullProtoOrPanelizeError: function addFullProtoOrPanelizeError() {
      //bc if we are already going to inform the user they must panelize,
      //it's less necessary to warn them that they're limited to full proto unless they do panelize
      if (!this.requiresPanelization()) {
        this.send('becomeDirty');
        this.get('errors').add('assemblyServiceAlert', 'Note that assembly pricing will be limited to Full Proto, unless you choose to panelize the board. Short-run assembly requires the panelization of boards under 16 square inches.');
      }
    },

    userHasSeletedTurnTime: (function () {
      return !_ember['default'].isEmpty(this.get('userSelectedTurnTime'));
    }).property('userSelectedTurnTime'),

    changeSilkscreenColor: (function () {
      if (this.get('silkscreenSides') !== 'None' && this.get('silkscreenColor') === 'None' || this.get('silkscreenSides') === 'None') {
        this.set('silkscreenColor', 'White');
      }
    }).observes('silkscreenSides'),

    changeSolderMaskColor: (function () {
      if (this.get('solderMaskSides') === 'None') {
        this.set('solderMaskColor', 'None');
      }
      if (this.get('solderMaskSides') !== 'None' && this.get('solderMaskColor') === 'None') {
        this.set('solderMaskColor', 'Green');
      }
    }).observes('solderMaskSides'),

    clearInProgressValues: function clearInProgressValues() {
      this.set('countOfHoles', undefined);
      this.set('height', undefined);
      this.set('slotsOrCutoutsPerBoard', undefined);
      this.set('userSelectedTurnTime', undefined);
      this.set('width', undefined);
      this.set('customFabNotes', undefined);
      this.set('turnDaysDisplay', undefined);
      this.set('quoteTotal', undefined);

      this.set('userAlteredAttributes', []);
    },

    dimensionTooSmall: function dimensionTooSmall(dimension) {
      dimension = this.get(dimension.toLowerCase());
      return dimension ? dimension < 0.25 : false;
    },

    //while this only returns additionalBoardQuantity, we needed a property that was only updated when quoteTotal
    //changed. Otherwise, additionalBoardQuantity would update in the quote footer before the pricing request completed
    extraBoardsOnSubtotalChange: (function () {
      return this.get('additionalBoardQuantity');
    }).property('quoteTotal'),

    fullProtoOrPanelize: function fullProtoOrPanelize() {
      var height = this.get('height');
      var width = this.get('width');
      var isFullProto = this.get('assemblyServiceName') === 'Full Proto';
      if (height && width) {
        return isFullProto && height * width < 16;
      }
    },

    hasStandardTurnTime: (function () {
      return this.get('turnTime') === '1 WEEK';
    }).property('turnTime'),

    isTooBig: function isTooBig() {
      var heightTooBig = this.get('height') > 18;
      var widthTooBig = this.get('width') > 18;
      return heightTooBig || widthTooBig;
    },

    isTooSmall: function isTooSmall() {
      var heightTooSmall = this.dimensionTooSmall('height');
      var widthTooSmall = this.dimensionTooSmall('width');
      return heightTooSmall || widthTooSmall;
    },

    //perBoardPrice: function() {
    //  return 654.32;
    /*
    var quantity =  this.get('quoteAssemblyPricing.quantity');
    if (quantity === '0' || Ember.isBlank(quantity)) {
      return 0;
    } else {
      return (this.get('quoteTotal') / quantity).toFixed();
    } */
    // }.property('quoteTotal', 'turnTime', 'quoteAssemblyPricing.quantity'),
    //}.property('quoteTotal'),

    requiresPanelization: function requiresPanelization() {
      var height = this.get('height');
      var width = this.get('width');
      var heightRequiresPanelization = height ? height >= 0.25 && height < 0.75 : false;
      var widthRequiresPanelization = width ? width >= 0.25 && width < 0.75 : false;

      return heightRequiresPanelization || widthRequiresPanelization;
    },

    setServiceTypeDefaults: function setServiceTypeDefaults() {
      this.setProperties({
        'copperWeight': '1 oz.',
        'minTraceAndSpace': '6 - 9 mils',
        'routing': 'Individual',
        'material': 'FR4 150 Tg',
        'silkscreenColor': 'White',
        'silkscreenSides': 'Both',
        'solderMaskColor': 'Green',
        'solderMaskSides': 'Both',
        'silkScreenClipping': true,
        'smallestHoleSize': 'Over 15 mils',
        'thickness': '0.062 in',
        'turnTime': '1 WEEK',
        'layers': '2',
        'innerTrace': '',
        'surfaceFinish': 'Silver',
        'customFabQuote': false,
        'customFabNotes': '',
        'serviceType': '',
        'engineering': 'Limited Review',
        'stdMLConstruction': true,
        'inspection': 'IPC A 600 Class 2',
        'edgeLengthOfGoldFingers': 0,
        'goldFingerSides': 1,
        'bevel': 'None',
        'crossSectionRpt': false,
        'platedSlots': undefined,
        'platedCutouts': undefined,
        'slotsOrCutoutsPerBoard': undefined,
        'counterSink': undefined,
        'counterBore': undefined,
        'layersOther': '',
        'materialOther': '',
        'thicknessOther': '',
        'copperWeightOther': '',
        'innerTraceOther': '',
        'outerTraceOther': '',
        'smallestDrillOther': '',
        'inspectionOther': '',
        'finishOther': '',
        'lotCode': false,
        'dateCode': false,
        'ulCertification': false,
        'electricalTest': true,
        'viaPlug': false,
        'fileConvert': false,
        'platedEdges': false,
        'blindVias': false,
        'castelHoles': false,
        'contrldImp': false,
        'contrldImpList': 'None',
        'serialize': false,
        'fai': 'None',
        'cov19Critical': false,
        'logo': false,
        'panelApprove': false,
        'contDepthMill': false,
        'dpasPriority': false,
        'cageCode': false,
        'coC': false
      });
    },

    configureToRequestAllTurnTimes: function configureToRequestAllTurnTimes() {
      this.set('serviceType', null);
    },

    setupToValidate: function setupToValidate() {
      this.alterAttributes();
      this.configureToRequestAllTurnTimes();
    },

    //TODO: Deprecating for now, remove completely when proved unecessary.
    //We were trying to handle two different types of sunstone failures: unexepected results from the API (sunstoneFailure) and complete failures (sunstoneUnavailable)
    //Now we have consolidated both of these cases into sunstoneUnavailable and don't believe the sunstoneFailure case is necessary.
    /*
    sunstoneFailure: function() {
      return this.get('validationStatus') === 'SunstoneFailure';
    }.property('validationStatus'),
    */

    triggerPriceDifferences: function triggerPriceDifferences() {
      return this.get('triggerPriceDifference') || false;
    },

    updateTurnTimeSelectionComponent: (function () {
      return false;
    }).property(),

    updateWithTurnTime: function updateWithTurnTime(turnTimeResponse) {
      this.setProperties({ turnTime: turnTimeResponse.get('turnTime'),
        turnDaysDisplay: turnTimeResponse.get('turnDaysDisplay'),
        serviceType: turnTimeResponse.get('serviceType'),
        quoteTotal: turnTimeResponse.get('quoteTotal'),
        validationStatus: turnTimeResponse.get('validationStatus'),
        perBoardPrice: turnTimeResponse.get('perBoardPrice'),
        customFabQuote: turnTimeResponse.get('customFabQuote'),
        customFabNotes: turnTimeResponse.get('customFabNotes'),
        hasValidTurnTime: true });
      if (this.fullProtoOrPanelize()) {
        this.addFullProtoOrPanelizeError();
      }
      this.toggleProperty('triggerPriceDifference');
    },

    userTurnTimePreference: (function () {
      return this.get('userSelectedTurnTime') || '1 WEEK';
    }).property('userSelectedTurnTime')

  });
});