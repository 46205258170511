define("web-app-frontend/controllers/contact", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    isSubmitted: false,
    contactUsPageCaptchaWidgetId: undefined,

    actions: {
      submit: function submit() {
        var grecaptcha = window.grecaptcha;
        var captchaId = this.get("contactUsPageCaptchaWidgetId");
        var token = grecaptcha.getResponse(captchaId);
        var self = this;
        var submitBtnComponent = this.get("submitButton");
        this.model.setupForCommit();
        self.model.set("hubspotUtkCookie", $.cookie("hubspotutk"));
        this.model.set("recaptchaToken", token);
        this.model.save().then(function () {
          submitBtnComponent.send("hasSucceeded");
          self.set("isSubmitted", true);
          // targetting based off of window height to not get any jumping
          if ($(window).height() < 1050) {
            $("html, body").animate({ scrollTop: $("#contact-form").offset().top - 20 }, 100);
          }
        })["catch"](function (error) {
          var captchaError = error.errors.find(function (err) {
            return err.source.pointer === "data/attributes/recaptchaToken";
          });

          if (captchaError) {
            // this.set('captchaError', captchaError);
            submitBtnComponent.send("showCustomError", captchaError.title);
          } else {
            submitBtnComponent.send("showError");
          }
          console.log("save contact fail");
          submitBtnComponent.send("showError");
        })["finally"](function () {
          submitBtnComponent.send("hideSpinner");
        });
      },

      validateContact: function validateContact() {

        this.model.alterAttributes();
        this.model.save().then(function () {
          console.log("validateContact success");
        })["catch"](function () {
          console.log("validateContact fail");
        });
      }
    }
  });
});