define('web-app-frontend/components/worksheet-select-box', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    worksheetName: null,
    closeModal: 'closeModal',
    hideSpinner: 'hideSpinner',
    session: _ember['default'].inject.service(),

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    userPresent: function userPresent() {
      return this.get('session.isAuthenticated');
    },

    buildOauthToken: function buildOauthToken() {
      var tokenString = '';
      if (this.userPresent()) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    actions: {
      bomSetWorksheet: function bomSetWorksheet() {
        var selectedWorksheet = $(document).find('#x-worksheet-selection').val();
        var self = this;
        var projectGUID = this.projectGUID;
        var bomGUID = this.bomGUID;
        var fileBOMName = this.fileBOMName;
        var progress = this.progress;
        var worksheetUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/bomupload/' + '?Api-Key=SCWEB';
        worksheetUrl += '&bomGUID=' + bomGUID;
        worksheetUrl += '&FileBOMName=' + fileBOMName;
        worksheetUrl += '&SelectedWorksheet=' + selectedWorksheet;
        worksheetUrl += '&isKittedBom=' + self.isKittedBom;
        worksheetUrl += '&SessionGUID=' + $.cookie('sessionGUID');

        var oauthToken = this.buildOauthToken();
        var myComponent = this.myComponent;
        $.ajax({
          url: worksheetUrl,
          type: 'PUT',
          dataType: 'json',
          headers: oauthToken
        }).done(function () {
          //takes single param for returned value if needed.
          self.sendAction('hideSpinner');
          self.sendAction('closeModal');
          self.openBOMModal(myComponent, "quoting.bom-loading-modal", progress);
          window.setTimeout(self.fileUpload.bomParseStatus(projectGUID, bomGUID, progress), 500);
        }).fail(function (data) {
          //Oops, something went wrong.
          console.log('failure, data is: ', data);
          self.sendAction('hideSpinner');
        });
      }
    },

    openBOMModal: function openBOMModal(component, modalName, model) {
      component.send('openModal', modalName, model);
    },

    closeBOMModal: function closeBOMModal(component) {
      component.send('closeModal');
    }

  });
});