define('web-app-frontend/models/order-contact', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    dayEmergencyContactName: _emberData['default'].attr("string"),
    dayEmergencyContactPreference: _emberData['default'].attr("string", { defaultValue: 'Phone' }),
    dayEmergencyContactDetails: _emberData['default'].attr("string"),
    nightEmergencyContactName: _emberData['default'].attr("string"),
    nightEmergencyContactPreference: _emberData['default'].attr("string", { defaultValue: 'Phone' }),
    nightEmergencyContactDetails: _emberData['default'].attr("string"),
    nightSameAsDay: _emberData['default'].attr("boolean", { defaultValue: false }),
    projectGUID: _emberData['default'].attr("string"),

    setNighttimeContactInfo: function setNighttimeContactInfo() {
      this.setProperties({ nightEmergencyContactName: this.get('dayEmergencyContactName'),
        nightEmergencyContactPreference: this.get('dayEmergencyContactPreference'),
        nightEmergencyContactDetails: this.get('dayEmergencyContactDetails')
      });
    },

    clearNighttimeContactInfo: (function () {
      if (this.get('nightSameAsDay') === true) {
        this.setProperties({ nightEmergencyContactName: '',
          nightEmergencyContactPreference: 'Phone',
          nightEmergencyContactDetails: ''
        });
        this.clearNighttimeContactErrors();
      }
    }).observes('nightSameAsDay'),

    clearNighttimeContactErrors: function clearNighttimeContactErrors() {
      //else validation errors might show in locked section
      this.get('errors').remove('nightEmergencyContactName');
      this.get('errors').remove('nightEmergencyContactDetails');
    }
  });
});