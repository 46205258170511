define('web-app-frontend/models/quote-fabrication', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    additionalBoardQuantity: _emberData['default'].attr('number'),
    assemblyServiceName: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    copperWeight: _emberData['default'].attr('string'),
    copperWeightDisplay: _emberData['default'].attr('string'),
    countOfHoles: _emberData['default'].attr('number'),
    customFabNotes: _emberData['default'].attr('string'),
    customFabQuote: _emberData['default'].attr('boolean', { defaultValue: false }),
    isOfflineFabrication: _emberData['default'].attr('boolean'),
    dateCode: _emberData['default'].attr('boolean'),
    dateCodeDisplay: _emberData['default'].attr('string'),
    designViolations: _emberData['default'].attr(),
    edgeLengthOfGoldFingers: _emberData['default'].attr('number'),
    edgeLengthOfGoldFingersDisplay: _emberData['default'].attr('string'),
    electricalTest: _emberData['default'].attr('boolean', { defaultValue: true }),
    electricalTestDisplay: _emberData['default'].attr('string'),
    expedite: _emberData['default'].attr('boolean'),
    estimatedShipDate: _emberData['default'].attr('string'),
    fabNotes: _emberData['default'].attr('string'),
    height: _emberData['default'].attr('number'),
    invalidatedQuoteTotal: _emberData['default'].attr('number'),
    isITAR: _emberData['default'].attr('boolean'),
    isReorder: _emberData['default'].attr('boolean'),
    lastQuoteDate: _emberData['default'].attr('string'),
    layers: _emberData['default'].attr('string'),
    lotCode: _emberData['default'].attr('boolean'),
    lotCodeDisplay: _emberData['default'].attr('string'),
    minTraceAndSpace: _emberData['default'].attr('string'),
    minTraceAndSpaceDisplay: _emberData['default'].attr('string'),
    perBoardPrice: _emberData['default'].attr('number'),
    projectGUID: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    quoteDiscount: _emberData['default'].attr('number'),
    quoteTotal: _emberData['default'].attr('number'),
    quoteTotalWithDiscount: _emberData['default'].attr('number'),
    referralServicePartnerCode: _emberData['default'].attr('string'),
    routing: _emberData['default'].attr('string'),
    routingDisplay: _emberData['default'].attr('string'),
    saveAction: _emberData['default'].attr('string'),
    serviceType: _emberData['default'].attr('string'),
    silkscreenColor: _emberData['default'].attr('string'),
    silkscreenColorDisplay: _emberData['default'].attr('string'),
    silkscreenSides: _emberData['default'].attr('string'),
    silkscreenSidesDisplay: _emberData['default'].attr('string'),
    slotsOrCutoutsPerBoard: _emberData['default'].attr('number'),
    smallestHoleSize: _emberData['default'].attr('string'),
    smallestHoleSizeDisplay: _emberData['default'].attr('string'),
    solderMaskColor: _emberData['default'].attr('string'),
    solderMaskColorDisplay: _emberData['default'].attr('string'),
    solderMaskSidesDisplay: _emberData['default'].attr('string'),
    surfaceFinish: _emberData['default'].attr('string'),
    surfaceFinishDisplay: _emberData['default'].attr('string'),
    thickness: _emberData['default'].attr('string'),
    thicknessDisplay: _emberData['default'].attr('string'),
    totalQuantity: _emberData['default'].attr('number'),
    turnDaysDisplay: _emberData['default'].attr('string'),
    turnTime: _emberData['default'].attr('string'),
    userSelectedTurnTime: _emberData['default'].attr('string'),
    ulCertification: _emberData['default'].attr('boolean'),
    ulCertificationDisplay: _emberData['default'].attr('string'),
    validationStatus: _emberData['default'].attr('string'),
    width: _emberData['default'].attr('number'),
    engineering: _emberData['default'].attr('string'),
    material: _emberData['default'].attr('string'),
    solderMaskSides: _emberData['default'].attr('string'),
    silkScreenClipping: _emberData['default'].attr('boolean'),
    silkscreenClippingDisplay: _emberData['default'].attr('string'),
    inspection: _emberData['default'].attr('string'),
    goldFingerSides: _emberData['default'].attr('number'),
    bevel: _emberData['default'].attr('string'),
    crossSectionRpt: _emberData['default'].attr('boolean'),
    crossSectionRptDisplay: _emberData['default'].attr('string'),
    platedSlots: _emberData['default'].attr('number'),
    platedCutouts: _emberData['default'].attr('number'),
    materialOther: _emberData['default'].attr('string'),
    counterSink: _emberData['default'].attr('number'),
    counterBore: _emberData['default'].attr('number'),
    viaPlug: _emberData['default'].attr('boolean'),
    viaPlugDisplay: _emberData['default'].attr('string'),
    fileConvert: _emberData['default'].attr('boolean'),
    fileConvertDisplay: _emberData['default'].attr('string'),
    platedEdges: _emberData['default'].attr('boolean'),
    platedEdgesDisplay: _emberData['default'].attr('string'),
    blindVias: _emberData['default'].attr('boolean'),
    blindViasDisplay: _emberData['default'].attr('string'),
    castelHoles: _emberData['default'].attr('boolean'),
    castelHolesDisplay: _emberData['default'].attr('string'),
    contrldImp: _emberData['default'].attr('boolean'),
    contrldImpList: _emberData['default'].attr('string'),
    contrldImpDisplay: _emberData['default'].attr('string'),
    serialize: _emberData['default'].attr('boolean'),
    serializeDisplay: _emberData['default'].attr('string'),
    fai: _emberData['default'].attr('string'),
    faiDisplay: _emberData['default'].attr('string'),
    cov19Critical: _emberData['default'].attr('boolean'),
    cov19CriticalDisplay: _emberData['default'].attr('string'),
    logo: _emberData['default'].attr('boolean'),
    logoDisplay: _emberData['default'].attr('string'),
    panelApprove: _emberData['default'].attr('boolean'),
    panelApproveDisplay: _emberData['default'].attr('string'),
    contDepthMill: _emberData['default'].attr('boolean'),
    contDepthMillDisplay: _emberData['default'].attr('string'),
    dpasPriority: _emberData['default'].attr('boolean'),
    dpasPriorityDisplay: _emberData['default'].attr('string'),
    layersOther: _emberData['default'].attr('number'),
    thicknessOther: _emberData['default'].attr('number'),
    copperWeightOther: _emberData['default'].attr('string'),
    innerTrace: _emberData['default'].attr('string'),
    innerTraceOther: _emberData['default'].attr('string'),
    outerTraceOther: _emberData['default'].attr('string'),
    smallestDrillOther: _emberData['default'].attr('string'),
    inspectionOther: _emberData['default'].attr('string'),
    finishOther: _emberData['default'].attr('string'),
    stdMLConstruction: _emberData['default'].attr('boolean'),
    stdMLConstructionDisplay: _emberData['default'].attr('string'),
    cageCode: _emberData['default'].attr('boolean'),
    cageCodeDisplay: _emberData['default'].attr('string'),
    coC: _emberData['default'].attr('boolean'),
    coCDisplay: _emberData['default'].attr('string'),

    project: _emberData['default'].belongsTo('project', { async: true }),
    pricingObject: _emberData['default'].belongsTo('quote-fabrication-pricing', { async: true }),

    hasStandardTurnTime: (function () {
      return this.get('turnTime') === '1 WEEK';
    }).property('turnTime')
  });
});