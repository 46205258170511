define('web-app-frontend/routes/resource-center/services/turn-key', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: "Turn-Key PCB Assembly",

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Screaming Circuits can do full or partial turnkey assemblies with any combination of parts or boards that you order or we source for you.'
      }
    }]
  });
});