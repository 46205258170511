define('web-app-frontend/controllers/quoting/bom-loading-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    quotingController: _ember['default'].inject.controller('quoting'),

    actions: {
      abortBomUpload: function abortBomUpload() {
        var quotingController = this.get('quotingController');
        var partsComponent = quotingController.get('quotePartsPricingComponent');
        var bomUploadComponent = partsComponent.get('bomFileUploadComponent');

        this.store.deleteRecord(this.model);
        this.send('closeModal');
        bomUploadComponent.hideSpinners();
        partsComponent.send('updateUploadText', 'No File Chosen');
      }
    }
  });
});