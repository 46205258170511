define("web-app-frontend/templates/components/form-passwordfield-display", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/form-passwordfield-display.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "error-spacing");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", "password", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [1, 30], [1, 35]]]]], [], []], "class", ["subexpr", "@mut", [["get", "attribute", ["loc", [null, [1, 42], [1, 51]]]]], [], []], "id", ["subexpr", "@mut", [["get", "inputId", ["loc", [null, [1, 55], [1, 62]]]]], [], []]], ["loc", [null, [1, 0], [1, 64]]]], ["inline", "error-display", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 24], [3, 29]]]]], [], []], "attribute", ["subexpr", "@mut", [["get", "attribute", ["loc", [null, [3, 40], [3, 49]]]]], [], []], "type", "input"], ["loc", [null, [3, 2], [3, 64]]]]],
      locals: [],
      templates: []
    };
  })());
});