define("web-app-frontend/controllers/quoting/bom-alternative-modal", ["exports", "ember", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendConfigEnvironment) {
    exports["default"] = _ember["default"].Controller.extend({
        isErrorActive: false,
        alternativePartsErrors: "",
        showSpinnerForSearch: false,
        showSpinnerForSave: false,
        disableAMLBtns: _ember["default"].computed.or('showSpinnerForSearch', 'showSpinnerForSave'),
        session: _ember["default"].inject.service(),
        AMLParts: [],

        addAlternativeBomLine: function addAlternativeBomLine(data) {
            var self = this;
            var bomlines = [];
            if (data.projectBOMModel.bomLines && data.projectBOMModel.bomLines.data) {
                data.projectBOMModel.bomLines.data.forEach(function (line) {
                    line.DBAction = "NEW";
                    bomlines.push(self.createAlternativeBomLine(line));
                });
            }
            if (bomlines) {
                bomlines.forEach(function (element) {
                    return self.get('model.alternativeParts').pushObject(element);
                });
                self.get('AMLParts').addObjects(bomlines);
            }
        },

        setAlternativePartProperties: function setAlternativePartProperties(line) {
            return {
                "bmlId": line.get('id'),
                "lineNumber": line.get('lineNumber'),
                "SubmittedManufactureNumber": line.get('submittedReferenceData.manufactureNumber'),
                "ResultPartDescription": line.get('resultReferenceData.partDescription'),
                "ResultManufactureNumber": line.get('manufactureNumber'),
                "sourceVendor": line.get('sourceVendor'),
                "SelectedSourcingAction": this.get('model.selectedSourcingAction')
            };
        },

        createAlternativeBomLine: function createAlternativeBomLine(line) {
            var project = this.get('model.project');
            var bomLine = this.store.createRecord('bomLine', line);
            var bmlId = line.bmlId;
            var bom = project.get('bomDetails');
            bomLine.setProperties({ id: bmlId,
                manufactureNumber: line.resultReferenceData.manufactureNumber,
                manufactureName: line.resultReferenceData.manufactureName,
                purchasedPartNumberOverride: line.resultReferenceData.purchasedPartNumberOverride,
                partDescription: line.resultReferenceData.partDescription,
                partImageURL: line.resultReferenceData.partImageURL,
                partImageURLssl: line.resultReferenceData.partImageURLssl,
                vendorPartNumber: line.resultReferenceData.vendorPartNumber,
                sourceVendor: line.resultReferenceData.sourceVendor,
                quantityValidation: line.resultReferenceData.quantityValidation,
                project: project,
                bom: bom
            });
            return bomLine;
        },

        saveBomLine: function saveBomLine(data, url, saveAlternativeLineDeferred) {
            var self = this;
            var oauthToken = this.fetchOauthToken();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(data),
                contentType: 'application/json; charset=UTF-8',
                cache: false,
                headers: oauthToken,
                dataType: 'json'
            }).done(function () {
                saveAlternativeLineDeferred.resolve();
            }).fail(function () {
                self.set('showSpinnerForSave', false);
            });
        },

        fetchOauthToken: function fetchOauthToken() {
            var tokenString = '';
            if (this.get('session.isAuthenticated')) {
                tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
            }
            return { 'Authorization': tokenString };
        },

        openBOMModal: function openBOMModal(modalName) {
            var self = this;
            var project = self.store.peekRecord('project', self.get('model.project.id'));
            var bom = project.get('bomDetails');

            bom.set('quotedBoardCount', project.get('assemblyDetails.quantity'));

            var bomLines = bom.get('bomLines').filterBy('bmlIsSelected', true);

            var itemNumbersPresent = !bomLines.mapBy('itemNumber').contains(0);

            var modalModel = { storedBomLines: bomLines,
                bom: bom,
                project: project,
                newBom: false,
                itemNumbersPresent: itemNumbersPresent
            };
            self.send('openModal', modalName, modalModel);
        },

        getSourcedPartsTotal: function getSourcedPartsTotal(bomLines, attribute) {
            var attrArray = bomLines.filterBy('sourcingAction', 'Purchase').mapBy(attribute);
            if (attrArray.length > 0) {
                return attrArray.reduce(function (a, b) {
                    return a + b;
                });
            } else {
                return 0;
            }
        },

        deleteAlternativePart: function deleteAlternativePart(id, lineNumber, mfgNumber) {

            console.log("In deleteAlternativePart--> ID = " + id + ", LineNum = " + lineNumber + ", MfgNum = " + mfgNumber);

            var targetObject = this.get('targetObject');

            var store = targetObject.get('store');

            var projectId = targetObject.get('model.project.id');

            var bom = store.peekRecord('project', projectId).get('bomDetails');

            var bomLinesToDelete = targetObject.get('model.alternativeParts').filter(function (x) {
                return x.id === id && x.get('manufactureNumber') === mfgNumber;
            });

            if (id === 0) {
                bomLinesToDelete.forEach(function (x) {
                    return x.unloadRecord();
                }); // unload from the store
                targetObject.set('AMLParts', targetObject.get('AMLParts').filter(function (x) {
                    return x.id !== id && x.manufactureNumber !== mfgNumber;
                })); // clear from local state
            } else {
                    if (bomLinesToDelete && bomLinesToDelete.length > 0) {
                        bomLinesToDelete.forEach(function (e) {
                            return e.DBAction = "DEL";
                        });
                        targetObject.get('AMLParts').addObjects(bomLinesToDelete);
                    }
                }
            //reopening the Alternative BOM Modal
            var _targetObject$model = targetObject.model;
            var selectedLineId = _targetObject$model.selectedLineId;
            var selectedSourcingAction = _targetObject$model.selectedSourcingAction;
            var project = _targetObject$model.project;
            var selectedMfgNumber = _targetObject$model.selectedMfgNumber;

            //filtering out the deleted parts
            var alternativeParts = bom.get('bomLines').filter(function (bomline) {
                return bomline.get('lineNumber') === lineNumber && bomline.get('DBAction') !== 'DEL';
            });

            targetObject.send('openModal', 'quoting.bom-alternative-modal', {
                alternativeParts: alternativeParts,
                selectedLineId: selectedLineId,
                selectedMfgNumber: selectedMfgNumber,
                project: project,
                bom: bom,
                selectedSourcingAction: selectedSourcingAction
            });
        },

        actions: {
            closeAlternateBomModal: function closeAlternateBomModal() {
                var self = this;
                var bom = this.get('store').peekRecord('project', this.get('model.project.id')).get('bomDetails');
                self.send('closeModal');
                self.openBOMModal('quoting.bom-modal');
                self.set('isErrorActive', false);
                self.set('alternativePartsErrors', '');
                self.set('AMLParts', []);

                var alternativeParts = bom.get('bomLines').filter(function (bomline) {
                    return bomline.get('lineNumber') === self.get('model.alternativeParts')[0].get('lineNumber');
                });

                //Rollback the DB action from DEL to null if the user dosen't save changes
                alternativeParts.forEach(function (e) {
                    e.set('DBAction', null);
                });

                //Clear searched parts which are not saved in DB yet.
                self.get('model.alternativeParts').forEach(function (record) {
                    if (record.get('id') === 0) {
                        record.unloadRecord();
                    }
                });
            },

            searchAlternativePart: function searchAlternativePart() {
                var self = this;
                self.set('isErrorActive', false);

                var bomlines = this.get('model.alternativeParts').filterBy('manufactureNumber', this.get('searchparts').toUpperCase());

                if (_ember["default"].isBlank(this.get('searchparts')) || bomlines.length > 0) {
                    self.set('isErrorActive', true);
                    if (bomlines.length > 0) {
                        self.set('alternativePartsErrors', 'AlternativePart number is already present.');
                    } else if (_ember["default"].isBlank(this.get('searchparts'))) {
                        self.set('alternativePartsErrors', 'Please enter alternativePart Number.');
                    }
                } else {
                    self.set('showSpinnerForSearch', true);
                    var data = {
                        "ProjectBOMAltPartSearchModel": {
                            "bomLineId": this.get('model.selectedLineId'),
                            "mfgPartNumber": this.get('searchparts')
                        }
                    };

                    var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + this.get('model.project.id') + '/alternatepart?Api-Key=SCWEB&SessionGUID=' + $.cookie('sessionGUID');
                    var oauthToken = this.fetchOauthToken();

                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: JSON.stringify(data),
                        contentType: 'application/json; charset=UTF-8',
                        cache: false,
                        headers: oauthToken,
                        dataType: 'json'
                    }).done(function (data) {
                        self.addAlternativeBomLine(data);
                        self.set('searchparts', '');
                        self.set('alternativePartsErrors', '');
                        self.set('showSpinnerForSearch', false);
                    }).fail(function (data) {
                        self.set('searchparts', '');
                        self.set('isErrorActive', true);
                        self.set('alternativePartsErrors', data.responseJSON.model);
                        self.set('showSpinnerForSearch', false);
                    });
                }
            },

            saveAlternativePart: function saveAlternativePart() {
                var _this = this;

                var self = this;

                var project = self.store.peekRecord('project', self.get('model.project.id'));

                var bmhid = project.get('bomDetails').get('bomHeaderId');

                var originalBOM = project.get('bomDetails');

                var projectguid = self.get('model.project.id');

                var newAlternateParts = this.AMLParts.filter(function (x) {
                    return x.DBAction === "NEW";
                });

                var amlPartsToDelete = this.AMLParts.filter(function (x) {
                    return x.DBAction === "DEL";
                });

                var isNewPartPresent = newAlternateParts !== null && newAlternateParts.length > 0;

                var isDeletePartPresent = amlPartsToDelete !== null && amlPartsToDelete.length > 0;

                if (!isDeletePartPresent && !isNewPartPresent) {
                    this.send('closeModal');
                    self.openBOMModal('quoting.bom-modal');
                } else {
                    var data;
                    var saveAlternativeLineDeferred;
                    var url;

                    (function () {
                        //fetch the lineNumber from AMLParts prop
                        var lineNumber = self.AMLParts[0].get('lineNumber');

                        //unload the AML lines from store as we update those lines from DB using "bom-line" Query
                        var bomLinesToUnload = originalBOM.get('bomLines').filter(function (x) {
                            return x.get('lineNumber') === lineNumber;
                        });

                        self.set('showSpinnerForSave', true);

                        data = {
                            "ProjectBOMAltModel": {
                                "bomHeaderId": _this.get('model.bom.bomHeaderId'),
                                "bomLines": []
                            }
                        };

                        newAlternateParts.forEach(function (element) {
                            var alternativePart = self.setAlternativePartProperties(element);
                            alternativePart.DBAction = "NEW";
                            data["ProjectBOMAltModel"]["bomLines"].pushObject(alternativePart);
                        });

                        amlPartsToDelete.forEach(function (element) {
                            var alternativePart = self.setAlternativePartProperties(element);
                            alternativePart.DBAction = "DEL";
                            data["ProjectBOMAltModel"]["bomLines"].pushObject(alternativePart);
                        });

                        saveAlternativeLineDeferred = new _ember["default"].RSVP.defer('saveAlternativeLineDeferred');
                        url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + _this.get('model.project.id') + '/alternatepart?Api-Key=SCWEB&SessionGUID=' + $.cookie('sessionGUID');

                        self.saveBomLine(data, url, saveAlternativeLineDeferred);

                        saveAlternativeLineDeferred.promise.then(function () {

                            // self.store.unloadAll('bom-progress');
                            // self.store.unloadAll('bom-line');
                            //self.store.unloadAll('bom');

                            bomLinesToUnload.forEach(function (e) {
                                return e.unloadRecord();
                            });

                            self.store.query('bom-line', { bomheaderId: bmhid, projectGUID: projectguid, lineNumber: lineNumber }).then(function (lines) {

                                if (lines) {
                                    lines.forEach(function (line) {
                                        line.bom = originalBOM;
                                        line.project = project;
                                    });
                                }

                                originalBOM.get('bomLines').addObjects(lines);

                                var storedBomLines = originalBOM.get('bomLines').filterBy('bmlIsSelected', true);
                                //if BOM is not attached to Project then calculate the pricing on UI
                                if (project.get('partsIncluded').toString().toLowerCase() === 'unknown') {
                                    originalBOM.setProperties({ partsTotal: self.getSourcedPartsTotal(storedBomLines, 'quotedLineTotalPrice'),
                                        partCostPerBoard: self.getSourcedPartsTotal(storedBomLines, 'calculatedLinePerBoardPrice'),
                                        partsCount: self.getSourcedPartsTotal(storedBomLines, 'placementsPerBoard'),
                                        quotedBoardCount: self.get('firstObject.quotedBoardCount')
                                    });
                                }
                                self.set('showSpinnerForSave', false);
                                self.send('closeModal');
                                self.set('isErrorActive', false);
                                self.set('AMLParts', []);
                                self.openBOMModal('quoting.bom-modal');
                            });
                        });
                    })();
                }
            }
        }
    });
});