define("web-app-frontend/models/user", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    // Attribtues
    commitOnSuccess: _emberData["default"].attr("boolean"),
    companyName: _emberData["default"].attr("string"),
    contactPhone: _emberData["default"].attr("string"),
    contactPermission: _emberData["default"].attr("boolean"),
    emailAddress: _emberData["default"].attr("string"),
    faxNumber: _emberData["default"].attr("string"),
    firstName: _emberData["default"].attr("string"),
    lastName: _emberData["default"].attr("string"),
    loginName: _emberData["default"].attr("string"),
    mobile: _emberData["default"].attr("string"),
    title: _emberData["default"].attr("string"),
    titleDescription: _emberData["default"].attr("string"),
    userGUID: _emberData["default"].attr("string"), //for caching - clone will have unique IDs but must store parent record's ID/the userGUID
    industry: _emberData["default"].attr("string"),
    industryDescription: _emberData["default"].attr("string"),

    keysToSerialize: ["commitOnSuccess", "companyName", "contactPhone", "contactPermission", "emailAddress", "faxNumber", "firstName", "industry", "industryDescription", "lastName", "loginName", "mobile", "title", "titleDescription"]
    // Associations
  });
});