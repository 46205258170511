define('web-app-frontend/adapters/choice-production-contact', ['exports', 'web-app-frontend/adapters/application', 'ember', 'ember-data', 'web-app-frontend/config/environment', 'web-app-frontend/utils/error-formatting'], function (exports, _webAppFrontendAdaptersApplication, _ember, _emberData, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    validationToken: "",

    headers: _ember['default'].computed(function () {
      return {
        'SCValidationToken': this.get('validationToken')
      };
    }).property('validationToken').volatile(),

    pathForType: function pathForType() {
      return 'system/contactus?Api-Key=' + _webAppFrontendConfigEnvironment['default'].EmberENV.client_id;
    },

    createRecord: function createRecord(store, typeClass, snapshot) {
      var record = snapshot.serialize();
      var data = { 'contactUsModel': record };
      this.set('validationToken', record.validationToken);
      return this.ajax(this.buildURL(typeClass.modelName), 'POST', { data: data });
    },

    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var record = snapshot.serialize();
      var data = { 'contactUsModel': record };
      this.set('validationToken', record.validationToken);
      return this.ajax(this.buildURL(typeClass.modelName), 'POST', { data: data });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.contactUsModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.contactUsModel.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});