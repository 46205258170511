define('web-app-frontend/controllers/quoting/assembly-pricing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    quotingNewController: _ember['default'].inject.controller('quoting/new'),
    projectController: _ember['default'].inject.controller('project'),

    createNewQuoteAssemblyPricingObject: function createNewQuoteAssemblyPricingObject(id) {
      var record;
      if (!this.store.hasRecordForId('quoteAssemblyPricing', id)) {
        record = this.store.createRecord('quoteAssemblyPricing', { id: id,
          projectGUID: id,
          turnTimeCode: 'FP5',
          userSelectedTurnTime: 'FP5'
        });
      } else {
        record = this.store.peekRecord('quoteAssemblyPricing', id);
      }

      return record;
    }
  });
});