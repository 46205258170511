define("web-app-frontend/templates/components/bom-row", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 7,
              "column": 2
            }
          },
          "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "fa fa-exclamation-triangle fa-lg ml10 red");
          dom.setAttribute(el1, "rel", "popover");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "popover-wrapper none");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "error-text");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3, 1]), 0, 0);
          return morphs;
        },
        statements: [["content", "bomLine.whyNotQuoteable", ["loc", [null, [5, 28], [5, 57]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-exclamation-triangle fa-lg ml10 light-orange");
            dom.setAttribute(el1, "rel", "popover");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "popover-wrapper none");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "error-text");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n  ");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createUnsafeMorphAt(dom.childAt(fragment, [3, 1]), 0, 0);
            return morphs;
          },
          statements: [["content", "bomLine.whyNotQuoteable", ["loc", [null, [10, 28], [10, 57]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "bomLine.isRefDesError", ["loc", [null, [7, 12], [7, 33]]]]], [], 0, null, ["loc", [null, [7, 2], [12, 2]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 35,
              "column": 0
            },
            "end": {
              "line": 43,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "td-select minWidth140");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "bomLine.mountTypes", ["loc", [null, [37, 28], [37, 46]]]]], [], []], "optionValuePath", "content.value", "optionLabelPath", "content.text", "value", ["subexpr", "@mut", [["get", "bomLine.mountType", ["loc", [null, [40, 26], [40, 43]]]]], [], []], "classNames", "full-width x-bom-action"], ["loc", [null, [37, 4], [41, 58]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 43,
              "column": 0
            },
            "end": {
              "line": 45,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "bomLine.mountType", ["loc", [null, [44, 26], [44, 47]]]]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 49,
                "column": 4
              },
              "end": {
                "line": 53,
                "column": 4
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["content", "bomLine.minimumOrderQty", ["loc", [null, [51, 8], [51, 35]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 53,
                "column": 4
              },
              "end": {
                "line": 55,
                "column": 4
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createTextNode("--");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 60,
                  "column": 8
                },
                "end": {
                  "line": 62,
                  "column": 8
                }
              },
              "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              var el2 = dom.createTextNode("--");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 71,
                      "column": 16
                    },
                    "end": {
                      "line": 76,
                      "column": 16
                    }
                  },
                  "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "small flex jcSpacebetween mt5");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  dom.setAttribute(el2, "class", "small");
                  var el3 = dom.createTextNode("Min Order QTY:  ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "bold");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  dom.setAttribute(el2, "class", "small");
                  var el3 = dom.createTextNode("Mult Order QTY: ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("span");
                  dom.setAttribute(el3, "class", "bold");
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 0, 0);
                  morphs[1] = dom.createMorphAt(dom.childAt(element4, [3, 1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "bomLine.minimumOrderQty", ["loc", [null, [73, 72], [73, 99]]]], ["content", "bomLine.multipleOrderQty", ["loc", [null, [74, 72], [74, 100]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 62,
                    "column": 8
                  },
                  "end": {
                    "line": 85,
                    "column": 8
                  }
                },
                "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "rel", "popover");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "fa fa-cubes green font-18");
                dom.setAttribute(el2, "rel", "popover");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "popover-wrapper none");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                dom.setAttribute(el3, "class", "bg-faded-green p10 borderRadius5 flex column");
                var el4 = dom.createTextNode(" \n              ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("div");
                dom.setAttribute(el4, "class", "flex jcSpacebetween");
                var el5 = dom.createTextNode("\n                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("div");
                dom.setAttribute(el5, "class", " bold");
                var el6 = dom.createTextNode("IN STOCK");
                dom.appendChild(el5, el6);
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n                ");
                dom.appendChild(el4, el5);
                var el5 = dom.createElement("img");
                dom.setAttribute(el5, "src", "\\assets\\images\\emojis\\emojiHappyFace.svg");
                dom.setAttribute(el5, "alt", "Happy face emoji");
                dom.setAttribute(el5, "class", "mt ml5");
                dom.appendChild(el4, el5);
                var el5 = dom.createTextNode("\n              ");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("br");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("div");
                var el4 = dom.createTextNode("\n              ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("p");
                dom.setAttribute(el4, "class", "semi-bold mb10");
                var el5 = dom.createTextNode("Purchase QTY: ");
                dom.appendChild(el4, el5);
                var el5 = dom.createComment("");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n              ");
                dom.appendChild(el3, el4);
                var el4 = dom.createElement("p");
                dom.setAttribute(el4, "class", "small");
                var el5 = dom.createTextNode("This part is in stock with little risk of delay due to part shortage.");
                dom.appendChild(el4, el5);
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("\n            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1, 3]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 3, 3);
                morphs[1] = dom.createMorphAt(dom.childAt(element5, [4, 1]), 1, 1);
                return morphs;
              },
              statements: [["block", "if", [["get", "bomLine.isPurchasedQuantityAffected", ["loc", [null, [71, 22], [71, 57]]]]], [], 0, null, ["loc", [null, [71, 16], [76, 23]]]], ["content", "bomLine.purchasedQuantity", ["loc", [null, [79, 54], [79, 83]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 94,
                        "column": 14
                      },
                      "end": {
                        "line": 99,
                        "column": 14
                      }
                    },
                    "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "small flex jcSpacebetween mt5");
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("p");
                    dom.setAttribute(el2, "class", "small");
                    var el3 = dom.createTextNode("Min Order QTY:  ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3, "class", "bold");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                  ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("p");
                    dom.setAttribute(el2, "class", "small");
                    var el3 = dom.createTextNode("Mult Order QTY: ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("span");
                    dom.setAttribute(el3, "class", "bold");
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n                ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element2 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 0, 0);
                    morphs[1] = dom.createMorphAt(dom.childAt(element2, [3, 1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "bomLine.minimumOrderQty", ["loc", [null, [96, 70], [96, 97]]]], ["content", "bomLine.multipleOrderQty", ["loc", [null, [97, 70], [97, 98]]]]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 85,
                      "column": 8
                    },
                    "end": {
                      "line": 108,
                      "column": 8
                    }
                  },
                  "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "rel", "popover");
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("span");
                  dom.setAttribute(el2, "class", "fa fa-cubes orange font-18");
                  dom.setAttribute(el2, "rel", "popover");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "popover-wrapper none");
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  dom.setAttribute(el3, "class", "bg-faded-orange p10 borderRadius5 flex column");
                  var el4 = dom.createTextNode(" \n              ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("div");
                  dom.setAttribute(el4, "class", "flex jcSpacebetween");
                  var el5 = dom.createTextNode("\n              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("div");
                  dom.setAttribute(el5, "class", " bold");
                  var el6 = dom.createTextNode("LOW STOCK");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n              ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("img");
                  dom.setAttribute(el5, "src", "\\assets\\images\\emojis\\emojiNeutralFace.svg");
                  dom.setAttribute(el5, "alt", "Neutral face emoji");
                  dom.setAttribute(el5, "class", "mt ml5");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode("\n              ");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("            ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("br");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("div");
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("p");
                  dom.setAttribute(el4, "class", "semi-bold mb10");
                  var el5 = dom.createTextNode("Purchase QTY: ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createComment("");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("p");
                  dom.setAttribute(el4, "class", "small");
                  var el5 = dom.createTextNode("At this moment our preferred vendor set has enough stock for your project, but inventory is low and there is risk your project will be delayed.");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n            ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n          ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n        ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1, 3]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 3, 3);
                  morphs[1] = dom.createMorphAt(dom.childAt(element3, [4, 1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "if", [["get", "bomLine.isPurchasedQuantityAffected", ["loc", [null, [94, 20], [94, 55]]]]], [], 0, null, ["loc", [null, [94, 14], [99, 21]]]], ["content", "bomLine.purchasedQuantity", ["loc", [null, [102, 54], [102, 83]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.4.0",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 117,
                          "column": 14
                        },
                        "end": {
                          "line": 122,
                          "column": 14
                        }
                      },
                      "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("span");
                      dom.setAttribute(el1, "class", "small flex jcSpacebetween mt5");
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("p");
                      dom.setAttribute(el2, "class", "small");
                      var el3 = dom.createTextNode("Min Order QTY:  ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      dom.setAttribute(el3, "class", "bold");
                      var el4 = dom.createComment("");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                  ");
                      dom.appendChild(el1, el2);
                      var el2 = dom.createElement("p");
                      dom.setAttribute(el2, "class", "small");
                      var el3 = dom.createTextNode("Mult Order QTY: ");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createElement("span");
                      dom.setAttribute(el3, "class", "bold");
                      var el4 = dom.createComment("");
                      dom.appendChild(el3, el4);
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      var el2 = dom.createTextNode("\n                ");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element0 = dom.childAt(fragment, [1]);
                      var morphs = new Array(2);
                      morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 0, 0);
                      morphs[1] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 0, 0);
                      return morphs;
                    },
                    statements: [["content", "bomLine.minimumOrderQty", ["loc", [null, [119, 70], [119, 97]]]], ["content", "bomLine.multipleOrderQty", ["loc", [null, [120, 70], [120, 98]]]]],
                    locals: [],
                    templates: []
                  };
                })();
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.4.0",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 108,
                        "column": 8
                      },
                      "end": {
                        "line": 130,
                        "column": 6
                      }
                    },
                    "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "rel", "popover");
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("span");
                    dom.setAttribute(el2, "class", "fa fa-cubes red font-18");
                    dom.setAttribute(el2, "rel", "popover");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n          ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createElement("div");
                    dom.setAttribute(el2, "class", "popover-wrapper none");
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    dom.setAttribute(el3, "class", "bg-faded-red p10 borderRadius5 flex column");
                    var el4 = dom.createTextNode(" \n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("div");
                    dom.setAttribute(el4, "class", "flex jcSpacebetween");
                    var el5 = dom.createTextNode("\n              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("div");
                    dom.setAttribute(el5, "class", " bold");
                    var el6 = dom.createTextNode("OUT OF STOCK");
                    dom.appendChild(el5, el6);
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n              ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createElement("img");
                    dom.setAttribute(el5, "src", "\\assets\\images\\emojis\\emojiCryingFace.svg");
                    dom.setAttribute(el5, "alt", "Crying face emoji");
                    dom.setAttribute(el5, "class", "mt ml5");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createTextNode("\n              ");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createComment("");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("            ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("br");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n            ");
                    dom.appendChild(el2, el3);
                    var el3 = dom.createElement("div");
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("p");
                    dom.setAttribute(el4, "class", "semi-bold mb10");
                    var el5 = dom.createTextNode("Purchase QTY: ");
                    dom.appendChild(el4, el5);
                    var el5 = dom.createComment("");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n              ");
                    dom.appendChild(el3, el4);
                    var el4 = dom.createElement("p");
                    dom.setAttribute(el4, "class", "small");
                    var el5 = dom.createTextNode("At this moment our preferred vendor set does not have this part in inventory. We will attempt to source this part, but your project may be delayed.");
                    dom.appendChild(el4, el5);
                    dom.appendChild(el3, el4);
                    var el4 = dom.createTextNode("\n            ");
                    dom.appendChild(el3, el4);
                    dom.appendChild(el2, el3);
                    var el3 = dom.createTextNode("\n          ");
                    dom.appendChild(el2, el3);
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n        ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n      ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element1 = dom.childAt(fragment, [1, 3]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 3, 3);
                    morphs[1] = dom.createMorphAt(dom.childAt(element1, [4, 1]), 1, 1);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "bomLine.isPurchasedQuantityAffected", ["loc", [null, [117, 20], [117, 55]]]]], [], 0, null, ["loc", [null, [117, 14], [122, 21]]]], ["content", "bomLine.purchasedQuantity", ["loc", [null, [125, 54], [125, 83]]]]],
                  locals: [],
                  templates: [child0]
                };
              })();
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.0",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 8
                    },
                    "end": {
                      "line": 130,
                      "column": 6
                    }
                  },
                  "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "bomLine.isInventoryHealthAtNoStock", ["loc", [null, [108, 18], [108, 52]]]]], [], 0, null, ["loc", [null, [108, 8], [130, 6]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.0",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 85,
                    "column": 8
                  },
                  "end": {
                    "line": 130,
                    "column": 6
                  }
                },
                "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "bomLine.isInventoryHealthAtRisk", ["loc", [null, [85, 18], [85, 49]]]]], [], 0, 1, ["loc", [null, [85, 8], [130, 6]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 8
                },
                "end": {
                  "line": 130,
                  "column": 6
                }
              },
              "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "bomLine.isInventoryHealthInStock", ["loc", [null, [62, 18], [62, 50]]]]], [], 0, 1, ["loc", [null, [62, 8], [130, 6]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 59,
                "column": 4
              },
              "end": {
                "line": 131,
                "column": 4
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "bomLine.isSourceVendorUnknown", ["loc", [null, [60, 14], [60, 43]]]]], [], 0, 1, ["loc", [null, [60, 8], [130, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.0",
              "loc": {
                "source": null,
                "start": {
                  "line": 148,
                  "column": 12
                },
                "end": {
                  "line": 152,
                  "column": 12
                }
              },
              "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["content", "alternativePart.displayPartNumberShort", ["loc", [null, [150, 16], [150, 58]]]]],
            locals: ["alternativePart"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 143,
                "column": 6
              },
              "end": {
                "line": 155,
                "column": 6
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "fa fa-pencil-square-o dark-gray font-18 semi-bold");
            dom.setAttribute(el1, "rel", "popover");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "popover-wrapper none");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "bg-lightest-grey padded-10-20 borderRadius5 bold text-nowrap minWidth140");
            var el3 = dom.createTextNode("Alternate Parts");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2, "class", "mt5");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 3]), 1, 1);
            return morphs;
          },
          statements: [["block", "each", [["get", "alternativeParts", ["loc", [null, [148, 20], [148, 36]]]]], [], 0, null, ["loc", [null, [148, 12], [152, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 155,
                "column": 6
              },
              "end": {
                "line": 157,
                "column": 6
              }
            },
            "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-search dark-gray font-18");
            dom.setAttribute(el1, "aria-hidden", "true");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 47,
              "column": 0
            },
            "end": {
              "line": 160,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "text-center ");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "td-select");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "underline");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [7, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 2, 2);
          morphs[3] = dom.createElementMorph(element6);
          morphs[4] = dom.createMorphAt(element6, 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "bomLine.isPurchasedQuantityAffected", ["loc", [null, [49, 10], [49, 45]]]]], [], 0, 1, ["loc", [null, [49, 4], [55, 11]]]], ["block", "unless", [["get", "bomLine.provideOrDNP", ["loc", [null, [59, 14], [59, 34]]]]], [], 2, null, ["loc", [null, [59, 4], [131, 15]]]], ["inline", "view", ["select"], ["content", ["subexpr", "@mut", [["get", "bomLine.sourcingActions", ["loc", [null, [135, 28], [135, 51]]]]], [], []], "optionValuePath", "content.value", "optionLabelPath", "content.text", "value", ["subexpr", "@mut", [["get", "bomLine.sourcingAction", ["loc", [null, [138, 26], [138, 48]]]]], [], []], "classNames", "full-width x-bom-action"], ["loc", [null, [135, 4], [139, 58]]]], ["element", "action", ["openAlternativeBomModal"], [], ["loc", [null, [142, 25], [142, 62]]]], ["block", "if", [["get", "isAlternativePartAdded", ["loc", [null, [143, 12], [143, 34]]]]], [], 3, 4, ["loc", [null, [143, 6], [157, 13]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 160,
            "column": 11
          }
        },
        "moduleName": "web-app-frontend/templates/components/bom-row.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "text-center popover-error");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "popover-placements");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "rel", "popover");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "popover-wrapper none");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" placements per board at designators ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" with ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" additional for attrition.");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "rel", "popover");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "popover-wrapper none");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "maxWidth500");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "rel", "popover");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "popover-wrapper none");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [4]);
        var element8 = dom.childAt(element7, [3, 1]);
        var element9 = dom.childAt(fragment, [6]);
        var element10 = dom.childAt(fragment, [8]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element8, 0, 0);
        morphs[4] = dom.createMorphAt(element8, 2, 2);
        morphs[5] = dom.createMorphAt(element8, 4, 4);
        morphs[6] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element9, [3]), 1, 1);
        morphs[8] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[9] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
        morphs[10] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[11] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "bomLine.shouldDisplayErrorIcon", ["loc", [null, [2, 8], [2, 38]]]]], [], 0, 1, ["loc", [null, [2, 2], [12, 9]]]], ["content", "bomLine.lineNumber", ["loc", [null, [15, 4], [15, 26]]]], ["content", "bomLine.placementsPerBoard", ["loc", [null, [18, 22], [18, 52]]]], ["content", "bomLine.placementsPerBoard", ["loc", [null, [20, 7], [20, 37]]]], ["content", "bomLine.displayReferenceDesignators", ["loc", [null, [20, 74], [20, 113]]]], ["content", "bomLine.extraForAttrition", ["loc", [null, [20, 119], [20, 148]]]], ["content", "bomLine.displayPartNumberShort", ["loc", [null, [24, 22], [24, 56]]]], ["inline", "bom-line-detail-popover", [], ["bomLine", ["subexpr", "@mut", [["get", "bomLine", ["loc", [null, [26, 38], [26, 45]]]]], [], []], "imageURL", ["subexpr", "@mut", [["get", "bomLine.partImageURLssl", ["loc", [null, [26, 55], [26, 78]]]]], [], []]], ["loc", [null, [26, 4], [26, 80]]]], ["content", "bomLine.displayPartDescriptionLong", ["loc", [null, [30, 22], [30, 60]]]], ["inline", "bom-line-detail-popover", [], ["bomLine", ["subexpr", "@mut", [["get", "bomLine", ["loc", [null, [32, 38], [32, 45]]]]], [], []], "imageURL", ["subexpr", "@mut", [["get", "bomLine.partImageURLssl", ["loc", [null, [32, 55], [32, 78]]]]], [], []]], ["loc", [null, [32, 4], [32, 80]]]], ["block", "if", [["get", "isMountTypeUnknown", ["loc", [null, [35, 6], [35, 24]]]]], [], 2, 3, ["loc", [null, [35, 0], [45, 7]]]], ["block", "unless", [["get", "isKittedBom", ["loc", [null, [47, 10], [47, 21]]]]], [], 4, null, ["loc", [null, [47, 0], [160, 11]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});