define('web-app-frontend/helpers/fabrication-includes', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Helper.helper(function (_ref, options) {
    var _ref2 = _slicedToArray(_ref, 1);

    var params = _ref2[0];

    var stringValues = {
      'electricalTest': 'Electrical Test',
      'dateCode': 'Date Code',
      'lotCode': 'Lot Code',
      'ulCertification': 'UL Certification',
      'viaPlug': 'Via Plug',
      'fileConvert': 'File Conversion',
      'platedEdges': 'Plated Edges',
      'blindVias': 'Blind / Buried Vias',
      'castelHoles': 'Castellated Holes',
      'contrldImp': 'Controlled Impedance',
      'contrldImpList': 'Controlled Impedance',
      'logo': 'Sunstone Logo',
      'serialize': 'Serialization',
      'panelApprove': 'Panel Approval Prior to Manufacturing',
      'contDepthMill': 'Controlled Depth Milling',
      'dpasPriority': 'DPAS Priority',
      'cov19Critical': 'COVID-19 Critical Project',
      'cageCode': 'Cage Code',
      'coC': 'Certificate of Conformance'
    };

    var requestedOptions = [];
    for (var optionName in options) {
      if (options[optionName] === true) {
        requestedOptions.push(stringValues[optionName]);
      }
    }

    if (requestedOptions.length > 0) {
      var optionsString = requestedOptions.join(', ');
      return 'Includes ' + optionsString + '.';
    } else {
      return '';
    }
  });
});