define('web-app-frontend/controllers/modals/sign-in-or-register-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    session: _ember['default'].inject.service(),

    closeModalAfterSignIn: (function () {
      //TODO: I don't see evidence that this is used. Closure is done by the application.js controller
      console.log("********************* signInOrRegisterModal/closeModalAfterSignIn *********************************");
      this.get('applicationController').send('toggleSignIn');
      this.send('closeModal');
    }).observes('applicationController.session.isAuthenticated'),

    saveForLaterOnceLoggedIn: (function () {
      console.log("********************* signInOrRegisterModal/saveForLaterOnceLoggedIn *********************************");
      //deferred used with forced sign-in from quote footer saveForLater
      if (this.deferred && this.get('session.isAuthenticated') && this.get('session.data.user')) {
        this.deferred.resolve();
      }
    }).observes('applicationController.session.data.user'),

    actions: {
      signInAndAuthenticate: function signInAndAuthenticate(id, password, deferred) {
        //console.log('sign-in-or-register signInAndAuthenticate');
        //console.log(deferred);
        this.get('applicationController').send('signInAndAuthenticate', id, password, deferred);
      },

      linkToRegister: function linkToRegister() {
        this.send('closeModal');
        this.transitionTo('register');
      },

      toggleSignIn: function toggleSignIn(signInParam) {
        this.get('applicationController').send('toggleSignIn', signInParam);
        this.send('resetModal', false);
      },

      forgotPassword: function forgotPassword() {
        var store = this.get('store');
        var resetPasswordObject = store.createRecord('user-password-forgot');
        this.send('openModal', 'user.account.forgot-password-modal', resetPasswordObject);
      },

      resetModal: function resetModal() {
        var redirect = arguments.length <= 0 || arguments[0] === undefined ? true : arguments[0];

        this.get('signInModalForm').send('resetFields');
        if (this.deferred) {
          this.deferred.reject();
        }
        this.send('closeModal');
        if (this.get('applicationController.currentPath') === 'passthrough-sign-in') {
          if (redirect) {
            this.transitionToRoute('project-sign-in');
          }
        }
      }

    }
  });
});