define('web-app-frontend/components/order-snippet', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    cancel: 'cancel',
    preventValidationOnToggle: 'preventValidationOnToggle',
    toggleComponent: 'toggleComponent',

    mobileViewSnippet: false,
    isEditableOrActive: _ember['default'].computed.or('isEditable', 'isActive'),

    showCancel: (function () {
      if (this.get('clientState') === this.get('name')) {
        return false;
      }
      return true;
    }).property('clientState', 'name'),

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    hasErrorsAndIsCollapsed: (function () {
      return !this.get('isShown') && this.get('hasErrors');
    }).property('isShown', 'hasErrors'),

    mouseDown: function mouseDown(event) {
      //for click on order-snippet title:
      //mouseDown occurs before focusout event which is non-cancelable - we don't want an additional validation occurring
      //when a section is collapsing because if it's invalid, the collapse will be halted.
      var sectionName = this.get('name');
      if (this.get('isEditable')) {
        if (event.target.id === 'cancel') {
          this.send('cancel', sectionName);
        }
      }
    },
    actions: {
      cancel: function cancel(cancelThis) {
        if (cancelThis !== 'kitted') {
          this.sendAction('preventValidationOnToggle', cancelThis);
        }
        this.sendAction('cancel', cancelThis, this.get('model'), this.get('clientState'));
      },

      toggleComponent: function toggleComponent(showThis) {
        if (this.get('isEditable')) {
          this.sendAction('toggleComponent', showThis, true);
        }
      },

      toggleMobileViewSnippet: function toggleMobileViewSnippet() {
        this.toggleProperty('mobileViewSnippet');
      }
    }
  });
});