define('web-app-frontend/models/activity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attributes
    action: _emberData['default'].attr('string'),
    activityType: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    creationDate: _emberData['default'].attr('string'),
    description: _emberData['default'].attr('string'),
    descriptionHTML: _emberData['default'].attr('string'),
    isResolved: _emberData['default'].attr('boolean'),
    entity: _emberData['default'].attr('string'),
    entityId: _emberData['default'].attr('number'),
    projectGUID: _emberData['default'].attr('string'),
    resolutionDate: _emberData['default'].attr('string'),
    sessionGUID: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    titleHTML: _emberData['default'].attr('string'),
    userGUID: _emberData['default'].attr('string'),

    shouldLinkToUser: (function () {
      return this.get('entity') === 'User';
    }).property('entity'),

    shouldLinkToQuote: (function () {
      return this.get('entity') === 'Quote';
    }).property('entity'),

    shouldLinkToOrder: (function () {
      return this.get('entity') === 'Order';
    }).property('entity'),

    reviewEntityDisplay: (function () {
      return 'Review your ' + this.get('entity').toLowerCase();
    }).property('entity')

  });
});