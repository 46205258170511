define('web-app-frontend/serializers/timeline-node', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, typeClass, payload) {
      var nodes = payload.timeline.data;
      var projectGUID = nodes.get('firstObject').projectGUID;
      var project = store.peekRecord('project', projectGUID);
      var nodeRecords = [];
      var count = store.peekAll('timeline-node').get('length');
      var date, record;

      // for dev testing
      // var testCompletionDate = moment().subtract('2', 'd');

      // Need to manually create record in order to assign to parent Project
      // model relationship.
      nodes.forEach(function (node) {
        node.id = count;
        count++;
        record = store.createRecord('timeline-node', node);

        // TEST BLOCK
        // if (node.sequence < 50) {
        //   testCompletionDate = moment(testCompletionDate).add(1, 'd');
        //   record.set('completionDate', testCompletionDate.format());
        // } else {
        //   record.set('completionDate', null);
        // }

        //Read date in UTC format
        date = moment.utc(node.estimatedDateComplete);
        //Convert date to local timezone
        var localDate = date.local();
        record.set('month', moment.months(localDate.month()));
        record.set('date', localDate.date());
        record.set('project', node.projectGUID);
        nodeRecords.push(record);
      });

      project.set('timeLineNodes', nodeRecords);

      payload = { timelineNodes: nodeRecords };
      return this._super(store, typeClass, payload);
    }
  });
});