define('web-app-frontend/models/bom', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    bmhFileName: _emberData['default'].attr('string'),
    bomHeaderId: _emberData['default'].attr('number'),
    bomFileName: _emberData['default'].attr('string'),
    bomWorksheetQuoted: _emberData['default'].attr('string'),
    bomIncludeInPartsTotal: _emberData['default'].attr('boolean'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    creationDate: _emberData['default'].attr('string'),
    errors: _emberData['default'].attr(),
    lowSuccessWarning: _emberData['default'].attr('boolean'),
    partsCount: _emberData['default'].attr('number'),
    partsDiscount: _emberData['default'].attr('number'),
    partsTotal: _emberData['default'].attr('number'),
    partCostPerBoard: _emberData['default'].attr('number'),
    totalParts: _emberData['default'].attr('number', { defaultValue: 0 }),
    quotedBoardCount: _emberData['default'].attr('number', { defaultValue: 0 }),
    type: _emberData['default'].attr('string'),
    bmhMinimumPartsOrderCharge: _emberData['default'].attr('number', { defaultValue: 0 }),
    isKittedBom: _emberData['default'].attr('boolean'),

    // set by the confirmation of notice within the bom modal.
    customerNotifiedOfCustomQuote: _emberData['default'].attr('boolean', { defaultValue: false }),

    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),
    bomLines: _emberData['default'].hasMany('bom-line', { async: true }),

    // Computed Properties
    isDeleted: (function () {
      return this.get('currentState.stateName') === 'root.deleted.saved' || this.get('currentState.stateName') === 'root.loading';
    }).property('currentState'),

    // Model Methods:
    customerWillProvideParts: _emberData['default'].attr('boolean', { defaultValue: false }),
    checkIfCustomerWillProvideParts: (function () {
      var self = this;
      var bomLines = self.get('bomLines');
      var count;
      bomLines.then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        count = lines.filterBy('sourcingAction', 'CustProvided').filterBy('bmlIsSelected', true).length;
        if (count > 0) {
          self.set('customerWillProvideParts', true);
        } else {
          self.set('customerWillProvideParts', false);
        }
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    noInvalidParts: (function () {
      return this.get('invalidPartsCount') === 0 || this.get('project.userRemovedParts');
    }).property('invalidPartsCount', 'project.userRemovedParts'),

    setInvalidPartsCount: (function () {
      var bomLines = this.get('bomLines').filterBy('bmlIsSelected', true);
      var invalidPartsCount = bomLines.filterBy('sourcingAction', 'PromptCustomer').length;
      this.set('invalidPartsCount', invalidPartsCount);
    }).observes('bomLines.@each.sourcingAction').on('init'),

    recalculateTotalPrice: (function () {
      var _this = this;

      var MINIMUM_PARTS_ORDER_CHARGE = this.get('bmhMinimumPartsOrderCharge');

      //PN: if the order has been placed then do not use bom line pricing, use the price returned from server
      if (this.get('project.isUserEditable') === true) {
        if (this.get('hasDirtyAttributes')) {
          var self;
          var bomLines;

          (function () {
            self = _this;
            bomLines = self.get('bomLines');

            // If there are not "Quote" or "Purchase" lines it indicates that this is a kittedBOM request
            var isKittedBomRequest = !(bomLines.filter(function (x) {
              return x.get('sourcingAction') === "Quote" || x.get('sourcingAction') === "Purchase";
            }).length > 0);

            bomLines.then(function (bomLines) {
              if (self.get('isDeleted')) {
                return;
              }
              var totalPrice;
              var totalPriceArray = bomLines.filterBy('sourcingAction', 'Purchase').filterBy('bmlIsSelected', true).mapBy('quotedLineTotalPrice');
              if (totalPriceArray.length) {
                totalPrice = totalPriceArray.reduce(function (a, b) {
                  return a + b;
                });
              } else {
                totalPrice = 0;
              }

              if (isKittedBomRequest) {
                self.set('partCostPerBoard', 0);
                self.set('partsTotal', 0);
              } else if (totalPrice < MINIMUM_PARTS_ORDER_CHARGE) {
                self.set('partCostPerBoard', MINIMUM_PARTS_ORDER_CHARGE / self.get('quotedBoardCount'));
                self.set('partsTotal', totalPrice < MINIMUM_PARTS_ORDER_CHARGE ? MINIMUM_PARTS_ORDER_CHARGE : totalPrice);
              } else {
                var perBoardPriceArray = bomLines.filterBy('sourcingAction', 'Purchase').filterBy('bmlIsSelected', true).mapBy('calculatedLinePerBoardPrice');

                var perBoardPrice = 0;

                if (perBoardPriceArray.length) {
                  perBoardPrice = perBoardPriceArray.reduce(function (a, b) {
                    return a + b;
                  });
                }

                self.set('partCostPerBoard', perBoardPrice);
                self.set('partsTotal', totalPrice < MINIMUM_PARTS_ORDER_CHARGE ? MINIMUM_PARTS_ORDER_CHARGE : totalPrice);
              }
            });
          })();
        }
      }
    }).observes('bomLines.@each.sourcingAction').on('init'),

    recalculateTotalPartsSourced: (function () {
      if (this.get('hasDirtyAttributes')) {
        var self = this;
        var bomLines = self.get('bomLines');

        bomLines.then(function (bomLines) {
          if (self.get('isDeleted')) {
            return;
          }
          var totalParts;
          var totalPartsArray = bomLines.filterBy('sourcingAction', 'Purchase').filterBy('bmlIsSelected', true).mapBy('placementsPerBoard');
          if (totalPartsArray.length) {
            totalParts = totalPartsArray.reduce(function (a, b) {
              return a + b;
            });
          }

          self.set('partsCount', totalParts);
        });
      }
    }).observes('bomLines.@each.sourcingAction').on('init'),

    checkIfValid: function checkIfValid(deferred) {
      var self = this;
      var bomLines = self.get('bomLines');

      bomLines.then(function (lines) {
        var count = lines.filterBy('shouldDisplayErrorIcon', true).length;
        if (count > 0) {
          deferred.resolve(false);
        } else {
          deferred.resolve(true);
        }
      });
    },

    // variables and observers for BOM Summary Variables START
    hasPartsMarkedDNP: _emberData['default'].attr('boolean'),
    dnpCount: _emberData['default'].attr('number'),
    hasPartsToBeProvidedByUser: _emberData['default'].attr('boolean'),
    custProvidedPartCount: _emberData['default'].attr('number'),
    hasPartsMarkedQuoteForMe: _emberData['default'].attr('boolean'),
    quotedPartCount: _emberData['default'].attr('number'),
    erroredPartCount: _emberData['default'].attr('number'),
    hasErroredParts: _emberData['default'].attr('boolean'),

    setErroredPartsCount: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        var count = lines.filterBy('shouldDisplayErrorIcon', true).length;
        self.set('erroredPartCount', count);
        if (count > 0) {
          self.set('hasErroredParts', true);
        } else {
          self.set('hasErroredParts', false);
        }
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    setPartsSourcingAttributes: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        var dnpCount = lines.filterBy('sourcingAction', 'DNP').filterBy('bmlIsSelected', true).length;
        if (dnpCount > 0) {
          self.set('hasPartsMarkedDNP', true);
          self.set('dnpCount', dnpCount);
        } else {
          self.set('hasPartsMarkedDNP', false);
        }

        var custProvidedCount = lines.filterBy('sourcingAction', 'CustProvided').filterBy('bmlIsSelected', true).length;
        if (custProvidedCount > 0) {
          self.set('hasPartsToBeProvidedByUser', true);
          self.set('custProvidedPartCount', custProvidedCount);
        } else {
          self.set('hasPartsToBeProvidedByUser', false);
        }

        var quotedCount = lines.filterBy('sourcingAction', 'Quote').filterBy('bmlIsSelected', true).length;
        if (quotedCount > 0) {
          self.set('hasPartsMarkedQuoteForMe', true);
          self.set('quotedPartCount', quotedCount);
        } else {
          self.set('hasPartsMarkedQuoteForMe', false);
        }
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    sourcedPartCount: _emberData['default'].attr('number', { defaultValue: 0 }),
    getSourcedPartCount: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        self.set('sourcedPartCount', lines.filterBy('bmlIsSelected', true).filterBy('sourcingAction', 'Purchase').length);
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    notSourcedPartCount: _emberData['default'].attr('number', { defaultValue: 0 }),
    countPartsNotSourced: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        self.set('notSourcedPartCount', lines.filterBy('bmlIsSelected', true).rejectBy('sourcingAction', 'Purchase').length);
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    lowInventoryPartCount: _emberData['default'].attr('number', { defaultValue: 0 }),
    countLowInventoryPartCount: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        if (self.get('isDeleted')) {
          return;
        }
        var filteredLines = lines.filter(function (line) {
          return line.get('inventoryHealth') !== "Good" && !line.get('provideOrDNP') && !line.get('isSourceVendorUnknown') && line.get('bmlIsSelected') === true;
        });

        self.set('lowInventoryPartCount', filteredLines.length);
      });
    }).observes('bomLines.@each.sourcingAction').on('init'),

    showLowInventoryPartCount: (function () {
      return this.get('lowInventoryPartCount') !== 0;
    }).property('lowInventoryPartCount'),

    singleLowInventoryPartCount: (function () {
      return this.get('lowInventoryPartCount') === 1;
    }).property('lowInventoryPartCount'),

    isInventoryHealthAtNoStockProp: _emberData['default'].attr('boolean'),
    isInventoryHealthAtRiskProp: _emberData['default'].attr('boolean'),

    setInventoryHealth: (function () {
      var self = this;
      this.get('bomLines').then(function (lines) {
        var noDNPAndCustProvided = lines.filterBy('bmlIsSelected', true).rejectBy('sourcingAction', 'DNP').rejectBy('sourcingAction', 'CustProvided');
        self.set('isInventoryHealthAtNoStockProp', noDNPAndCustProvided.filterBy('inventoryHealth', 'NoStock').rejectBy('sourceVendor', 'Unknown').length !== 0);
        self.set('isInventoryHealthAtRiskProp', noDNPAndCustProvided.filterBy('inventoryHealth', 'Risk').length !== 0);
      });
    }).observes('bomLines.@each.inventoryHealth', 'bomLines.@each.sourcingAction').on('init'),

    isInventoryHealthAtRiskAndNoStock: (function () {
      return this.get('isInventoryHealthAtNoStockProp') && this.get('isInventoryHealthAtRiskProp');
    }).property('isInventoryHealthAtNoStockProp', 'isInventoryHealthAtRiskProp')

  });
});
// END
//TODO: Add functions or attributes to store psuedo attribnutes for total partts to be sourced and their total price