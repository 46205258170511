define('web-app-frontend/components/about-section-links', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isHistory: (function () {
      return this.get('indexName') === 'history';
    }).property('isHistory'),

    isNews: (function () {
      return this.get('indexName') === 'news';
    }).property('isNews'),

    isPartners: (function () {
      return this.get('indexName') === 'partners';
    }).property('isPartners'),

    isSponsorship: (function () {
      return this.get('indexName') === 'sponsorship';
    }).property('isSponsorship')
  });
});