define('web-app-frontend/controllers/upload', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isSubmitted: false,

    showErrorAlert: (function () {
      if (this.get('showError')) {
        this.send('openModal', 'modals/alert-modal', { text: 'Error uploading file.' });
        this.set('showError', false);
      }
    }).observes('showError'),

    actions: {
      submit: function submit() {
        var self = this;
        this.model.setupForCommit();
        this.model.save().then(function () {
          self.set('isSubmitted', true);
          // targetting based off of window height to not get any jumping
          if ($(window).height() < 1050) {
            $('html, body').animate({ scrollTop: $('#contact-form').offset().top - 20 }, 100);
          }
        })['catch'](function () {
          console.log('submit job file failed');
          self.set('showError', true);
        });
      },

      updateUploadText: function updateUploadText(fileName) {
        _ember['default'].$.find('.faux-button-upload span.fileName')[0].innerHTML = fileName;
      }

    }
  });
});