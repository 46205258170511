define("web-app-frontend/adapters/activity", ["exports", "web-app-frontend/adapters/application"], function (exports, _webAppFrontendAdaptersApplication) {
  exports["default"] = _webAppFrontendAdaptersApplication["default"].extend({
    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL() {
      return this._super('activities');
    },

    query: function query(store, type, queryParams) {
      var url = this.buildURL(type);
      var keys = Object.keys(queryParams);
      var queryURL = "";
      keys.forEach(function (key) {
        //most often user/activities?UserGUID=UserID
        if (queryURL !== "") {
          queryURL = queryURL + "&";
        }
        queryURL = queryURL + key + '=' + queryParams[key];
      });
      queryURL = url + "?" + queryURL;
      return this.ajax(queryURL, 'GET');
    }
  });
});