define('web-app-frontend/routes/quoting/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    headTags: [{
      type: 'meta',
      tagId: 'meta-prerender-status-code',
      attrs: {
        name: 'prerender-status-code',
        content: '401'
      }
    }, {
      type: 'meta',
      typeId: 'meta-robots',
      attrs: {
        name: 'robots',
        content: 'noindex'
      }
    }],

    titleToken: 'New Quote',
    session: _ember['default'].inject.service(),

    beforeModel: function beforeModel(transition) {
      // prevent enter quoting on API down
      if (this.controllerFor('application').get('isAPIDown')) {
        this.controllerFor('application').send('alertAPIDown');
        transition.abort();
      }
      // prevent enter quoting on old browsers
      else if (this.controllerFor('application').get('browserOld')) {
          this.controllerFor('application').send('alertOldBrowser');
          transition.abort();
        }
        // prevent enter quoting if cookies are disabled
        else if (this.controllerFor('application').get('isCookieDisabled')) {
            this.controllerFor('application').send('alertCookieDisabled');
            transition.abort();
          }
    },

    actionsToPerformOnEnter: (function () {
      _ember['default'].$('body').addClass('quoting');
      window.scrollTo(0, 0);
    }).on('activate'),

    actionsToPerformOnExit: (function () {
      _ember['default'].$('body').removeClass('quoting');
    }).on('deactivate'),

    resetController: function resetController(controller, isExiting, transition) {
      //available params controller, isExiting, transition
      // We want to remove cookie from quote/new only if they have successfully completed form
      // otherwise it is needed for the redirect to order.edit.  Se resetController block there as well.
      console.log('resetController transition', transition);
      if (isExiting && _ember['default'].isPresent(transition) && transition.targetName !== 'register') {
        console.log('REMOVING currentProjectGUID cookie');
        $.removeCookie('currentProjectGUID', { path: '/' });

        //For some reason linking from quoting.new -> user by clicking project box is causing transition to be undefined.
      } else if (!transition) {
          $.removeCookie('currentProjectGUID', { path: '/' });
        }
    },

    setupController: function setupController(controller, context, transition) {
      var userPresent = this.get('session.isAuthenticated');
      var deferred = new _ember['default'].RSVP.defer('quote#new setupController deferred');
      var store = this.store;
      console.log('this is a test');

      var quotingController = controller.get('quotingController');
      var self = this;

      this.resetDefaultValues(quotingController);

      if (!$.cookie('currentProjectGUID')) {
        // creates project and *sets cookie*
        console.log('createNewProject');

        if (!this.get('session.isAuthenticated')) {
          store.unloadAll('project');
        }
        this.controllerFor('application').set('currentTransition', transition);
        var projectController = controller.get('projectController');
        projectController.createNewProject(deferred);
      } else {
        var projectPromise = store.findRecord('project', $.cookie('currentProjectGUID'));
        projectPromise.then(function () {
          self.transitionTo('quote.edit', $.cookie('currentProjectGUID'));
        });
      }

      deferred.promise.then(function (projectGUID) {
        quotingController.set('showProjectInformation', true);
        quotingController.set('showAssembly', false);
        quotingController.set('showFabrication', false);
        quotingController.set('showParts', false);

        // set project
        var project = store.peekRecord('project', projectGUID);
        quotingController.set('project', project);

        //unload all record types that caching has been applied to
        store.unloadAll('quote-assembly-pricing');
        store.unloadAll('assembly-turn-time');
        store.unloadAll('assembly-price-difference');

        // create and set new assembly
        var assemblyPricingController = self.controllerFor('quoting/assembly-pricing');
        var assemblyPricing = assemblyPricingController.createNewQuoteAssemblyPricingObject(projectGUID);
        quotingController.set('assemblyPricing', assemblyPricing);

        //unload all record types that caching has been applied to
        store.unloadAll('quote-fabrication-pricing');
        store.unloadAll('fabrication-turn-time');
        store.unloadAll('fabrication-price-difference');
        store.unloadAll('design-violation');

        // create and set new fab
        var fabricationPricingController = self.controllerFor('quoting/fabrication-pricing');
        var fabricationPricing = fabricationPricingController.createNewQuoteFabricationPricingObject(projectGUID);
        quotingController.set('fabricationPricing', fabricationPricing);
      });

      // unload any existing pricing object

      // set new parts
      // set new pricing
    },

    resetDefaultValues: function resetDefaultValues(controller) {
      controller.set('projectFile', null);
      // any controller values that may need to be set inorder to return the quoting section to default should go here.

      controller.set('showProjectInformation', false);
      controller.set('showAssembly', false);
      controller.set('showFabrication', false);
      controller.set('showParts', false);
      controller.set('showCustomQuote', false);

      controller.set('projectInformationTouched', false);
      controller.set('assemblyTouched', false);
      controller.set('fabricationTouched', false);
      controller.set('partsTouched', false);

      controller.set('assemblyEdit', false);
      controller.set('fabricationEdit', false);
      controller.set('partsEdit', false);
      controller.set('pricingEdit', false);

      controller.set('showFooterError', false);
      controller.set('allCollapsed', false);
      controller.set('quoteLocked', false);

      controller.set('assemblyPricing', null);
      controller.set('fabricationPricing', null);
    }
  });
});