define("web-app-frontend/controllers/quoting", ["exports", "ember", "web-app-frontend/config/environment", "web-app-frontend/controllers/mixins/quoting-errors"], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendControllersMixinsQuotingErrors) {
  exports["default"] = _ember["default"].Controller.extend(_webAppFrontendControllersMixinsQuotingErrors["default"], {
    session: _ember["default"].inject.service(),

    applicationController: _ember["default"].inject.controller('application'),
    quotingNewController: _ember["default"].inject.controller('quoting/new'),
    assemblyController: _ember["default"].inject.controller('quoting/assembly'),
    fabricationController: _ember["default"].inject.controller('quoting/fabrication'),
    partsController: _ember["default"].inject.controller('quoting/parts'),
    projectController: _ember["default"].inject.controller('project'),
    taxController: _ember["default"].inject.controller('quoting/tax-shipping-estimate-modal'),
    assemblyPricingController: _ember["default"].inject.controller('quoting/assembly-pricing'),
    fabricationPricingController: _ember["default"].inject.controller('quoting/fabrication-pricing'),
    bomController: _ember["default"].inject.controller('quoting/bom-modal'),
    bomAlternativeController: _ember["default"].inject.controller('quoting/bom-alternative-modal'),

    showProjectInformation: false,
    showAssembly: false,
    showFabrication: false,
    showParts: false,
    showCustomQuote: false,

    projectInformationTouched: true,
    assemblyTouched: false,
    fabricationTouched: false,
    partsTouched: false,
    customQuoteTouched: false,

    projectInformationEdit: false,
    assemblyEdit: false,
    fabricationEdit: false,
    partsEdit: false,

    allCollapsed: false,
    customQuote: false,
    quoteLocked: false,
    customMsg: '',
    Layers: '',

    refreshTurnTimes: false,
    triggerProjectReprice: false,
    triggerProjectRepriceMessage: false,

    showProjectRepriceMessage: (function () {
      var showMessage = false;
      if ((this.get('fabricationTouched') && !this.get('project.userRemovedFab') || this.get('project.partsIncluded') && this.get('project.bomDetails')) && this.get('triggerProjectRepriceMessage')) {
        showMessage = true;
      }
      return showMessage;
    }).property('fabricationTouched', 'project.userRemovedFab', 'project.partsIncluded', 'project.bomDetails', 'triggerProjectRepriceMessage'),

    //PN : Added following flag to remove custom quote functionality
    partsValid: _ember["default"].computed.or('project.partsValidOrRemoved', 'project.userSkippedOnlineQuoting'),
    //allFormSectionsValid: Ember.computed.and('assemblyPricing.isValid', 'fabValidForPricing', 'project.partsValidOrRemoved'),
    allFormSectionsValid: _ember["default"].computed.and('assemblyPricing.isValid', 'fabValidForPricing', 'partsValid'),
    fabPricingCommitted: _ember["default"].computed.and('project.fabricationDetails.quoteTotal', 'fabricationPricing.hasValidTurnTime'),
    fabValidForPricing: _ember["default"].computed.or('project.userRemovedFab', 'fabPricingCommitted', 'fabPricingCustom'),
    fabPricingCustom: _ember["default"].computed.and('project.fabricationDetails.customFabQuote', 'fabricationPricing.hasValidTurnTime'),

    isMobile: _ember["default"].computed.alias('applicationController.isMobile'),
    notCustomQuoteOrApprovedCustomQuote: _ember["default"].computed.or('project.isNotCustomQuote', 'project.hasCustomQuoteApproved'),
    isAnySectionOpen: _ember["default"].computed.or('showProjectInformation', 'showAssembly', 'showFabrication', 'showParts'),
    allFormSectionsClosed: _ember["default"].computed.not('isAnySectionOpen'),
    showPricingManual: true,
    showPricing: _ember["default"].computed.and('notCustomQuoteOrApprovedCustomQuote', 'allFormSectionsValid', 'allFormSectionsClosed', 'showPricingManual'),
    showSpinner: _ember["default"].computed.and('allFormSectionsValid', 'allFormSectionsClosed', 'showPricingManual'),
    // OBSERVERS
    pricingUnavailableChanged: (function () {
      console.log('pricingUnavailable changed');
      this.quoteFabricationSnippet.set('pricingUnavailable', this.quoteFabricationPricingComponent.get('pricingUnavailable'));
    }).observes('quoteFabricationPricingComponent.pricingUnavailable'),

    validateFabricationPricingForm: (function () {
      if (this.get('quoteFabricationPricingComponent.model') && !this.get('showFabrication') && this.project.get('fabIncludedBoolean')) {
        console.log('validate fab pricing form on init');
        this.send('validateFabrication');
      }
    }).observes('quoteFabricationPricingComponent.model'),

    validateAssemblyPricingForm: (function () {
      if (this.assemblyPricing && !this.get('showAssembly')) {
        console.log('validate assembly pricing form on init');
        this.send('validateAssembly');
      }
    }).observes('quoteAssemblyPricingComponent.model'),

    setIsReoderOnFabrication: (function () {
      if (this.fabricationPricing && this.assemblyPricing) {
        this.fabricationPricing.set('isReorder', this.assemblyPricing.get('isReorder'));
      }
    }).observes('assemblyPricing.isReorder'),

    setAssemblyServiceTypeOnFabrication: (function () {
      if (this.fabricationPricing && this.assemblyPricing) {
        this.fabricationPricing.set('assemblyServiceName', this.assemblyPricing.get('assemblyServiceName'));
      }
    }).observes('assemblyPricing.assemblyServiceName'),

    customQuoteDetails: (function () {
      if (this.get('project.customFabQuote') && this.get('project.customFabNotes')) {
        var msgs = this.get('project.customFabNotes').split('|');
        this.set('customMsg', msgs);
      } else {
        this.set('customMsg', '');
      }
    }).observes('project.customFabNotes'),

    observeCustomQuote: (function () {
      if (this.get('project.isCustomQuote')) {
        // should not open after custom quote triggered in parts.
        // allCollapsed signifies it would need to be opened
        // when the other sections are closed
        if (this.get('partsTouched') && this.get('allCollapsed')) {
          this.set('customQuoteTouched', true);
          this.set('showCustomQuote', true);
        }
      }
    }).observes('project.isCustomQuote'),

    // FUNCTIONS
    printOnTransition: function printOnTransition() {
      if (this.get('printTransition')) {
        this.send('collapseAndPrint');
      }
      this.set('printTransition', false);
    },

    fabricationReadyForSubmit: function fabricationReadyForSubmit() {
      return (this.get('fabricationTouched') || this.get('showFabrication')) && !this.project.get('userRemovedFab');
    },

    allSectionsHaveBeenTouched: function allSectionsHaveBeenTouched() {
      return this.get('assemblyTouched') && this.get('fabricationTouched') && this.get('projectInformationTouched') && this.get('partsTouched');
    },

    fetchOauthToken: function fetchOauthToken() {
      var tokenString = '';
      if (this.get('session.isAuthenticated')) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    resetFormSectionsToPreCustomState: function resetFormSectionsToPreCustomState() {
      var self = this;
      var project = this.get('project');
      var wasCustomAssembly = project.get('customAssemblyQuote');
      var wasCustomFab = project.get('customFabQuote');
      this.send('toggleLockQuote', false, 'parts');
      this.set('customQuoteTouched', false);
      project.set('clientState', 'parts');
      project.save().then(function () {
        if (wasCustomAssembly) {
          var assemblyPricing = self.get('assemblyPricing');
          assemblyPricing.set('customAssemblyQuote', project.get('customAssemblyQuote'));
          assemblyPricing.set('customAssemblyNotes', project.get('customAssemblyNotes'));
          self.send('validateAssembly');
        }
        if (wasCustomFab) {
          var fabricationPricing = self.get('fabricationPricing');
          fabricationPricing.set('customFabQuote', project.get('customFabQuote'));
          fabricationPricing.set('customFabNotes', project.get('customFabNotes'));
          self.send('validateFabrication');
        }
      });
    },

    // Simply build this ajax long hand instead of trying to shoe horn it into the adapter for the projectCheckout model.
    submitCancelOfCustomQuote: function submitCancelOfCustomQuote() {
      var self = this;
      var project = this.get('project');
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + project.get('id') + '/cancel?Api-Key=SCWEB';
      var oauthToken = this.fetchOauthToken();

      $.ajax({
        url: url,
        type: 'PUT',
        headers: oauthToken,
        contentType: 'application/json; charset=UTF-8',
        cache: false
      }).done(function (data) {
        //Upload done, close modal - bubbles up to application route
        console.log('SUCCESS!', data);
        if (project.get('partsIncludedBoolean')) {
          project.get('bomDetails.bomLines').forEach(function (line) {
            line.rollbackAttributes();
          });
          project.get('bomDetails').reload(); //reload won't take on lines in a dirty take
        }
        self.resetFormSectionsToPreCustomState();
      }).fail(function (data) {
        console.log('cancel failed', data);
      });
    },

    setLayers: (function () {
      if (this.get('fabricationPricing.layers') === 'Other') {
        this.set('Layers', this.get('fabricationPricing.layersOther'));
      } else {
        this.set('Layers', this.get('fabricationPricing.layers'));
      }
    }).observes('fabricationPricing.layers', 'fabricationPricing.layersOther'),

    actions: {
      setProjectFile: function setProjectFile(projectFile) {
        this.set('projectFile', projectFile);
      },

      addFabricationToQuote: function addFabricationToQuote(project) {
        var self = this;
        var fabricationTaskDeferred = new _ember["default"].RSVP.defer('addFabricationToQuote findOrCreateQuoteFabrication deferred');
        this.send('findOrCreateQuoteFabrication', project.id, true, fabricationTaskDeferred);
        fabricationTaskDeferred.promise.then(function () {
          self.send('validateForm');
          project.reload().then(function () {
            self.send('toggleComponent', 'fabrication');
          });
        })["catch"](function (errors) {
          console.log('add fab to quote fail: ', errors);
          //Log error but do not notify user.
          self.handleApplicationError("quoting.js --> addFabricationToQuote", errors, false);
        });
      },

      cancel: function cancel(quoteModel, controllerName) {
        if (controllerName !== 'pricing' && controllerName !== 'parts') {
          if (quoteModel) {
            var rollbackRecord = this.store.peekRecord('quote-' + controllerName, quoteModel.get('id'));
            this.get('applicationController').updateChangedValues(quoteModel, rollbackRecord);
          }
        }

        //assembly controller is being used in "Project Information" and "Assembly Quote" section
        if (controllerName.toLowerCase() === "assembly") {
          //check if Project Information section is open and then close it
          if (this.get("showProjectInformation")) {
            this.set("showProjectInformation", false);
          }
          //check if Assembly Quote section is open and then close it
          if (this.get("showAssembly")) {
            this.set("showAssembly", false);
          }
        } else {
          this.set('show' + controllerName.capitalize(), false);
        }

        this.set('allCollapsed', true);
      },

      cancelCustomQuote: function cancelCustomQuote() {
        var self = this;
        var deferred = new _ember["default"].RSVP.defer('cancelCustomQuote');
        self.send('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to cancel your custom quote?',
          subtext: 'This will remove it from pending review by Customer Service',
          customSubmitText: 'Yes, continue to cancel',
          deferredObject: deferred });

        deferred.promise.then(function () {
          self.submitCancelOfCustomQuote();
        })["catch"](function () {
          self.send('closeModal');
        });
      },

      collapse: function collapse() {
        this.set('showAssembly', false);
        this.set('showFabrication', false);
        this.set('showParts', false);
        this.set('showCustomQuote', false);
        // so markup gets removed after a user prints and interacts with form again
        this.set('isPrinting', false);
      },

      collapseComponent: function collapseComponent(section) {
        this.set('show' + section.capitalize(), false);
        this.send('scrollToSnippet', section);
      },

      collapseAndPrint: function collapseAndPrint() {
        this.set('showAssembly', false);
        this.set('showFabrication', false);
        this.set('showParts', false);
        this.set('showCustomQuote', false);

        // scroll top
        $('.header')[0].scrollIntoView();
        // renders markup for print price options
        this.set('isPrinting', true);

        _ember["default"].run.later(function () {
          window.print();
        }, 400);
      },

      deleteFabQuotePricingAndFabQuote: function deleteFabQuotePricingAndFabQuote(projectGUID) {
        var self = this;
        //Setting showPricingManual to false here to stop jumping to Pricing section from Fab Section
        //when user skips fabrication. Setting it back to true when the user clicks on Update button in the Fab section 
        self.set('showPricingManual', false);
        var fabricationPricing = this.get('fabricationPricing');
        var deferred = new _ember["default"].RSVP.defer('deleteFabDeferred');

        fabricationPricing.clearInProgressValues();
        fabricationPricing.setServiceTypeDefaults();

        var fab = this.store.peekRecord('quote-fabrication', projectGUID);
        if (!fab) {
          fab = this.store.createRecord('quote-fabrication', { id: projectGUID });
        }
        var fabricationController = this.get('fabricationController');
        fabricationController.deleteFabricationQuote(fab, deferred);
        deferred.promise.then(function () {
          self.set('showFabrication', true);
          self.send('toggleComponent', 'fabrication');
          self.toggleProperty('refreshTurnTimes');
          self.set('showPricingManual', false);
        });
      },

      deleteBOM: function deleteBOM(projectGUID, parentDeferred) {
        var controller = this;
        var bom = this.store.peekRecord('bom', projectGUID);

        if (!bom) {
          bom = this.store.createRecord('bom');
          bom.set('id', projectGUID);
        }

        var partsController = this.get('partsController');
        partsController.deletePartsAndClearBOM(bom, controller, parentDeferred);
        this.send('closeModal');
      },

      duplicateProject: function duplicateProject(projectGUID) {
        var self = this;
        var deferredProjectName = new _ember["default"].RSVP.defer('Project Name Deferred');

        var duplicate = this.store.createRecord('project', { guidForDuplication: projectGUID,
          commitOnSuccess: true,
          sessionGUID: $.cookie('sessionGUID'),
          hubSpotConversationId: $.cookie('hubSpotConversationId') });
        duplicate.save().then(function (duplicate) {
          duplicate.set('hubSpotConversationId', '');
          duplicate.reload().then(function () {
            self.send('openModal', 'order/project-name-modal', duplicate, deferredProjectName);
          });

          deferredProjectName.promise.then(function () {
            self.transitionToRoute('quote.edit', duplicate.get('projectGUID'));
          });
        })["catch"](function (errors) {
          console.log(errors);
          //Log error but do not notify user.
          self.handleApplicationError("quoting.js --> duplicateProject", errors, false);
        });
      },

      focusInput: function focusInput(element) {
        element.focus();
      },

      findOrCreateQuoteAssembly: function findOrCreateQuoteAssembly(projectGUID, saveForLater, parentDeferred) {
        var self = this;
        var assemblyController = self.get('assemblyController');
        var deferred = new _ember["default"].RSVP.defer('quotingController#findOrCreateQuoteAssembly');

        // finds/creates & updates based upon pricing model
        assemblyController.findOrCreateQuoteAssembly(projectGUID, deferred);

        deferred.promise.then(function (quoteAssembly) {
          if (saveForLater) {
            // quoteAssembly.saveAction set to SAVEFORLATER will allow for saving of 'invalid' assembly configs.
            quoteAssembly.set('saveAction', 'SAVEFORLATER');
          }

          quoteAssembly.setupForCommit();
          quoteAssembly.save().then(function () {
            // project needs to be reloaded for the propert update of pricing options when turntime is changed
            // via a collapsed quote-snippet dropdown.
            if (!saveForLater) {
              self.project.reload().then(function () {
                self.send('resolveDeferred', parentDeferred);
              });
            } else {
              self.send('resolveDeferred', parentDeferred);
            }
            self.set('projectInformationEdit', true);
            self.set('assemblyEdit', true);
          })["catch"](function (errors) {
            console.log('assembly has errors', errors);
            //Notify user about the error
            self.handleApplicationError("quoting.js --> findOrCreateQuoteAssembly 1", errors, false, "Problem saving assembly details.");

            self.send('rejectDeferred', parentDeferred, errors);
          });
        })["catch"](function (errors) {
          console.log('findOrCreateQuoteAssembly failed', errors);
          //Notify user about the error
          self.handleApplicationError("quoting.js --> findOrCreateQuoteAssembly 2", errors, false, "Problem saving assembly details.");
        });
      },

      findOrCreateQuoteFabrication: function findOrCreateQuoteFabrication(projectGUID, saveForLater, parentDeferred) {
        var self = this;
        var fabricationController = self.get('fabricationController');
        var deferred = new _ember["default"].RSVP.defer('quotingController#findOrCreateQuoteFabrication');

        fabricationController.findOrCreateQuoteFabrication(projectGUID, deferred);

        deferred.promise.then(function (quoteFab) {
          if (saveForLater) {
            quoteFab.set('saveAction', 'SAVEFORLATER');
          }

          quoteFab.setupForCommit();
          quoteFab.save().then(function () {
            console.log('findOrCreateQuoteFabrication saved');
            if (!saveForLater) {
              self.project.reload().then(function () {
                self.send('resolveDeferred', parentDeferred);
              });
            } else {
              self.send('resolveDeferred', parentDeferred);
            }
            self.set('fabricationEdit', true);
          })["catch"](function (errors) {
            console.log('fabrication not saved', errors);
            if (errors.errors.filterBy('details', "We are sorry, fabrication quoting is temporarily unavailable.").length > 0) {
              self.send('openModal', 'modals/alert-modal', { text: 'Unfortunately, our fabrication partner appears to be&nbsp;offline. You&nbsp;may continue quoting without fabrication or try again in a few&nbsp;minutes. Ugh,&nbsp;Sorry&nbsp;about&nbsp;that!' });
            }
            //Notify user about the error
            self.handleApplicationError("quoting.js --> findOrCreateQuoteFabrication", errors, false, "Problem saving fabrication details.");
            self.send('rejectDeferred', parentDeferred, errors);
          });
        });
      },

      isItarChanged: function isItarChanged() {
        var self = this;
        if (this.get('fabricationPricing') && this.get('assemblyPricing')) {
          var validateDeferred = new _ember["default"].RSVP.defer('quotingController#isItarChanged1');
          var findOrCreateDeferred = new _ember["default"].RSVP.defer('quotingController#isItarChanged2');
          console.log('ONE');
          this.send('validateAssembly', validateDeferred);

          validateDeferred.promise.then(function () {
            self.send('findOrCreateQuoteAssembly', self.get('project.id'), false, findOrCreateDeferred);
          })["catch"](function (errors) {
            console.log('isItarChanged validation failed: ', errors);
            //Log error but do not notify user.
            self.handleApplicationError("quoting.js --> isItarChanged 1", errors, false);
          });

          findOrCreateDeferred.promise.then(function () {
            //to update quote snippet copy and inititate auto upgrade
            self.send('validateForm');
          })["catch"](function (errors) {
            console.log('isItarChanged findOrCreateQuoteAssembly failed: ', errors);
            //Log error but do not notify user.
            self.handleApplicationError("quoting.js --> isItarChanged 2", errors, false);
          });
        }
      },

      postToSkipBomEndpoint: function postToSkipBomEndpoint(projectGUID, customValue, deferred) {
        console.log('quoting postToSkipBomEndpoint');
        var partsController = this.get('partsController');
        partsController.postToSkipBomEndpoint(projectGUID, customValue, deferred);
      },

      postToClearBomEndpoint: function postToClearBomEndpoint(projectGUID, deferred) {
        console.log('quoting postToClearBomEndpoint');
        var partsController = this.get('partsController');
        partsController.postToClearBomEndpoint(projectGUID, deferred);
      },

      skipOnlineParts: function skipOnlineParts() {
        var customValue = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
        var deferred = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        var self = this;
        var postSkipDeferred = new _ember["default"].RSVP.defer('skip online parts quoting');
        self.send('postToSkipBomEndpoint', self.project.get('id'), customValue, postSkipDeferred);

        postSkipDeferred.promise.then(function () {
          self.project.reload().then(function () {
            //TODO: determine why project still has associated bom data after this reload so the
            //unloading below doesn't need to occur
            self.store.unloadAll('bom');
            self.store.unloadAll('bom-line');
            if (deferred) {
              deferred.resolve();
            }
          });
        });
      },

      resolveDeferred: function resolveDeferred(deferred) {
        if (deferred) {
          return deferred.resolve();
        }
      },

      rejectDeferred: function rejectDeferred(deferred, errors) {
        if (deferred) {
          return deferred.reject(errors);
        }
      },

      saveForLater: function saveForLater(project, deferred) {
        var shouldPromptForLogin = arguments.length <= 2 || arguments[2] === undefined ? false : arguments[2];

        // deferred & shouldPromptForLogin only sent from quote.footer saveForLater action.
        //resolution of deferred transitions user to quoting index
        //shouldPromptForLogin prevents forced login anonymous transition b/w sections

        var self = this;
        var projectGUID = project.get('id');
        var canSubmitFabrication = self.fabricationReadyForSubmit();
        var userSignedIn = this.get('session.isAuthenticated');

        var assemblyTaskDeferred = new _ember["default"].RSVP.defer('saveForLater #assemblyTaskDeferred');
        var fabricationTaskDeferred = new _ember["default"].RSVP.defer('saveForLater #fabricationTaskDeferred');
        var loginDeferred = new _ember["default"].RSVP.defer('quoting saveForLater loginDeferred');

        if (!userSignedIn && shouldPromptForLogin) {
          //send deferred to login so save proceeds after login?
          self.send('openModal', 'modals/sign-in-or-register-modal', null, loginDeferred);
        } else {
          loginDeferred.resolve();
        }

        loginDeferred.promise.then(function () {
          self.send('findOrCreateQuoteAssembly', projectGUID, true, assemblyTaskDeferred);

          if (canSubmitFabrication) {
            self.send('findOrCreateQuoteFabrication', projectGUID, true, fabricationTaskDeferred);
          } else {
            fabricationTaskDeferred.resolve();
          }

          _ember["default"].RSVP.allSettled([assemblyTaskDeferred.promise, fabricationTaskDeferred.promise]).then(function () {
            self.send('saveProject', project, deferred);
          });
        })["catch"](function (errors) {
          //Log error but do not notify user.
          self.handleApplicationError("quoting.js --> saveForLater", errors, false);
          self.send('rejectDeferred', deferred);
        });
      },

      saveProject: function saveProject(project, parentDeferred) {
        //again, parentDeferred coming from quote-footer saveAndTransitionToQuoting
        var self = this;
        if (project) {
          //commitOnSuccess might still be false, if validations were initiated in project-name-modal,
          //but then user canceled out of the modal, instead of saving with a project name.
          project.set('commitOnSuccess', true);
          project.save().then(function () {
            console.log('save Project success');
            self.send('resolveDeferred', parentDeferred);
          })["catch"](function (errors) {
            console.log('save Project failed', errors);
            //Log error but do not notify user.
            self.handleApplicationError("quoting.js --> saveProject", errors, false);
            self.send('rejectDeferred', parentDeferred, errors);
          });
        }
      },

      scrollToSnippet: function scrollToSnippet(snippetName) {
        var targetSnippet = this.get('quote' + snippetName.capitalize() + 'Snippet');
        // add a bit of delay so the component height can expand all the way
        if (targetSnippet) {
          _ember["default"].run.later(function () {
            $('html, body').animate({ scrollTop: targetSnippet.$().offset().top - 10 }, 200);
          }, 500);
        }
      },

      setClientStateOnProject: function setClientStateOnProject(section, projectGUID, parentDeferred) {
        console.log('setClientStateOnProject section', section);
        console.log('setClientStateOnProject projectGUID', projectGUID);
        var project = this.store.peekRecord('project', projectGUID);
        var self = this;
        project.set('clientState', section);
        if (section === 'files') {
          project.set('hubSpotConversationId', $.cookie('hubSpotConversationId'));
        } else {
          project.set('hubSpotConversationId', '');
        }
        project.save().then(function () {
          project.set('hubSpotConversationId', '');
          console.log('clientState successfully set on project');
          if (parentDeferred) {
            self.send('resolveDeferred', parentDeferred);
          }
          return;
        })["catch"](function (errors) {
          console.log('clientState failed', errors);
          //Log error but do not notify user.
          self.handleApplicationError("quoting.js --> setClientStateOnProject", errors, false);
          self.send('rejectDeferred', parentDeferred, errors);
        });
      },

      showServiceTypeWindow: function showServiceTypeWindow() {
        if (!this.get("showFabrication")) {
          this.toggleProperty('showFabrication');
        }
      },

      // this action is curretly only used for mobile in quote-parts
      skipPartsAndContinue: function skipPartsAndContinue() {
        var self = this;
        var projectGUID = this.get('project.id');
        //var customParts = this.get('project.partsIncluded').toLowerCase() === 'false' ?  false : true;
        var customParts = _ember["default"].isEmpty(this.get('project.bomDetails')) ? false : true;
        if (this.get('project.userSkippedOnlineQuoting')) {
          customParts = true;
        }
        var deferred = new _ember["default"].RSVP.defer('delete parts mobile');
        this.send('skipOnlineParts', customParts, deferred);

        if (this.get('project.bomDetails')) {
          this.get('project.bomDetails').set('partsTotal', 0);
        }
        deferred.promise.then(function () {
          self.toggleProperty('refreshTurnTimes');
          self.send('setClientStateOnProject', 'pricing', projectGUID);
          self.set('partsEdit', false);
          self.toggleProperty('refreshTurnTimes');
          self.send('toggleComponent', 'pricing');
          self.get('project').set('customPartsQuote', customParts); //in order to show upload form.
        });
      },

      toggleComponent: function toggleComponent(showThis, fromComponent) {
        if (!this.get('quoteLocked')) {
          var scrollTarget = fromComponent ? fromComponent : showThis;
          this.send('collapse'); //collapse all sections

          // has a run later of 500 to wait for collapse
          this.send('scrollToSnippet', scrollTarget); // scroll to desired component

          // Due to the animations on the pricing section.
          if (showThis === 'pricing') {
            this.set('allCollapsed', true);
          } else {
            // runs later to run after the scroll animation completes
            _ember["default"].run.later(this, function () {
              this.set(showThis + 'Touched', true); // 'touch' desired component
              this.set('show' + showThis.capitalize(), true); //open desired component
            }, 1000);
            this.set('allCollapsed', false);
          }
        }
      },

      toggleEdit: function toggleEdit(section, value) {
        this.set(section + 'Edit', value);
      },

      toggleLockQuote: function toggleLockQuote(lockState, toggleSection) {
        this.set('quoteLocked', lockState);
        if (toggleSection) {
          this.send('toggleComponent', toggleSection);
        }
      },

      toggleRefreshTurnTimes: function toggleRefreshTurnTimes() {
        this.toggleProperty('refreshTurnTimes');
      },

      addOrRemoveFab: function addOrRemoveFab(removal, projectGUID) {
        if (removal === true) {
          this.send('deleteFabQuotePricingAndFabQuote', projectGUID);
        } else {
          this.send('addFabricationToQuote', this.get('project'));
        }
      },

      addOrRemoveParts: function addOrRemoveParts(removal, projectGUID) {
        var self = this;
        if (removal === true) {
          //POST to /skipbom which includes a DELETE to /bom set customValue to
          // false so that parts will not trigger customQuote workflow.
          var deferred = new _ember["default"].RSVP.defer('skipOnlineParts');
          this.send('skipOnlineParts', false, deferred);

          if (this.get('project.bomDetails')) {
            this.get('project.bomDetails').set('partsTotal', 0);
          }
          deferred.promise.then(function () {
            self.toggleProperty('refreshTurnTimes');
            self.send('setClientStateOnProject', 'pricing', projectGUID);
          });
          self.send('toggleComponent', 'parts');
        } else {
          //temp: state maintained server side so shouldn't be setting directly here
          //hoping for way to set this on the endpoint, SC-698
          //this.get('project').set('partsIncluded', 'True');
          var postClearDeferred = new _ember["default"].RSVP.defer('clear online parts quoting');
          self.send('postToClearBomEndpoint', self.project.get('id'), postClearDeferred);
          postClearDeferred.promise.then(function () {
            self.project.reload().then(function () {
              self.send('toggleComponent', 'parts');
            });
          });
        }
      },

      toggleSkip: function toggleSkip(section, removal) {
        var projectGUID = this.get('project.id');

        if (section === 'fabrication') {
          this.send('addOrRemoveFab', removal, projectGUID);
        } else if (section === 'parts') {
          this.send('addOrRemoveParts', removal, projectGUID);
        }
        this.set(section + 'Edit', false);
      },

      transitionToOrder: function transitionToOrder() {
        var self = this;
        var projectGUID = this.project.get('id');
        var deferred = new _ember["default"].RSVP.defer('quotingController#setClientStateOnProject');

        self.send('setClientStateOnProject', 'files', projectGUID, deferred);

        deferred.promise.then(function () {
          $("html, body").scrollTop(0);
          self.get('applicationController').send('closeModal');
          self.transitionToRoute('order.edit', projectGUID);
        });
      },

      transitionToRoute: function transitionToRoute(route) {
        console.log('TRANSITION TO ROUTE', route);
        this.transitionToRoute(route);
      },

      touchNextSection: function touchNextSection(currentSection) {
        // this is only triggered by validateForm triggered by the edit update action...
        // we need to manually trigger next section if they are not hitting a components saveAndPerformAction hook.
        var nextSection;
        if (currentSection === 'projectInformation') {
          nextSection = 'parts';
        } else if (currentSection === 'parts') {
          nextSection = 'assembly';
        } else if (currentSection === 'assembly') {
          nextSection = 'fabrication';
        }

        if (!this.get(nextSection + 'Touched')) {
          this.set(nextSection + 'Touched', true);
        }
      },

      validateAssembly: function validateAssembly(deferred) {
        var quoteAssemblyPricingComponent = this.get('quoteAssemblyPricingComponent');
        quoteAssemblyPricingComponent.send('validateQuoteAssemblyPricing', deferred);
      },

      validateFabrication: function validateFabrication(deferred) {
        var quoteFabricationPricingComponent = this.get('quoteFabricationPricingComponent');
        quoteFabricationPricingComponent.send('validateQuoteFabricationPricing', deferred);
      },

      saveAndValidateForm: function saveAndValidateForm(spinnerDeferred) {
        console.log('saveAndValidateForm');
        var self = this;
        var saveForLaterDeferred = new _ember["default"].RSVP.defer('saveAndValidateForm# parent saveForLaterDeferred object');
        var bomDetailsPromise;
        var promises = [];

        this.send('saveForLater', this.get('project'), saveForLaterDeferred);

        saveForLaterDeferred.promise.then(function () {
          var validateAssemblyDeferred = new _ember["default"].RSVP.defer('saveAndValidateForm -- validateAssembly deferred');
          self.send('validateAssembly', validateAssemblyDeferred);
          promises.push(validateAssemblyDeferred.promise);

          // don't validate Fabrication if it hasn't been touched yet, or has been explicity removed
          if (self.get('fabricationTouched') && !self.get('project.userRemovedFab')) {
            var validateFabricationDeferred = new _ember["default"].RSVP.defer('saveAndValidateForm fab deferred');
            promises.push(validateFabricationDeferred.promise);

            var findOrCreateDeferred = new _ember["default"].RSVP.defer('quoteFabricationPricing save deferred');
            if (self.get('triggerProjectReprice')) {
              //Wait till fabrication is saved to trigger price grid refresh
              promises.push(findOrCreateDeferred.promise);
            }
            self.send('validateFabrication', validateFabricationDeferred);
            validateFabricationDeferred.promise.then(function () {
              console.log("Create valid fab deferred RESOLVED!");
              if (self.get('triggerProjectReprice')) {
                //Update fabrication here in case of rubber banding of turntimes
                var projectGUID = self.get('project.id');
                self.send('findOrCreateQuoteFabrication', projectGUID, false, findOrCreateDeferred);
                findOrCreateDeferred.promise.then(function () {
                  console.log("Save fab deferred RESOLVED!");
                });
              }
            })["catch"](function (errors) {
              console.log('validateFabrication failed: ', errors);
              if (self.get('triggerProjectReprice')) {
                findOrCreateDeferred.resolve();
              }
            });
          }

          if (self.get('project.partsIncluded') && self.get('project.bomDetails')) {
            bomDetailsPromise = self.get('project.bomDetails').reload();
            console.log("Create bom details promise");
            promises.push(bomDetailsPromise);
          }

          _ember["default"].RSVP.allSettled(promises).then(function () {
            console.log("ALL SETTLED");
            spinnerDeferred.resolve();
            self.toggleProperty('refreshTurnTimes');
          });
        })["catch"](function (errors) {
          console.log('save for later failed: ', errors);
          //Log error but do not notify user.
          self.handleApplicationError("quoting.js --> saveAndValidateForm", errors, false);
          spinnerDeferred.reject();
        });
      },

      validateForm: function validateForm(section) {
        console.log('validateForm');
        var self = this;
        var bomDetailsPromise = false;
        var promises = [];
        this.send('validateAssembly');

        // don't validate Fabrication if it is skipped
        if (self.get('fabricationTouched') && !self.get('project.userRemovedFab')) {
          var validateFabricationDeferred = new _ember["default"].RSVP.defer('saveAndValidateForm fab deferred');
          promises.push(validateFabricationDeferred.promise);
          self.send('validateFabrication', validateFabricationDeferred);
          validateFabricationDeferred.promise.then(function () {
            console.log("Create valid fab deferred RESOLVED!");
          });
        }

        if (self.get('project.partsIncluded') && self.get('project.bomDetails')) {
          bomDetailsPromise = self.get('project.bomDetails').reload();
          promises.push(bomDetailsPromise);
          bomDetailsPromise.then(function () {
            console.log("Create bom details promise resolved;");
          });
        }

        _ember["default"].RSVP.allSettled(promises).then(function () {
          console.log("ALL SETTLED");
          self.toggleProperty('refreshTurnTimes');
        });

        if (section) {
          this.send('collapseComponent', section);
          this.send('touchNextSection', section);
        }
      }
    }
  });
});