define('web-app-frontend/components/feed-item', ['exports', 'ember', 'web-app-frontend/utils/quote-state-evaluator'], function (exports, _ember, _webAppFrontendUtilsQuoteStateEvaluator) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['feed-item'],
    transitionToQuoteState: 'transitionToQuoteState',

    iconType: (function () {
      var type = this.get('activity.entity');
      if (type === 'Quote') {
        return 'icon-receipt';
      } else if (type === 'Order') {
        return 'icon-clipboard';
      } else {
        return 'icon-profile';
      }
    }).property('activity.entity'),

    actions: {
      transitionToQuoteState: function transitionToQuoteState() {
        var store = this.get('targetObject.store');
        var self = this;

        store.findRecord('project', this.get('activity.projectGUID')).then(function (project) {
          if ((0, _webAppFrontendUtilsQuoteStateEvaluator['default'])(project.get('clientState'), project.get('isUserEditable')) === 'quote') {
            self.sendAction('transitionToQuoteState', 'quote', self.get('activity.projectGUID'));
          } else {
            self.sendAction('transitionToQuoteState', 'order', self.get('activity.projectGUID'));
          }
        });
      }
    }

  });
});