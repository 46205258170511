define('web-app-frontend/serializers/assembly-price-difference', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var object = payload.assemblyPricing.data.get('firstObject');
      object.id = id;
      var data = { assemblyPriceDifference: object };
      return this._super(store, typeClass, data);
    }
  });
});