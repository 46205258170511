define("web-app-frontend/templates/components/kit-box", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 18,
              "column": 4
            }
          },
          "moduleName": "web-app-frontend/templates/components/kit-box.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h5");
          dom.setAttribute(el1, "class", "semi-bold");
          var el2 = dom.createTextNode("When do you expect the last box to arrive?");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "input-calendar ib mt10 mb20");
          var el2 = dom.createTextNode("\n         ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "custom-pikaday-picker", [], ["value", ["subexpr", "@mut", [["get", "model.orderKit.kitArrivalDate", ["loc", [null, [8, 39], [8, 68]]]]], [], []], "format", "MMMM DD, YYYY", "disableWeekends", true, "minDate", ["subexpr", "@mut", [["get", "todayDate", ["loc", [null, [11, 41], [11, 50]]]]], [], []], "maxDate", ["subexpr", "@mut", [["get", "twoMonths", ["loc", [null, [12, 41], [12, 50]]]]], [], []]], ["loc", [null, [8, 9], [12, 52]]]], ["inline", "error-display", [], ["tagName", "span", "model", ["subexpr", "@mut", [["get", "model.orderKit", ["loc", [null, [15, 31], [15, 45]]]]], [], []], "attribute", "kitArrivalDate", "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [17, 47], [17, 68]]]]], [], []]], ["loc", [null, [14, 6], [17, 70]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "web-app-frontend/templates/components/kit-box.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          var el2 = dom.createElement("p");
          dom.setAttribute(el2, "class", "float-right underline");
          var el3 = dom.createTextNode("Remove");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createElementMorph(element0);
          return morphs;
        },
        statements: [["element", "action", ["removeBox"], [], ["loc", [null, [19, 8], [19, 30]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 25,
              "column": 8
            },
            "end": {
              "line": 27,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/kit-box.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "error-text");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "model.orderKit.boxError", ["loc", [null, [26, 35], [26, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/kit-box.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bordered mt20 bg-lightest-grey");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "padded");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" first box should not be removable and should show last box datepicker ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "semi-bold");
        var el4 = dom.createTextNode("Box ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" Details");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "error-spacing");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "group-form");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "h6");
        var el5 = dom.createTextNode("Where will the box be coming from?");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        dom.setAttribute(el4, "class", "h6 block");
        var el5 = dom.createTextNode("Describe the box's contents");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0, 1]);
        var element2 = dom.childAt(element1, [10]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element1, 3, 3);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [8]), 1, 1);
        morphs[3] = dom.createMorphAt(element2, 3, 3);
        morphs[4] = dom.createMorphAt(element2, 7, 7);
        return morphs;
      },
      statements: [["block", "if", [["get", "firstBox", ["loc", [null, [5, 10], [5, 18]]]]], [], 0, 1, ["loc", [null, [5, 4], [20, 11]]]], ["inline", "math-helper", [["get", "index", ["loc", [null, [22, 44], [22, 49]]]], "+", 1], [], ["loc", [null, [22, 30], [22, 57]]]], ["block", "if", [["get", "model.boxError", ["loc", [null, [25, 14], [25, 28]]]]], [], 2, null, ["loc", [null, [25, 8], [27, 15]]]], ["inline", "form-field-display", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [32, 33], [32, 38]]]]], [], []], "attribute", "from", "value", ["subexpr", "@mut", [["get", "model.from", ["loc", [null, [34, 33], [34, 43]]]]], [], []], "action", "validateOrderKit", "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [36, 49], [36, 70]]]]], [], []]], ["loc", [null, [32, 6], [36, 72]]]], ["inline", "form-field-display", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [39, 33], [39, 38]]]]], [], []], "attribute", "contents", "value", ["subexpr", "@mut", [["get", "model.contents", ["loc", [null, [41, 33], [41, 47]]]]], [], []], "action", "validateOrderKit", "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [43, 49], [43, 70]]]]], [], []]], ["loc", [null, [39, 6], [43, 72]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});