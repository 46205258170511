define('web-app-frontend/models/bom-line', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    calculatedLinePerBoardPrice: _emberData['default'].attr('number'),
    displayPartDescriptionLong: _emberData['default'].attr('string'),
    displayPartDescriptionShort: _emberData['default'].attr('string'),
    displayManufacture: _emberData['default'].attr('string'),
    displayPartNumberShort: _emberData['default'].attr('string'),
    displayPartNumberLong: _emberData['default'].attr('string'),
    displayReferenceDesignators: _emberData['default'].attr('string'),
    extraForAttrition: _emberData['default'].attr('number'),
    footprint: _emberData['default'].attr('string'),
    itemNumber: _emberData['default'].attr('number'),
    lineNumber: _emberData['default'].attr('number'),
    mountType: _emberData['default'].attr('string'),
    placementsPerBoard: _emberData['default'].attr('number'),
    qtyAvailable: _emberData['default'].attr('number'),
    quoteableAsIs: _emberData['default'].attr('boolean', { defaultValue: true }),
    quotedBoardCount: _emberData['default'].attr('number'),
    quotedLineTotalPrice: _emberData['default'].attr('number'),
    // resultReferenceData START
    manufactureName: _emberData['default'].attr('string'),
    manufactureNumber: _emberData['default'].attr('string'),
    partDescription: _emberData['default'].attr('string'),
    partImageURL: _emberData['default'].attr('string'),
    partImageURLssl: _emberData['default'].attr('string'),
    purchasedPartNumberOverride: _emberData['default'].attr('string'),
    quantityValidation: _emberData['default'].attr('string'),
    sourceVendor: _emberData['default'].attr('string'),
    vendorPartNumber: _emberData['default'].attr('string'),
    // END 
    sourcingAction: _emberData['default'].attr('string'),
    whyNotQuoteable: _emberData['default'].attr('string'),
    submittedReferenceData: _emberData['default'].attr(),
    inventoryHealth: _emberData['default'].attr('string'),

    //bomLineId                  : DS.attr('number'),
    bmlIsSelected: _emberData['default'].attr('boolean'),
    VendorUnitCostBook: _emberData['default'].attr('number'),
    VendorUnitCostDiscounted: _emberData['default'].attr('number'),
    RoHSCompliant: _emberData['default'].attr('string'),
    AdditionalCharges: _emberData['default'].attr('number'),
    LeadTime: _emberData['default'].attr('string'),
    purchasedQuantity: _emberData['default'].attr('number'),
    minimumOrderQty: _emberData['default'].attr('number'),
    multipleOrderQty: _emberData['default'].attr('number'),
    isPurchasedQuantityAffected: _emberData['default'].attr('boolean'),
    // Associations
    bom: _emberData['default'].belongsTo('bom', { async: true }),
    project: _emberData['default'].belongsTo('project', { async: true }),

    // isAlternativePartAdded: function(){
    //   return this.get('model.alternativeParts').lenght > 0;
    // }.property('modelalternativeParts'),

    itemNumberPresent: (function () {
      return this.get('itemNumber') !== 0;
    }).property('itemNumber'),

    isSourceVendorUnknown: (function () {
      return this.get('sourceVendor') === 'Unknown';
    }).property('sourceVendor'),

    isRefDesError: (function () {
      return (this.get('quantityValidation') === 'Mismatch' && this.get('sourceVendor') !== 'Unknown' || this.get('placementsPerBoard') === 0) && !(this.get('sourcingAction') === 'DNP' || this.get('sourcingAction') === 'CustProvided' || this.get('sourcingAction') === 'Quote');
    }).property('quantityValidation', 'sourceVendor', 'placementsPerBoard', 'sourcingAction'),

    quotedLineTotalPriceFormatted: (function () {
      return this.get('quotedLineTotalPrice').toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }).property('quotedLineTotalPriceFormatted'),

    mountTypes: [{ text: 'Unknown', value: 'Unknown' }, { text: 'SMT', value: 'SMT' }, { text: 'ThruHole', value: 'ThruHole' }, { text: 'Bottom Termination (BGA/QFN)', value: 'BGA' }],

    sourcingActions: (function () {
      if (this.get('quoteableAsIs')) {
        if (this.get('isPurchasedQuantityAffected')) {
          return [{ text: 'Choose Action', value: 'PromptCustomer' }, { text: 'Source MOQ for $' + this.get('quotedLineTotalPriceFormatted'), value: 'Purchase' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Do Not Place', value: 'DNP' }, { text: 'Please Requote', value: 'Quote' }];
        } else {

          return [{ text: 'Choose Action', value: 'PromptCustomer' }, { text: 'Source for $' + this.get('quotedLineTotalPriceFormatted'), value: 'Purchase' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Do Not Place', value: 'DNP' }, { text: 'Please Requote', value: 'Quote' }];
        }
      } else {
        return [{ text: 'Choose Action', value: 'PromptCustomer' }, { text: 'I will provide', value: 'CustProvided' }, { text: 'Do Not Place', value: 'DNP' }, { text: 'Please Quote', value: 'Quote' }];
      }
    }).property('quoteableAsIs', 'quotedLineTotalPrice', 'isPurchasedQuantityAffected'),

    shouldDisplayErrorIcon: (function () {
      if (this.get('sourcingAction') === 'PromptCustomer' && this.get('bmlIsSelected', true) || this.get('sourcingAction') === 'CustProvided' && this.get('mountType') === 'Unknown' && this.get('bmlIsSelected', true)) {
        return true;
      } else {
        return false;
      }
    }).property('sourcingAction', 'mountType'),

    provideOrDNP: (function () {
      if (this.get('sourcingAction') === 'DNP' || this.get('sourcingAction') === 'CustProvided') {
        return true;
      } else {
        return false;
      }
    }).property('sourcingAction'),

    isInventoryHealthInStock: (function () {
      if (this.get('inventoryHealth') === "Good") {
        return true;
      }
      return false;
    }).property('inventoryHealth'),

    isInventoryHealthAtRisk: (function () {
      if (this.get('inventoryHealth') === "Risk") {
        return true;
      }
      return false;
    }).property('inventoryHealth'),

    isInventoryHealthAtNoStock: (function () {
      if (this.get('inventoryHealth') === "NoStock") {
        return true;
      }
      return false;
    }).property('inventoryHealth')
  });
});