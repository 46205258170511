define('web-app-frontend/models/survey', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attributes
    surveyKey: _emberData['default'].attr('string'),
    orderNumber: _emberData['default'].attr('string'),
    surveyName: _emberData['default'].attr('string'),
    surveyTitle: _emberData['default'].attr('string'),
    surveyInstructions: _emberData['default'].attr('string'),
    surveyFootnote: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr("boolean"),
    type: _emberData['default'].attr("string"),
    projectGUID: _emberData['default'].attr('string'),

    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),
    user: _emberData['default'].belongsTo('user', { async: true }),
    questions: _emberData['default'].hasMany('questions', { async: true })
  });
});