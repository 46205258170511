define('web-app-frontend/adapters/user-email-address', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },

    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL(type, userID) {
      var url = "user/" + userID + "/contactemailaddress";
      return this._super(url);
    },

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      if (!snapshot.attr('committedToServer')) {
        return this.createRecord(store, type, snapshot);
      } else {
        var record = snapshot.record;
        var userGUID = snapshot.attr('userGUID');
        var id = record.get('serverId');
        var payload = { userEmailAddress: record };
        var baseURL = this.buildURL(type.modelName, userGUID);
        var updateURL = baseURL + '/' + id;

        return this.ajax(updateURL, "PUT", { data: payload });
      }
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var userGUID = snapshot.attr('userGUID');
      var payload = { userEmailAddress: record };

      return this.ajax(this.buildURL(type.modelName, userGUID), "POST", { data: payload });
    },

    deleteRecord: function deleteRecord(store, type, snapshot) {
      var record = snapshot.record;
      var contactID = record.get('serverId');
      var userGUID = snapshot.attr('userGUID');
      var baseURL = this.buildURL(type.modelName, userGUID);
      var deleteURL = baseURL + '/' + contactID;

      return this.ajax(deleteURL, 'DELETE');
    },

    findAll: function findAll(store, type) {
      var userID = store.peekAll('user').get('firstObject.id');
      return this.ajax(this.buildURL(type.modelName, userID), "GET");
    },

    ajax: function ajax(url, type, hash) {
      url = url + "?Api-Key=SCWEB";
      return this._super(url, type, hash);
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.userEmailContactModel && response.userEmailContactModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.userEmailContactModel.errors);
        } else {
          if (response.userEmailAddress.errors && response.userEmailAddress.errors != null) {
            errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.userEmailAddress.errors);
          }
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }

  });
});