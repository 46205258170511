define('web-app-frontend/components/rc-hover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['rc-hover '],
    isHovered: false,

    mouseEnter: function mouseEnter() {
      this.set('isHovered', true);
    },

    mouseLeave: function mouseLeave() {
      this.set('isHovered', false);
    }
  });
});