define('web-app-frontend/serializers/project-note', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      var projectNotes = payload.projectNotesModel;
      projectNotes.id = projectNotes.projectGUID;
      projectNotes.commitOnSuccess = false;

      var data = { projectNotes: projectNotes };

      return this._super(store, typeClass, data);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var projectNotes = payload.projectNotesModel;
      projectNotes.id = projectNotes.projectGUID;
      var data = { projectNotes: projectNotes };
      return this._super(store, typeClass, data);
    }
  });
});