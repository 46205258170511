define("web-app-frontend/serializers/user-email-address", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id, requestType) {
      var userEmailAddress = payload.userEmailAddress;
      if (requestType !== "deleteRecord") {
        // validations for this object return an id of 0, because when you click submit, it also triggers a validation we are seeing
        // race conditions where the serverId value was getting set twice.  Only set serverId if value is greater than 0
        if (userEmailAddress.id > 0) {
          userEmailAddress.serverId = userEmailAddress.id;
        }
        delete userEmailAddress.id; //Remove server generated id.
        userEmailAddress.id = id; //Apply GUID created by generateIdForRecord method in adapter.
      } else if (requestType === "deleteRecord") {
          if (!userEmailAddress) {
            userEmailAddress = {};
          }
          userEmailAddress.id = id;
        }
      payload = { userEmailAddress: userEmailAddress };
      return this._super(store, typeClass, payload);
    },

    normalizeArrayResponse: function normalizeArrayResponse(store, typeClass, payload) {
      var self = this;
      var userGUID = payload.userEmailAddress.url.match(/user\/(.*?)\/contactemailaddress/)[1];

      payload.userEmailAddress.data.forEach(function (contact) {

        Object.keys(contact).forEach(function (key) {
          if (contact[key] === null) {
            contact[key] = '';
          }
        });

        contact.serverId = contact.id;
        contact.userGUID = userGUID;
        contact.committedToServer = true;
        delete contact.id;
        contact.id = self.generateIdForRecord();
        delete contact.type;
      });

      payload = { userEmailAddress: payload.userEmailAddress.data };
      return this._super(store, typeClass, payload);
    },

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }
  });
});