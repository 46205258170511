define('web-app-frontend/controllers/quoting/assembly', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    quotingNewController: _ember['default'].inject.controller('quoting/new'),
    projectController: _ember['default'].inject.controller('project'),

    createNewQuoteAssembly: function createNewQuoteAssembly(quoteAssemblyPricing, projectGUID, deferred) {
      var quoteAssembly = this.store.createRecord('quoteAssembly', { id: projectGUID,
        projectGUID: projectGUID });

      this.get('applicationController').updateChangedValues(quoteAssembly, quoteAssemblyPricing, deferred);
    },

    findOrCreateQuoteAssembly: function findOrCreateQuoteAssembly(projectGUID, deferred) {
      var store = this.store;
      var quoteAssemblyPricing = store.peekRecord('quoteAssemblyPricing', projectGUID);
      var hasQuoteAssemblyRecord = store.recordIsLoaded('quoteAssembly', projectGUID);
      var quoteAssembly;

      if (hasQuoteAssemblyRecord) {
        quoteAssembly = store.peekRecord('quoteAssembly', projectGUID);
        this.get('applicationController').updateChangedValues(quoteAssembly, quoteAssemblyPricing, deferred);
      } else {
        this.createNewQuoteAssembly(quoteAssemblyPricing, projectGUID, deferred);
      }
    }
  });
});