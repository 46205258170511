define('web-app-frontend/components/order-contact', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    toggleComponent: 'toggleComponent',
    collapse: 'collapse',
    close: 'close',
    contactPref: ['Phone', 'Email'],
    setClientStateOnProject: 'setClientStateOnProject',
    setSectionErrorState: 'setSectionErrorState',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    validateOnContactPrefChange: (function () {
      if (this.model && this.model.get('hasDirtyAttributes')) {
        //check to make sure desired attrs are actually changed, prevents validate calls on load
        var changedAttributes = Object.keys(this.model.changedAttributes());
        if (changedAttributes.contains('dayEmergencyContactPreference') || changedAttributes.contains('nightEmergencyContactPreference')) {
          this.send('validateOrderContact');
        }
      }
    }).observes('model.dayEmergencyContactPreference', 'model.nightEmergencyContactPreference'),

    actions: {
      performAction: function performAction(action, actionParam) {
        this.sendAction('setClientStateOnProject', actionParam);
        this.sendAction(action, actionParam);
      },

      preventValidationOnToggle: function preventValidationOnToggle() {
        this.set('preventValidationOnToggle', true);
      },

      saveAndPerformAction: function saveAndPerformAction(action, actionParam) {
        var orderContact = this.model;
        var self = this;
        var quoteSubmitButton = this.get('quoteSubmitButton');

        if (orderContact.get('nightSameAsDay')) {
          orderContact.setNighttimeContactInfo();
        }

        orderContact.setupForCommit();
        orderContact.save().then(function () {
          self.send('performAction', action, actionParam);
        })['catch'](function (errors) {
          console.log('saveAndPerformAction Failed', errors);
          //else validation errors might show in locked section
          if (orderContact.get('nightSameAsDay')) {
            orderContact.clearNighttimeContactErrors();
          }
        })['finally'](function () {
          quoteSubmitButton.send('canSubmit');
          orderContact.set('commitOnSuccess', false);
        });
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      },

      validateOrderContact: function validateOrderContact() {
        var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        if (!this.get('preventValidationOnToggle')) {
          var orderContact = this.model;
          orderContact.alterAttributes(all);
          orderContact.save().then(function () {})['catch'](function (error) {
            console.log('validateOrderContact Failed', error);
          }).then(function () {
            //else validation errors might show in locked section
            if (orderContact.get('nightSameAsDay')) {
              orderContact.clearNighttimeContactErrors();
            }
            return false;
          });
        } else {
          this.set('preventValidationOnToggle', false);
        }
      }
    }
  });
});