define("web-app-frontend/controllers/order/confirm-attrition-modal", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    applicationController: _ember["default"].inject.controller("application"),
    nameError: false,
    revisionError: false,
    closeModal: "closeModal",

    actions: {
      affirmAttritionPolicy: function affirmAttritionPolicy() {
        this.model.set("acceptAttritionPolicy", true);
        this.send("closeModal");
      },
      closeConfirmAttritionModal: function closeConfirmAttritionModal() {
        this.model.set("acceptAttritionPolicy", false);
        this.send("closeModal");
      }
    }
  });
});