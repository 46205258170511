define('web-app-frontend/serializers/project-checkout-message', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      var projectCheckoutMessage = payload.projectCheckoutMessage;
      projectCheckoutMessage.id = projectCheckoutMessage.projectGUID;
      projectCheckoutMessage.commitOnSuccess = false;

      var data = { projectCheckoutMessage: projectCheckoutMessage };

      return this._super(store, typeClass, data);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var projectCheckoutMessage = payload.projectCheckoutMessage;
      projectCheckoutMessage.id = projectCheckoutMessage.projectGUID;
      var data = { projectCheckoutMessage: projectCheckoutMessage };
      return this._super(store, typeClass, data);
    }
  });
});