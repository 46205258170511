define("web-app-frontend/templates/resource-center/terms-and-conditions", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 290,
              "column": 10
            },
            "end": {
              "line": 292,
              "column": 44
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("parts kit requirements");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 311,
              "column": 10
            },
            "end": {
              "line": 313,
              "column": 38
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("page about files");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 337,
              "column": 8
            },
            "end": {
              "line": 339,
              "column": 32
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("File Requirements");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 341,
              "column": 8
            },
            "end": {
              "line": 343,
              "column": 43
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Parts Kit Requirements.");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 364,
              "column": 37
            },
            "end": {
              "line": 366,
              "column": 44
            }
          },
          "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("Parts Kit Requirements");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 376,
            "column": 20
          }
        },
        "moduleName": "web-app-frontend/templates/resource-center/terms-and-conditions.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row mt60 mb40");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ib mr20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "icon-compass-orange font-65");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ib");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h6");
        dom.setAttribute(el5, "class", "underline");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        dom.setAttribute(el5, "id", "general");
        var el6 = dom.createTextNode("Screaming Circuits Terms & Conditions");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row mt20 mb40");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Thank you for taking the time to review our terms and\n        conditions. All orders we accept are subject to our terms and conditions\n        unless otherwise specifically agreed to in writing by Screaming\n        Circuits. These terms and conditions supersede all others. If you have\n        any questions, please contact us.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "payments");
        var el5 = dom.createTextNode("Payments");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("By default, we accept Visa, Mastercard and Amex for payment.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("We can also accept wire transfers or ACH payments; however, we\n          cannot proceed with ordering materials or scheduling your job until\n          payment has been received.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("When adding a new credit card to our system, the card will go\n          through a validation process. This means you might see a small pending\n          charge on your credit card statement. This charge will only appear the\n          first time a new card is validated and will be removed within a few\n          days, depending on bank processing time.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Credit cards will be charged when we accept the order and schedule\n          your job.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("ul");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("We first evaluate the order to ensure accuracy, quote any\n            components that weren't auto quoted online, and/or verify if any\n            extra charges are needed for special handling or nonstandard\n            processes.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("If there are any differences from the online quote and/or order or\n            additional charges necessary, your customer service representative\n            will contact you for approval before proceeding.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("li");
        var el7 = dom.createTextNode("Subsequent charges or refunds may occur in the event the pricing\n            changes before shipment.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you cancel an order, you are liable for the cost of parts that\n          have been purchased or expenses that have been incurred prior to\n          canceling an order.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If parts expedite is needed, an additional shipping cost(s) will be\n          included on the formal quote we provide. This request must be made\n          during the quoting and review process.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "credit-terms");
        var el5 = dom.createTextNode("Credit Terms Deposits and\n        Payments");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Due to supply chain issues and holding of customer inventory, we may\n          require a deposit for materials before we can proceed with\n          procurement. If your materials order is $5,000 or greater and your\n          order is not due to ship until 30 or more days after the order is\n          placed, we will require a deposit for the full materials cost. Once\n          deposit is received, we will begin procurement of materials. Please\n          refer to the \"Payments\" section for deposit methods.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you are supplying all materials to us, and we are holding them\n          for more than 30 days before production will begin, then we may\n          require a holding deposit of up to 50% of your assembly cost.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("In the event that new or additional materials need to be purchased,\n          we will not ask for additional deposit if a deposit has already been\n          made.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Net Credit Terms are subject to Screaming Circuits approval.\n          Invoices against credit terms must be paid within agreed upon\n          timeline.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Failure to pay invoices on time, may cause open orders to be held\n          from shipping.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "shipping");
        var el5 = dom.createTextNode("Shipping");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("We use UPS as our standard carrier.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("In the USA, we ship:\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Ground at no charge");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("2nd day air for $40.00");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("Overnight for $80.00");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If provided, we can use your shipping account number.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("International destinations will require a customer shipping quote\n          and will be provided by your customer service representative.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "warranty-and-screaming-cicuits-liability");
        var el5 = dom.createTextNode("Warranty and Screaming Circuits' Liability");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Screaming Circuits warrants each assembly for workmanship up to 30\n          days after delivery.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("For manufacturing defects caused by Screaming Circuits, we will, at\n          our discretion, repair the defects or refund the price of the assembly\n          labor.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Any modifications done to the assembly after you’ve received it\n          nullifies this warranty.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Screaming Circuits is not responsible for any damage or lack of\n          functionality caused by defective design, components or PCBs provided\n          by you, or as a result of deficiencies in the electronic materials or\n          digital data provided by you. Please note, we default to CAD data\n          above all other provided data.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Due to the nature of quick-turn manufacturing, Screaming Circuits\n          does not test incoming components nor outgoing assemblies. Any\n          liability for defective components lies with the manufacturer and\n          counterfeit components lies with the component supplier.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("For high value components, please contact your customer service\n          representative about component risk management.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("In no event shall Screaming Circuits’ liability exceed the amount of\n          the order placed.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Screaming Circuits gives no other warranty; either expressed or\n          implied, and specifically disclaims all other warranties, including\n          warranties for merchantability and fitness.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "warranty-claim");
        var el5 = dom.createTextNode("How to make a warranty\n        claim");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you believe that your assemblies have manufacturing defects\n          caused by Screaming Circuits, contact your customer service\n          representative to discuss what you've found and arrange for an RMA\n          (return materials authorization) number.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Follow the instructions provided by your customer service\n          representative for returning the defective assemblies.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Make sure that the RMA number is prominently displayed on your\n          shipping label.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("No repair work will be authorized without the RMA number having been\n          issued.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If we deem that the issue does not fall within our warrantee\n          coverage, your customer service representative will contact you to\n          discuss options, including paid rework.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "project-and-job-requirements");
        var el5 = dom.createTextNode("Project and\n        Job Requirements");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Our\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        covers what we know we can do in the turn time promised. Our warranty\n        covers projects that fit within our standard process. In some cases,\n        nonstandard projects may be covered by the warranty if this has been\n        approved for coverage in writing by our manufacturing engineering\n        department. To fulfill our commitment to you, we need a few conditions\n        to be met. We need the components and operations to fit our Standard\n        Processes, we need accurate and complete data, we need the right\n        components presented in the right way, and we need clear and fast\n        communications with you.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "standard-vs-special");
        var el5 = dom.createTextNode("Standard Process Vs.\n        Special");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Once we receive your quote or order, we will evaluate it to ensure\n          that it fits our Standard Process. If it does not, we will notify you\n          as soon as we can after making the determination.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Any services ordered that fall outside of the Screaming Circuits\n          Standard Assembly Process may add time and cost to your project. We\n          will create a custom quote, outlining the particulars of your project,\n          prior to acceptance of the job.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you know that your job will not meet our Standard Process or are\n          unsure if it does, please contact your customer service representative\n          prior to placing your order.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "non-standard-technology");
        var el5 = dom.createTextNode("Non-Standard\n        Technology");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We work hard to stay abreast of the advances in\n        electronic components, PC boards, and related technology. But not even\n        the engineers at Screaming Circuits can keep up with all the latest\n        technology. Sometimes components get out into the field before we’ve had\n        a chance to develop processes for them. Other times, technology has been\n        out for a while, but it’s so rare that we’ve never had the opportunity\n        to work with it. Some processes just take extra care and handling.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("These technologies and processes that fall into our\n        Non-Standard Category require a custom quote and are not covered by our\n        Standard Warranty. If you need something cutting edge or rare and aren’t\n        sure if it’s standard or not, contact your customer service\n        representative and we’ll discuss it. Our\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        table in this website covers standard vs. non-standard limits in great\n        detail. The limits that cause the most confusion are:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Flex and rigid-flex circuits: We build a lot of flex, but it\n          typically takes fixtures and or additional handling, so it's not a\n          part of standard process.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("IC lead pitches less than 0.4mm: We have successfully assembled a\n          few projects smaller than 0.4mm pitch, but they are uncommon enough to\n          not fit in our Standard Process.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Passives smaller than 0201: We have built a few boards with 01005\n          passives, but they are non-standard.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("BGAs with more than one ball size or pitch: Weird and non-standard,\n          but they do exist. Not in our standard process.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "need-a-question");
        var el5 = dom.createTextNode("When We Need a Question\n        Answered By You");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We might need an answer any time of the day or night.\n        Questions are common for prototypes during quoting and/ or during the\n        assembly process. A pad may not fit the part. A few parts might be\n        missing. Something in the BOM (Bill of Materials) might be unclear, etc.\n        When issues arise, we need to know what to do. Our manufacturing floor\n        runs 24 hours most days. That means, if one of these problems shows up,\n        it might show up during non-standard business hours");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("We will make a judgement call and do our best to decide if we need\n          you input immediately or not.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("For minor issues that can easily be fixed one way or another without\n          affecting delivery, we may choose what we consider to be the best\n          option.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If the board components or the number of issues would be overly\n          difficult to deal with after assembly, we will stop the Build Clock\n          and contact you for further instructions.\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("ul");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("li");
        var el8 = dom.createTextNode("If the question can’t be resolved within 30 minutes, we may have\n              to stop your job and take it off the machines, adding additional\n              time to the Build Clock.");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you don’t want your job held up after hours, Be sure to provide\n          us a good email address and phone number that you will have access to\n          after hours. Please keep in mind that we run shifts around the clock\n          and might need to reach out to you at 2:00 AM if that’s when your job\n          is in process.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "the-build-clock");
        var el5 = dom.createTextNode("The Build Clock &\n        Turn Times");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("The Build Clock is what we use to track progress of your job and \n        determine if it’s on time or not. We anchor the build clock off the last material \n        received date. Turn times are predicated by jobs that fit within our\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(". In order\n        to meet your confirmed ship date, we need a few things from you.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("First, we need all your\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          files in\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createElement("u");
        var el8 = dom.createElement("i");
        var el9 = dom.createTextNode("by noon the day before");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          the build clock starts.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If we have received all your materials (parts and pcbs) by noon\n          Pacific Standard Time, your build clock starts that day at noon. If\n          the parts come in after noon the build clock starts the next day at\n          noon. This includes all parts that you are supplying and/or that we\n          are purchasing.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Our build clock is a rolling 24hrs. What that mean is, if you have a\n          24hr assembly and we have received all your materials and there are no\n          issues, you build clock will start at noon on the day your kit is\n          complete and roll to noon the following day.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Our turn times are based on a Monday - Friday work week and do not\n          include holidays. We do have teams that work weekends but weekends are\n          not counted as part of the turn times.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Additional services, not part of our standard process, such as\n          baking parts or boards, conformal coat, or AOI, will all add days to\n          the assembly turn time.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Our commercial shipments are picked up at 3:30 PM PST or later.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you elect for will-call instead of using a shipping service, we\n          will contact you once your order is ready to be picked up, generally\n          after 3:30 pm PST.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "stopping-the-build-clock");
        var el5 = dom.createTextNode("Things that can\n        Stop the Build Clock Or Keep it from Starting");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We would love to never stop the clock on your build,\n        but some things do occasionally slow us down or prevent us from\n        completing your project. Your Build Clock may be stopped, or your\n        delivery date may change for any of the following reasons:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Files received late from you.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          We can’t plan your job without the files. We require all data files in\n          house\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("b");
        var el7 = dom.createElement("u");
        var el8 = dom.createElement("i");
        var el9 = dom.createTextNode("by noon the day before");
        dom.appendChild(el8, el9);
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          your parts arrive.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("A new BOM or file set received.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          This will require us to re-program your job, resulting in us stopping\n          the build clock and adding a day to your assembly time to re-program \n          and/or audit your new BOM or files. ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Late parts.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          We can’t build a board without parts. The clock doesn’t start until we\n          have all the required parts by noon. (Read more about\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          here in our resource center)");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Not enough parts.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          We need more parts than your BOM calls for. Because of attrition we\n          typically need 10% more parts, sometimes more.\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "href", "resource-center/terms-and-conditions");
        var el7 = dom.createTextNode("See Screaming Circuits Attrition Policy.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("PCB or parts mismatches.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          If the components don’t match their land pads and we can’t reliably\n          make them work, the clock will have to be stopped.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Files with missing or unclear information.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          The clock won’t start if the files are incomplete. Read our resource\n          center\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          to more detail on what we need.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Mixed kits.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          If the parts for multiple jobs are mixed into a single kit, the clock\n          won’t start until everything has been separated and sorted. This\n          typically adds one day to the turn‑time.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Defective PCBs, parts or files.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          The clock will not start if the parts, PCBs, or files are defective.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createElement("b");
        var el7 = dom.createTextNode("Inclement weather.");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          In the case of inclement weather or natural disaster, we will close\n          the plant to protect the safety of our employees. This will stop or\n          delay the start of the build clock. We will reopen the plant as soon\n          as it is safe to do so and production will resume where it left off.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        dom.setAttribute(el4, "id", "files-and-parts");
        var el5 = dom.createTextNode("Files and Parts Kit\n        Requirements");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Manufacturing is a partnership between you and us and\n        the ultimate result depends a great deal upon how well we can understand\n        what you need. That being the case, the better organized (organized\n        meaning clear, complete, and accurate) your file set and parts kit are,\n        the less likely we are to have issues or need to call with questions.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("For complete details, go to our resource center\n        sections\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        and\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("In summary, please ensure:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("ul");
        dom.setAttribute(el4, "class", "article-list bulleted");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("BOMs are provided in Microsoft Excel or delimited text formats.\n          Files can be converted from other formats for a $125.00 fee.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("Provide RS-274X Gerber files as generated by your CAD program as if\n          you were sending them for PCB fabrication. At a minimum we need\n          silkscreen, solder paste (or stencil), and top and bottom copper\n          layers for assembly. ODB++ and a few other intelligent CAD file\n          formats are also acceptable and recommended");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("All parts with pin 1 or polarity must be identified with a\n          visibility mark. If a part is not clearly marked with polarity\n          indicators, clear assembly drawings will be required.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("For Short-Run production orders, PCBs smaller than .5 inches in any\n          dimension or less than .75 square inches, or PCBs that are not\n          rectangular, must be panelized as detailed in our Panelization\n          Guidelines. Fiducials are required.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you are providing a parts kit keep in mind that we need more\n          parts than your BOM calls. Parts are small and our machines move fast.\n          Because of that, as with any manufacturer, some small parts are lost\n          or damaged in the process. As you source parts remember Screaming\n          Circuits Attrition Policy (");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(".)");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("li");
        var el6 = dom.createTextNode("If you are supplying the bare boards to us and your boards will be\n          panelized, please be sure to request the panel data or Gerber from\n          your board house and send those to us once you receive them. We cannot\n          proceed without these files.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [3, 1]);
        var element2 = dom.childAt(element1, [55]);
        var element3 = dom.childAt(element2, [7, 2]);
        var element4 = dom.childAt(element1, [61]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1, 3, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [25]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [35]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [47]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [49, 1]), 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]), 2, 2);
        morphs[6] = dom.createElementMorph(element3);
        morphs[7] = dom.createMorphAt(dom.childAt(element2, [11]), 2, 2);
        morphs[8] = dom.createMorphAt(element4, 1, 1);
        morphs[9] = dom.createMorphAt(element4, 3, 3);
        morphs[10] = dom.createMorphAt(dom.childAt(element1, [65, 9]), 1, 1);
        morphs[11] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "link-to", ["Back to Resource Center", "resource-center"], [], ["loc", [null, [8, 30], [11, 12]]]], ["inline", "link-to", ["Standard Process", "resource-center.services.assembly"], [], ["loc", [null, [146, 8], [146, 74]]]], ["inline", "link-to", ["Standard Process", "resource-center.services.assembly"], [], ["loc", [null, [186, 8], [186, 74]]]], ["inline", "link-to", ["Standard Process", "resource-center.services.assembly"], [], ["loc", [null, [238, 8], [238, 74]]]], ["inline", "link-to", ["Project Files", "resource-center.process.file-requirements"], [], ["loc", [null, [242, 10], [245, 12]]]], ["block", "link-to", ["resource-center.process.parts-kit-requirements"], [], 0, null, ["loc", [null, [290, 10], [292, 56]]]], ["element", "action", ["transitionAndScrollTo", "resource-center.process.parts-kit-requirements", "planning-for-attrition"], [], ["loc", [null, [299, 12], [303, 14]]]], ["block", "link-to", ["resource-center.process.file-requirements"], [], 1, null, ["loc", [null, [311, 10], [313, 50]]]], ["block", "link-to", ["resource-center.process.file-requirements"], [], 2, null, ["loc", [null, [337, 8], [339, 44]]]], ["block", "link-to", ["resource-center.process.parts-kit-requirements"], [], 3, null, ["loc", [null, [341, 8], [343, 55]]]], ["block", "link-to", ["resource-center.process.parts-kit-requirements"], [], 4, null, ["loc", [null, [364, 37], [366, 56]]]], ["content", "marketing-footer", ["loc", [null, [376, 0], [376, 20]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});