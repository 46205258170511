define('web-app-frontend/initializers/custom-model', ['exports', 'ember-data'], function (exports, _emberData) {

  var alreadyRun = false;

  exports['default'] = {
    name: 'custom-model',
    initialize: function initialize() {
      if (alreadyRun) {
        return;
      } else {
        alreadyRun = true;
      }

      _emberData['default'].Model.reopen({
        //Ember data's 'changedAttributes' only returns the attributes dirtied since the last request.
        //Since we're making a request on every focusOut event, we must store a separate, complete list
        //of all the attributes touched until the record is saved, not just validated

        userAlteredAttributes: [],

        //Properties for caching purposes:
        requestID: _emberData['default'].attr('number', { defaultValue: 0 }),
        hashed: _emberData['default'].attr('string'),
        isFulfilled: _emberData['default'].attr('boolean', { defaultValue: false }),
        isCache: _emberData['default'].attr('boolean', { defaultValue: false }),

        adapterDidInvalidate: function adapterDidInvalidate(errors) {
          var recordErrors = this.get('errors');
          for (var key in errors) {
            if (!errors.hasOwnProperty(key)) {
              continue;
            }
            recordErrors.add(key, errors[key]);
          }
        },

        alterAttributes: function alterAttributes() {
          var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

          var model = this;
          var userAlteredAttributes = model.get('userAlteredAttributes').slice();
          if (all === true) {
            var allAttributes = Object.keys(model.toJSON());
            model.set('userAlteredAttributes', allAttributes);
          } else {
            var attributeChanges = Object.keys(model.changedAttributes());
            attributeChanges.forEach(function (attribute) {
              if (userAlteredAttributes.indexOf(attribute) === -1) {
                userAlteredAttributes.push(attribute);
                model.set('userAlteredAttributes', userAlteredAttributes);
              }
            });
          }
        },

        resetUserAlteredAttributes: function resetUserAlteredAttributes() {
          this.set('userAlteredAttributes', []);
        },

        cloneRecord: function cloneRecord(additionalProperties) {
          var cloneRelationships = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

          // primarily used for creating the objects needed for rollbacks due to the fact that the
          // model is saved after each entry the Ember Data .rollbackAttributes() will not suffice.
          // Also used for pricing differece comparison objects.
          var model = this;
          var keysToCopy = model.get('constructor.attributes._keys.list');
          var clone = model.store.createRecord(model.get('constructor.modelName').camelize());
          keysToCopy.forEach(function (key) {
            if (typeof model.get(key) !== 'undefined') {
              clone.set(key, model.get(key));
            }
          });
          clone.set('isClone', true);

          if (cloneRelationships) {
            model.cloneHasMany(clone);
          }
          if (additionalProperties) {
            clone.setProperties(additionalProperties);
          }
          return clone;
        },

        cloneHasMany: function cloneHasMany(clone) {
          var model = this;
          this.constructor.eachRelationship(function (key, type) {
            if (type.kind === 'hasMany') {
              var cloneAssociations = clone.get(key);
              var recordsToCopy = model.get(key);
              recordsToCopy.forEach(function (childRecord) {
                var clonedChild = childRecord.cloneRecord({}, true);
                cloneAssociations.pushObject(clonedChild);
              });
            }
          });
        },

        createCacheCopy: function createCacheCopy() {
          var copyRelationships = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];
          var copyId = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

          this.incrementRequestId(); //This is used to ensure any still-to-return AJAX promises will know they are stale.
          var cacheCopy = this.cloneRecord({ isCache: true,
            commitOnSuccess: false }, copyRelationships);
          if (copyId) {
            cacheCopy.set('id', cacheCopy.get('requestID'));
          }
          return cacheCopy;
        },

        removeRelationships: function removeRelationships() {
          var model = this;
          var relatedRecords;
          this.constructor.eachRelationship(function (key, relationship) {
            if (relationship.kind === 'hasMany') {
              relatedRecords = model.get(key);
              var list = relatedRecords.toArray();
              list.forEach(function (record) {
                relatedRecords.removeObject(record);
                record.unloadRecord();
              });
            } else {//belongsTo
              //TODO delete belongsTo
            }
          });
        },

        mapFromCopyOnRequestFulfillment: function mapFromCopyOnRequestFulfillment(cacheCopy) {
          var errorsOnly = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

          //To filter out-of-order returns from the server, we only map to the baseUser when the requestIDs still match
          cacheCopy.set('isFulfilled', true);
          if (this.get('requestID') === cacheCopy.get('requestID')) {
            cacheCopy.mapTo(this, errorsOnly);
          }
        },

        setupForCommit: function setupForCommit() {
          var model = this;
          model.set('commitOnSuccess', true);
          var attributes = Object.keys(model.toJSON());
          attributes.forEach(function (attribute) {
            // if either null or undefined
            if (model.get(attribute) === undefined) {
              model.set(attribute, '');
            }
          });
          model.alterAttributes(true);
          return true;
        },

        incrementRequestId: function incrementRequestId() {
          var count = this.get('requestID') + 1;
          this.set('requestID', count);
          return count;
        },

        findMatchingRecord: function findMatchingRecord() {
          var baseRecord = this;
          var modelName = this.get('constructor.modelName');
          var allRecords = this.get('store').peekAll(modelName).get('content');

          for (var i = 0; i < allRecords.length; i++) {
            if (allRecords[i].record.get('isCache') && allRecords[i].record.get('hashed') === baseRecord.get('hashed')) {
              return allRecords[i].record;
            }
          }
        },

        generateHash: function generateHash() {
          var keysToSerialize = this.get('keysToSerialize');
          var valuesArray = [];
          var localModel = this;
          keysToSerialize.forEach(function (key) {
            var localKey = localModel.get(key);
            //localKey = localKey === 0 ? undefined : localKey;
            valuesArray.push(localKey);
          });
          var newHash = valuesArray.join('_');
          localModel.set('hashed', newHash);
          return newHash;
        },

        mapMatchToBaseRecord: function mapMatchToBaseRecord(matchingRecord) {
          var errorsOnly = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

          var baseRecord = this;
          if (!matchingRecord.get('isFulfilled')) {
            //This records is still waiting response from the server, we'll defer mapping until the server has responeded.
            //We do this by updating the requestID to match that of the base record so it is no longer stale.
            //TODO: Test this interaction.
            matchingRecord.set('requestID', baseRecord.get('requestID'));
          } else {
            //Map results and errors from the cached record to the base record
            matchingRecord.mapTo(baseRecord, errorsOnly);
            // self.clearSpinners();
          }
        },

        mapErrorsFrom: function mapErrorsFrom(cacheCopy) {
          //Map Errors...
          var baseRecord = this;
          baseRecord.get('errors').clear(); //clear stale errors
          cacheCopy.get('errors.content').forEach(function (error) {
            baseRecord.get('errors').add(error.attribute, error.message);
          });
          return baseRecord;
        },

        //mapTo takes the property values, and errors, of "this" model and maps it to the target model
        mapTo: function mapTo(baseRecord, errorsOnly) {
          var cacheCopy = this;

          //TODO: Add type checking

          //Map Errors...
          baseRecord = baseRecord.mapErrorsFrom(cacheCopy);
          if (errorsOnly) {
            return;
          }

          //Map data
          var keysToCopy = cacheCopy.get('constructor.attributes._keys.list');
          keysToCopy.forEach(function (key) {
            if (!['isCache', 'requestID', 'hashed', 'isFulfilled'].contains(key)) {
              if (typeof cacheCopy.get(key) !== 'undefined') {
                baseRecord.set(key, cacheCopy.get(key));
              }
            }
          });

          baseRecord.removeRelationships();

          cacheCopy.cloneHasMany(baseRecord);
          // cacheCopy.cloneBelongsTo(baseRecord);
        }
      });
    }
  };
});