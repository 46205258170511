define('web-app-frontend/routes/resource-center/services/layout-engineering', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'Layout Engineering',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Thanks to thousands of assemblies and layouts, Screaming Circuits designers know how to engineer PCB layouts that succeed.'
      }
    }]
  });
});