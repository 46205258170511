define('web-app-frontend/models/quote', ['exports', 'ember-data', 'web-app-frontend/utils/quote-state-evaluator'], function (exports, _emberData, _webAppFrontendUtilsQuoteStateEvaluator) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attributes
    assemblyIncluded: _emberData['default'].attr('string'),
    assemblyNotes: _emberData['default'].attr('string'),
    assemblyTurnDaysDisplay: _emberData['default'].attr('string'),
    canBeReordered: _emberData['default'].attr("boolean"),
    commitOnSuccess: _emberData['default'].attr("boolean"),
    partCostPerBoard: _emberData['default'].attr("number"),
    totalCostPerBoard: _emberData['default'].attr("number"),
    clientState: _emberData['default'].attr('string'),
    creationDate: _emberData['default'].attr("date"),
    customAssemblyNotes: _emberData['default'].attr('string'),
    customAssemblyQuote: _emberData['default'].attr('boolean'),
    customFabNotes: _emberData['default'].attr('string'),
    customFabQuote: _emberData['default'].attr('boolean'),
    customPartsQuote: _emberData['default'].attr('boolean'),
    estimatedShipDate: _emberData['default'].attr("date"),
    fabIncluded: _emberData['default'].attr('string'),
    fabNotes: _emberData['default'].attr('string'),
    fabTurnDaysDisplay: _emberData['default'].attr('string'),
    hasFiles: _emberData['default'].attr("boolean"),
    isArchivable: _emberData['default'].attr("boolean"),
    isKitLate: _emberData['default'].attr("boolean"),
    isReorder: _emberData['default'].attr("boolean"),
    modificationDate: _emberData['default'].attr("string"),
    name: _emberData['default'].attr("string"),
    onHold: _emberData['default'].attr('boolean'),
    onHoldReason: _emberData['default'].attr('string'),
    orderNumber: _emberData['default'].attr("string"),
    orderTotal: _emberData['default'].attr("number"),
    parentOrderNumber: _emberData['default'].attr("string"),
    partsCount: _emberData['default'].attr('number'),
    partsIncluded: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr("string"),
    quantity: _emberData['default'].attr('number'),
    revision: _emberData['default'].attr('string'),
    sessionGUID: _emberData['default'].attr("string"),
    status: _emberData['default'].attr("string"),
    type: _emberData['default'].attr("string"),
    requiresKit: _emberData['default'].attr("boolean"),
    hasShippingAddress: _emberData['default'].attr("boolean"),
    formalQuoteRequestedDate: _emberData['default'].attr("string"),

    project: _emberData['default'].belongsTo('project', { async: true }),
    user: _emberData['default'].belongsTo('user', { nested: true }),

    projectName: (function () {
      if (this.get('name')) {
        return this.get('name');
      } else {
        return 'Untitled';
      }
    }).property('name', 'orderNumber'),

    customQuoteLocked: (function () {
      return this.get('status') === 'Custom Quote Pending' || this.get('status') === 'Custom Quote Received';
    }).property('status'),

    isOrderType: (function () {
      return this.get('type') === 'UserOrder';
    }).property('type'),

    isQuoteType: (function () {
      return this.get('type') === 'UserQuote';
    }).property('type'),

    newQuote: (function () {
      return this.get('status') === 'New Quote';
    }).property('status'),

    savedQuote: (function () {
      return this.get('status') === 'Saved Quote';
    }).property('status'),

    formalQuote: (function () {
      return this.get('status') === 'Tracked Quote';
    }).property('status'),

    quoteFormIsIncomplete: (function () {
      var clientState = this.get('clientState');
      return (0, _webAppFrontendUtilsQuoteStateEvaluator['default'])(clientState, true) === 'quote';
    }).property('clientState'),

    assemblyIncludedBoolean: (function () {
      return this.get('assemblyIncluded').toLowerCase() === 'true';
    }).property('assemblyIncluded'),

    fabIncludedBoolean: (function () {
      return this.get('fabIncluded').toLowerCase() === 'true';
    }).property('fabIncluded'),

    partsIncludedBoolean: (function () {
      return this.get('partsIncluded').toLowerCase() === 'true';
    }).property('partsIncluded')
  });
});