define('web-app-frontend/serializers/quote-fabrication-pricing', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    //overrides the root key for an outgoing request. local modelName is "quoteFabricationPricing." server expects "orderFabricationInputModel"
    payloadKeyFromModelName: function payloadKeyFromModelName() {
      return "orderFabricationInputModel";
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var self = this;
      var allDesignViolations = [];
      var currentQuoteFabPricing = store.peekRecord('quoteFabricationPricing', id);
      var turnTimes = payload.orderFabrication.data || [];

      this.destroyAllHasMany(currentQuoteFabPricing, 'fabTurnTimes'); //destroys relationship AND record

      // defining variable here to avoid circular currentQuoteFabPricing = currentQuoteFabPricing.toJSON() issue
      var fabPricing = currentQuoteFabPricing.toJSON();
      fabPricing.project = id;

      var cacheCopy = currentQuoteFabPricing.findMatchingRecord();
      fabPricing.id = cacheCopy.get('requestID');
      fabPricing.isCache = cacheCopy.get('isCache');
      fabPricing.isFulfilled = cacheCopy.get('isFulfilled');
      fabPricing.hashed = cacheCopy.get('hashed');
      fabPricing.userSelectedTurnTime = cacheCopy.get('userSelectedTurnTime');
      fabPricing.userAlteredAttributes = cacheCopy.get('userAlteredAttributes');

      fabPricing.fabTurnTimes = [];

      turnTimes.forEach(function (turnTime) {
        var designViolations = turnTime.designViolations;
        turnTime.id = self.generateIdForRecord();
        fabPricing.fabTurnTimes.push(turnTime.id);
        fabPricing.customFabQuote = turnTime.customFabQuote;

        if (!designViolations.length || fabPricing.customFabQuote) {
          turnTime.designViolations = [];
          turnTime.hasDesignViolations = false;
        } else {
          designViolations.forEach(function (designViolation) {
            designViolation.id = self.generateIdForRecord();
            if (designViolation.designViolationProperties === null) {
              designViolation.designViolationProperties = ['timeOut'];
            }
          });
          turnTime.designViolations = designViolations.map(function (designViolation) {
            return designViolation.id;
          });
          allDesignViolations = allDesignViolations.concat(designViolations);
        }
      });

      payload = { quoteFabricationPricing: fabPricing, fabricationTurnTimes: turnTimes, designViolations: allDesignViolations };
      return this._super(store, typeClass, payload, id);
    },

    destroyAllHasMany: function destroyAllHasMany(model, relationship) {
      if (model) {
        var children = model.get(relationship);
        var list = children.toArray();

        list.forEach(function (child) {
          children.removeObject(child);
          child.unloadRecord();
        });
      }
    },

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }
  });
});