define('web-app-frontend/controllers/modals/confirmation-modal-smart', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    isSaving: false,
    isError: false,
    isChecked: false,

    actions: {
      approve: function approve(deferred) {
        var self = this;
        self.set('isSaving', true);
        self.set('isError', false);
        self.set('isChecked', true);
        var closeDeferred = new _ember['default'].RSVP.defer('submit formal quote modal defer');
        closeDeferred.promise.then(function () {
          self.send('closeModal');
          self.set('isSaving', false);
        })['catch'](function () {
          console.log('I am in modal ..need to show the error');
          self.set('isSaving', false);
          this.set('isChecked', false);
          self.set('isError', true);
        });

        deferred.resolve(closeDeferred);
      },

      reject: function reject(deferred) {
        deferred.reject();
        this.set('isError', false);
        this.set('isChecked', false);
        this.send('closeModal');
      }
    }
  });
});