define('web-app-frontend/routes/resource-center', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'Resource Center',

    // this puts the resource-center meta tag on all of the child routes in the resource-center
    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Everything you need to design and build a successful product.'
      }
    }, {
      type: 'meta',
      tagId: 'resource-center-id',
      attrs: {
        property: 'resource-center',
        content: 'true'
      }
    }],

    // created to extend ResetScrollMixin
    actions: {
      // this is so window will scroll top for all child routes
      didTransition: function didTransition() {
        console.log('didTransition!');
        window.scrollTo(0, 0);
        return true;
      }
    }
  });
});