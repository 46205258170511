define('web-app-frontend/controllers/quoting/fabrication-pricing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    createNewQuoteFabricationPricingObject: function createNewQuoteFabricationPricingObject(id) {
      if (!this.store.hasRecordForId('quoteFabricationPricing', id)) {
        var record = this.store.createRecord('quoteFabricationPricing', { id: id, projectGUID: id });
        record.quoteAssemblyPricing = this.store.peekRecord('quoteAssemblyPricing', id);
        return record;
      } else {
        return this.store.peekRecord('quoteFabricationPricing', id);
      }
    }
  });
});