define('web-app-frontend/mixins/credit-card', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    isVisa: _ember['default'].computed.equal('creditCardType', 'VISA'),
    isMastercard: _ember['default'].computed.equal('creditCardType', 'MasterCard'),
    isAmex: _ember['default'].computed.equal('creditCardType', 'American Express'),
    isOther: _ember['default'].computed.equal('creditCardType', 'Other'),

    // Types of cards based upon first number in creditCardNumber.
    setCreditCardType: function setCreditCardType() {
      //user-credit-detail model uses 'creditCardNumber', project-billing model uses 'creditCarNumberPlain'
      var cardNumber = this.get('creditCardNumber') || this.get('creditCardNumberPlain');
      if (cardNumber) {
        var firstChar = cardNumber.charAt(0);
        var validNums = ['3', '4', '5'];
        if (firstChar === '4') {
          this.set('creditCardType', 'VISA');
        } else if (firstChar === '5') {
          this.set('creditCardType', 'MasterCard');
        } else if (firstChar === '3') {
          this.set('creditCardType', 'American Express');
        } else if (_ember['default'].$.inArray(firstChar, validNums) === -1) {
          this.set('creditCardType', 'Other');
        }
      }
    },

    yearOptions: (function () {
      console.log('in year options');
      var years = [];
      var thisYear = moment().year();
      var futureYear = thisYear + 25;

      // iterate through each year from this -> 25 years in the future
      for (var i = thisYear; i <= futureYear; i++) {
        years.push(i);
      }
      return years;
    }).property(),

    monthOptions: (function () {
      console.log('in month options');
      var months = [];
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(function (i) {
        months.pushObject({
          displayName: moment.months(i) + ' (' + (i + 1) + ')',
          value: i + 1
        });
      });

      return months;
    }).property()
  });
});