define('web-app-frontend/instance-initializers/configure-trackjs', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize(application) {
    var trackJs = application.lookup('service:trackjs');

    trackJs.configure({
      onError: function onError(payload, err) {
        // exclude errors from log in page
        console.log('trakjs in initializer');
        console.log(err);
        console.log(err.name);
        if (!_ember['default'].isEmpty(err)) {
          if (err.name === 'TransitionAborted') {
            return false;
          }
        }
        return true;
      }
    });
  }

  exports['default'] = {
    name: 'trackjs-error-and-serializer-configuration',
    initialize: initialize
  };
});