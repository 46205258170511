define('web-app-frontend/components/order-checkout', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    checkoutComplete: 'checkoutComplete',
    formalQuoteComplete: 'formalQuoteComplete',
    close: 'close',
    openModal: 'openModal',
    setClientStateOnProject: 'setClientStateOnProject',
    setSectionErrorState: 'setSectionErrorState',
    toggleComponent: 'toggleComponent',
    transitionToQuote: 'transitionToQuote',
    showSpinner: false,
    showFormalQuoteSpinner: false,

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    showCheckoutChanged: (function () {
      var self = this;
      if (this.get('showCheckout')) {
        self.set('showSpinner', true);
        var project = this.project;
        var checkoutMessage = this.get('targetObject.store').findRecord('projectCheckoutMessage', project.id, { reload: true }).then(function (message) {
          self.set('checkoutMessage', message);
          self.set('showSpinner', false);
        });
        this.set('checkoutMessage', checkoutMessage);
      }
    }).observes('showCheckout'),

    enableCheckoutButton: (function () {
      var quoteSubmitButton = this.get('quoteSubmitButton');
      if (this.get('anyFormErrors') || this.get('checkoutQuotingError')) {
        quoteSubmitButton.send('cannotSubmit');
      } else {
        quoteSubmitButton.send('canSubmit');
      }
    }).observes('anyFormErrors', 'checkoutQuotingError'),

    quotingErrorMessage: (function () {
      var message = "Your quote has been modified it must be confirmed. <u>Click here to review all changes</u> before placing this order.";
      return message;
    }).property('checkoutAssemblyError', 'checkoutFabError', 'checkoutPartsError'),

    showErrors: (function () {
      if (this.get('anyFormErrors') || this.get('internalServerError')) {
        return true;
      } else {
        return false;
      }
    }).property('anyFormErrors', 'internalServerError'),

    actions: {
      openModal: function openModal(modalTemplate, model) {
        this.sendAction('openModal', modalTemplate, model);
      },

      checkout: function checkout(commitOnSuccess, deferred) {
        var quoteSubmitButton = this.get('quoteSubmitButton');
        quoteSubmitButton.send('loading');
        var self = this;
        var project = this.project;
        var hubSpotConversationId = $.cookie('hubSpotConversationId') !== undefined && commitOnSuccess ? $.cookie('hubSpotConversationId') : '';
        var checkoutRecord = this.get('targetObject.store').createRecord('project-checkout', { projectGUID: project.id,
          commitOnSuccess: commitOnSuccess,
          hubSpotConversationId: hubSpotConversationId });
        this.set('checkoutRecord', checkoutRecord);
        checkoutRecord.save().then(function (response) {
          console.log('checkout successful', response);
          //on checkout save, clientState on the project is set to 'complete' on the server side
          //(since we can no longer PUT updates to the project)
          if (deferred) {
            deferred.resolve();
          }
          if (commitOnSuccess) {
            project.reload().then(function (project) {
              project.set('hubSpotConversationId', '');
              self.sendAction('checkoutComplete', project);
              quoteSubmitButton.send('canSubmit');
            })['catch'](function (errors) {
              console.log('checkoutComplete failed', errors);
              quoteSubmitButton.send('canSubmit');
            });
          }
        })['catch'](function (errors) {
          if (deferred) {
            deferred.reject(errors);
          }
          console.log('checkout failed', errors);
          console.log(checkoutRecord.get('errors.messages').join('<br/>'));
          var errorHTML = "<ul style='margin-top:10px'>";
          checkoutRecord.get('errors.messages').forEach(function (message) {
            errorHTML += "<li>" + message + "</li>";
          });
          errorHTML += "</ul>";

          self.set('internalServerError', errorHTML);
          //self.set('internalServerError', 'An error has occurred. Please give us a call at 1-866-784-5887 for assistance.');
          quoteSubmitButton.send('canSubmit');
          if (commitOnSuccess) {
            console.log('in track js notification code');
            if (window.trackJs) {
              window.trackJs.console.error('checkout failed');
              if (errors.errors) {
                window.trackJs.track(errors.errors);
              } else {
                window.trackJs.track(errors);
              }
            }
          }
        });
      },

      performAction: function performAction(action, actionParam, deferred) {
        this.send(action, actionParam, deferred);
      },

      saveAndPerformAction: function saveAndPerformAction(action) {
        if (this.get('project.customPartsQuote')) {
          this.send(action);
        } else {
          var self = this;
          var quoteSubmitButton = this.get('quoteSubmitButton');
          var preCheckoutDeferred = new _ember['default'].RSVP.defer('pre checkout defer');
          self.send('performAction', action, false, preCheckoutDeferred);

          //Show pop-up only after pre checkout call
          preCheckoutDeferred.promise.then(function () {
            var deferred = new _ember['default'].RSVP.defer('submit checkout defer');
            if (self.get('project.partsIncludedBoolean') && !self.get('project.customPartsQuote') && !self.get('project.userSkippedOnlineQuoting')) {
              self.sendAction('openModal', 'modals/order-confirmation-modal', { text: 'Please confirm you have reviewed your order details',
                checkBoxText: 'I have downloaded and reviewed the BOM.',
                id: self.get('project.id'),
                partsIncludedBoolean: self.get('project.partsIncludedBoolean'),
                customPartsQuote: self.get('project.customPartsQuote'),
                userSkippedOnlineQuoting: self.get('project.userSkippedOnlineQuoting'),
                customSubmitText: 'Yes, continue to place order',
                deferredObject: deferred });
            } else {
              self.sendAction('openModal', 'modals/confirmation-modal', { text: 'Please confirm you have reviewed your order details',
                customSubmitText: 'Yes, continue to place order',
                deferredObject: deferred });
            }

            deferred.promise.then(function () {
              console.log('calling checkout with true');
              self.send('performAction', action, true);
            })['catch'](function () {
              console.log('checkout cancelled by user');
            });
          })['catch'](function (error) {
            console.log('Pre-checkout failed', error);
          })['finally'](function () {
            quoteSubmitButton.send('canSubmit');
          });
        }
      },

      preFlightFormalQuoteRequest: function preFlightFormalQuoteRequest() {
        var self = this;
        var quoteSubmitButton = this.get('quoteSubmitButton');
        self.set('showFormalQuoteSpinner', true);
        var preSubmitDeferred = new _ember['default'].RSVP.defer('pre submit defer');
        self.send('requestFormalQuote', false, preSubmitDeferred);
        preSubmitDeferred.promise.then(function () {
          self.send('submitFormalQuoteRequest');
          self.set('showFormalQuoteSpinner', false);
        })['catch'](function () {
          self.set('showFormalQuoteSpinner', false);
        })['finally'](function () {
          quoteSubmitButton.send('canSubmit');
        });
      },

      submitFormalQuoteRequest: function submitFormalQuoteRequest() {
        var self = this;
        var deferred = new _ember['default'].RSVP.defer('submit formal quote request defer');
        if (self.get('project.customPartsQuote')) {
          if (self.get('project.partsIncludedBoolean') && !self.get('project.userSkippedOnlineQuoting')) {

            this.sendAction('openModal', 'modals/order-confirmation-modal', { text: 'Please confirm you have reviewed your formal quote details',
              checkBoxText: 'I have downloaded and reviewed the BOM.',
              id: self.get('project.id'),
              partsIncludedBoolean: self.get('project.partsIncludedBoolean'),
              userSkippedOnlineQuoting: self.get('project.userSkippedOnlineQuoting'),
              customSubmitText: 'Yes, request a formal quote',
              deferredObject: deferred });
          } else {
            this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Please confirm you have reviewed your formal quote details',
              customSubmitText: 'Yes, request a formal quote',
              deferredObject: deferred });
          }
        } else {
          var subText = '';
          if (self.get('project.assemblyDetails.assemblySubtotal') > 25000) {
            subText = "<p class='text-left'>Higher volume quotes require additional review for project scope and possible discounts. We’re more than happy to put together a formal quote before you commit to an order. Here is what to expect:<br><br></p><ul class='text-left'><li>The formal quote process will continue over email and phone - expect an initial contact from us within one business day.<br><br></li><li>It may take a number of days to complete the formal quote process dependent on complexity, parts inventory, and your availability to respond to questions.<br><br></li><li>The timeline on this checkout page does not apply to formal quote requests. An updated start date and ship date will be provided during the formal quoting process.<br><br></li><li>Status on all open formal quote requests are listed at the top of your Quotes page within the Project Box section of this website.<br><br></li><li>There is no commitment to purchase.<br><br></li><li>You may cancel or modify the quote request by contacting customer service.</li></ul>";
          } else {
            subText = "<p class='text-left'>We’re more than happy to put together a formal quote before you commit to an order. Here is what to expect:<br><br></p><ul class='text-left'><li>The formal quote process will continue over email and phone - expect an initial contact from us within one business day.<br><br></li><li>It may take a number of days to complete the formal quote process dependent on complexity, parts inventory, and your availability to respond to questions.<br><br></li><li>The timeline on this checkout page does not apply to formal quote requests. An updated start date and ship date will be provided during the formal quoting process.<br><br></li><li>Status on all open formal quote requests are listed at the top of your Quotes page within the Project Box section of this website.<br><br></li><li>There is no commitment to purchase.<br><br></li><li>You may cancel or modify the quote request by contacting customer service.</li></ul>";
          }

          if (self.get('project.partsIncludedBoolean') && !self.get('project.userSkippedOnlineQuoting')) {

            this.sendAction('openModal', 'modals/confirmation-modal-smart', { text: '<h4 class="mt20 text-left">Requesting a formal quote:</h4>',
              subtext: subText,
              checkBoxText: 'I have downloaded and reviewed the BOM.',
              id: self.get('project.id'),
              partsIncludedBoolean: self.get('project.partsIncludedBoolean'),
              customPartsQuote: self.get('project.customPartsQuote'),
              userSkippedOnlineQuoting: self.get('project.userSkippedOnlineQuoting'),
              deferredObject: deferred });
          } else {
            this.sendAction('openModal', 'modals/confirmation-modal', { text: '<h4 class="mt20 text-left">Requesting a formal quote:</h4>',
              subtext: "<p class='text-left'>We’re more than happy to put together a formal quote before you commit to an order. Here is what to expect:<br><br></p><ul class='text-left'><li>The formal quote process will continue over email and phone - expect an initial contact from us within one business day.<br><br></li><li>It may take a number of days to complete the formal quote process dependent on complexity, parts inventory, and your availability to respond to questions.<br><br></li><li>The timeline on this checkout page does not apply to formal quote requests. An updated start date and ship date will be provided during the formal quoting process.<br><br></li><li>Status on all open formal quote requests are listed at the top of your Quotes page within the Project Box section of this website.<br><br></li><li>There is no commitment to purchase.<br><br></li><li>You may cancel or modify the quote request by contacting customer service.</li></ul>",
              deferredObject: deferred });
          }
        }
        deferred.promise.then(function (modalDeferred) {
          self.send('requestFormalQuote', true, modalDeferred);
        })['catch'](function (error) {
          //Close the modal and show error
          //modalDeferred.reject();
          console.log('formal quote cancelled by user' + error);
        });
      },

      requestFormalQuote: function requestFormalQuote(commitOnSuccess, modalDeferred) {
        var self = this;
        var project = this.project;
        var quoteSubmitButton = this.get('quoteSubmitButton');

        if (this.get('project.customPartsQuote')) {
          quoteSubmitButton.send('loading');
        }

        var hubSpotConversationId = $.cookie('hubSpotConversationId') !== undefined && commitOnSuccess ? $.cookie('hubSpotConversationId') : '';

        var checkoutRecord = this.get('targetObject.store').createRecord('project-checkout', { projectGUID: project.id,
          formalQuote: true,
          commitOnSuccess: commitOnSuccess,
          hubSpotConversationId: hubSpotConversationId });

        checkoutRecord.save().then(function (response) {
          console.log('formal quote request successful', response);
          if (commitOnSuccess) {
            project.reload().then(function (project) {
              project.set('hubSpotConversationId', '');
              //Close the modal here as we are redirecting user to quote details page
              if (modalDeferred) {
                modalDeferred.resolve();
              }

              self.sendAction('formalQuoteComplete', project);

              if (this.get('project.customPartsQuote')) {
                quoteSubmitButton.send('canSubmit');
              }
            })['catch'](function () {
              //Close the modal and show error
              if (modalDeferred) {
                modalDeferred.reject();
              }

              quoteSubmitButton.send('canSubmit');
            });
          } else {
            if (modalDeferred) {
              modalDeferred.resolve();
            }
          }
        })['catch'](function (errors) {
          //Close the modal and show error
          if (modalDeferred) {
            modalDeferred.reject();
          }
          console.log('Formal quote failed', errors);
          console.log(checkoutRecord.get('errors.messages').join('<br/>'));
          var errorHTML = "<ul style='margin-top:10px'>";
          checkoutRecord.get('errors.messages').forEach(function (message) {
            errorHTML += "<li>" + message + "</li>";
          });
          errorHTML += "</ul>";
          self.set('internalServerError', errorHTML);
          //self.set('internalServerError', 'An error has occurred. Please give us a call at 1-866-784-5887 for assistance.');
          if (commitOnSuccess) {
            if (window.trackJs) {
              window.trackJs.console.error('Formal quote failed');
              if (errors.errors) {
                window.trackJs.track(errors.errors);
              } else {
                window.trackJs.track(errors);
              }
            }
          }
        });
      },

      transitionToQuote: function transitionToQuote() {
        this.sendAction('transitionToQuote');
      }

    }
  });
});