define('web-app-frontend/components/user-notifications', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
      var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/system/notificationmessage?Api-Key=' + _ember['default'].ENV.client_id;

      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json'
      }).done(function (data) {
        if (!_ember['default'].isEmpty(data.notificationModel.notificationTextFormatted)) {
          self.set('notificationMessage', data.notificationModel.notificationTextFormatted);
        }
      }).fail(function () {
        console.error('Error fetching Company Updates');
      });
    }
  });
});