define('web-app-frontend/adapters/project-billing', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    buildURL: function buildURL(type, id) {
      var url = "project/" + id + "/payment";
      return this._super(url);
    },

    findRecord: function findRecord(store, typeClass, id) {
      return this.ajax(this.buildURL(typeClass.modelName, id), "GET");
    },

    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      var payload = { projectPaymentModel: record };
      return this.ajax(this.buildURL(typeClass.modelName, id), 'PUT', { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.projectPaymentModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.projectPaymentModel.errors);
          console.log(errors);
          errors.forEach(function (item) {
            console.log(item.source.pointer);
            if (item.source.pointer.indexOf('creditCardNumberEncrypted') !== -1) {
              item.source.pointer = item.source.pointer.replace('creditCardNumberEncrypted', 'creditCardNumberPlain');
            }
          });
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});