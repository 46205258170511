define('web-app-frontend/components/pricing-carousel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    openModal: 'openModal',
    openTaxShippingEstimateModal: 'openTaxShippingEstimateModal',
    transitionToOrder: 'transitionToOrder',
    transitionToRoute: 'transitionToRoute',
    priceOptionsFromCarousel: 'priceOptionsFromCarousel',
    showLoading: null,
    toggleSkip: 'toggleSkip',
    fullProtoTurnTimesCount: 0,
    shortRunTurnTimesCount: 0,
    totalTurnTimeCount: 0,

    //to toggle notice that pricing selections are not available due to service downtime.
    monitorFabricationServiceAvailiability: (function () {
      var project = this.get('model');
      var fabQuotingOutage = project.get('fabIncluded') === 'True' && this.get('projectPricingOptions').filterBy('fabSystemLiveStatus', false).length > 0;
      project.set('fabQuotingAvailable', !fabQuotingOutage);
    }).observes('projectPricingOptions.@each.fabSystemLiveStatus'),

    carouselReadyChanged: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.send('buildCarousel');
      });
    }).observes('carouselReady'),

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    scrollToSlide: function scrollToSlide(slide) {
      var arrayLength = this.get('projectPricingOptions.length');
      var slidesShown = $('.pricing-carousel').slick('slickGetOption', 'slidesToShow');
      if (arrayLength > 5 && $(window).outerWidth() > 768) {
        if (slide <= 2) {
          // go to first slide if slide is first two slides
          $('.pricing-carousel').slick('slickGoTo', 0);
        } else if (arrayLength - slide <= 2) {
          // if slide is near the end
          // offset by however many slides are shown
          $('.pricing-carousel').slick('slickGoTo', arrayLength - slidesShown);
        } else {
          // to aim for the middle slide in a group of 5
          $('.pricing-carousel').slick('slickGoTo', slide - 2);
        }
      } else {
        // if only one slide is showing (mobile) we can slide right to it
        $('.pricing-carousel').slick('slickGoTo', slide);
      }
    },

    setShowLoading: (function () {
      this.set('showLoading', true);
      this.gatherPricingOptions();
    }).on('init'),

    unloadDefaultRecordIfPresent: function unloadDefaultRecordIfPresent(pricingOptions, id) {
      // to unload record auto generated by EmberData due to finding by a projectGUID, but
      // there not being a turntime associated.
      var record = pricingOptions.findBy('id', id);
      if (record) {
        record.unloadRecord();
      }
    },

    gatherPricingOptions: (function () {
      var component = this;
      var quotePricingView = component.get('parentView');
      var store = quotePricingView.get('targetObject.store');
      var projectGUID = component.get('model').get('id');
      var pricingOptions;
      var deferred = new _ember['default'].RSVP.defer('gatherPricingOptions');
      var project = component.get('model');
      project.set('fabQuotingAvailable', true);

      component.set('showLoading', true);

      if (store.peekAll('project-turn-time').get('length') > 0) {
        // store.unloadAll('project-turn-time');
        _ember['default'].run(function () {
          var pOptions = store.peekAll('project-turn-time');

          pOptions.forEach(function (element) {
            element.unloadRecord();
          });
        });
      }

      if (!component.get('model.hasCustomQuotePendingApproval')) {
        store.query('projectTurnTime', { id: projectGUID }).then(function (records) {
          var id = records.get('projectGUID');
          pricingOptions = store.peekAll('projectTurnTime');
          component.unloadDefaultRecordIfPresent(pricingOptions, id);
          deferred.resolve(pricingOptions);
        })['catch'](function (error) {
          console.log('fetch of turn times failed: ', error);
        });

        deferred.promise.then(function (pricingOptions) {
          var sortedPrices = pricingOptions; //.sortBy('totalTurnDays');
          var selected = sortedPrices.findBy('isSelected', true);

          if (selected && component.get('_state') === 'inDOM') {
            component.set('selectedIndex', sortedPrices.indexOf(selected));
          }

          if (component.get('_state') === 'inDOM') {
            component.set('projectPricingOptions', sortedPrices);
            // sends pricing options to quote-pricing for use for print
            component.sendAction('priceOptionsFromCarousel', sortedPrices);
            var shortRunPriceCount = sortedPrices.filterBy('assemblyServiceName', 'Short-Run').get('length');
            var fullProtoPriceCount = sortedPrices.filterBy('assemblyServiceName', 'Full Proto').get('length');
            component.set('totalTurnTimeCount', sortedPrices.get('length'));
            component.set('fullProtoTurnTimesCount', fullProtoPriceCount);
            component.set('shortRunTurnTimesCount', shortRunPriceCount);
            component.set('showLoading', false);
            // to update carousel if change takes place when pricing is in the viewport.
            component.send('prepCarousel');
            component.rerender();
          }
        });
      }
    }).observes('refreshTurnTimes'),

    actions: {
      openModal: function openModal(modal, model) {
        this.sendAction('openModal', modal, model);
      },

      openTaxShippingEstimateModal: function openTaxShippingEstimateModal(model) {
        this.sendAction('openTaxShippingEstimateModal', model);
      },

      buildCarousel: function buildCarousel() {
        if (this.$('.pricing-carousel')) {

          if (this.$('.pricing-carousel').hasClass('slick-initialized')) {
            this.$('.pricing-carousel').removeClass('slick-initialized');
            console.log('Removed slickJS classes from Carousel');
          }

          if (!this.$('.pricing-carousel').hasClass('slick-initialized')) {
            this.$('.pricing-carousel').slick({
              infinite: false,
              slidesToShow: 5,
              slidesToScroll: 1,
              focusOnSelect: false,
              prevArrow: '<div class="slick-prev"><div class="slick-arrow-bg"><i class="arrow-left-grey"></i></div></div>',
              nextArrow: '<div class="slick-next"><div class="slick-arrow-bg"><i class="arrow-right-grey"></i></div></div>',
              responsive: [{
                breakpoint: 1200,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1
                }
              }, {
                breakpoint: 992,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  centerMode: false
                }
              }, {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
                  arrows: true
                }
              }, {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  centerMode: false,
                  centerPadding: '20px',
                  arrows: true
                }
              }]
            });
          }

          this.scrollToSlide(this.selectedIndex);
        }
      },

      prepCarousel: function prepCarousel() {
        this.toggleProperty('carouselReady');
      },

      removeActiveClass: function removeActiveClass() {
        this.$('.pricing-carousel').find('.chosen-price').removeClass('chosen-price');
      },

      transitionToOrder: function transitionToOrder(model) {
        this.sendAction('transitionToOrder', model);
      },

      transitionToRoute: function transitionToRoute(route) {
        this.sendAction('transitionToRoute', route);
      },

      toggleSkip: function toggleSkip(section, removal) {
        this.sendAction('toggleSkip', section, removal);
      }
    }
  });
});