define('web-app-frontend/components/color-select', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: ['ul'],
    classNames: ['color-select'],
    selectedColor: null,
    colorAttribute: null,

    didInsertElement: function didInsertElement() {
      this.set('colorAttribute', this.id.camelize());
      var color = this.model.get(this.colorAttribute) || 'None';
      var colorClass = color.toLowerCase();
      this.set('selectedColor', color);
      $(this.get('element')).find('li.' + colorClass).addClass('active');
      // this.updateSelectedColorFromSilkscreenSidesField();
    },

    click: function click(e) {
      var selection = _ember['default'].$(e.target);

      if (selection.is('li')) {
        _ember['default'].$(this.get('element')).find('li.active').removeClass('active');
        selection.addClass('active');
        var color = selection.attr('data-color');
        this.set('selectedColor', color);
        this.sendAction();
      }
    },

    setInputColor: (function () {
      var selectedElement = _ember['default'].$(this.get('element')).find('li.active');
      if (selectedElement.length) {
        var color = selectedElement.attr('data-color');
        this.model.set(this.colorAttribute, color);
      } else {
        this.model.set(this.colorAttribute, this.selectedColor);
      }
    }).observes('selectedColor')

  });
});
// updateSelectedColorFromSilkscreenSidesField: function() {
//   if (this.model && this.colorAttribute === 'silkscreenColor') {
//     if (this.model.get('silkscreenSides') === 'None') {
//       console.log("Disable silkscreen color selection");
//       Ember.$(this.get('element')).addClass('disable');
//       Ember.$(this.get('element')).prev().html("Disabled: Silkscreen is set to 'None' in section above");
//       Ember.$(this.get('element')).find('li.active').removeClass('active');
//     }
//     if ((this.model.get('silkscreenSides') !== 'None') && (this.model.get('silkscreenColor') === 'None')) {
//       $(this.get('element')).find('li.' + 'white').addClass('active');
//       Ember.$(this.get('element')).removeClass('disable');
//       Ember.$(this.get('element')).prev().html("Color of markings on the baord");
//     }
//   }
// }.observes("this.model.silkscreenSides")