define('web-app-frontend/adapters/quote-fabrication-pricing', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/fab/pricing';
      return this._super(url);
    },

    //payload's default root key is overwritten with payloadKeyFromModelName in the QAP serializer
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var id = snapshot.record.get('projectGUID');
      var serializer = store.serializerFor(type.modelName);
      snapshot.id = id;
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(this.buildURL(type.modelName, id), "POST", { data: data });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, response;
      errors = [];
      if (status === 400) {
        response = payload;

        if (response.orderFabricationInputModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.orderFabricationInputModel.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else if (status === 503) {
        // We are having to build this design violation manually, knowing that the status code of 503
        // will always represent this unavailable service.
        // The visual interaction will be handled by the monitorForServiceAvailability observer in the quote-fabrication-pricing
        // component.
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError('timeOut', 'We are sorry, fabrication quoting is temporarily unavailable.', errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});