define('web-app-frontend/adapters/project', ['exports', 'web-app-frontend/adapters/quoting-parent', 'ember'], function (exports, _webAppFrontendAdaptersQuotingParent, _ember) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      //possible params store, snapshot
      return false;
    },

    pathForType: function pathForType() {
      return 'project';
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    },

    queryRecord: function queryRecord(store, type, query) {
      var id = query.id;
      var url = this.buildURL(type.modelName, id);
      if (_ember['default'].isEmpty(id)) {
        url = url + 'search';
        return this.ajax(url, "GET", {}, query);
      } else {
        return this.ajax(url, "GET");
      }
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = { sessionModel: { sessionGUID: record.get('sessionGUID'), commitOnSuccess: record.get('commitOnSuccess'), hubSpotConversationId: record.get('hubSpotConversationId') } };

      if (record.get('guidForDuplication')) {
        var id = record.get('guidForDuplication');
        return this.ajax(this.buildURL(type.modelName, id) + '/duplicate', "PUT", { data: payload });
      } else {
        //normal project creation
        return this.ajax(this.buildURL(type.modelName), "POST", { data: payload });
      }
    },

    deleteRecord: function deleteRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      var promise = this.ajax(this.buildURL(type.modelName, id), 'DELETE').then(function (response) {
        console.log('Delete Record Response', response);
      });

      return promise;
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      var payload = { projectUpdateModel: record };

      return this.ajax(this.buildURL(type.modelName, id), 'PUT', { data: payload });
    }
  });
});