define('web-app-frontend/controllers/user/account/contacts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    contactController: _ember['default'].inject.controller('user/account/contact-modal'),
    session: _ember['default'].inject.service(),

    actions: {
      openNewContactModal: function openNewContactModal() {
        var newContact = this.get("store").createRecord('user-email-address', { userGUID: this.get('session.data.authenticated.UserGUID') });
        this.send('openModal', "user/account/contact-modal", newContact);
        this.get('contactController').set('newContact', true);
      },

      openEditContactModal: function openEditContactModal(contact) {
        var rollbackState = contact.cloneRecord({ serverId: null });
        contact.set('commitOnSuccess', false);
        this.send('openModal', "user.account.contact-modal", contact);
        this.get('contactController').set('rollbackState', rollbackState);
        this.get('contactController').set('newContact', false);
      },

      destroyContact: function destroyContact(contact) {
        var self = this;
        var deferred = new _ember['default'].RSVP.defer('remove user contact confirmation');
        this.send('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to remove this contact?',
          customSubmitText: 'Yes, continue to remove',
          deferredObject: deferred });

        deferred.promise.then(function () {
          self.get('store').findRecord('userEmailAddress', contact.id).then(function (contact) {
            //Validations may have changed commitOnSuccess to false,
            contact.transitionTo('loaded.saved');
            //we don't care, we just want to delete the record, even if changes have been made locally.
            contact.destroyRecord();
          });
        });
      }
    }
  });
});