define('web-app-frontend/models/tax-shipping-estimate', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyTurnTimeCode: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    fabServiceType: _emberData['default'].attr('string'),
    fabTurnTimeCode: _emberData['default'].attr('string'),
    projectAssemblyCompleteDate: _emberData['default'].attr('string'),
    projectEndDate: _emberData['default'].attr('string'),
    projectFabCompleteDate: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'),
    projectShipping: _emberData['default'].attr('number', { defaultValue: 0 }),
    projectStartDate: _emberData['default'].attr('string'),
    projectTotal: _emberData['default'].attr('number', { defaultValue: 0 }),
    shippingDays: _emberData['default'].attr('number', { defaultValue: 5 }),
    isFormalQuote: _emberData['default'].attr('boolean'),

    taxShippingEstimate: (function () {
      return this.get('projectTotal') + this.get('projectShipping');
    }).property('projectTotal', 'projectShipping'),

    deliveryLanguage: (function () {
      return this.get('shippingDays') === 5 ? 'Delivery Around' : 'Delivery By';
    }).property('shippingDays')
  });
});