define("web-app-frontend/components/range-slider", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    start: undefined,
    step: 1,
    margin: undefined,
    limit: undefined,
    connect: false,
    orientation: "horizontal",
    direction: "ltr",
    behaviour: "tap-drag",
    animate: true,
    attribute: null,

    min: 0,
    max: 100,

    range: (function () {
      return {
        min: this.get('min'),
        max: this.get('max')
      };
    }).property('min', 'max'),

    valuesArray: (function () {
      return this.get('values').split(', ');
    }).property('valuesArray'),

    textValuesArray: (function () {
      return this.get('textValues').split(', ');
    }).property('textValuesArray'),

    didInsertElement: function didInsertElement() {
      this.set('attribute', this.id.camelize());

      this.slider = this.$().noUiSlider({
        start: this.get('start'),
        step: this.get('step'),
        margin: this.get('margin'),
        limit: this.get('limit'),
        range: this.get('range'),
        connect: this.get('connect'),
        orientation: this.get('orientation'),
        direction: this.get('direction'),
        behaviour: this.get('behaviour'),
        animate: this.get('animate')
      });

      //set default location of slider on load
      this.placeFromModel();

      this.$().noUiSlider_pips({
        mode: 'count',
        values: this.get('valuesArray').length,
        stepped: true,
        // setting a high density means little checkmarks do not show
        density: 100
      });

      var _this = this;

      this.$().on("change", function () {
        _this.setCurrentValue(_this.slider);
        _this.sendAction();
      });

      this.$('.noUi-value.noUi-value-horizontal.noUi-value-large').each(function (index) {
        var val = $(this).html();
        val = rewriteVal(parseFloat(val), index, _this.get('price'));
        $(this).html(val);
      });

      function rewriteVal(val, index, price) {
        if (price && _this.get('pricePos') === index) {
          return _this.get('textValuesArray')[index] + '<br/>($' + price + ')';
        } else {
          return _this.get('textValuesArray')[index];
        }
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.placeFromModel();
    },

    willDestroyElement: function willDestroyElement() {
      this.slider[0].destroy();
    },

    setIndexVal: (function () {
      if (this.slider) {
        var val = this.get('start');
        this.slider.val(val);
      }
    }).observes('start'),

    placeFromModel: function placeFromModel() {
      var self = this;
      var indexPlace;
      // find the index place in the array for the current value
      this.get('valuesArray').forEach(function (value, index) {
        if (value === self.get('model.' + self.get('attribute'))) {
          indexPlace = index;
        }
      });
      // set slider to the place of the current value
      this.set('start', indexPlace);
    },

    setCurrentValue: function setCurrentValue(slider) {
      var sliderIndex = parseInt(slider.val());
      this.model.set(this.attribute, this.get('valuesArray')[sliderIndex]);
    }

  });
});