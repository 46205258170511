define('web-app-frontend/components/link-to-input', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    inputActive: false,
    buttonActive: false,
    buttonAction: null,
    buttonText: null,
    linkToInputButtonAction: 'linkToInputButtonAction',
    linkToCancelAction: 'linkToCancelAction',
    showSpinner: false,

    actions: {
      cancelInput: function cancelInput(cancelAction) {
        this.send('toggleInput');
        this.set('showSpinner', false);
        this.sendAction('linkToCancelAction', cancelAction);
      },

      sendAction: function sendAction(action) {
        var self = this;
        self.set('showSpinner', true);

        var spinnerDeferred = new _ember['default'].RSVP.defer('linkToInputButtonAction spinner state');

        self.sendAction('linkToInputButtonAction', action, spinnerDeferred);

        spinnerDeferred.promise.then(function () {
          self.set('showSpinner', false);
        })['catch'](function () {
          self.set('showSpinner', false);
        });
      },

      toggleInput: function toggleInput() {
        this.toggleProperty('inputActive');
      }
    }
  });
});