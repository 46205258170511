define('web-app-frontend/components/inspectlet-optin', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({

		show: false,
		showAdditionalDetails: false,
		enableInspectlet: 'enableInspectlet',
		actions: {
			showAdditionalDetails: function showAdditionalDetails() {
				this.set('showAdditionalDetails', !this.get('showAdditionalDetails'));
			},
			dismiss: function dismiss() {
				this.set('show', false);
			},
			enableInspectlet: function enableInspectlet() {
				this.send('dismiss');
				this.sendAction('enableInspectlet');
			}
		}

	});
});