define('web-app-frontend/models/quote-assembly', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyNotes: _emberData['default'].attr('string'),
    assemblyService: _emberData['default'].attr('string'), //comes from assembly service details, on project
    assemblySubtotal: _emberData['default'].attr('number'),
    bgaCount: _emberData['default'].attr('number'),
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    customAssemblyNotes: _emberData['default'].attr('string'),
    customAssemblyQuote: _emberData['default'].attr('boolean', { defaultValue: false }),
    finePitchCount: _emberData['default'].attr('number'),
    hasBGA: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasClass3: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDoubleSidedSMT: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasDoubleSidedThruHole: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasFabrication: _emberData['default'].attr('boolean'),
    hasLeadFree: _emberData['default'].attr('boolean', { defaultValue: true }),
    isaReorder: _emberData['default'].attr('boolean'),
    isITAR: _emberData['default'].attr('boolean', { defaultValue: false }),
    isFlexBoard: _emberData['default'].attr('boolean', { defaultValue: false }),
    kitOption: _emberData['default'].attr('string'), //comes from assembly service details, on project
    projectGUID: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    referralCustomerEmail: _emberData['default'].attr('string'),
    referralCustomerId: _emberData['default'].attr('string'),
    referralPromotionID: _emberData['default'].attr('string'),
    referralRequestedService: _emberData['default'].attr('string'),
    referralSecondarySource: _emberData['default'].attr('string'),
    referralServicePartnerCode: _emberData['default'].attr('string'),
    referralTransactionId: _emberData['default'].attr('string'),
    requestedTurnKey: _emberData['default'].attr('boolean'),
    saveAction: _emberData['default'].attr('string'),
    smtCount: _emberData['default'].attr('number'),
    thruHoleCount: _emberData['default'].attr('number'),
    totalPlacements: _emberData['default'].attr('number'),
    turnDaysDisplay: _emberData['default'].attr('string'),
    turnTimeCode: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    uniquePartCount: _emberData['default'].attr('number'),
    userSelectedTurnTime: _emberData['default'].attr('string'),
    productLifecycle: _emberData['default'].attr('string'),

    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),
    pricingObject: _emberData['default'].belongsTo('quote-assembly-pricing', { async: true }),

    isDoubleSided: (function () {
      return this.get('hasDoubleSidedSMT') || this.get('hasDoubleSidedThruHole');
    }).property('hasDoubleSidedSMT', 'hasDoubleSidedThruHole'),

    hasStandardTurnTime: (function () {
      return this.get('turnTimeCode') === 'FP5';
    }).property('turnTimeCode')

  });
});