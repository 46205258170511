define('web-app-frontend/routes/resource-center/tips/index', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'resource-center',

    setupController: function setupController(controller) {
      controller.set('indexName', 'tips');
    },

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Screaming Circuits’ technicians have seen literally tens of thousands of PCB designs and our experts know what works. Following these best practices will ensure a successful prototype.'
      }
    }]
  });
});