define('web-app-frontend/components/faq-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: 'showFAQ:extend:disabled',
    classNames: 'faq-box',
    showFAQ: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('showFAQ', true);
    },

    willDestroyElement: function willDestroyElement() {
      this.set('showFAQ', false);
    }
  });
});