define('web-app-frontend/components/quoting-submit-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ib'],
    saveAndPerformAction: 'saveAndPerformAction',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    click: function click(e) {
      var self = this;
      if (this.isDisabled() || this.isLoading()) {
        return false;
      } else {
        this.send('loading');
        // This .later is necessary to delay in the event that a user clicks directly from an input field, and focusOut and click are triggered simultaneously.
        _ember['default'].run.later(function () {
          self.sendAction('saveAndPerformAction', self.get('action'), self.get('template'), self.get('fromComponent'));
        }, 50);
      }
      e.preventDefault();
    },

    isDisabled: function isDisabled() {
      return this.$('.btn').hasClass('disabled');
    },

    isLoading: function isLoading() {
      return this.$().find('i').hasClass('fa-spinner');
    },

    actions: {
      loading: function loading() {
        var icon = this.$().find('i');

        icon.removeClass();
        icon.addClass('fa fa-spinner fa-pulse mr5');
      },

      success: function success() {
        var icon = this.$().find('i');

        icon.removeClass('fa-spinner fa-pulse');
        icon.addClass('fa-check');
      },

      cannotSubmit: function cannotSubmit() {
        var icon = this.$().find('i');

        this.$('.btn').addClass('disabled');

        // removes all classes
        icon.removeClass();
        icon.addClass('fa fa-times mr5');
      },

      canSubmit: function canSubmit() {
        var icon = this.$().find('i');

        this.$('.btn').removeClass('disabled');

        // removes all classes
        icon.removeClass();

        if (!this.noDownArrow) {
          icon.addClass('fa fa-arrow-down mr5');
        }

        if (this.rightArrow) {
          icon.addClass('fa fa-arrow-right ml5');
        }
      }
    }
  });
});