define('web-app-frontend/components/order-shipping', ['exports', 'ember', 'web-app-frontend/controllers/mixins/countries'], function (exports, _ember, _webAppFrontendControllersMixinsCountries) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendControllersMixinsCountries['default'], {
    toggleComponent: 'toggleComponent',
    collapse: 'collapse',
    close: 'close',
    shippingCarriers: ['UPS', 'FedEx'],
    fedexServices: ['FedEx 2nd Day', 'FedEx Express Saver', 'FedEx First Overnight', 'FedEx Freight', 'FedEx Ground', 'FedEx International Economy', 'FedEx International Priority', 'FedEx Priority Overnight', 'FedEx Standard Overnight'],
    upsServices: ['UPS 2nd Day Air', 'UPS 3 Day Select', 'UPS Next Day Air Early A.M.', 'UPS Worldwide Expedited', 'UPS Freight', 'UPS Ground', 'UPS Worldwide Express Plus', 'UPS Worldwide Saver', 'UPS Next Day Air Saver', 'UPS Worldwide Express', 'UPS Next Day Air'],
    formLocked: false,
    submitButtonAction: 'saveAddress',

    showUnconfirmedNoSuggestions: false,
    showUseOriginalAddress: false,
    showAddressSuggestions: false,
    showNonExemptStatusConfirm: false,
    showNonExemptStatus: false,
    showFileForExemptionConfirm: false,
    showFileForExemption: false,
    showExemptStatus: false,
    isAddressLinkDisabled: false,
    showSpinnerForTaxrate: false,
    isSpinnerSetForSummary: false,
    ownShippingAccount: _ember['default'].computed.equal('model.shippingMethod', 'Own Account'),
    dhl: _ember['default'].computed.equal('model.shippingTerms', 'DHL'),
    fedEx: _ember['default'].computed.equal('model.shippingTerms', 'FedEx'),
    ups: _ember['default'].computed.equal('model.shippingTerms', 'UPS'),

    setClientStateOnProject: 'setClientStateOnProject',
    setSectionErrorState: 'setSectionErrorState',
    exemptStatus: '',
    exemptionStringForMsg: '',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    exemptStatusAndMsg: (function () {
      if (this.model.get('hasTaxExemptionOnFile') && this.model.get('addressValidationResult.taxRateRaw') === 0) {
        this.set('exemptStatus', 'Exempt');
        this.set('exemptionStringForMsg', 'tax exemption');
      } else {
        this.set('exemptStatus', 'Partial Exemption');
        this.set('exemptionStringForMsg', 'partial tax exemption');
      }
    }).observes('model.hasTaxExemptionOnFile', 'model.addressValidationResult.taxRateRaw'),

    claimingTaxExempt: function claimingTaxExempt() {
      var quoteSubmitButton = this.get('quoteSubmitButton');
      var self = this;
      this.model.set('isTaxExempt', true);
      this.set('showSpinnerForTaxrate', true);
      this.model.save().then(function () {
        quoteSubmitButton.send('canSubmit');
        self.set('showSpinnerForTaxrate', false);
        self.set('isSpinnerSetForSummary', true);
        console.log('user claiming tax exempt status');
      })['catch'](function (errors) {
        quoteSubmitButton.send('canSubmit');
        self.set('showSpinnerForTaxrate', false);
        console.log('setting isTaxExempt status failed', errors);
      });
    },

    clearTermsAndService: (function () {
      if (this.model && !this.get('ownShippingAccount')) {
        this.model.clearTermsAndService();
      }
    }).observes('ownShippingAccount'),

    confirmingNonExempt: function confirmingNonExempt() {
      var quoteSubmitButton = this.get('quoteSubmitButton');
      var self = this;
      this.model.set('ignoreTaxAlert', true);
      this.set('showSpinnerForTaxrate', true);
      this.model.save().then(function () {
        quoteSubmitButton.send('canSubmit');
        self.set('showSpinnerForTaxrate', false);
        self.set('isSpinnerSetForSummary', true);
        console.log('user confirming non exempt status');
      })['catch'](function (errors) {
        quoteSubmitButton.send('canSubmit');
        console.log('setting ignoreTaxAlert status failed', errors);
        self.set('showSpinnerForTaxrate', false);
      });
    },

    determineExemptionStatus: function determineExemptionStatus() {
      var exemptionNotificationOrigin = this.get('exemptionNotificationOrigin');
      if (!this.model.get('isTaxExempt') && this.model.get('hasTaxExemptionOnFile')) {
        //endpoint doesn't return error, but order will not be valid for submission in this state
        this.send('setSectionErrorState', 'shippingError', true);
        this.send('changeNotification', exemptionNotificationOrigin, 'showUseExemptionConfirm', 'cannotSubmit');
      } else if (!this.model.get('isTaxExempt')) {
        //endpoint doesn't return error, but order will not be valid for submission in this state
        this.send('setSectionErrorState', 'shippingError', true);
        this.send('changeNotification', exemptionNotificationOrigin, 'showNonExemptStatusConfirm', 'cannotSubmit');
      } else {
        this.send('setSectionErrorState', 'shippingError', false);
        this.send('changeNotification', exemptionNotificationOrigin, 'showExemptStatus', 'canSubmit');
      }
    },

    resetComponentProperties: function resetComponentProperties() {
      this.setProperties({ formLocked: false,
        submitButtonAction: 'saveAddress',
        showUnconfirmedNoSuggestions: false,
        showUseOriginalAddress: false,
        showAddressSuggestions: false,
        showNonExemptStatusConfirm: false,
        showNonExemptStatus: false,
        showFileForExemptionConfirm: false,
        showFileForExemption: false,
        showExemptStatus: false });
    },

    setDefaultShippingService: (function () {
      if (!this.model) {
        return;
      }
      var courier = this.model.get('shippingTerms');
      var shipService = this.model.get('shippingService');
      if (courier === 'FedEx' && this.fedexServices.indexOf(shipService) === -1) {
        this.model.set('shippingService', 'FedEx 2nd Day');
      }
      if (courier === 'UPS' && this.upsServices.indexOf(shipService) === -1) {
        this.model.set('shippingService', 'UPS 2nd Day Air');
      }
    }).observes('model.shippingTerms'),

    validateOrderShipping: function validateOrderShipping() {
      var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      console.log('validateOrderShipping');
      var shippingAddress = this.model;
      shippingAddress.alterAttributes(all);
      shippingAddress.save().then(function () {})['catch'](function (errors) {
        console.log('validateOrderShipping failed', errors);
      });
      return false;
    },

    whiteListAddress: function whiteListAddress() {
      var quoteSubmitButton = this.get('quoteSubmitButton');
      var shippingAddress = this.model;
      var self = this;

      shippingAddress.whiteListAddress();
      shippingAddress.save().then(function () {
        console.log('address has been whitelisted');
        self.send('setSectionErrorState', 'shippingError', false);
        self.set('exemptionNotificationOrigin', 'showUseOriginalAddress');
        self.set('submitButtonAction', 'determineTaxStatus');
      })['catch'](function (errors) {
        console.log('shipping address update failed', errors);
        self.send('unlockForm', 'showUseOriginalAddress');
      })['finally'](function () {
        quoteSubmitButton.send('canSubmit');
      });
    },

    actions: {

      changeNotification: function changeNotification(currentAlert, confirmation, submitState) {
        if (confirmation === 'showUseOriginalAddress') {
          this.whiteListAddress();
        }

        if (confirmation === 'showFileForExemption' || confirmation === 'showExemptStatus') {
          this.claimingTaxExempt();
        }

        if (confirmation === 'showNonExemptStatus') {
          this.confirmingNonExempt();
        }

        if (confirmation === 'showExemptStatus' || confirmation === 'showNonExemptStatus' || confirmation === 'showFileForExemption') {
          this.send('setSectionErrorState', 'shippingError', false);
          this.set('submitButtonAction', 'toggleComponent');
        }

        if (currentAlert) {
          this.set(currentAlert, false);
        }
        this.set(confirmation, true);
        this.quoteSubmitButton.send(submitState);
      },

      saveAndClose: function saveAndClose() {
        var self = this;
        var shippingAddress = this.get('model');

        shippingAddress.whiteListAddress();
        shippingAddress.setupForCommit();
        shippingAddress.save().then(function () {
          self.sendAction("close");
        })['catch'](function () {
          console.log('save shipping address failed.');
        });
      },

      determineTaxStatus: function determineTaxStatus() {
        if (this.model.get('addressValidationResult.taxAlert')) {
          this.determineExemptionStatus();
        } else {
          this.send('toggleComponent');
        }
      },

      preventValidationOnToggle: function preventValidationOnToggle() {
        this.set('preventValidationOnToggle', true);
      },

      saveAddress: function saveAddress() {
        var quoteSubmitButton = this.get('quoteSubmitButton');
        var shippingAddress = this.model;
        var self = this;

        shippingAddress.resetDefaults();
        shippingAddress.setupForCommit();
        shippingAddress.save().then(function (address) {
          self.set('formLocked', true);
          quoteSubmitButton.send('cannotSubmit');

          if (address.get('hasAddressSuggestions')) {
            self.set('showAddressSuggestions', true);
            //endpoint doesn't return error, but order will not be valid for submission in this state
            self.send('setSectionErrorState', 'shippingError', true);
          } else if (address.get('unconfirmedNoSuggestions')) {
            self.set('showUnconfirmedNoSuggestions', true);
          } else {
            self.send('determineTaxStatus');
          }
        })['catch'](function (errors) {
          console.log('saveAndPerformAction Failed', errors);
          quoteSubmitButton.send('canSubmit');
        });
      },

      saveAndPerformAction: function saveAndPerformAction() {
        //doesn't utilize quoting-submit-button's 'and perform action' params
        //due to the need to trigger the address validation and tax excemption components.
        if (this.get('isSpinnerSetForSummary')) {
          this.set('showSpinnerForSummary', true);
          var deferredSummarySpinner = new _ember['default'].RSVP.defer('Summary Spinner');
          this.send(this.get('submitButtonAction'), deferredSummarySpinner);
          var self = this;
          deferredSummarySpinner.promise.then(function () {
            self.set('showSpinnerForSummary', false);
            self.set('isSpinnerSetForSummary', false);
          })['catch'](function () {
            self.set('isSpinnerSetForSummary', false);
            self.set('showSpinnerForSummary', false);
          });
        } else {
          this.send(this.get('submitButtonAction'));
        }
      },

      selectSuggestedAddress: function selectSuggestedAddress(suggestion) {
        var quoteSubmitButton = this.get('quoteSubmitButton');
        var shippingAddress = this.model;
        var self = this;
        suggestion.set('showSpinner', true);
        this.set('isAddressLinkDisabled', true);

        shippingAddress.updateWithSuggestion(suggestion);
        shippingAddress.save().then(function (address) {
          if (address.get('hasAddressSuggestions')) {
            self.set('showAddressSuggestions', true);
            //endpoint doesn't return error, but order will not be valid for submission in this state
            self.send('setSectionErrorState', 'shippingError', true);
          } else if (address.get('unconfirmedNoSuggestions')) {
            self.set('showUnconfirmedNoSuggestions', true);
          } else {
            self.send('setSectionErrorState', 'shippingError', false);
            self.set('exemptionNotificationOrigin', 'showAddressSuggestions');
            self.send('determineTaxStatus');
          }
          self.set('isAddressLinkDisabled', false);
        })['catch'](function (errors) {
          console.log('shipping address update failed', errors);
          quoteSubmitButton.send('canSubmit');
          self.set('isAddressLinkDisabled', false);
        });
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      },

      toggleComponent: function toggleComponent(deferredSummarySpinner) {
        //Trigger timeline reload
        this.set('reloadTimeLine', true);

        this.sendAction('setClientStateOnProject', 'billing', deferredSummarySpinner);
        this.sendAction('toggleComponent', 'billing');
        this.model.set('commitOnSuccess', false);

        this.get('quoteSubmitButton').send('canSubmit');
        this.resetComponentProperties();
      },

      unlockForm: function unlockForm(notificationToHide) {
        this.set('formLocked', false);
        this.set(notificationToHide, false);
        this.get('quoteSubmitButton').send('canSubmit');
      },

      validateAddress: function validateAddress() {
        if (!this.get('preventValidationOnToggle')) {
          var shippingAddress = this.get('model');
          shippingAddress.alterAttributes();
          shippingAddress.save().then(function () {})['catch'](function (error) {
            console.log('validate shipping address failed', error);
          });
        } else {
          this.set('preventValidationOnToggle', false);
        }
      },

      cleanShippingAccountAndValidateAddress: function cleanShippingAccountAndValidateAddress() {
        if (!_ember['default'].isEmpty(this.get('model.shippingAccountNumber'))) {
          var updatedShippingAccount = this.get('model.shippingAccountNumber').replace(/[^A-Za-z0-9]/g, '');
          this.set('model.shippingAccountNumber', updatedShippingAccount);
        }
        console.log('updated shipping account number : ' + this.get('model.shippingAccountNumber'));
        this.send('validateAddress');
      }
    }
  });
});