define('web-app-frontend/components/tool-tip', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'span',
        toolTipText: '',
        hasHtml: false,
        didRender: function didRender() {
            this._super.apply(this, arguments);
            if (this.hasHtml) {
                this.$('.custom-tooltiptext').html(this.toolTipText);
            }
        }

    });
});