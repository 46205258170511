define('web-app-frontend/components/share-project', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    openModal: 'openModal',

    actions: {
      openModal: function openModal(template, model, deferred) {
        this.sendAction('openModal', template, model, deferred);
      },

      openShareQuoteModal: function openShareQuoteModal() {
        var loginDeferred = new _ember['default'].RSVP.defer('quoting sharePdf loginDeferred');
        var userSignedIn = this.get('session.isAuthenticated');
        var self = this;

        if (!userSignedIn) {
          this.send('openModal', 'modals/sign-in-or-register-modal', null, loginDeferred);
        } else {
          loginDeferred.resolve();
        }

        loginDeferred.promise.then(function () {
          var store = self.get('store');
          var newProjectShare = store.createRecord('projectShare', { id: self.get('projectGUID') });
          self.sendAction('openModal', 'quoting.share-quote-modal', newProjectShare);
        })['catch'](function (error) {
          console.log('LogIn request failed', error);
        });
      }
    }
  });
});