define('web-app-frontend/routes/about', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'About',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Screaming Circuits pioneered quick-turn PCB assembly. As a division of Milwaukee Electronics, Screaming Circuits comes with the resources of one of the longest serving electronics manufacturing service providers in North America.'
      }
    }],

    activate: function activate() {
      this._super.apply(this, arguments);
      _ember['default'].$('body').addClass('absolute-header');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('absolute-header');
    }
  });
});