define('web-app-frontend/models/alert', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        title: _emberData['default'].attr('string'),
        type: _emberData['default'].attr('string'),
        text: _emberData['default'].attr('string'),
        updated: _emberData['default'].attr('date'),

        // Associations
        user: _emberData['default'].belongsTo('user', { nested: true })
    }).reopenClass({
        FIXTURES: [{ id: 1, title: "Project #1234-5648", type: "quote", text: "BUGGER has been the industry's standard dummy text ever since the 1500s", updated: new Date('December 25, 1995 23:15:30') }, { id: 2, title: "Project #2102-3921", type: "order", text: "from a Lorem Ipsum passage, and going through the cites of the word in classical literature", updated: new Date('January 12, 2010 21:10:30') }, { id: 3, title: "Project #2012-2013", type: "quote", text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", updated: Date.now() }, { id: 4, title: "Patrick", type: "account", text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", updated: Date.now() }, { id: 5, title: "Emily", type: "quote", text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", updated: Date.now() }, { id: 6, title: "Adriel", type: "account", text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", updated: Date.now() }, { id: 7, title: "Paresh", type: "quote", text: "Lorem Ipsum has been the industry's standard dummy text ever since the 1500s", updated: Date.now() }]
    });
});