define('web-app-frontend/models/layout-quote', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    componentCount: _emberData['default'].attr('number'),
    email: _emberData['default'].attr('string'),
    netCount: _emberData['default'].attr('number'),
    nodeCount: _emberData['default'].attr('number'),
    name: _emberData['default'].attr('string'),
    phoneNumber: _emberData['default'].attr('string'),
    projectName: _emberData['default'].attr('string'),
    specialNotes: _emberData['default'].attr('string'),
    quote: _emberData['default'].attr('number'),
    quoteState: _emberData['default'].attr('string'),
    validationToken: _emberData['default'].attr('string'),

    emailSent: (function () {
      return this.get('quoteState') === 'EmailSent';
    }).property('quoteState'),

    emailSentOrOverPrice: (function () {
      return this.get('emailSent') || this.get('overPrice');
    }).property('quoteState'),

    overPrice: (function () {
      return this.get('quoteState') === 'OverPrice';
    }).property('quoteState'),

    //2 part form is presented to user, but full model is submitted to endpoint in each half.
    //1st part only contains componentCount, netCount, and nodeCount. setupForCommit method
    //sets all other fields to '' for submission on part one of the form. We need them to be null
    //for proper validation on the second submission.
    resetForSecondHalf: function resetForSecondHalf() {
      this.setProperties({ commitOnSuccess: false,
        email: null,
        name: null,
        projectName: null
      });
      this.resetUserAlteredAttributes();
    },

    quoted: (function () {
      return this.get('quoteState') === 'Quoted';
    }).property('quoteState')
  });
});