define('web-app-frontend/routes/404', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    headTags: [{
      type: 'meta',
      tagId: 'meta-prerender-status-code',
      attrs: {
        name: 'prerender-status-code',
        content: '404'
      }
    }],

    redirect: function redirect() {
      var url = this.router.location.formatURL('/404');
      if (window.location.pathname !== url) {
        this.transitionTo('/404');
      }
    }
  });
});