define('web-app-frontend/transitions', ['exports'], function (exports) {
  exports['default'] = function () {

    this.transition(this.fromRoute('user.activity', 'user.quotes', 'user.orders', 'user'), this.toRoute('user.account'), this.use('crossFade', { duration: 100 }), this.reverse('crossFade', { duration: 100 }));

    this.transition(this.fromRoute('user.loading'), this.toRoute('user.orders', 'user.quotes', 'user.activity'), this.use('crossFade', { duration: 100 }));

    this.transition(this.childOf('.crossfade-outlet'), this.use('crossFade', { duration: 100 }));

    this.transition(this.childOf('.toDown-outlet'), this.use('toDown'), this.reverse('crossFade'));

    this.transition(this.hasClass('hovered'), this.use('crossFade'));

    this.transition(this.hasClass('footer-transition'), this.toValue(true), this.use('toRight', { duration: 300 }));

    this.transition(this.hasClass('fade-transition'), this.toValue(true), this.use('fade', { duration: 70 }), this.reverse('fade', { duration: 50 }));

    this.transition(this.hasClass('long-fade'), this.toValue(true), this.use('fade'), this.reverse('fade'));

    this.transition(this.matchSelector('.fade-transition'), this.use('fade', { duration: 120 }), this.reverse('fade', { duration: 120 }));
  };
});