define('web-app-frontend/components/print-project-summary', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
		exports['default'] = _ember['default'].Component.extend({
				session: _ember['default'].inject.service(),

				actions: {
						printSummary: function printSummary(projectGUID, PrintAllPrices) {
								var self = this;
								console.log('printing summary PDF');
								var windowHandle = window.open();
								var tokenString = '';
								tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
								var oauthToken = { 'Authorization': tokenString };
								var labelUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/summarypdf?Api-Key=SCWEB&PrintAllPrices=' + PrintAllPrices;
								labelUrl = labelUrl + '&SessionGUID=' + $.cookie('sessionGUID');
								$.ajax({
										url: labelUrl,
										type: 'POST',
										headers: oauthToken,
										cache: false,
										dataType: 'json',
										processData: false, // Don't process the files
										contentType: false }). // Set content type to false as jQuery will tell the server its a query string request
								done(function (data) {
										console.log('Generated summary PDF');
										console.log(data.projectSummaryPDF.summaryPDFLink);
										labelUrl = labelUrl + "&filename=" + data.projectSummaryPDF.summaryPDFLink;
										windowHandle.location.href = labelUrl;
								}).fail(function (data) {
										console.log('Summary PDF creation failed');
										console.log(data);
										windowHandle.close();

										if (data.status === 401) {
												var applicationController = _ember['default'].getOwner(self).lookup('controller:application');
												applicationController.send('authorizationFailed');
										}
								});
						}
				}
		});
});