define("web-app-frontend/components/user-account-form", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    jobTitles: [{ label: "Choose One", value: null, showTitleDesc: false }, { label: "Electronics Design Engineer", value: "ELECTRONICS_DESIGN_ENGINEER", showTitleDesc: false }, { label: "PCB Layout Specialist", value: "PCB_LAYOUT_SPECIALIST", showTitleDesc: false }, { label: "Engineering Manager", value: "ENGINEERING_MANAGER", showTitleDesc: false }, { label: "Project / Product Manager", value: "PROJECT_PRODUCT_MANAGER", showTitleDesc: false }, { label: "Purchasing", value: "PURCHASING", showTitleDesc: false }, { label: "Consultant", value: "CONSULTANT", showTitleDesc: false }, { label: "Software / Firmware Engineer", value: "SOFTWARE_FIRMWARE_ENGINEER", showTitleDesc: false }, { label: "Other Type Engineer", value: "OTHER_TYPE_ENGINEER", showTitleDesc: true }, { label: "Sales", value: "SALES", showTitleDesc: false }, { label: "Other", value: "OTHER", showTitleDesc: true }],

    industries: [{ label: "Choose One", value: null, showIndustryDesc: false }, { label: "Consumer Electronics", value: "CONSUMER_ELECTRONICS", showIndustryDesc: false }, { label: "Medical / Biotech", value: "MEDICAL_BIOTECH", showIndustryDesc: false }, { label: "Aerospace", value: "AEROSPACE", showIndustryDesc: false }, { label: "Energy", value: "ENERGY", showIndustryDesc: false }, { label: "Government / Military", value: "GOVERNMENT_MILITARY", showIndustryDesc: false }, { label: "Automotive", value: "AUTOMOTIVE", showIndustryDesc: false }, { label: "Agriculture", value: "AGRICULTURE", showIndustryDesc: false }, { label: "Networking / Telecom", value: "NETWORKING_TELECOM", showIndustryDesc: false }, { label: "Wearables / Internet Of Things", value: "WEARABLES_INTERNET_OF_THINGS", showIndustryDesc: false }, { label: "Exploration / Research", value: "EXPLORATION_RESEARCH", showIndustryDesc: false }, { label: "Industrial Controls", value: "INDUSTRIAL_CONTROLS", showIndustryDesc: false }, { label: "Instrumentation And Test", value: "INSTRUMENTATION_AND_TEST", showIndustryDesc: false }, { label: "Other", value: "OTHER", showIndustryDesc: true }],

    openModal: 'openModal',
    invalidateSession: 'invalidateSession',

    didInsertElement: function didInsertElement() {
      if (this.industries) {
        this.$('#industryId option:first').attr('disabled', true);
      }
      if (this.jobTitles) {
        this.$('#titleId option:first').attr('disabled', true);
      }
    },

    showIndustryDesc: (function () {
      var showOther = false;
      if (this.get('user.industry')) {
        var selectedOption = this.industries.findBy('value', this.get('user.industry'));
        if (selectedOption) {
          showOther = selectedOption.showIndustryDesc;
          if (selectedOption.showIndustryDesc === true) {
            return showOther;
          } else {
            this.set('user.industryDescription', null);
          }
        }
      }
    }).property('user.industry'),

    showTitleDesc: (function () {
      var showOther = false;
      if (this.get('user.title')) {
        var selectedOption = this.jobTitles.findBy('value', this.get('user.title'));
        if (selectedOption) {
          showOther = selectedOption.showTitleDesc;
          if (selectedOption.showTitleDesc === true) {
            return showOther;
          } else {
            this.set('user.titleDescription', null);
          }
        }
      }
    }).property('user.title'),

    actions: {
      updateUser: function updateUser(user) {
        var self = this;
        var submitBtnComponent = this.get('submitButton');

        var deferred = new _ember["default"].RSVP.defer('loginname change  deferred');
        if (user.get('loginName') !== this.get('originalLoginName')) {
          self.sendAction('openModal', 'modals/confirmation-modal', { text: 'Log in with your new username and password',
            subtext: 'To complete this username change we will log you off ScreamingCircuits.com and send a temporary password to your email account. Log back in<br/>using your new username and password.',
            customSubmitText: 'save and logout',
            deferredObject: deferred });
        } else {
          deferred.resolve();
        }
        deferred.promise.then(function () {
          console.log('user selected save');
          // displays errors for all inputs that must be defined
          // also sets 'commitOnSuccess' to true
          user.setupForCommit();
          user.save().then(function () {
            submitBtnComponent.send('hasSucceeded');
            if (user.get('loginName') !== self.get('originalLoginName')) {
              console.log('loginName has changed. Logout user');
              self.sendAction('invalidateSession');
            }
          })["catch"](function (errors) {
            console.log('updateUser errors: ', errors);
            //when not successful
            submitBtnComponent.send('showError');
          })["finally"](function () {
            submitBtnComponent.send('hideSpinner');
          });
        })["catch"](function (errors) {
          console.log('user selected cancel');
          console.log(errors);
          submitBtnComponent.send('hideSpinner');
        });
      },

      validateUser: function validateUser() {
        var baseUser = this.get('user');
        baseUser.alterAttributes();
        baseUser.generateHash();

        var matchingRecord = baseUser.findMatchingRecord();
        if (matchingRecord) {
          baseUser.mapMatchToBaseRecord(matchingRecord);
        } else {
          var cacheCopy = baseUser.createCacheCopy();
          cacheCopy.set('userGUID', baseUser.get('id'));
          cacheCopy.save().then(function () {})["catch"](function (errors) {
            console.log('validateUser errors: ', errors);
          })["finally"](function () {
            baseUser.mapFromCopyOnRequestFulfillment(cacheCopy);
          });
        }
        return false;
      }
    }
  });
});