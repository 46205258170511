define('web-app-frontend/controllers/mixins/activities', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    activityCount: 5,

    setActivitiesDisplayed: (function () {
      // This should live here instead of route to keep from having to redo load more on return to route.
      if (this.activities) {
        var displayActivities = this.activities.slice(0, this.get('activityCount'));
        this.set('displayActivities', displayActivities);
      }
    }).observes('activities'),

    shouldPaginate: (function () {
      var totalActivitiesCount = this.get('activities.length');
      var displayCount = this.get('activityCount');

      if (totalActivitiesCount <= displayCount) {
        return false;
      } else {
        return true;
      }
    }).property('activities', 'activityCount'),

    actions: {
      loadMore: function loadMore() {
        var baseCount = this.activityCount;
        this.set('activityCount', baseCount + 10);
        var additionalActivities = this.activities.slice(baseCount, this.activityCount);

        this.displayActivities.addObjects(additionalActivities);
      }
    }
  });
});