define("web-app-frontend/serializers/quote-fabrication", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      var quoteFabrication = payload.orderFabrication;
      var quoteTotal = payload.projectSummary.fabricationServiceSummary.fabricationSubTotal;
      quoteFabrication.id = quoteFabrication.projectGUID;
      quoteFabrication.pricingObject = quoteFabrication.id;
      quoteFabrication.userSelectedTurnTime = quoteFabrication.turnTime;
      quoteFabrication.quoteTotal = quoteTotal;
      var data = { quoteFabrication: quoteFabrication };
      return this._super(store, typeClass, data, id);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id, requestType) {
      var data;
      if (requestType !== "deleteRecord") {
        var quoteFabrication = payload.orderFabrication;
        quoteFabrication.id = quoteFabrication.projectGUID;
        data = { quoteFabrication: payload.orderFabrication };
        return this._super(store, typeClass, data);
      } else if (payload.model === "Fabrication deleted.") {
        if (!payload.orderFabrication) {
          data = { orderFabrication: payload };
        }
        return this._super(store, typeClass, data);
      } else {
        return false;
      }
    }
  });
});