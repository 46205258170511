define('web-app-frontend/components/quote-fabrication-pricing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    numLayersOptions: ['2', '4', '6', '8', '10', '12', '14', 'Other'],
    engineeringLevelOptions: ['Limited Review', 'Full Review (NRE)'],
    materials: ['FR4 150 Tg', 'FR4 170 Tg', 'Rogers 4000 Series', 'Other'],
    inspectionLevelOptions: ['IPC A 600 Class 2', 'IPC A 600 Class 3', 'IPC 6012 Class 2', 'IPC 6012 Class 3', 'IPC 6018 Class 2', 'IPC 6018 Class 3', 'Other'],
    goldFingerSidesOptions: [1, 2, 3, 4],
    bevelOptions: ['None', '15 degrees', '30 degrees', '45 degrees'],
    yesNoOptions: [{ text: 'No', value: false }, { text: 'Yes', value: true }],
    silkScreenOptions: ['Yellow', 'Black', 'White'],
    silkScreenSides: [{ text: 'Select', value: '' }, { text: 'Both', value: 'Both' }, { text: 'Top', value: 'Top' }, { text: 'Bottom', value: 'Bottom' }, { text: 'None', value: 'None' }],
    surfaceFinishOptions: ['Tin Lead', 'Silver', 'ENIG', 'Copper only', 'Hard Gold (8-15u")', 'Soft Gold (30 u")', 'Lead Free Hasl', 'Selective Plate', 'Other'],
    smallestDrillOptions: [{ text: 'Select', value: '' }, { text: 'Over 15 mils', value: 'Over 15 mils' }, { text: '15 mils', value: '15 mils' }, { text: '14 mils', value: '14 mils' }, { text: '13 mils', value: '13 mils' }, { text: '12 mils', value: '12 mils' }, { text: '11 mils', value: '11 mils' }, { text: '10 mils', value: '10 mils' }, { text: '9 mils', value: '9 mils' }, { text: '8 mils', value: '8 mils' }, { text: '7 mils', value: '7 mils' }, { text: '6 mils', value: '6 mils' }, { text: '5 mils', value: '5 mils' }, { text: '4 mils', value: '4 mils' }, { text: 'Other', value: 'Other' }],
    spaceAndTraceOptions: [{ text: 'Select', value: '' }, { text: '3 mils', value: '3 mils' }, { text: '4 mils', value: '4 mils' }, { text: '5 mils', value: '5 mils' }, { text: '6 - 9 mils', value: '6 - 9 mils' }, { text: '10 - 14 mils', value: '10 - 14 mils' }, { text: 'Over 14 mils', value: 'Over 14 mils' }, { text: 'Other', value: 'Other' }],
    solderMaskColorOptions: [{ text: 'Green (Semi-Gloss)', value: 'Green' }, { text: 'Red (Semi-Gloss)', value: "Red" }, { text: 'Blue (Semi-Gloss)', value: 'Blue' }, { text: 'White (Semi-Gloss)', value: 'White' }, { text: 'Black (Semi-Gloss)', value: 'Black' }, { text: 'Clear (Semi-Gloss)', value: 'Clear' }, { text: 'Purple (Semi-Gloss)', value: "Purple" }, { text: 'Yellow (Semi-Gloss)', value: "Yellow" }, { text: 'Orange (Semi-Gloss)', value: 'Orange' }, { text: 'Other', value: 'Other' }],
    minSpaceAndTraceList: { '1 oz.': [{ text: 'Select', value: '' }, { text: '3 mils', value: '3 mils' }, { text: '4 mils', value: '4 mils' }, { text: '5 mils', value: '5 mils' }, { text: '6 - 9 mils', value: '6 - 9 mils' }, { text: '10 - 14 mils', value: '10 - 14 mils' }, { text: 'Over 14 mils', value: 'Over 14 mils' }],
      '2 oz.': [{ text: '4 mils', value: '4 mils' }, { text: '5 mils', value: '5 mils' }, { text: '6 mils', value: '6 mils' }, { text: '7 - 10 mils', value: '7 - 10 mils' }, { text: '11 - 15 mils', value: '11- 15 mils' }, { text: 'Over 16 mils', value: 'Over 16 mils' }],
      '3 oz.': [{ text: '6 mils', value: '6 mils' }, { text: '7 mils', value: '7 mils' }, { text: '8 mils', value: '8 mils' }, { text: '9 - 12 mils', value: '9-12 mils' }, { text: '13 - 16 mils', value: '13 - 16 mils' }, { text: 'Over 17 mils', value: 'Over 17 mils' }],
      'Other': [{ text: 'Other', value: 'Other' }]
    },
    faiOptions: ['None', 'Sunstone Standard FAI Format', 'AS9102 Format'],
    controlledImpedanceOptions: ['None', '20% tolerance', '15% tolerance', '10% tolerance'],
    showTraceSpaceInnerOther: _ember['default'].computed.and('showOnCopperWgt', 'showMinTraceSpaceInner'),
    isValidSubmission: false,
    showNonStadFinish: _ember['default'].computed.equal('model.surfaceFinish', 'Other'),
    showNonStadCopperLayer: _ember['default'].computed.equal('model.layers', 'Other'),
    showOnGoldLength: _ember['default'].computed('model.edgeLengthOfGoldFingers', {
      get: function get() {
        return this.get('model.edgeLengthOfGoldFingers') > 0;
      }
    }),
    showOnInspectionLevel: _ember['default'].computed.equal('model.inspection', 'Other'),
    showCrossSectionRpt: _ember['default'].computed('model.inspection', {
      get: function get() {
        var inspectionValue = this.get('model.inspection');
        return inspectionValue === 'Other' || inspectionValue === 'IPC 6012 Class 2' || inspectionValue === 'IPC 6012 Class 3';
      }
    }),
    showMaterialNonStandard: _ember['default'].computed.equal('model.material', 'Other'),
    showNonStadThickness: _ember['default'].computed.equal('model.thickness', 'Other'),
    showOnCopperWgt: _ember['default'].computed.equal('model.copperWeight', 'Other'),
    showMinTraceSpaceInner: false,
    showDrilledHoleOther: _ember['default'].computed.equal('model.smallestHoleSize', 'Other'),
    disableControlsOnInspection: false,
    customMsg: '',

    cancel: 'cancel',
    findOrCreateQuoteFabrication: 'findOrCreateQuoteFabrication',
    openModal: 'openModal',
    setClientStateOnProject: 'setClientStateOnProject',
    toggleComponent: 'toggleComponent',
    toggleSkip: 'toggleSkip',
    validateForm: 'validateForm',
    fabricationUnavailable: false,
    autoUpgradeAlert: false,
    customQuoteOpen: false,
    showSolderMaskColor: _ember['default'].computed('model.solderMaskSides', {
      get: function get() {
        return this.get('model.solderMaskSides') !== 'None';
      }
    }),
    showSilkColorAndClipping: _ember['default'].computed('model.silkscreenSides', {
      get: function get() {
        return this.get('model.silkscreenSides') !== 'None';
      }
    }),

    showElectricalTestText: _ember['default'].computed('model.electricalTest', {
      get: function get() {
        return this.get('model.electricalTest');
      }
    }),

    fabSkipped: _ember['default'].computed.alias('project.userRemovedFab'),
    pricingUnavailable: _ember['default'].computed.alias('sunstoneUnavailable'),
    //pricingUnavailable: Ember.computed.or('sunstoneUnavailable', 'model.sunstoneFailure'), //Deprecating sunstoneFailure for now, consolidating to sunstoneUnavailable
    isITAR: _ember['default'].computed.equal('assemblyPricing.isITAR', true),
    otherFieldMaxLength: 60,

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    updateCustomFabQuoteFlag: (function () {
      if (this.get('assemblyPricing.isFlexBoard')) {
        this.set('model.customFabQuote', true);
      } else {
        this.set('model.customFabQuote', false);
      }
    }).observes('assemblyPricing.isFlexBoard'),

    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      if (this.get('model.layers') === 'Other' && this.get('model.layersOther') > 2 || this.get('model.layers') !== 'Other' && this.get('model.layers') !== '1' && this.get('model.layers') !== '2') {
        this.set('showMinTraceSpaceInner', true);
      } else {
        this.set('showMinTraceSpaceInner', false);
      }

      var self = this;
      Object.keys(this.get('minSpaceAndTraceList')).forEach(function (key) {
        if (key === self.get('model.copperWeight')) {
          self.set('spaceAndTraceOptions', self.get('minSpaceAndTraceList')[key]);
        }
      });

      //disables the radio buttons dependent on Inspection Level when going back to Quoting screen from Ordering Screen
      var inspectionValue = this.get('model.inspection');
      if (inspectionValue === 'IPC 6012 Class 2' || inspectionValue === 'IPC 6012 Class 3') {
        _ember['default'].$(this.get('element')).find('[name="date-code"]').attr("disabled", true);
      } else {
        _ember['default'].$(this.get('element')).find('[name="date-code"]').removeAttr("disabled");
      }

      //disables the radio buttons dependent on Routing when going back to Quoting screen from Ordering Screen
      if (this.get('model.routing') === 'Individual') {
        _ember['default'].$(this.get('element')).find('[name="panel-approval"]').attr("disabled", true);
      } else {
        _ember['default'].$(this.get('element')).find('[name="panel-approval"]').removeAttr("disabled");
      }
    },

    reActivatePriceDifferences: (function () {
      if (this.model && this.model.get('skipCheckPriceDifferences') && this.get('showFabrication')) {
        this.model.set('skipCheckPriceDifferences', false);
      }
    }).observes('showFabrication'),

    shouldShowUpdate: (function () {
      // is fabricatioinEdit true or has parts already been touched??
      return this.editing || this.get('targetObject.partsTouched');
    }).property('editing'),

    customQuoteDetails: (function () {
      if (this.get('model.customFabQuote') && this.get('model.customFabNotes')) {
        var msgs = this.get('model.customFabNotes').split('|');
        this.set('customMsg', msgs);
      } else {
        this.set('customMsg', '');
      }
    }).observes('model.customFabNotes'),

    // This is the obeserver to watch for the error returned from adapters/quote-fabrication-pricing watching
    // for a status code of 503 which means Sunstone is unavailable.
    monitorForServiceAvailability: (function () {
      var errors;
      var self = this;
      var fabPricing = this.model;
      if (fabPricing && fabPricing.get('errors.length') > 0) {
        errors = fabPricing.get('errors.content');
        errors.forEach(function (error) {
          if (error.attribute === 'timeOut') {
            // if error.attribute is timeOut
            self.set('sunstoneUnavailable', true); // show the notice that fab is unavailable
            //self.removeTimeOutError(); // remove errors from object to keep the orange prompts from showing.
          }
        });
      } else if (fabPricing && fabPricing.get('errors.length') === 0) {
          self.set('sunstoneUnavailable', false);
        }
    }).observes('model', 'model.errors.length'),

    // This methods responsibility is in clearingout the errors from the above 503 service unavailable response.
    removeTimeOutError: function removeTimeOutError() {
      var fabPricing = this.model;
      var errors = fabPricing.get('errors');
      errors.clear();
    },

    checkBoardSizeRequirements: function checkBoardSizeRequirements(quoteFabricationPricing, designViolation, keys) {
      quoteFabricationPricing.send('becomeDirty');
      if (quoteFabricationPricing.requiresPanelization()) {
        if (quoteFabricationPricing.isTooSmall() || quoteFabricationPricing.isTooBig()) {
          keys.forEach(function (key) {
            quoteFabricationPricing.get('errors').add(_ember['default'].String.camelize(key), designViolation.get('designViolationMessage'));
          });
        } else {
          quoteFabricationPricing.get('errors').add('height', 'Boards smaller than .75" must be panelized. Select a different routing option. ');
          //quoteFabricationPricing.get('errors').add('upgradeAlert', 'Configuration is ineligible for Quick Turn. Upgrade to Full Feature above. ');
        }
      } else {
          keys.forEach(function (key) {
            quoteFabricationPricing.send('becomeDirty');
            quoteFabricationPricing.get('errors').add(_ember['default'].String.camelize(key), designViolation.get('designViolationMessage'));
          });
        }
    },

    consolidateErrorsOnTurnTimes: function consolidateErrorsOnTurnTimes(quoteFabricationPricing, fabTurnTimes) {
      var self = this;

      if (quoteFabricationPricing.fullProtoOrPanelize()) {
        quoteFabricationPricing.addFullProtoOrPanelizeError();
      }

      console.log("going to combine all errors on Turn Times for display");
      fabTurnTimes.forEach(function (turnTime) {
        turnTime.get('designViolations').forEach(function (designViolation) {
          var keys = designViolation.get('designViolationProperties');
          if (keys.contains('Height') || keys.contains('Width')) {
            self.checkBoardSizeRequirements(quoteFabricationPricing, designViolation, keys);
          } else if (!keys.contains('Build Time')) {
            keys.forEach(function (key) {
              quoteFabricationPricing.send('becomeDirty');
              quoteFabricationPricing.get('errors').add(_ember['default'].String.camelize(key), designViolation.get('designViolationMessage'));
            });
          }
        });
      });
    },

    findValidTurnTime: function findValidTurnTime(deferred) {
      var quoteFabricationPricing = this.get('model');
      var fabTurnTimes = quoteFabricationPricing.get('fabTurnTimes.content');
      var newTurnTime;
      var self = this;

      console.log("looking for turntime responses");

      if (fabTurnTimes.get('length') > 0) {
        fabTurnTimes.any(function (turnTime) {
          if (turnTime.violationFree() && turnTime.get('turnTime') === quoteFabricationPricing.get('userTurnTimePreference')) {
            console.log('VALID REQUESTED TURNTIME');
            newTurnTime = turnTime;
            quoteFabricationPricing.updateWithTurnTime(newTurnTime);
            self.resolveDeferred(deferred);
            return true;
          }
        });

        if (!newTurnTime && this.get('assemblyPricing.isFlexBoard')) {
          fabTurnTimes.any(function (turnTime) {
            if (turnTime.violationFree()) {
              console.log('VALID REQUESTED TURNTIME');
              newTurnTime = turnTime;
              quoteFabricationPricing.updateWithTurnTime(newTurnTime);
              self.set('userSelectedTurnTime', turnTime.get('turnTime'));
              var cacheCopy = quoteFabricationPricing.findMatchingRecord();
              if (cacheCopy) {
                cacheCopy.set('userSelectedTurnTime', turnTime.get('turnTime'));
              }
              self.resolveDeferred(deferred);
              return true;
            }
          });
        }

        if (!newTurnTime) {
          fabTurnTimes.reverseObjects().any(function (turnTime) {
            if (turnTime.violationFree()) {
              console.log('VALID REQUESTED TURNTIME');
              newTurnTime = turnTime;
              quoteFabricationPricing.updateWithTurnTime(newTurnTime);
              self.resolveDeferred(deferred);
              return true;
            }
          });
        }

        if (!newTurnTime) {
          this.noValidTurnTimes(quoteFabricationPricing, deferred, fabTurnTimes);
        }
      } else {
        this.noValidTurnTimes(quoteFabricationPricing, deferred, fabTurnTimes);
      }
    },

    noValidTurnTimes: function noValidTurnTimes(quoteFabricationPricing, deferred, fabTurnTimes) {
      console.log('SO SAD NO VALIDTURNTIMES');
      quoteFabricationPricing.set('hasValidTurnTime', false);
      this.rejectDeferred(deferred, 'SO SAD NO VALIDTURNTIMES');
      this.consolidateErrorsOnTurnTimes(quoteFabricationPricing, fabTurnTimes);
      quoteFabricationPricing.toggleProperty('triggerPriceDifference');
    },

    resolveDeferred: function resolveDeferred(deferred) {
      if (deferred) {
        deferred.resolve();
      }
    },

    rejectDeferred: function rejectDeferred(deferred, errors) {
      if (deferred) {
        deferred.reject(errors);
      }
    },

    setValidSubmission: (function () {
      this.set('isValidSubmission', this.get('model.isValid'));
    }).observes('model.userAlteredAttributes'),

    setTraceSpaceInnerValue: function setTraceSpaceInnerValue() {
      if (this.get('model.layers') === 'Other' && this.get('model.layersOther') > 2 || this.get('model.layers') !== 'Other' && this.get('model.layers') !== '1' && this.get('model.layers') !== '2') {
        this.set('showMinTraceSpaceInner', true);

        var copperWeight = this.get('model.copperWeight');
        var innerTrace = this.get('model.innerTrace');

        if (!innerTrace) {
          //sets back to default value
          switch (copperWeight) {
            case 'Other':
              this.set('model.innerTrace', 'Other');
              this.set('showTraceSpaceInnerOther', true);
              break;
          }
        }
      } else {
        this.set('showMinTraceSpaceInner', false);
        this.set('model.innerTrace', '');
        this.set('model.innerTraceOther', '');
        this.set('model.stdMLConstruction', true);
      }
    },

    validateOnMaterial: (function () {
      this.resetOtherFieldAndPerformValidation(this.get('model.material'), 'model.materialOther');
    }).observes('model.material'),

    validateOnSmallestHoleSize: (function () {
      this.resetOtherFieldAndPerformValidation(this.get('model.smallestHoleSize'), 'model.smallestDrillOther');
    }).observes('model.smallestHoleSize'),

    validateOnSurfaceFinish: (function () {
      this.resetOtherFieldAndPerformValidation(this.get('model.surfaceFinish'), 'model.finishOther');
    }).observes('model.surfaceFinish'),

    validateOnInspectionLevel: (function () {
      var value = this.get('model.inspection');
      if (value === 'Other' || value === 'IPC 6012 Class 2' || value === 'IPC 6012 Class 3') {
        if (value !== 'Other') {
          this.set('disableControlsOnInspection', true);
        } else {
          this.set('disableControlsOnInspection', false);
        }
      } else {
        this.set('disableControlsOnInspection', false);
      }
      this.disableControlsOnInspecOptns();
    }).observes('model.inspection'),

    disableControlsOnRouting: (function () {
      if (this.get('model.routing') === 'Individual') {
        this.set('model.panelApprove', false);
        _ember['default'].$(this.get('element')).find('[name="panel-approval"]').attr("disabled", true);
      } else {
        _ember['default'].$(this.get('element')).find('[name="panel-approval"]').removeAttr("disabled");
      }
    }).observes('model.routing'),

    disableControlsOnInspecOptns: function disableControlsOnInspecOptns() {
      if (this.model) {
        if (this.get('disableControlsOnInspection')) {
          this.set('model.electricalTest', true);
          this.set('model.dateCode', true);
          _ember['default'].$(this.get('element')).find('[name="date-code"]').attr("disabled", true);
        } else {
          _ember['default'].$(this.get('element')).find('[name="date-code"]').removeAttr("disabled");
        }
      }
    },

    resetOtherFieldAndPerformValidation: function resetOtherFieldAndPerformValidation(property, otherFieldProperty) {
      this.resetFieldValue(property, otherFieldProperty);
      if (this.get('showFabrication')) {
        this.send('validateQuoteFabricationPricing');
      }
    },

    validateOnShow: (function () {
      if (this.get('model') && this.get('showFabrication') && !this.get('fabSkipped')) {
        //only run if fabrication is shown and section is not skipped
        //initiates a validation
        this.send('validateQuoteFabricationPricing');
      }
    }).observes('showFabrication'),

    commitFabQuote: function commitFabQuote(action, section, param) {
      var projectGUID = this.get('model.id');
      var self = this;
      var findOrCreateDeferred = new _ember['default'].RSVP.defer('quoteFabricationPricing save deferred');
      self.sendAction('findOrCreateQuoteFabrication', projectGUID, false, findOrCreateDeferred);

      findOrCreateDeferred.promise.then(function () {
        console.log('fab saved successfully!');
        if (self.get('model.engineering') === 'Limited Review') {
          var deferred = new _ember['default'].RSVP.defer('engineering');
          self.sendAction('openModal', 'modals/acknowledgement-modal', {
            text: '',
            checkBoxText: 'I understand the scope of the LIMITED review service and agree to continue my order.',
            deferredObject: deferred
          });
          deferred.promise.then(function () {
            self.send('performAction', action, section, param);
          });
        } else {
          self.send('performAction', action, section, param);
        }
      })['catch'](function (errors) {
        console.log('fab save failed', errors);
      })['finally'](function () {
        self.get('quoteSubmitButton').send('canSubmit');
      });
    },

    validationSuccessEvents: function validationSuccessEvents(deferred) {
      this.findValidTurnTime(deferred);
    },

    showControlsOnThickness: (function () {
      this.resetFieldValue(this.get('model.thickness'), 'model.thicknessOther');
    }).observes('model.thickness'),

    showControlsOnCopperWgt: function showControlsOnCopperWgt() {
      if (this.get('model.copperWeight') === 'Other') {
        this.set('model.minTraceAndSpace', 'Other');

        if (this.get('showMinTraceSpaceInner') === true) {
          this.set('showTraceSpaceInnerOther', true);
          this.set('model.innerTrace', 'Other');
        }
      } else {
        this.set('model.innerTraceOther', '');
        this.set('model.outerTraceOther', '');
        this.set('model.copperWeightOther', '');
        this.set('showTraceSpaceInnerOther', false);
      }
      this.updateTraceAndSpaceList();
    },

    validateStdLayerContruction: (function () {
      if (this.get('showFabrication') && this.model) {
        this.send('validateQuoteFabricationPricing');
      }
    }).observes('model.stdMLConstruction'),

    updateTraceAndSpaceList: function updateTraceAndSpaceList() {
      var self = this;
      Object.keys(this.get('minSpaceAndTraceList')).forEach(function (key) {
        if (key === self.get('model.copperWeight')) {
          self.set('spaceAndTraceOptions', self.get('minSpaceAndTraceList')[key]);
          if (key === '3 oz.') {
            self.set('model.innerTrace', '9 - 12 mils');
            self.set('model.minTraceAndSpace', '9 - 12 mils');
          } else if (key === '2 oz.') {
            self.set('model.innerTrace', '7 - 10 mils');
            self.set('model.minTraceAndSpace', '7 - 10 mils');
          } else if (key === '1 oz.') {
            self.set('model.innerTrace', '6 - 9 mils');
            self.set('model.minTraceAndSpace', '6 - 9 mils');
          } else if (key === 'Other') {
            if (self.get('showTraceSpaceInnerOther') === true) {
              self.set('model.innerTrace', 'Other');
            }
            self.set('model.minTraceAndSpace', 'Other');
          }
        }
      });
    },

    resetFieldValue: function resetFieldValue(fieldValue, otherFieldToReset) {

      if (otherFieldToReset && fieldValue !== 'Other') {
        this.set(otherFieldToReset, '');
      }
    },

    setSelectedItemOfDropDownAndLog: function setSelectedItemOfDropDownAndLog(value, component, field) {
      if (component) {
        console.log(component);
        if (this.model) {
          this.set(field, value);
          if (this.get('showFabrication')) {
            this.send('validateQuoteFabricationPricing');
          }
        }
      }
    },

    actions: {
      selectLayer: function selectLayer(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.layers', value);
          this.setTraceSpaceInnerValue();
          this.resetOtherFieldAndPerformValidation(this.get('model.layers'), 'model.layersOther');
        }
      },

      selectLayersOther: function selectLayersOther() {
        this.setTraceSpaceInnerValue();
        this.send('validateQuoteFabricationPricing');
      },

      selectCopperWeight: function selectCopperWeight() {
        this.showControlsOnCopperWgt();
        this.send('validateQuoteFabricationPricing');
      },

      selectSolderMaskColor: function selectSolderMaskColor(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.solderMaskColor');
      },
      selectSilkscreenSides: function selectSilkscreenSides(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.silkscreenSides');
      },

      selectSilkscreenColor: function selectSilkscreenColor(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.silkscreenColor');
      },

      selectMinTraceAndSpaceOuter: function selectMinTraceAndSpaceOuter(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.minTraceAndSpace');
      },

      selectMinTraceAndSpaceInner: function selectMinTraceAndSpaceInner(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.innerTrace');
      },

      selectEngineeringLevel: function selectEngineeringLevel(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.engineering');
      },

      selectSurfaceFinish: function selectSurfaceFinish(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.surfaceFinish', value);
        }
      },

      selectInspectionLevel: function selectInspectionLevel(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.inspection', value);

          if (value === 'IPC 6012 Class 2' || value === 'IPC 6012 Class 3') {
            this.set('model.crossSectionRpt', true);
          } else {
            this.set('model.crossSectionRpt', false);
          }

          this.resetOtherFieldAndPerformValidation(value, 'model.inspectionOther');
        }
      },

      selectControlledImpedanceList: function selectControlledImpedanceList(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.contrldImpList', value);
          if (value !== 'None') {
            this.set('model.serialize', true);
            _ember['default'].$(this.get('element')).find('[name="serialization"]').attr("disabled", true);
          } else {
            this.set('model.serialize', false);
            _ember['default'].$(this.get('element')).find('[name="serialization"]').removeAttr("disabled");
          }
          if (this.get('showFabrication')) {
            this.send('validateQuoteFabricationPricing');
          }
        }
      },

      selectGoldFingerLength: function selectGoldFingerLength() {

        if (this.get('model.edgeLengthOfGoldFingers') <= 0) {
          this.set('model.bevel', 'None');
          this.set('model.goldFingerSides', 1);
        }

        if (this.get('showFabrication')) {
          this.send('validateQuoteFabricationPricing');
        }
      },

      selectGoldFingerSide: function selectGoldFingerSide(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.goldFingerSides');
      },

      selectBevel: function selectBevel(value, component) {
        this.setSelectedItemOfDropDownAndLog(value, component, 'model.bevel');
      },

      selectMaterial: function selectMaterial(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.material', value);
        }
      },

      selectSmallestHoleSize: function selectSmallestHoleSize(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.smallestHoleSize', value);
        }
      },

      selectStdMLConstruction: function selectStdMLConstruction(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.stdMLConstruction', value);
        }
      },

      selectCrossSectionRpt: function selectCrossSectionRpt(value, component) {
        console.log(component);
        if (this.model) {
          this.set('model.crossSectionRpt', value);

          if (this.get('showFabrication')) {
            this.send('validateQuoteFabricationPricing');
          }
        }
      },

      selectFirstArticleInspection: function selectFirstArticleInspection(value) {

        if (this.model) {
          this.set('model.fai', value);
          if (value !== 'None') {
            this.sendAction('openModal', 'modals/alert-modal', { text: 'For an FAI report to be completed please include in your file set a print&nbsp;/&nbsp;drawing that contains the required measurements, tolerances&nbsp;and&nbsp;units.',
              customTitle: 'FAI Alert' });
          }

          if (this.get('showFabrication')) {
            this.send('validateQuoteFabricationPricing');
          }
        }
      },

      customQuoteEditing: function customQuoteEditing(isOpen) {
        this.set('customQuoteOpen', isOpen);
      },

      performAction: function performAction(action, section, param) {
        if (!this.get('targetObject').allSectionsHaveBeenTouched()) {
          //a true param would indicate that parts is being removed, and client state will get set after that deletion.
          if (!param) {
            this.sendAction('setClientStateOnProject', section, this.model.get('id'));
          }
          this.sendAction(action, section, param);
        } else {
          this.get('targetObject').set('showFabrication', false);
          this.sendAction('validateForm');

          if (this.get('project.isCustomQuote')) {
            this.get('targetObject').set('customQuoteTouched', true);
            this.get('targetObject').set('showCustomQuote', true);
          }
        }
      },

      saveAndPerformAction: function saveAndPerformAction(action, section, param) {
        // bypassing saving when section is skipped
        if (this.get('fabSkipped')) {
          //setting showPricingManual to true here so that user can go to pricing section
          this.get('targetObject').set('showPricingManual', true);
          this.send('performAction', action, section, param);
          this.get('quoteSubmitButton').send('canSubmit');
          return false;
        }

        if (!this.get('customQuoteOpen')) {
          var self = this;
          var quoteFabricationPricing = this.get('model');

          //no reason to make requests to calculate price difference tips because section collapses on success
          quoteFabricationPricing.set('skipCheckPriceDifferences', true);
          quoteFabricationPricing.setupForCommit();

          var deferred = new _ember['default'].RSVP.defer('qfpSaveAndPerformAction');
          this.send('validateQuoteFabricationPricing', deferred);
          deferred.promise.then(function () {
            self.commitFabQuote(action, section, param);
          })['catch'](function (errors) {
            console.log('qfp outter promise fail', errors);
            self.get('quoteSubmitButton').send('canSubmit');
          });
        } else {
          return false;
        }
      },

      validateQuoteFabricationPricing: function validateQuoteFabricationPricing(deferred) {
        if (this.project.get('userRemovedFab')) {
          return;
        }
        var quoteFabricationPricing = this.get('model');

        if (!quoteFabricationPricing || !this.get('fabricationTouched')) {
          console.log('no fabQuote present returning');return;
        }
        var self = this;

        //clear arg if it's a string value coming from radio box change
        if (typeof deferred === 'boolean' || typeof deferred === 'string') {
          deferred = null;
        }

        quoteFabricationPricing.generateHash();
        quoteFabricationPricing.setupToValidate();
        var matchingRecord = quoteFabricationPricing.findMatchingRecord();

        if (matchingRecord) {
          quoteFabricationPricing.mapMatchToBaseRecord(matchingRecord);
          this.validationSuccessEvents(deferred);
        } else {
          var cacheCopy = quoteFabricationPricing.createCacheCopy();
          cacheCopy.save().then(function (cacheCopy) {
            quoteFabricationPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
            self.validationSuccessEvents(deferred);
          })['catch'](function (errors) {
            console.log('validateQuoteFabricationPricing Failed', errors);
            cacheCopy.set('turnTime', cacheCopy.get('userSelectedTurnTime'));
            quoteFabricationPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
            cacheCopy.unloadRecord();
            self.rejectDeferred(deferred, errors);
          });
          return false;
        }
      },

      scrollToFullFeature: function scrollToFullFeature() {
        var container = $('html, body');

        container.animate({
          scrollTop: $('#full-feature-box .bordered').offset().top - 25
        }, 200);
      },

      toggleCustomQuote: function toggleCustomQuote(customQuoteActive) {
        this.sendAction('toggleCustomQuote', customQuoteActive);
      },

      cancel: function cancel(controllerName) {
        this.send('validateQuoteFabricationPricing');
        this.sendAction('cancel', this.get('model'), controllerName);
      },

      openCustomQuote: function openCustomQuote(scrollTo) {
        var customQuoteInput = this.get('customQuoteInput');
        customQuoteInput.send('toggleInput', scrollTo);
      },

      isEdit: function isEdit(param) {
        this.set('editing', param);
      },

      toggleComponent: function toggleComponent(param) {
        this.sendAction('toggleComponent', param);
      },

      toggleSkip: function toggleSkip(section, removal) {
        this.sendAction('toggleSkip', section, removal);
      },

      closeModal: function closeModal() {
        var applicationController = _ember['default'].getOwner(this).lookup('controller:application');
        applicationController.send('closeModal');
      }
    }
  });
});