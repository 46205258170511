define('web-app-frontend/components/quote-snippet', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['mbThreeThird quote-snippet'],
    name: null,
    assemblyPricing: null,
    mobileViewSnippet: false,
    cancel: 'cancel',
    cancelCustomQuote: 'cancelCustomQuote',
    findOrCreateQuoteAssembly: 'findOrCreateQuoteAssembly',
    findOrCreateQuoteFabrication: 'findOrCreateQuoteFabrication',
    skip: 'skip',
    saveForLater: 'saveForLater',
    showServiceTypeWindow: 'showServiceTypeWindow', // sent from fabrication-turn-time component
    toggleComponent: 'toggleComponent',
    toggleLockQuote: 'toggleLockQuote',
    toggleRefreshTurnTimes: 'toggleRefreshTurnTimes',
    toggleSkip: 'toggleSkip',
    saveAndValidateForm: 'saveAndValidateForm',
    visiblePricing: 'visiblePricing',
    openModal: 'openModal',
    showPriceAndTurnTime: false,

    // Observers
    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    setAssemblyPricing: (function () {
      this.set('assemblyPricing', this.get('assemblyPricing'));
    }).on('init'),

    // Properties
    title: (function () {
      if (this.get('displayName') !== 'Pricing' && this.get('displayName') !== 'Project Information' && !this.get('isMobile')) {
        return this.get('displayName') + ' Quote';
      } else {
        return this.get('displayName');
      }
    }).property('title'),

    canSkip: (function () {
      if (typeof this.get('isSkip') !== 'undefined') {
        return true;
      }
    }).property('canSkip'),

    isAssembly: (function () {
      if (this.get('name') === 'assembly') {
        return true;
      }
    }).property('isAssembly'),

    isCustomQuote: (function () {
      if (this.get('name') === 'customQuote') {
        return true;
      }
    }).property('isCustomQuote'),

    isFabrication: (function () {
      if (this.get('name') === 'fabrication') {
        return true;
      } else {
        return false;
      }
    }).property('isFabrication'),

    isPricing: (function () {
      if (this.get('name') === 'pricing') {
        return true;
      }
    }).property('isPricing'),

    isParts: (function () {
      if (this.get('name') === 'parts') {
        return true;
      }
    }).property('isParts'),

    displayName: (function () {
      var name = this.get('name');

      if (name === 'fabrication') {
        return 'PCB Fabrication';
      } else if (name === 'customQuote') {
        return 'Custom';
      } else if (name === 'projectInformation') {
        return 'Project Information';
      } else {
        return name.capitalize();
      }
    }).property('displayName'),

    hasErrorsAndIsCollapsed: (function () {
      return !this.get('isShown') && this.get('hasErrors');
    }).property('isShown', 'hasErrors'),

    // Methods
    allowUserToConfirmBomRemoval: function allowUserToConfirmBomRemoval(deferred) {
      this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to remove your BOM?',
        customSubmitText: 'Yes, continue to remove',
        deferredObject: deferred
      });
    },

    haveUserConfirmFabricationRemoval: function haveUserConfirmFabricationRemoval(deferred) {
      this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Are you sure you wish to remove PCB fabrication?',
        customSubmitText: 'Yes, continue to remove',
        deferredObject: deferred });
    },

    setCustomLabel: (function () {
      if (this.get('model.customFabQuote') || !this.get('model.customFabQuote') && this.get('model.quoteTotal') > 0) {
        this.set('showPriceAndTurnTime', true);
      } else {
        this.set('showPriceAndTurnTime', false);
      }
    }).observes('model.serviceType'),

    actions: {
      cancel: function cancel(snippetName) {
        this.sendAction('cancel', this.model, snippetName);
      },

      cancelCustomQuote: function cancelCustomQuote() {
        this.sendAction('cancelCustomQuote');
      },

      findOrCreateQuoteAssembly: function findOrCreateQuoteAssembly(id, saveForLater) {
        this.sendAction('findOrCreateQuoteAssembly', id, saveForLater);
      },

      findOrCreateQuoteFabrication: function findOrCreateQuoteFabrication(id, saveForLater) {
        this.sendAction('findOrCreateQuoteFabrication', id, saveForLater);
      },

      saveForLater: function saveForLater(deferred) {
        this.sendAction('saveForLater', deferred);
      },

      toggleSkip: function toggleSkip(removal) {
        var self = this;
        var deferred = new _ember['default'].RSVP.defer('toggle skip deferred');
        if (this.get('name') === 'parts' && removal) {
          this.allowUserToConfirmBomRemoval(deferred);
          deferred.promise.then(function () {
            self.sendAction('toggleSkip', self.get('name'), removal);
          });
        } else if (this.get('name') === 'fabrication' && removal) {
          this.haveUserConfirmFabricationRemoval(deferred);
          deferred.promise.then(function () {
            self.sendAction('toggleSkip', self.get('name'), removal);
          });
        } else {
          this.sendAction('toggleSkip', self.get('name'), removal);
        }
      },

      toggleSkipMobile: function toggleSkipMobile() {
        this.send('toggleSkip', true);
      },

      toggleComponent: function toggleComponent(showThis) {
        var self = this;
        if (this.name !== 'pricing' && this.get('hasTouched')) {
          if (!this.get('quoteLocked')) {
            //This is to ensure that changes save on transition from edit -> edit sections,
            //by clicking on the quote snippet title, as opposed to explicitly clicking "Update".
            var deferred = new _ember['default'].RSVP.defer('quoteSnippet toggleComponent deferred');
            this.sendAction('saveForLater', this.project, deferred);
            deferred.promise.then(function () {
              self.sendAction('toggleRefreshTurnTimes');
            });
          }
          this.sendAction('toggleComponent', showThis);
        }
      },

      toggleMobileViewSnippet: function toggleMobileViewSnippet() {
        this.toggleProperty('mobileViewSnippet');
      },

      //deferred passed here from assembly turn time to control spinner
      saveAndValidateForm: function saveAndValidateForm(spinnerDeferred) {
        this.sendAction('saveAndValidateForm', spinnerDeferred);
      }
    }
  });
});