define('web-app-frontend/components/error-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hideForSoftAlert: false,
    scrollToFullFeature: 'scrollToFullFeature',
    setFieldErrorState: 'setFieldErrorState',
    setSectionErrorState: 'setSectionErrorState',

    actions: {
      hideErrorDisplay: function hideErrorDisplay() {
        this.set('hideForSoftAlert', true);
      },

      showErrorDisplay: function showErrorDisplay() {
        this.set('hideForSoftAlert', false);
      },

      scrollToFullFeature: function scrollToFullFeature() {
        this.sendAction('scrollToFullFeature');
      },

      setFieldErrorState: function setFieldErrorState(state) {
        if (this.get('type') === 'input') {
          this.sendAction('setFieldErrorState', state);
        }
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      }
    }
  });
});