define('web-app-frontend/serializers/survey', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, typeClass, payload) {
      var survey = payload.survey;
      var questionsData = survey.questions.data;
      var questionIds = [];
      var questions = [];
      delete survey.questions;
      survey.project = survey.projectGUID;
      survey.user = survey.userGUID;
      questionsData.forEach(function (question) {
        questionIds.push(question.id);
        question.surveyId = survey.id;
        questions.push(question);
      });
      survey.questions = questionIds;
      payload = { survey: survey, question: questions };
      return this._super(store, typeClass, payload);
    },

    /*
      normalizeSaveResponse: function(store, typeClass, payload, id, requestType){
        console.log('@@@@@@ Survey SAVE payload from server');
        return true;
      },
    
    */

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      return this._super(store, typeClass, payload, id);
    }

  });
});