define("web-app-frontend/templates/quoting/worksheet-selection", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 10
              },
              "end": {
                "line": 10,
                "column": 10
              }
            },
            "moduleName": "web-app-frontend/templates/quoting/worksheet-selection.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-spin fa-spinner mr10");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/quoting/worksheet-selection.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "padded-10-20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "mt20 mb20");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h4");
          var el4 = dom.createTextNode("Please select the worksheet with the parts on it.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "mt40");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn btn-primary ib");
          var el5 = dom.createTextNode("\n");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("          Continue\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("p");
          dom.setAttribute(el4, "class", "bold ib ml20");
          var el5 = dom.createElement("a");
          var el6 = dom.createTextNode("Cancel");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [5]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3, 0]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(element0, 3, 3);
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["inline", "worksheet-select-box", [], ["worksheetNames", ["subexpr", "@mut", [["get", "model.worksheetNames", ["loc", [null, [5, 44], [5, 64]]]]], [], []], "projectGUID", ["subexpr", "@mut", [["get", "model.projectGUID", ["loc", [null, [5, 77], [5, 94]]]]], [], []], "bomGUID", ["subexpr", "@mut", [["get", "model.bomGUID", ["loc", [null, [5, 103], [5, 116]]]]], [], []], "fileBOMName", ["subexpr", "@mut", [["get", "model.fileBOMName", ["loc", [null, [5, 129], [5, 146]]]]], [], []], "myComponent", ["subexpr", "@mut", [["get", "this", ["loc", [null, [5, 159], [5, 163]]]]], [], []], "fileUpload", ["subexpr", "@mut", [["get", "model.fileUpload", ["loc", [null, [5, 175], [5, 191]]]]], [], []], "progress", ["subexpr", "@mut", [["get", "model.progress", ["loc", [null, [5, 201], [5, 215]]]]], [], []], "isKittedBom", ["subexpr", "@mut", [["get", "model.isKittedBom", ["loc", [null, [5, 228], [5, 245]]]]], [], []], "class", "mt20", "register-as", ["subexpr", "@mut", [["get", "worksheetSelectBox", ["loc", [null, [5, 271], [5, 289]]]]], [], []]], ["loc", [null, [5, 6], [5, 291]]]], ["element", "action", ["selectWorksheet"], [], ["loc", [null, [7, 43], [7, 71]]]], ["block", "if", [["get", "showSpinner", ["loc", [null, [8, 16], [8, 27]]]]], [], 0, null, ["loc", [null, [8, 10], [10, 17]]]], ["element", "action", ["cancelWorkSheetModal"], [], ["loc", [null, [13, 35], [13, 68]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/quoting/worksheet-selection.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-component", [], ["title", "Upload BOM", "close", "cancelWorkSheetModal", "class", "bom-modal multi-part"], 0, null, ["loc", [null, [1, 0], [17, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});