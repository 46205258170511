define('web-app-frontend/components/orphan-section-links', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isServices: (function () {
      return this.get('indexName') === 'services';
    }).property('isServices'),

    isProcess: (function () {
      return this.get('indexName') === 'process';
    }).property('isProcess')
  });
});