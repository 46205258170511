define('web-app-frontend/adapters/layout-quote', ['exports', 'ember', 'ember-data', 'web-app-frontend/config/environment', 'web-app-frontend/utils/error-formatting'], function (exports, _ember, _emberData, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _emberData['default'].RESTAdapter.extend({
    host: _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl,
    namespace: 'api',

    ajax: function ajax(url, type, hash) {
      url = url + "?Api-Key=SCWEB";
      return this._super(url, type, hash);
    },

    buildURL: function buildURL(endpoint) {
      var url = "system/" + endpoint;
      return this._super(url);
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = { layoutQuoteModel: record };
      var tokenForHeader = { SCValidationToken: record.get('validationToken') };
      this.set('headers', tokenForHeader);

      return this.ajax(this.buildURL("layout"), "POST", { data: payload });
    },

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.layoutQuoteModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.layoutQuoteModel.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    },

    //overwrite bc by default this method pluralizes AND camelizes type name for a GET request,
    //and endpoint uses singular 'layout'
    pathForType: function pathForType(type) {
      var camelized = _ember['default'].String.camelize(type);
      return camelized;
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = { layoutQuoteModel: record };
      var tokenForHeader = { SCValidationToken: record.get('validationToken') };
      this.set('headers', tokenForHeader);

      //endpoint only supports POST, not PUT
      return this.ajax(this.buildURL("layout"), "POST", { data: payload });
    }
  });
});