define('web-app-frontend/components/form-passwordfield-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['confirm-checkmark'],
    classNameBindings: ['passwordsMatch::disable-check', 'errorActive:error-active'],
    errorActive: false,

    validate: 'validatePassword',

    keyUp: function keyUp() {
      this.sendAction();
    },

    focusOut: function focusOut() {
      this.sendAction('validate');
    },

    actions: {
      setFieldErrorState: function setFieldErrorState(state) {
        this.set('errorActive', state);
      }
    }
  });
});