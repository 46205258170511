define('web-app-frontend/serializers/fabrication-price-difference', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var object = payload.orderFabrication.data.get('firstObject');
      object.id = id;

      payload = { fabricationPriceDifference: object };
      return this._super(store, typeClass, payload, id);
    }
  });
});