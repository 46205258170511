define('web-app-frontend/components/form-field-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['errorActive:error-active'],
    errorActive: false,
    scrollToFullFeature: 'scrollToFullFeature',
    setSectionErrorState: 'setSectionErrorState',

    focusOut: function focusOut() {
      this.sendAction();
    },

    trimStrings: function trimStrings() {
      var attribute = this.get('attribute');
      var formFieldEntry = this.get('model').get(attribute);
      this.get('model').set(attribute, formFieldEntry.trim());
    },

    classes: (function () {
      if (!_ember['default'].isEmpty(this.get('cssClasses'))) {
        return this.get('attribute') + " " + this.get('cssClasses');
      }
      return this.get('attribute');
    }).property('attribute', 'cssClasses'),

    actions: {
      scrollToFullFeature: function scrollToFullFeature() {
        this.sendAction('scrollToFullFeature');
      },

      setFieldErrorState: function setFieldErrorState(state) {
        this.set('errorActive', state);
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      }
    }
  });
});