define('web-app-frontend/adapters/user-session', ['exports', 'web-app-frontend/adapters/application'], function (exports, _webAppFrontendAdaptersApplication) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({

    buildURL: function buildURL(type, id) {
      var url = 'user/' + id + '/sessions';
      return this._super(url);
    },

    createRecord: function createRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      var payload = { sessionModel: record };
      return this.ajax(this.buildURL(type.modelName, id), "POST", { data: payload });
    },

    ajax: function ajax(url, type, hash) {
      if (type !== 'GET') {
        url = url + "?Api-Key=SCWEB";
        return this._super(url, type, hash);
      } else {
        return this._super(url, type, hash);
      }
    }
  });
});