define('web-app-frontend/controllers/user/account/forgot-password-confirmation-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),

    actions: {
      closeModal: function closeModal() {
        console.log('i am in the closeModal');
        if (this.get('applicationController.currentPath') === 'passthrough-sign-in') {
          console.log('redirect to home page ');
          this.send('openModal', 'modals.sign-in-or-register-modal');
        } else {
          return true;
        }
      }

    }
  });
});