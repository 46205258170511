define('web-app-frontend/routes/user/activity', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Activity',
    session: _ember['default'].inject.service(),

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    model: function model() {
      var user_GUID = this.get('session.data.authenticated.UserGUID');
      return this.store.query('activity', { UserGUID: user_GUID });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.setProperties({
        activities: model
      });
    }
  });
});