define('web-app-frontend/initializers/viewport-config', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports.initialize = initialize;

  var defaultConfig = {
    viewportSpy: false,
    viewportScrollSensitivity: 1,
    viewportRefreshRate: 100,
    viewportListeners: [],
    viewportTolerance: {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0
    }
  };

  var merge = _ember['default'].merge;

  function initialize(_container, application) {
    var _config$viewportConfig = _webAppFrontendConfigEnvironment['default'].viewportConfig;
    var viewportConfig = _config$viewportConfig === undefined ? {} : _config$viewportConfig;

    var mergedConfig = merge(defaultConfig, viewportConfig);

    application.register('config:in-viewport', mergedConfig, { instantiate: false });
  }

  exports['default'] = {
    name: 'viewport-config',
    initialize: initialize
  };
});