define("web-app-frontend/router", ["exports", "ember", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _webAppFrontendConfigEnvironment["default"].locationType,

    pushGoogleTagEvent: (function (infos) {
      if (_webAppFrontendConfigEnvironment["default"].environmentIsProduction) {
        this._super(infos);
        _ember["default"].run.next(function () {
          //TODO: Should the regex pattern be stored at a global level so it doesn't need to be recreated with every load?
          var noGuidLocation = window.location.pathname.replace(/[{]?[0-9a-fA-F]{8}[-]?([0-9a-fA-F]{4}[-]?){3}[0-9a-fA-F]{12}[}]?/, "{GUID}");
          window.dataLayer.push({ 'event': 'VirtualPageview', 'virtualPageURL': noGuidLocation, 'virtualPageTitle': $(document).attr('title') });
        });
      }
    }).on('didTransition')
  });

  exports["default"] = Router.map(function () {
    this.route("register");
    this.route("project-sign-in");
    this.route("passthrough-sign-in");

    this.route("services");

    this.route("user", { resetNamespace: true }, function () {
      this.route("orders");
      this.route("activity");
      this.route("quotes");

      this.route("account", function () {
        this.route("billing");
        this.route("contacts");
        this.route("password");
      });
    });

    this.route("quoting", { resetNamespace: true, path: 'quote/' }, function () {
      this.route("new");
    });

    this.route('project', { resetNamespace: true, path: '/project/:id/' }, function () {
      this.route('quote', { resetNamespace: true }, function () {
        this.route("edit");
      });

      this.route('order', { resetNamespace: true, path: '' }, function () {
        // both new and existing orders will use edit route with query param for new/edit/show state.
        this.route('edit', { path: '/order' });
      });
    });

    this.route('resource-center', { resetNamespace: true }, function () {
      this.route('services', function () {
        this.route('index', { path: '/' });
        this.route('pcb-fabrication');
        this.route('assembly');
        this.route('parts-sourcing');
        this.route('turn-key');
        this.route('fast-turn-times');
        this.route('standards');
        this.route('volume-production');
      });
      this.route('process', function () {
        this.route('index', { path: '/' });
        this.route('production-process');
        this.route('file-requirements');
        this.route('parts-kit-requirements');
        this.route('delivery-guarantee');
        this.route('downloads');
      });
      this.route('tips', function () {
        this.route('index', { path: '/' });
        this.route('article');
        this.route('checking-design-data');
        this.route('good-marking');
        this.route('counterfeits');
        this.route('reading-data-sheet');
        this.route('component-selection');
        this.route('do-boms');
        this.route('designing-rohs');
      });
      this.route('tutorials', function () {
        this.route('index', { path: '/' });
        this.route('article');
        this.route('panelizing-pcb');
        this.route('submitting-order');
        this.route('packing-parts');
      });
      this.route('index', { path: '/' });
      this.route('faq');
      this.route('terms-and-conditions');
      this.route('privacy-policy');
    });

    this.route('about', { resetNamespace: true }, function () {
      this.route('index', { path: '/' });
      this.route('history');
      this.route('partners');
      this.route('sponsorship');
      this.route('news');
    });

    this.route('contact');

    this.route('upload');

    this.route('404', { path: '/*path' });
    this.route('file-download-error');
    this.route('notifications');
  });
});