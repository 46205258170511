define('web-app-frontend/routes/order/edit', ['exports', 'ember', 'web-app-frontend/mixins/analytics', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _webAppFrontendMixinsAnalytics, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsAnalytics['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    headTags: [{
      type: 'meta',
      tagId: 'meta-prerender-status-code',
      attrs: {
        name: 'prerender-status-code',
        content: '401'
      }
    }],

    controllerName: 'ordering',
    session: _ember['default'].inject.service(),

    titleToken: function titleToken(model) {
      // first time we load this edit route for a project we assume
      // that projectName is undefined. So we can assume we are in checkout phase.
      if (model.get('projectName')) {
        return model.get('projectName');
      } else {
        return 'Checkout';
      }
    },

    activate: function activate() {
      this._super();
      _ember['default'].$('body').addClass('order');
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    afterModel: function afterModel(model) {
      //If order is not editable by user or if it is a formal quote ..skip parts check
      if (!(model.get('isUserEditable') !== true || model.get('formalQuote') === true)) {
        //If there is problem with parts send user back to quoting screen so that user can resolve it.
        if (!model.get('partsValidOrRemoved') && !model.get('userSkippedOnlineQuoting')) {
          this.transitionTo('quote.edit', model);
        }
      }
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('order');
    },

    model: function model() {
      var self = this;
      console.log('In the model hook for order.edit route');
      var projectId = this.modelFor('project').id;
      return this.store.findRecord('project', projectId, { reload: true }).then(function (localModel) {
        if (localModel.get('needsToBeRequoted')) {
          console.log('Sending request to requote project on the server side');
          return self.store.queryRecord('project', { id: projectId, RefreshMode: 'Auto' });
        } else {
          return localModel;
        }
      });
    },

    setupController: function setupController(controller, model) {
      // to trigger default behavior
      this._super(controller, model);
      var self = this;
      var projectGUID = model.get('id');

      this.unloadStaleRecords(controller);
      controller.store.findRecord('project', projectGUID).then(function (project) {
        controller.set('project', project);

        //not delayed enough... Set manual timeout?
        _ember['default'].run.schedule('afterRender', this, function () {
          controller.printOnTransition();
          $('.header')[0].scrollIntoView();
        });

        var deferred = new _ember['default'].RSVP.defer('get project file deferred');
        self.findAndSetOrderFile(controller, projectGUID, deferred);
        // Order file is the only object needed present for setPageState to properly run.
        deferred.promise.then(function () {
          self.setPageState(controller, project);
        });

        self.findAndSetOrderContact(controller, projectGUID);
        if (project.get('requiresKittedInformation') === true) {
          self.findAndSetOrderKit(controller, projectGUID);
        }
        self.findAndSetShippingInformation(controller, projectGUID);
        self.findAndSetBillingInformation(controller, projectGUID, model);
        self.findAndSetProjectNotes(controller, projectGUID);

        if (project.get('isUserEditable') !== true) {
          self.findAndSetActivities(controller, projectGUID);
        } else {
          self.setPromotion(controller, project); //promo record not needed on order show page
        }

        if (project.get('isUserEditable') !== true) {
          self.findAndSetSurvey(controller, projectGUID);
        }
      });
    },

    //reload on a record won't work if the record is presently invalid (the request occurs, but the local
    //record won't be updated with the return data) so in lieu of a reload, we will fully unload and re-fetch
    unloadStaleRecords: function unloadStaleRecords(controller) {
      controller.store.unloadAll('projectFile');
      controller.store.unloadAll('orderContact');
      controller.store.unloadAll('orderKit');
      controller.store.unloadAll('kitBox');
      controller.store.unloadAll('projectShipping');
      controller.store.unloadAll('projectBilling');
      controller.store.unloadAll('projectPromotion');
      controller.store.unloadAll('projectNote');
      controller.store.unloadAll('survey');
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.setProperties({
          project: null,
          activities: null,
          billingInfo: null,
          shippingAddress: null,
          projectFile: null,
          orderContact: null,
          orderKit: null,
          projectNotes: null,
          survey: null,
          autoTransitionToContacts: false
        });
      }
    },

    findAndSetActivities: function findAndSetActivities(controller, projectGUID) {
      var userGUID = this.get('session.data.authenticated.UserGUID');
      this.store.query('activity', { projectGUID: projectGUID, userGUID: userGUID }).then(function (activities) {
        controller.set('activities', activities);
      })['catch'](function (errors) {
        console.log('GET activities failed', errors);
      });
    },

    findAndSetBillingInformation: function findAndSetBillingInformation(controller, projectGUID) {
      var billingController = controller.get('billingController');

      this.store.findRecord('project-billing', projectGUID).then(function (billingInfo) {
        //c.o.s. always returned from GET as True
        billingInfo.set('commitOnSuccess', false);

        //USA default cannot just be set as the default value at the model level, for even the new records are
        //fetched from the server, not created locally, and can be returned w/o a value for the country field
        if (!billingInfo.get('country')) {
          billingInfo.set('country', 'USA');
        }

        controller.setProperties({
          billingInfo: billingInfo,
          hasPurchaseOrderNumber: !_ember['default'].isEmpty(billingInfo.get('purchaseOrderNumber'))
        });
      })['catch'](function () {
        var newBillingInfo = billingController.createNewAddress(projectGUID);
        controller.setProperties({
          billingInfo: newBillingInfo
        });
      });
    },

    findAndSetShippingInformation: function findAndSetShippingInformation(controller, projectGUID) {
      var shippingAddressController = controller.get('shippingController');
      this.store.findRecord('project-shipping', projectGUID).then(function (shippingAddress) {
        //c.o.s. always returned from GET as True
        shippingAddress.set('commitOnSuccess', false);

        //defaults that cannot just be set as the default values at the model level, for even the new records are
        //fetched from the server, not created locally, and can be returned w/o a value for the country
        //and shipping method fields
        if (!shippingAddress.get('country')) {
          shippingAddress.set('country', 'USA');
        }
        if (!shippingAddress.get('shippingMethod')) {
          shippingAddress.set('shippingMethod', 'Ground');
        }

        controller.setProperties({
          shippingAddress: shippingAddress
        });
      })['catch'](function () {
        var newShippingAddress = shippingAddressController.createNewShippingAddress(projectGUID);
        controller.set('shippingAddress', newShippingAddress);
      });
    },

    findAndSetOrderFile: function findAndSetOrderFile(controller, projectGUID, deferred) {
      var store = this.store;
      var projectFileController = controller.get('fileController');
      this.store.findRecord('project-file', projectGUID).then(function (file) {
        controller.setProperties({
          projectFile: file
        });

        if (!_ember['default'].isBlank(file.get('fileName')) && store.peekRecord('project', projectGUID).get('clientState') === 'files' && !file.get('isOriginalFiles')) {
          controller.set('autoTransitionToContacts', true);
        }

        deferred.resolve(file);
      })['catch'](function () {
        // an empty object will be returned from
        var blankFile = projectFileController.generateBlankProjectFile(projectGUID);
        controller.set('projectFile', blankFile);
      });
    },

    findAndSetOrderContact: function findAndSetOrderContact(controller, projectGUID) {
      var orderContactController = controller.get('contactController');
      this.store.findRecord('orderContact', projectGUID).then(function (orderContact) {
        //c.o.s. always returned from GET as True
        orderContact.set('commitOnSuccess', false);

        //defaults that cannot just be set as the default values at the model level, for even the new records are
        //fetched from the server, not created locally, and can be returned w/o a value for the dayEmergencyContactPreference
        //and nightEmergencyContactPreference fields
        if (!orderContact.get('dayEmergencyContactPreference')) {
          orderContact.set('dayEmergencyContactPreference', 'Phone');
        }
        if (!orderContact.get('nightEmergencyContactPreference')) {
          orderContact.set('nightEmergencyContactPreference', 'Phone');
        }

        controller.setProperties({
          orderContact: orderContact
        });
      })['catch'](function () {
        var newOrderContact = orderContactController.createNewOrderContact(projectGUID);
        controller.set('orderContact', newOrderContact);
      });
    },

    findAndSetProjectNotes: function findAndSetProjectNotes(controller, projectGUID) {
      var projectNotesController = controller.get('notesController');
      this.store.findRecord('projectNote', projectGUID).then(function (projectNotes) {
        //c.o.s. always returned from GET as True
        projectNotes.set('commitOnSuccess', false);
        controller.setProperties({
          projectNotes: projectNotes
        });
      })['catch'](function () {
        var newProjectNotes = projectNotesController.createNewProjectNotes(projectGUID);
        controller.set('projectNotes', newProjectNotes);
      });
    },

    findAndSetSurvey: function findAndSetSurvey(controller, projectGUID) {
      this.store.queryRecord('survey', { id: projectGUID, surveyType: 'THANKYOU' }).then(function (survey) {
        controller.set('survey', survey);
      })['catch'](function (errors) {
        console.log('GET survey failed', errors);
      });
    },

    findAndSetOrderKit: function findAndSetOrderKit(controller, projectGUID) {
      var orderKitController = controller.get('kitController');
      var kitBoxController = controller.get('kitBoxController');
      controller.store.findRecord('orderKit', projectGUID).then(function (orderKit) {
        //c.o.s. always returned from GET as True
        orderKit.set('commitOnSuccess', false);
        controller.set('orderKit', orderKit);
        if (!orderKit.get('kitBoxes').get('length')) {
          kitBoxController.createNewKitBox(orderKit);
        }
      })['catch'](function () {
        var newOrderKit = orderKitController.createNewOrderKit(projectGUID);
        controller.set('orderKit', newOrderKit);
        kitBoxController.createNewKitBox(newOrderKit);
      });
    },

    renderTemplate: function renderTemplate(controller, project) {
      if (project.get('isUserEditable') !== true || project.get('formalQuote') === true) {
        this.render('order.show', { controller: 'ordering' });
      } else {
        this.render('ordering', { controller: 'ordering' });
      }
    },

    setPageState: function setPageState(controller, project) {
      if (project.get('isUserEditable') !== true) {
        this.resetControllerParamsForShow(controller, project);
      } else {
        this.resetControllerParamsForEdit(controller, project);
      }
      this.resetErrors(controller);
    },

    setPromotion: function setPromotion(controller, project) {
      //find or create?
      var promotionController = controller.get('promotionController');
      var newPromotion = promotionController.createNewPromotion(project);
      controller.set('promotion', newPromotion);
    },

    resetControllerParamsForEdit: function resetControllerParamsForEdit(controller, project) {
      this.setStateOfProjectFiles(controller, project);

      controller.send('collapse');

      // open section last touched by user.
      var sectionLastTouched = project.get('clientState');
      controller.set('show' + sectionLastTouched.capitalize(), true);
      var isAnySectionOpen = false;
      //Check at least one section is open if not open the files section
      ['files', 'contacts', 'kitted', 'notes', 'shipping', 'billing', 'checkout'].any(function (section) {
        if (controller.get('show' + section.capitalize()) === true) {
          isAnySectionOpen = true;
          return true;
        } else {
          return false;
        }
      });
      if (!isAnySectionOpen) {
        console.log('No section open - open the first one');
        controller.set('showFiles', true);
      }
      _ember['default'].run.schedule('afterRender', this, function () {
        controller.send('scrollToSnippet', sectionLastTouched);
      });

      this.setEditabilityFromClientState(controller, sectionLastTouched);
    },

    resetControllerParamsForShow: function resetControllerParamsForShow(controller, project) {
      console.log('in the reset show function');

      //no billing info shows on show
      var projectStatus = project.get('status');
      var filesEditable = false;
      if (projectStatus === 'Order Placed' || projectStatus === 'Order In Review') {
        filesEditable = _ember['default'].isEmpty(controller.projectFile.get('fileName')) && !controller.projectFile.get('filesReceivedOffline');
      }
      var kittedEditable = projectStatus === 'Order Placed';
      controller.send('collapse');

      controller.setProperties({
        filesEditable: filesEditable,
        kittedEditable: kittedEditable,
        isShowController: true
      });
    },

    resetErrors: function resetErrors(controller) {
      controller.setProperties({
        filesError: false,
        contactsError: false,
        kitError: false,
        shippingError: false,
        billingError: false
      });
    },

    setEditabilityFromClientState: function setEditabilityFromClientState(controller, sectionLastTouched) {
      //default all sections to uneditable
      ['files', 'contacts', 'kitted', 'notes', 'shipping', 'billing', 'checkout'].forEach(function (section) {
        controller.set(section + 'Editable', false);
      });

      //all sections up through the last user-touched section become editable
      ['files', 'contacts', 'kitted', 'notes', 'shipping', 'billing', 'checkout'].any(function (section) {
        if (sectionLastTouched !== section) {
          controller.set(section + 'Editable', true);
          return false;
        }
        if (sectionLastTouched === section) {
          controller.set(section + 'Editable', true);
          return true;
        }
      });

      //conditional to handle auto toggle of contacts if fileUpload transition is automatic.
      if (controller.get('autoTransitionToContacts')) {
        controller.set('contactsEditable', true);
        controller.set('showContacts', true);
        controller.set('showFiles', false);
      }
    },

    setStateOfProjectFiles: function setStateOfProjectFiles(controller, project) {
      var projectFile = controller.projectFile;
      var projectStatus = project.get('status');

      if (projectStatus === 'Order Placed' || projectStatus === 'Order In Review') {
        controller.set('filesEditable', _ember['default'].isEmpty(projectFile.get('fileName')) && !projectFile.get('filesReceivedOffline'));
      } else if (projectFile.get('fileName') || !projectFile.get('fileName') && projectFile.get('fileUploadDate')) {
        controller.set('filesEditable', true);
      } else {
        //if file form hasn't been filled out by user yet, sectionLastTouched will set showFiles to true;
        controller.set('filesEditable', true);
      }
    },

    actions: {
      //This action is required in the Route due to it's renderTempate call that is not available from the controller.
      checkoutComplete: function checkoutComplete(project) {
        var controller = this.controller;
        var model = project;
        var analyticsPayload = { action: 'purchase', category: 'Web Order', value: project.get('orderTotal'), orderNumber: project.get('orderNumber') };
        this.sendGA4Event(analyticsPayload); //Analytics Mixin
        this.sendMSAnalyticsEvent(analyticsPayload); //Analytics Mixin
        this.sendTaboolaAnalyticsEvent(analyticsPayload);

        // This was needed due to completed quotes still showing in the user/quotes index list even after checkout.
        // This will remove the record from the list if present.
        var projectsQuoteObject = this.store.peekRecord('quote', project.get('id'));
        if (projectsQuoteObject) {
          this.store.unloadRecord(projectsQuoteObject);
        }

        this.setupController(controller, model);
        this.renderTemplate(controller, model);
      },

      formalQuoteComplete: function formalQuoteComplete(project) {
        var controller = this.controller;
        var model = project;
        var analyticsPayload = { action: 'purchase', category: 'Formal Quote', value: project.get('orderTotal'), orderNumber: project.get('orderNumber') };
        this.sendGA4Event(analyticsPayload); //Analytics Mixin
        this.sendMSAnalyticsEvent(analyticsPayload);
        this.sendTaboolaAnalyticsEvent(analyticsPayload);
        this.setupController(controller, model);
        this.renderTemplate(controller, model);
      }
    }
  });
});