define("web-app-frontend/components/project-information", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    isValidSubmission: false,
    hasValidTurnTimes: true,

    cancel: 'cancel',
    findOrCreateAssemblyPricing: "findOrCreateNewQuoteAssemblyPricingObject",
    findOrCreateQuoteAssembly: "findOrCreateQuoteAssembly",
    isItarChanged: 'isItarChanged',
    setClientStateOnProject: 'setClientStateOnProject',
    toggleComponent: "toggleComponent",
    toggleCustomQuote: 'toggleCustomQuote',
    toggleSkip: "toggleSkip",
    validateForm: 'validateForm',
    saveAndValidateForm: 'saveAndValidateForm',
    customQuoteOpen: false,
    //Used to force recalculate fab and parts pricing in case quantity and ITAR property changes
    triggerProjectReprice: 'triggerProjectReprice',
    showProjectRepriceMessage: 'showProjectRepriceMessage',
    triggerProjectRepriceMessage: 'triggerProjectRepriceMessage',
    originalQuantity: 0,
    originalITAR: false,
    original_IsFlexBoard: false,
    isAssemblySubtotalExceed: false,
    openModal: 'openModal',
    isItarModified: false,

    productLifecycleOptions: [{ text: 'Select', value: null }, { text: 'Prototype', value: 'Prototype' }, { text: 'Production', value: 'Production' }, { text: 'Both (Prototype/Production)', value: 'Both (Prototype/Production)'
    }, { text: 'Budgetary Quote', value: 'Budgetary Quote' }],
    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    showQuantityAlert: (function () {
      if (this.model) {
        return this.get('model.quantity') >= 5000;
      }
    }).property('model.quantity'),

    reActivatePriceDifferences: (function () {
      if (this.model && this.model.get('skipCheckPriceDifferences') && this.get('showAssembly')) {
        this.model.set('skipCheckPriceDifferences', false);
      }
    }).observes('showAssembly'),

    validateOnSMTChange: (function () {
      // conditional prevents issue with continued validation loops
      if (this.model) {
        var changedAttrs = Object.keys(this.get('model').changedAttributes());
        if (changedAttrs.indexOf('hasDoubleSidedSMT') > -1) {
          this.send('validateQuoteAssemblyPricing');
        }
      }
    }).observes('model.hasDoubleSidedSMT'),

    validateOnThruHoleChange: (function () {
      // conditional prevents issue with continued validation loops
      if (this.model) {
        var changedAttrs = Object.keys(this.get('model').changedAttributes());
        if (changedAttrs.indexOf('hasDoubleSidedThruHole') > -1) {
          this.send('validateQuoteAssemblyPricing');
        }
      }
    }).observes('model.hasDoubleSidedThruHole'),

    validateOnShow: (function () {
      if (this.get('showProjectInformation')) {
        if (this.get('model.quantity')) {
          this.set('originalQuantity', this.get('model.quantity'));
          this.set('originalITAR', this.get('model.isITAR'));
          this.set('original_IsFlexBoard', this.get('model.isFlexBoard'));
        }
        this.send('validateQuoteAssemblyPricing');
      }
    }).observes('model', 'showProjectInformation').on('init'),

    shouldShowUpdate: (function () {
      return this.get('editing') && this.get('targetObject.partsTouched');
    }).property('editing', 'project.partsIncludedBoolean'),

    commitAssemblyQuote: function commitAssemblyQuote(_ref) {
      var action = _ref.action;
      var section = _ref.section;
      var param = _ref.param;

      var projectGUID = this.get('model.id');
      var self = this;
      var deferred = new _ember["default"].RSVP.defer('qAP saveAndPerformAction');

      self.sendAction('findOrCreateQuoteAssembly', projectGUID, false, deferred);

      deferred.promise.then(function () {
        //action used here is 'toggleSkip'
        self.send('performAction', action, section, param, projectGUID);
      })["catch"](function (errors) {
        console.log('saveAndPerformAction Failed', errors);
      })["finally"](function () {
        //Do not cancel spinner here, once the fab and parts repricing is done another function will cancel the spinner
        if (!self.get('triggerProjectReprice')) {
          self.get('quoteSubmitButton').send('canSubmit');
        }
      });
    },

    monitorAssemblySubTotal: (function () {
      this.set('isAssemblySubtotalExceed', false);
      if (this.model.get('errors.length') > 0 && this.model.get('errors').has('AssemblySubtotal')) {
        this.set('isAssemblySubtotalExceed', true);
      }
    }).observes('model.errors.length'),

    actions: {
      checkQuantityAndValidate: function checkQuantityAndValidate() {
        //Pass runValidation=false so that following action will not trigger Fab repricing
        //Fab will be recalculated on the update button click
        this.send('validateQuoteAssemblyPricing');
      },

      customQuoteEditing: function customQuoteEditing(isOpen) {
        this.set('customQuoteOpen', isOpen);
      },

      isItarChanged: function isItarChanged() {
        this.set('isItarModified', true);
        this.send('validateQuoteAssemblyPricing');
      },
      updateProductLifecycle: function updateProductLifecycle(selectedValue) {
        this.set('model.productLifecycle', selectedValue);
        this.send('validateQuoteAssemblyPricing');
      },
      isFlexBoardChanged: function isFlexBoardChanged() {
        if (this.get('model.isFlexBoard') === true) {
          this.set('model.customAssemblyQuote', true);
        } else {
          this.set('model.customAssemblyQuote', false);
        }
        this.send('validateQuoteAssemblyPricing');
      },

      performAction: function performAction(action, section, param, projectGUID) {
        var self = this;
        if (!this.get('targetObject').allSectionsHaveBeenTouched()) {
          //all 3 of these checks might be overkill but didn't have time to sleuth out what the other possibilities are.
          //when the action is to skip fab, client state will get set in delete fab and needs to occur last for correct api responses.
          var actionToSkipFab = action === 'toggleSkip' && section === 'fabrication' && param === true;
          if (!actionToSkipFab) {
            this.sendAction('setClientStateOnProject', section, projectGUID);
          }
          this.sendAction(action, section, param);
        } else {
          if (this.get('project.isCustomQuote')) {
            this.get('targetObject').set('customQuoteTouched', true);
            this.get('targetObject').set('showCustomQuote', true);
          }
        }

        if (this.get('triggerProjectReprice')) {
          var deferred = new _ember["default"].RSVP.defer('saveAndValidateForm deferred');
          this.sendAction('saveAndValidateForm', deferred);
          deferred.promise.then(function () {
            self.set('triggerProjectReprice', false);
            self.set('triggerProjectRepriceMessage', false);
            self.get('quoteSubmitButton').send('canSubmit');
            self.get('targetObject').set('showProjectInformation', false);
          })["catch"](function (errors) {
            console.log('saveAndValidateForm failed');
            console.log(errors);
            self.set('triggerProjectReprice', false);
            self.set('triggerProjectRepriceMessage', false);
            self.get('quoteSubmitButton').send('canSubmit');
          });
        } else {
          this.sendAction('validateForm');
          self.get('targetObject').set('showProjectInformation', false);
          self.get('targetObject').set('projectInformationTouched', true);
        }
      },

      validateQuoteFabricationPricing: function validateQuoteFabricationPricing(deferred) {
        if (this.project.get('userRemovedFab')) {
          return;
        }
        var quoteFabricationPricing = this.get('model');

        if (!quoteFabricationPricing || !this.get('fabricationTouched')) {
          console.log('no fabQuote present returning');return;
        }
        var self = this;

        //clear arg if it's a string value coming from radio box change
        if (typeof deferred === 'boolean' || typeof deferred === 'string') {
          deferred = null;
        }

        quoteFabricationPricing.generateHash();
        quoteFabricationPricing.setupToValidate();
        var matchingRecord = quoteFabricationPricing.findMatchingRecord();

        if (matchingRecord) {
          quoteFabricationPricing.mapMatchToBaseRecord(matchingRecord);
          this.validationSuccessEvents(deferred);
        } else {
          var cacheCopy = quoteFabricationPricing.createCacheCopy();
          cacheCopy.save().then(function (cacheCopy) {
            quoteFabricationPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
            self.validationSuccessEvents(deferred);
          })["catch"](function (errors) {
            console.log('validateQuoteFabricationPricing Failed', errors);
            cacheCopy.set('turnTime', cacheCopy.get('userSelectedTurnTime'));
            quoteFabricationPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
            cacheCopy.unloadRecord();
            self.rejectDeferred(deferred, errors);
          });
          return false;
        }
      },

      saveAndPerformAction: function saveAndPerformAction(action, section, param) {
        if (!this.get('shouldShowUpdate') && !this.get('isItarModified')) {
          var quoteSubmitButton = this.get('quoteSubmitButton');
          var self = this;
          var deferred = new _ember["default"].RSVP.defer('itar confirmation deferred');

          this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Export Controlled',
            subtext: 'You indicated that this project is not ITAR or Export controlled. Please click "OK" to confirm this, or click "Cancel" to go back and change your selection.',
            deferredObject: deferred });
          deferred.promise.then(function () {
            self.set('isItarModified', true);
            self.send('saveAssembly', action, section, param);
          }, function () {
            quoteSubmitButton.send('canSubmit');
          })["catch"](function (error) {
            console.log('Quote Assembly failed', error);
          });
        } else {
          this.send('saveAssembly', action, section, param);
        }
      },

      saveAssembly: function saveAssembly(action, section, param) {
        if (this.get('originalQuantity') !== 0 && (this.get('originalQuantity') !== this.get('model.quantity') || this.get('originalITAR') !== this.get('model.isITAR') || this.get('original_IsFlexBoard') !== this.get('model.isFlexBoard'))) {
          this.set('triggerProjectReprice', true);
          //Display message to user about delay
          this.set('triggerProjectRepriceMessage', true);
        }
        //no reason to make requests to calculate price difference tips because section collapses on success
        this.get('model').set('skipCheckPriceDifferences', true);
        //action name and params passed along via form's submit button
        this.send('validateQuoteAssemblyPricing', false, true, { action: action, section: section, param: param });
      },

      /* jshint ignore:start */
      validateQuoteAssemblyPricing: function validateQuoteAssemblyPricing(deferred, commit, paramsForSaveEvent) {
        if (deferred === undefined) deferred = false;
        if (commit === undefined) commit = false;

        var quoteAssemblyPricing = this.get('model');
        if (!quoteAssemblyPricing) {
          return;
        }

        // Radio Button triggers populate this value with a true boolean
        if (typeof deferred === 'boolean') {
          deferred = null;
        }

        //TODO: have Corinne check if this conditional is still working as intended
        if (!this.get('customQuoteOpen')) {
          var self = this;
          if (commit) {
            quoteAssemblyPricing.setupForCommit();
          } else {
            quoteAssemblyPricing.alterAttributes();
          }
          quoteAssemblyPricing.generateHash();
          var matchingRecord = quoteAssemblyPricing.findMatchingRecord();
          if (matchingRecord) {
            quoteAssemblyPricing.mapMatchToBaseRecord(matchingRecord);
            quoteAssemblyPricing.validationSuccessEvents();
            if (matchingRecord.hasValidTurnTimes()) {
              if (paramsForSaveEvent) {
                this.commitAssemblyQuote(paramsForSaveEvent);
              }
              if (deferred) {
                deferred.resolve(quoteAssemblyPricing);
              }
            } else {
              console.log('No valid assembly turntimes');
              if (deferred) {
                deferred.reject("No valid Assembly turntimes");
              }
              if (commit) {
                self.set('triggerProjectReprice', false);
                self.set('triggerProjectRepriceMessage', false);
                self.get('quoteSubmitButton').send('canSubmit');
              }
            }
          } else {
            var cacheCopy = quoteAssemblyPricing.createCacheCopy();
            cacheCopy.configureToRequestAllTurnTimes();
            cacheCopy.save().then(function (cacheCopy) {
              cacheCopy.selectTurnTime(); //handles rubberbanding and association of selected turntime attrs to form object.
              quoteAssemblyPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
              if (deferred) {
                deferred.resolve(quoteAssemblyPricing);
              }
              if (paramsForSaveEvent) {
                self.commitAssemblyQuote(paramsForSaveEvent);
              }
            })["catch"](function (errors) {
              console.log('validateQuoteAssemblyPricing failed', errors);
              //was set to empty string in adapter in order to retrieve all turn times
              cacheCopy.set('turnTimeCode', cacheCopy.get('userSelectedTurnTime'));
              cacheCopy.invalidateTurnTimes(quoteAssemblyPricing);
              quoteAssemblyPricing.mapFromCopyOnRequestFulfillment(cacheCopy);
              if (deferred) {
                deferred.reject(errors);
              }
              if (commit) {
                self.set('triggerProjectReprice', false);
                self.set('triggerProjectRepriceMessage', false);
                self.get('quoteSubmitButton').send('canSubmit');
              }
            })["finally"](function () {
              quoteAssemblyPricing.validationSuccessEvents();
            });
            return false;
          }
        } else {
          return false;
        }
      },
      /* jshint ignore:end */

      cancel: function cancel(controllerName) {
        this.send('validateQuoteAssemblyPricing');
        this.sendAction('cancel', this.get('model'), controllerName);
      }
    }
  });
});