define('web-app-frontend/models/user-credit-detail', ['exports', 'ember-data', 'web-app-frontend/mixins/credit-card'], function (exports, _emberData, _webAppFrontendMixinsCreditCard) {
  exports['default'] = _emberData['default'].Model.extend(_webAppFrontendMixinsCreditCard['default'], {
    // Attributes
    commitOnSuccess: _emberData['default'].attr("boolean"),
    creditCardExpirationMonth: _emberData['default'].attr('number'),
    creditCardExpirationYear: _emberData['default'].attr('number'),
    creditCardNumber: _emberData['default'].attr("string"),
    creditCardType: _emberData['default'].attr("string"),
    nameOnCard: _emberData['default'].attr("string"),
    userGUID: _emberData['default'].attr('string'),

    isEmpty: (function () {
      var creditCardNumber = this.get('creditCardNumber');
      var nameOnCard = this.get('nameOnCard');

      if (!creditCardNumber && !nameOnCard) {
        return true;
      } else {
        return false;
      }
    }).property('creditCardNumber', 'nameOnCard'),

    expirationDate: (function () {
      return this.get('creditCardExpirationMonth') + '/' + this.get('creditCardExpirationYear');
    }).property('creditCardExpirationMonth', 'creditCardExpirationYear'),
    availableCards: _emberData['default'].attr('array')
  });
});