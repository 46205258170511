define('web-app-frontend/serializers/project-shipping', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      addressValidationResult: { embedded: 'always' }
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, type, payload, id) {
      var projectShipping = payload.shippingAddress;
      projectShipping.id = id;
      projectShipping.project = id;

      var addressValidationResult = { id: id };
      if (payload.addressValidationResult) {
        addressValidationResult = payload.addressValidationResult;
        addressValidationResult.id = id;
        projectShipping.addressValidationResult = addressValidationResult;
      }

      var suggestions = [];
      if (addressValidationResult.addressSuggestions) {
        suggestions = addressValidationResult.addressSuggestions.data;
        suggestions.forEach(function (suggestion, index) {
          suggestion['id'] = index + 1;
        });
        addressValidationResult.suggestions = suggestions;
      }

      store.unloadAll('address-suggestion');
      payload = { projectShipping: projectShipping, addressValidationResult: addressValidationResult, addressSuggestions: suggestions };

      return this._super(store, type, payload, id);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var projectShipping = payload.shippingAddress;

      projectShipping.id = id;
      projectShipping.project = id;
      store.peekRecord('project', id).set('shipping', projectShipping);

      payload = { projectShipping: projectShipping };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});