define("web-app-frontend/adapters/bom", ["exports", "web-app-frontend/adapters/quoting-parent", "web-app-frontend/config/environment"], function (exports, _webAppFrontendAdaptersQuotingParent, _webAppFrontendConfigEnvironment) {
  exports["default"] = _webAppFrontendAdaptersQuotingParent["default"].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/bom';
      return this._super(url);
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    },

    queryRecord: function queryRecord(store, typeClass, query) {
      var bmhId = query.bomheaderId;
      var projectguid = query.projectGUID;

      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/bomheader/' + bmhId + '/' + projectguid;

      return this.ajax(url, "GET");
    },

    deleteRecord: function deleteRecord(store, type, snapshot) {
      var projectGUID = snapshot.record.get('id');
      return this.ajax(this.buildURL(type.modelName, projectGUID), 'DELETE');
    }
  });
});