define('web-app-frontend/routes/resource-center/tips/good-marking', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'Using Good Markings to Remove Ambiguity',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Learn what Screaming Circuits knows about marketing components on your printed circuits boards.'
      }
    }]
  });
});