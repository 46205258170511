define('web-app-frontend/routes/index', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'application',
    titleToken: "PCB Assembly On-Demand Manufacturing", //Special page title for the index page

    activate: function activate() {
      this._super.apply(this, arguments);
      _ember['default'].$('body').addClass('absolute-header');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('absolute-header');
    }
  });
});