define('web-app-frontend/adapters/user', ['exports', 'ember-data', 'web-app-frontend/adapters/application', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersApplication, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    shouldReloadRecord: function shouldReloadRecord() {
      return true;
    },

    pathForType: function pathForType() {
      return "user";
    },

    createRecord: function createRecord(store, type, snapshot) {
      var userGUID = snapshot.record.get('userGUID');
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      snapshot.id = userGUID;
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(this.buildURL(type.modelName, userGUID), "PUT", { data: data });
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var id = snapshot.record.get('id');
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      snapshot.id = id;
      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(this.buildURL(type.modelName, id), "PUT", { data: data });
    },

    ajax: function ajax(url, type, hash) {
      if (type === 'PUT') {
        url = url + "?Api-Key=SCWEB";
        return this._super(url, type, hash);
      } else {
        return this._super(url, type, hash);
      }
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors = undefined,
          response = undefined;
      if (status === 400) {
        response = payload;
        errors = {};
        if (response.user.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.user.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});