define('web-app-frontend/controllers/quoting/share-quote-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),

    actions: {
      shareQuote: function shareQuote() {
        var self = this;
        this.set('showSpinner', true);
        this.model.setupForCommit();
        this.model.save().then(function () {
          self.store.unloadAll('projectShare');
          self.get('applicationController').send('closeModal');
        })['catch'](function (error) {
          console.log('save shareQuote request failed', error);
        }).then(function () {
          self.set('showSpinner', false);
        });
      },

      closeModal: function closeModal() {
        this.store.unloadAll('projectShare');
        this.get('applicationController').send('closeModal');
      }
    }
  });
});