define('web-app-frontend/adapters/timeline-node', ['exports', 'web-app-frontend/adapters/quoting-parent'], function (exports, _webAppFrontendAdaptersQuotingParent) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/timeline';
      return this._super(url);
    },

    queryRecord: function queryRecord(store, typeClass, query) {
      var id = query.id;
      return this.ajax(this.buildURL(typeClass.modelName, id), "GET");
    }
  });
});