define('web-app-frontend/routes/resource-center/services/volume-production', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta', 'web-app-frontend/utils/caesar-shift', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta, _webAppFrontendUtilsCaesarShift, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'Volume Production',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Once your prototype is successful, you may need a manufacturing partner to build larger quantities.'
      }
    }],

    setupController: function setupController(controller) {
      var choiceProducationContact = this.store.createRecord('choiceProductionContact');
      var tokenDeferred = new _ember['default'].RSVP.defer('validation token');
      this.setSCContactValidationToken(tokenDeferred);
      tokenDeferred.promise.then(function (token) {
        choiceProducationContact.set('validationToken', token);
      });
      controller.set('model', choiceProducationContact);
      controller.set('isSubmitted', false);
    },

    setSCContactValidationToken: function setSCContactValidationToken(deferred) {
      var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/system/validationtoken?Api-Key=SCWEB';

      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json'
      }).done(function (data) {
        //Generate caesarShift cipher before storing token in the cookie
        var cipherText = (0, _webAppFrontendUtilsCaesarShift['default'])(data.model);
        deferred.resolve(cipherText);
      }).fail(function (errors) {
        console.log('sc validation token for choice-production-contact failed.', errors);
        deferred.reject();
      });
    },

    resetController: function resetController() {
      this.store.unloadAll('choiceProductionContact');
    },

    beforeModel: function beforeModel() {
      this.transitionTo('index');
    }
  });
});