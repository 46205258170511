define('web-app-frontend/services/store', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Store.extend({
    findOneQuery: function findOneQuery(type, id, query) {
      var store = this;
      var typeClass = store.modelFor(type);
      var adapter = store.adapterFor(typeClass);
      var serializer = store.serializerFor(typeClass);
      var url = adapter.buildURL(type, id);
      var ajaxPromise = adapter.ajax(url, 'GET', { data: query });

      return ajaxPromise.then(function (rawPayload) {
        var extractedPayload = serializer.extract(store, typeClass, rawPayload, id, 'find');
        return store.push(typeClass, extractedPayload);
      });
    },

    updateId: function updateId(internalModel, data) {
      //var oldId = internalModel.id + "";
      var id = this.coerceId(data.id);

      //assert("An adapter cannot assign a new id to a record that already has an id. " + internalModel + " had id: " + oldId + " and you tried to update it with " + id + ". This likely happened because your server returned data in response to a find or update that had a different id than the one you sent.", oldId === null || id === oldId);

      this.typeMapFor(internalModel.type).idToRecord[id] = internalModel;

      internalModel.setId(id);
    },

    coerceId: function coerceId(id) {
      return id == null || id === '' ? null : id + '';
    }

  });
});