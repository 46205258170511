define('web-app-frontend/adapters/project-promotion', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({

    buildURL: function buildURL(modelName, id) {
      var url = 'project/' + id + '/promotion';
      // super will pass to pathForType callback.
      return this._super(url);
    },

    createRecord: function createRecord(store, typeClass, snapshot) {
      var record = snapshot.record;
      var id = record.get('projectGUID');
      var payload = { projectPromotionModel: record };

      return this.ajax(this.buildURL(typeClass.modelName, id), 'POST', { data: payload });
    },

    //endpoint only supports POST method
    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var record = snapshot.record;
      var id = record.get('projectGUID');
      var payload = { projectPromotionModel: record };

      return this.ajax(this.buildURL(typeClass.modelName, id), 'POST', { data: payload });
    },

    deleteRecord: function deleteRecord(store, typeClass, snapshot) {
      var id = snapshot.record.get('projectGUID');
      var promotionCode = snapshot.attr('promotionCode');

      return this.ajax(this.buildURL(typeClass.modelName, id), 'DELETE', {}, { PromotionCode: promotionCode });
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      errors = [];
      response = payload;
      if (status === 400) {
        if (response.projectPromotionModel.errors != null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(response.projectPromotionModel.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else if (status === 404) {
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError("promotionCode", "Promo code not found", errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    }
  });
});