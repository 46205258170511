define("web-app-frontend/routes/upload", ["exports", "ember", "web-app-frontend/config/environment", "web-app-frontend/utils/caesar-shift"], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsCaesarShift) {
  exports["default"] = _ember["default"].Route.extend({
    session: _ember["default"].inject.service(),

    setupController: function setupController(controller) {
      var jobFile = this.store.createRecord('job-file');
      var tokenDeferred = new _ember["default"].RSVP.defer('validation token');
      var headers = this.fetchOauthToken();
      this.setSCContactValidationToken(headers, tokenDeferred);
      tokenDeferred.promise.then(function (token) {
        jobFile.set('validationToken', token);
      });
      controller.set('model', jobFile);
      controller.set('isSubmitted', false);
    },

    userPresent: function userPresent() {
      return this.get('session.isAuthenticated');
    },

    refreshToken: (function () {
      var self = this;
      console.log('in the refresh token method');
      var tokenDeferred = new _ember["default"].RSVP.defer('validation token');
      var headers = this.fetchOauthToken();
      this.setSCContactValidationToken(headers, tokenDeferred);
      tokenDeferred.promise.then(function (token) {
        self.controllerFor('upload').get('model').set('validationToken', token);
      });
    }).observes('session.isAuthenticated'),

    fetchOauthToken: function fetchOauthToken() {
      var tokenString = '';
      if (this.userPresent()) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    setSCContactValidationToken: function setSCContactValidationToken(headers, deferred) {
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/system/validationtoken?Api-Key=SCWEB';

      $.ajax({
        url: url,
        type: 'GET',
        headers: headers,
        dataType: 'json'
      }).done(function (data) {
        //Generate caesarShift cipher before storing token in the cookie
        var cipherText = (0, _webAppFrontendUtilsCaesarShift["default"])(data.model);
        deferred.resolve(cipherText);
      }).fail(function (errors) {
        console.log('sc validation token for upload job files failed.', errors);
        deferred.reject();
      });
    },

    resetController: function resetController() {
      this.store.unloadAll('job-file');
    }
  });
});