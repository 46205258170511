define('web-app-frontend/adapters/kit-box', ['exports', 'web-app-frontend/adapters/quoting-parent'], function (exports, _webAppFrontendAdaptersQuotingParent) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({

    generateIdForRecord: function generateIdForRecord() {
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0;
        return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
      });
      return uuid;
    }
  });
});