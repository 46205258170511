define('web-app-frontend/routes/resource-center/layout', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'web-app-frontend/utils/caesar-shift'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _webAppFrontendUtilsCaesarShift) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    title: 'Screaming Circuits | PCB Board Layout',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Tips and hints from Screaming Circuits to improve your electronic PCB layout.'
      }
    }],

    model: function model() {
      return this.store.createRecord('layoutQuote');
    },

    beforeModel: function beforeModel(transition) {
      // prevent enter quoting on API down
      if (this.controllerFor('application').get('isAPIDown')) {
        this.controllerFor('application').send('alertAPIDown');
        transition.abort();
      }
    },

    setupController: function setupController(controller, layoutQuote) {
      var id = layoutQuote.get('id');
      this.store.findRecord('layoutQuoteToken', id).then(function (layoutQuoteToken) {
        //Now that caesarShift is part of utility, layout-quote-token can be removed in the future
        var cipherText = (0, _webAppFrontendUtilsCaesarShift['default'])(layoutQuoteToken.get('token'));
        layoutQuoteToken.set('cipherText', cipherText);
        cipherText = layoutQuoteToken.get('cipherText');
        layoutQuote.set('validationToken', cipherText);
      })['catch'](function (errors) {
        console.log("GET layout-quote-token failed", errors);
      });

      controller.set('model', layoutQuote);
    }
  });
});