define('web-app-frontend/initializers/older-browsers', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = {
    name: 'older-browsers',
    after: ['store'],

    initialize: function initialize() {
      if (_ember['default'].$('html').is('.polyfill')) {
        // trim function for IE8
        String.prototype.trim = function () {
          return this.replace(/^\s+|\s+$/g, '');
        };

        // simple Object.keys polyfill that could work
        if (!Object.keys) {
          Object.keys = function (obj) {
            var keys = [];

            for (var i in obj) {
              if (obj.hasOwnProperty(i)) {
                keys.push(i);
              }
            }

            return keys;
          };
        }
      }
    }
  };
});