define("web-app-frontend/templates/modals/payment-modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 20
              },
              "end": {
                "line": 22,
                "column": 20
              }
            },
            "moduleName": "web-app-frontend/templates/modals/payment-modal.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "fa fa-spin fa-spinner mt10");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/modals/payment-modal.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "padded-10-20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row mb10 EBiz-modal");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("iframe");
          dom.setAttribute(el3, "class", "EBiz-modal__ebiz-iframe");
          dom.setAttribute(el3, "allowTransparency", "true");
          dom.setAttribute(el3, "frameborder", "0");
          dom.setAttribute(el3, "scrolling", "yes");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "EBiz-modal__bottom");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "alert alert-warning EBiz-modal__alert");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          dom.setAttribute(el5, "class", "EBiz-modal__alert-text");
          var el6 = dom.createTextNode("When adding a new credit card to our system, the card will go through a validation process. This means you might see a small pending charge on your credit card statement. This charge will only appear the first time a new card is validated and will be removed within a few days, depending on bank processing time.");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "EBiz-modal__actions-container");
          var el5 = dom.createTextNode("\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "checkbox");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("label");
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createComment("");
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("span");
          dom.setAttribute(el7, "class", "EBiz-modal__remember-payment");
          var el8 = dom.createTextNode("Remember Payment");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                    ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n                ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "EBiz-modal__close-btn");
          var el6 = dom.createTextNode("\n                    ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("a");
          var el7 = dom.createTextNode("Close");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("                ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3, 3]);
          var element3 = dom.childAt(element2, [3]);
          var element4 = dom.childAt(element3, [1]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          morphs[1] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 1, 1);
          morphs[2] = dom.createAttrMorph(element4, 'class');
          morphs[3] = dom.createElementMorph(element4);
          morphs[4] = dom.createMorphAt(element3, 3, 3);
          return morphs;
        },
        statements: [["attribute", "src", ["get", "model.paymentUrl", ["loc", [null, [5, 18], [5, 34]]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.orderBilling.rememberThisCard", ["loc", [null, [13, 52], [13, 87]]]]], [], []]], ["loc", [null, [13, 20], [13, 89]]]], ["attribute", "class", ["concat", ["btn muted ", ["subexpr", "if", [["get", "showSpinnerForCloseBtn", ["loc", [null, [19, 45], [19, 67]]]], "disabled", ""], [], ["loc", [null, [19, 40], [19, 83]]]]]]], ["element", "action", ["closePaymentModal"], [], ["loc", [null, [19, 85], [19, 116]]]], ["block", "if", [["get", "showSpinnerForCloseBtn", ["loc", [null, [20, 26], [20, 48]]]]], [], 0, null, ["loc", [null, [20, 20], [22, 27]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 20
          }
        },
        "moduleName": "web-app-frontend/templates/modals/payment-modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "modal-component", [], ["title", "Add a Card", "class", "payment-modal"], 0, null, ["loc", [null, [1, 0], [28, 20]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});