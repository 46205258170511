define("web-app-frontend/templates/components/order-kit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 4
            },
            "end": {
              "line": 17,
              "column": 4
            }
          },
          "moduleName": "web-app-frontend/templates/components/order-kit.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "kit-box", [], ["project", ["subexpr", "@mut", [["get", "project", ["loc", [null, [11, 16], [11, 23]]]]], [], []], "reloadTimeLine", ["subexpr", "@mut", [["get", "reloadTimeLine", ["loc", [null, [12, 23], [12, 37]]]]], [], []], "model", ["subexpr", "@mut", [["get", "box", ["loc", [null, [13, 14], [13, 17]]]]], [], []], "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [14, 30], [14, 51]]]]], [], []], "index", ["subexpr", "@mut", [["get", "index", ["loc", [null, [15, 14], [15, 19]]]]], [], []]], ["loc", [null, [10, 6], [16, 8]]]]],
        locals: ["box", "index"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 60,
              "column": 8
            },
            "end": {
              "line": 69,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/order-kit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "quoting-submit-button", [], ["text", "Update Kitted Information", "action", "collapse", "noDownArrow", true, "register-as", ["subexpr", "@mut", [["get", "quoteSubmitButton", ["loc", [null, [66, 24], [66, 41]]]]], [], []], "template", "complete"], ["loc", [null, [62, 10], [68, 12]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 8
            },
            "end": {
              "line": 76,
              "column": 8
            }
          },
          "moduleName": "web-app-frontend/templates/components/order-kit.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "quoting-submit-button", [], ["text", "Continue To Project Notes", "action", "toggleComponent", "template", "notes", "register-as", ["subexpr", "@mut", [["get", "quoteSubmitButton", ["loc", [null, [74, 24], [74, 41]]]]], [], []]], ["loc", [null, [70, 10], [75, 12]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 83,
            "column": 6
          }
        },
        "moduleName": "web-app-frontend/templates/components/order-kit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-11");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "padded-lr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("You have indicated that you will be shipping us parts and board. First\n        tell us when you think the last box will arrive. Then provide details\n        below for each box.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "padded");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn btn-blue addAdditionalBox");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "fa fa-plus mr5");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        Add another box");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "mt40 semi-bold");
        var el5 = dom.createTextNode("Important Note About Shipping to Screaming\n        Circuits");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("Please make sure all boxes have your\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "bold");
        dom.setAttribute(el5, "data-toggle", "tooltip");
        dom.setAttribute(el5, "data-placement", "top");
        var el6 = dom.createTextNode("order number");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        on them. When drop-shipping from a vendor, such as Digi-Key, place the\n        Screaming Circuits order number in the “order notes” or in the “Ship To\n        Address”.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "checkbox mt20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "semi-bold");
        var el6 = dom.createTextNode("Notice: Extra Parts for Attrition");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          I have read the");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        dom.setAttribute(el6, "class", "ml5");
        var el7 = dom.createTextNode("Screaming Circuits Attrition Policy");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          and understand the importance of providing additional parts for the\n          manufacturing process.\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "error-spacing");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "mt20 mb40");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        dom.setAttribute(el5, "class", "ib padded-sm ml10");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("a");
        var el7 = dom.createTextNode("Save and Close");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [5]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [5, 1]);
        var element5 = dom.childAt(element2, [7, 3]);
        var element6 = dom.childAt(element5, [3]);
        var element7 = dom.childAt(element2, [11]);
        var element8 = dom.childAt(element7, [3, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createAttrMorph(element4, 'title');
        morphs[4] = dom.createMorphAt(element5, 1, 1);
        morphs[5] = dom.createElementMorph(element6);
        morphs[6] = dom.createMorphAt(dom.childAt(element2, [9]), 1, 1);
        morphs[7] = dom.createMorphAt(element7, 1, 1);
        morphs[8] = dom.createElementMorph(element8);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["get", "showKitted", ["loc", [null, [1, 21], [1, 31]]]], "none"], [], ["loc", [null, [1, 12], [1, 40]]]], " row fade-transition kittedSection"]]], ["block", "each", [["get", "kittedBoxes", ["loc", [null, [9, 12], [9, 23]]]]], [], 0, null, ["loc", [null, [9, 4], [17, 13]]]], ["element", "action", ["addBox"], [], ["loc", [null, [20, 52], [20, 71]]]], ["attribute", "title", ["get", "project.orderNumber", ["loc", [null, [32, 18], [32, 37]]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "model.acceptAttritionPolicy", ["loc", [null, [40, 42], [40, 69]]]]], [], []]], ["loc", [null, [40, 10], [40, 71]]]], ["element", "action", ["openModal", "order.confirm-attrition-modal", ["get", "this.model", ["loc", [null, [43, 65], [43, 75]]]]], [], ["loc", [null, [43, 12], [43, 77]]]], ["inline", "error-display", [], ["tagName", "span", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [53, 16], [53, 21]]]]], [], []], "attribute", "acceptAttritionPolicy", "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [55, 32], [55, 53]]]]], [], []]], ["loc", [null, [51, 8], [56, 10]]]], ["block", "if", [["get", "isShowController", ["loc", [null, [60, 14], [60, 30]]]]], [], 1, 2, ["loc", [null, [60, 8], [76, 15]]]], ["element", "action", ["saveAndPerformAction", "close"], [], ["loc", [null, [78, 13], [78, 54]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});