define("web-app-frontend/templates/quoting/footer", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/quoting/footer.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "quote-footer", [], ["project", ["subexpr", "@mut", [["get", "project", ["loc", [null, [1, 23], [1, 30]]]]], [], []], "assembly", ["subexpr", "@mut", [["get", "assemblyPricing", ["loc", [null, [2, 24], [2, 39]]]]], [], []], "fabrication", ["subexpr", "@mut", [["get", "fabricationPricing", ["loc", [null, [3, 27], [3, 45]]]]], [], []], "toggleComponent", "toggleComponent", "focusInput", "focusInput", "fabricationTouched", ["subexpr", "@mut", [["get", "fabricationTouched", ["loc", [null, [6, 34], [6, 52]]]]], [], []], "partsTouched", ["subexpr", "@mut", [["get", "partsTouched", ["loc", [null, [7, 28], [7, 40]]]]], [], []], "showFooterError", ["subexpr", "@mut", [["get", "showFooterError", ["loc", [null, [8, 31], [8, 46]]]]], [], []], "saveForLater", "saveForLater"], ["loc", [null, [1, 0], [9, 44]]]]],
      locals: [],
      templates: []
    };
  })());
});