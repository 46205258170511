define('web-app-frontend/adapters/survey', ['exports', 'ember', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _ember, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },

    buildURL: function buildURL(type, id, surveyType) {
      var url = 'project/' + id + '/survey';
      if (!_ember['default'].isEmpty(surveyType)) {
        url = url + "/" + surveyType;
      }
      return this._super(url);
    },

    queryRecord: function queryRecord(store, typeClass, query) {
      var id = query.id;
      var surveyType = query.surveyType;
      return this.ajax(this.buildURL(typeClass.modelName, id, surveyType), "GET");
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id, null), "GET");
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var id = record.get('id');
      var projectGUID = record.get('projectGUID');
      var questions = record.get('questions');
      record.questions = [];
      questions.forEach(function (question) {
        record.questions.pushObject({ "svqQuestionTitle": question.get('svqQuestionTitle'),
          "svqQuestionText": question.get('svqQuestionText'),
          "svqCommentText": question.get('svqCommentText'),
          "svqThreshold": question.get('svqThreshold'),
          "svqScale": question.get('svqScale'),
          "svqRating": question.get('svqRating'),
          "svfFeedbackId": question.get('svfFeedbackId'),
          "svqType": question.get('svqType'),
          "svfUserComments": question.get('svfUserComments') });
      });
      var payload = { survey: record };
      return this.ajax(this.buildURL(type.modelName, projectGUID, id), "PUT", { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      errors = [];
      response = payload;
      delete payload.questions;
      if (status === 500) {
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError("base", "An error has occurred. Please give us a call at 1-866-784-5887 for assistance.", errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    }

  });
});