define('web-app-frontend/controllers/quoting/fabrication', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    quotingController: _ember['default'].inject.controller('quoting'),
    quotingNewController: _ember['default'].inject.controller('quoting/new'),
    projectController: _ember['default'].inject.controller('project'),

    createNewQuoteFabricationObject: function createNewQuoteFabricationObject(fabricationPricing, projectGUID, deferred) {
      var quoteFabrication = this.store.createRecord('quoteFabrication', { id: projectGUID,
        projectGUID: projectGUID });
      this.get('applicationController').updateChangedValues(quoteFabrication, fabricationPricing, deferred);
    },

    findOrCreateQuoteFabrication: function findOrCreateQuoteFabrication(projectGUID, deferred) {
      var store = this.store;
      var fabricationPricing = store.recordForId('quoteFabricationPricing', projectGUID);
      var fabricationRecordPresent = store.recordIsLoaded('quote-fabrication', projectGUID);
      if (fabricationRecordPresent) {
        var quoteFab = store.peekRecord('quoteFabrication', projectGUID);
        this.get('applicationController').updateChangedValues(quoteFab, fabricationPricing, deferred);
      } else {
        this.createNewQuoteFabricationObject(fabricationPricing, projectGUID, deferred);
      }
    },

    deleteFabricationQuote: function deleteFabricationQuote(fabRecord, parentDeferred) {
      var record;
      var self = this;
      var projectGUID = fabRecord.get('id');
      var deleteDeferred = new _ember['default'].RSVP.defer('deleteFab');

      //newly created record is in created.uncommitted state, and destroyRecord doesn't
      //initiate a DELETE request from that state, so faking it
      fabRecord.transitionTo('loaded.saved');
      fabRecord.destroyRecord().then(function () {
        fabRecord.unloadRecord();
        deleteDeferred.resolve();
        record = self.store.peekRecord('quote-fabrication-pricing', projectGUID);
        record.get('errors').clear();
      })['catch'](function (errors) {
        deleteDeferred.resolve();
        console.log('deleteFabricationQuote Failed', errors);
      });

      deleteDeferred.promise.then(function () {
        var project = self.store.peekRecord('project', projectGUID);
        project.set('clientState', 'fabrication');

        project.save().then(function () {
          parentDeferred.resolve();
        })['catch'](function (errors) {
          parentDeferred.reject();
          console.log('project reload failed', errors);
        });
      });
    }
  });
});