define('web-app-frontend/components/summary-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['mb40'],
    transitionAndScrollTo: 'transitionAndScrollTo',
    showShipDate: (function () {
      var shipTouch = this.get('shippingTouched');
      var estShip = this.get('estShipDate');
      return !_ember['default'].isEmpty(shipTouch) && !_ember['default'].isEmpty(estShip) && shipTouch === true;
    }).property('shippingTouched', 'estShipDate'),

    didInsertElement: function didInsertElement() {
      if ($(window).width() >= 992) {
        $('.sticky-column').stick_in_parent({
          offset_top: 10
        });
      }
    },

    actions: {
      transitionAndScrollTo: function transitionAndScrollTo(route, id) {
        this.sendAction('transitionAndScrollTo', route, id);
      }
    }
  });
});