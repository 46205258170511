define('web-app-frontend/components/order-files', ['exports', 'ember'], function (exports, _ember) {
	exports['default'] = _ember['default'].Component.extend({
		fileMissing: false,
		todayDate: moment().toDate(),
		twoMonths: moment().add(60, 'days').toDate(),

		close: 'close',
		closeModal: 'closeModal',
		collapse: 'collapse',
		disableFileEditability: 'disableFileEditability',
		openModal: 'openModal',
		setClientStateOnProject: 'setClientStateOnProject',
		setProjectFile: 'setProjectFile',
		setSectionErrorState: 'setSectionErrorState',
		toggleComponent: 'toggleComponent',
		reloadTimeLine: 'reloadTimeLine',

		clearFileMissingError: (function () {
			if (this.get('projectFile') && (this.get('projectFile.filesReceivedOffline') || this.get('projectFile.fileName') || this.get('projectFile.showDate') || this.get('projectFile.showOriginal'))) {
				this.set('projectFile.filesMissingError', false);
				this.send('setSectionErrorState', 'filesError', false);
			}
		}).observes('projectFile.fileName', 'projectFile.filesNow', 'projectFile.filesReceivedOffline'),

		setFileUploadDateOnChange: (function () {
			var projectFile = this.get('projectFile');
			if (projectFile) {
				if (projectFile.get('showDate') && projectFile.uploadDateHasPassed()) {
					console.log('updating file upload date to todays date');
					projectFile.set('fileUploadDate', this.get('todayDate'));
				}
			}
		}).observes('projectFile.filesNow'),

		saveForRecordWithUploadDate: function saveForRecordWithUploadDate(projectFile, action, actionParam) {
			var self = this;
			projectFile.alterAttributes();
			projectFile.formatDateForSave();
			projectFile.save().then(function () {
				if (action) {
					self.send('performAction', action, actionParam);
				}
				//Trigger timeline reload
				self.set('reloadTimeLine', true);
			})['catch'](function (errors) {
				console.log('order-files saveAndPerformAction failed', errors);
			});
		},

		setFileUploadDateOnInit: (function () {
			var projectFile = this.get('projectFile');
			if (projectFile) {
				if (projectFile.get('isOriginalFiles')) {
					projectFile.set('filesNow', 'Original');
				}
				if (projectFile && projectFile.get('fileUploadDate')) {
					if (projectFile.uploadDateHasPassed() && !projectFile.get('filesReceivedOffline') && projectFile.get('canUpdateFiles') && _ember['default'].isEmpty(projectFile.get('fileName'))) {
						projectFile.set('fileUploadDate', this.get('todayDate'));
						this.saveForRecordWithUploadDate(projectFile);
					} else {
						var previouslySelectedDate = projectFile.get('fileUploadDate');
						projectFile.set('fileUploadDate', previouslySelectedDate);
					}
				} else if (projectFile && !projectFile.get('fileUploadDate')) {
					projectFile.set('fileUploadDate', this.get('todayDate'));
				}
			}
		}).observes('projectFile').on('init'),

		actions: {
			disableFileEditability: function disableFileEditability() {
				this.sendAction('disableFileEditability');
			},

			performAction: function performAction(action, actionParam) {
				this.sendAction('setClientStateOnProject', actionParam);
				this.sendAction(action, actionParam);
			},

			saveAndPerformAction: function saveAndPerformAction(action, actionParam) {
				var projectFile = this.projectFile;
				var quoteSubmitButton = this.get('quoteSubmitButton');
				if (projectFile.get('showFile') && !projectFile.get('fileName') && !projectFile.get('filesReceivedOffline')) {
					this.set('projectFile.filesMissingError', true);
					this.send('setSectionErrorState', 'filesError', true);
				} else if (projectFile.get('showDate')) {
					//projectFile record gets saved on file upload, so this save only needs to be called if the user
					//has opted to provide the file at a future date, and it's saving the date to the record
					this.saveForRecordWithUploadDate(projectFile, action, actionParam);
				} else {
					//toggleComponent -> close out
					console.log('No save action for this option');
					this.send('performAction', action, actionParam);
				}
				quoteSubmitButton.send('canSubmit');
			},

			setClientStateOnProject: function setClientStateOnProject(clientState) {
				this.sendAction('setClientStateOnProject', clientState);
			},

			setProjectFile: function setProjectFile(projectFile) {
				this.sendAction('setProjectFile', projectFile);
			},

			setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
				this.sendAction('setSectionErrorState', sectionErrorStateName, state);
			},

			updateUploadText: function updateUploadText(fileName) {
				this.$().find('.faux-button-upload span.fileName')[0].innerHTML = fileName;
			}
		}
	});
});