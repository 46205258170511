define('web-app-frontend/models/design-violation', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    designViolationMessage: _emberData['default'].attr('string'),
    designViolationProperties: _emberData['default'].attr('array'),

    // Associations
    quoteAssembly: _emberData['default'].belongsTo('quote-assembly-pricing'),

    fabricationTurnTime: _emberData['default'].belongsTo('fabrication-turn-time', { async: false })
  });
});