define("web-app-frontend/serializers/project-billing", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload) {
      var record = payload.projectPaymentModel;
      record.id = record.projectGUID;
      record.project = record.projectGUID;
      if (payload.projectPaymentModel.availableCards) {
        if (payload.projectPaymentModel.availableCards.data) {
          record.availableCards = payload.projectPaymentModel.availableCards.data;
        }
      }

      if (record.creditCardNumberObfuscated) {
        record.cardOnFile = true;
        record.cardOnFileNumberObfuscated = record.creditCardNumberObfuscated;
        record.cardOnFileType = record.creditCardType;
      } else {
        record.cardOnFile = false;
        record.cardOnFileNumberObfuscated = "";
        record.cardOnFileType = "";
      }

      record.newCard = !record.cardOnFile;
      var data = { projectBilling: record };
      return this._super(store, typeClass, data);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var record = payload.projectPaymentModel;
      var currentPaymentRecord = store.peekRecord('projectBilling', id);

      if (payload.projectPaymentModel.availableCards) {
        if (payload.projectPaymentModel.availableCards.data) {
          record.availableCards = payload.projectPaymentModel.availableCards.data;
        }
      }

      record.id = id;
      record.project = record.projectGUID;
      //otherwise the number gets wiped from user view as soon as the first validation succeeds
      record.creditCardNumberPlain = currentPaymentRecord.get('creditCardNumberPlain');

      var data = { projectBilling: record };
      return this._super(store, typeClass, data);
    }
  });
});