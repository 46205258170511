define('web-app-frontend/routes/user/account/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    headTags: [{
      type: 'meta',
      tagId: 'meta-robots',
      attrs: {
        name: 'robots',
        content: 'noindex'
      }
    }],

    titleToken: 'Account',
    session: _ember['default'].inject.service(),

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    model: function model() {
      var store = this.get('store');
      //reload on a record won't work if the record is presently invalid (the request occurs, but the local
      //record won't be updated with the return data) so in lieu of a reload, we will fully unload and re-fetch.
      store.unloadAll('user');
      if (this.get('session.isAuthenticated')) {
        return store.findRecord('user', this.get('session.data.authenticated.UserGUID'));
      } else {
        this.transitionTo('/project-sign-in');
      }
    },

    setupController: function setupController(controller, user) {
      this._super(controller, user);

      controller.setProperties({
        user: user,
        originalLoginName: user.get('loginName')
      });
    }
  });
});