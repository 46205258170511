define('web-app-frontend/models/address-validation-result', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    addressValidationFlag: _emberData['default'].attr('string'),
    addressValidationErrorMessage: _emberData['default'].attr('string'),
    exemptionState: _emberData['default'].attr('string'),
    exemptionFormUrl: _emberData['default'].attr('string'),
    isAddressValidationError: _emberData['default'].attr('boolean'),
    isTaxValidationError: _emberData['default'].attr('boolean'),
    taxValidationErrorMessage: _emberData['default'].attr('string'),
    taxAlert: _emberData['default'].attr('boolean'),
    taxRate: _emberData['default'].attr('string'),
    taxRateRaw: _emberData['default'].attr('number'),
    taxAmount: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),

    // Associations
    projectShipping: _emberData['default'].belongsTo('project-shipping', { async: true }),
    suggestions: _emberData['default'].hasMany('address-suggestions', { async: true })
  });
});