define("web-app-frontend/adapters/application", ["exports", "ember", "ember-data", "web-app-frontend/config/environment"], function (exports, _ember, _emberData, _webAppFrontendConfigEnvironment) {
  exports["default"] = _emberData["default"].RESTAdapter.extend({
    host: _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl,
    namespace: 'api',
    session: _ember["default"].inject.service(),

    //Added to resolve deprecation
    //The default behavior of shouldReloadAll will change in Ember Data 2.0 to always return false when there is at least one record in the store.
    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    headers: (function () {
      if (localStorage['ember_simple_auth-session']) {
        return {
          'Authorization': 'Bearer ' + JSON.parse(localStorage['ember_simple_auth-session']).authenticated.access_token
        };
      }
    }).property('session.authenticated.access_token').volatile(),

    ajax: function ajax(url, type, options) {
      if (_ember["default"].isEmpty(options)) {
        options = {};
      }
      if (_ember["default"].isEmpty(options.data)) {
        options.data = {};
      }
      options.data['Api-Key'] = _ember["default"].ENV.client_id; // Add an access token param.
      return this._super(url, type, options);
    },

    //This hook is necessary to handle the logout functionality brought on by 400 response
    // for /token when a session cannot be renewed.
    ajaxError: function ajaxError(jqXHR) {
      var applicationController = _ember["default"].getOwner(this).lookup('controller:application');
      if (jqXHR.status === 401) {
        applicationController.send('authorizationFailed');
        //request failed.
      }
    },

    isInvalid: function isInvalid(status) {
      return status === 400;
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      if (status === 401) {
        var applicationController = _ember["default"].getOwner(this).lookup('controller:application');
        applicationController.send('authorizationFailed');
      }
      return this._super(status, headers, payload, requestData);
    }
  });
});
// import config from '../config/environment';