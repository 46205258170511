define('web-app-frontend/controllers/quoting/worksheet-selection', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    quotingController: _ember['default'].inject.controller('quoting'),
    showSpinner: false,

    actions: {
      hideSpinner: function hideSpinner() {
        this.set('showSpinner', false);
      },

      selectWorksheet: function selectWorksheet() {
        this.set('showSpinner', true);
        this.get('worksheetSelectBox').send('bomSetWorksheet');
      },

      cancelWorkSheetModal: function cancelWorkSheetModal() {
        var pricingComponent = this.get('quotingController').get('quotePartsPricingComponent');
        pricingComponent.set('showSpinner', false);
        pricingComponent.send('updateUploadText', 'No file chosen');
        this.send('closeModal');
      }
    }
  });
});