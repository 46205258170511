define('web-app-frontend/components/bom-save-button', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['btn', 'btn-primary', 'mr10'],
    attributeBindings: ['aria-hidden', 'data-dismiss'],
    tagName: 'button',
    closeModal: 'closeModal',
    triggerSummary: 'triggerSummary',
    toggleCustomQuote: 'toggleCustomQuote',
    toggleNeedToResolveErrors: 'toggleNeedToResolveErrors',
    session: _ember['default'].inject.service(),

    click: function click() {
      var self = this;
      var bom = this.get('bom');
      var bomLines = bom.get('bomLines'); // triggers promise value to chain .then() to below
      var data = {
        "projectBOMInputModel": {
          "bomHeaderId": bom.get('bomHeaderId'),
          "bomLines": []
        }
      };

      //start Spinner
      this.set('showSpinner', true);
      //resetBomModalFilters
      this.component.set('sortValue', 'default');
      this.component.set('filterValue', 'all');
      this.component.set('markAllValue', 'default');

      var buildDataDeferred = new _ember['default'].RSVP.defer('build bom data payload');
      bomLines.then(function (bomLines) {
        bomLines = bomLines.filterBy('bmlIsSelected', true);
        // FIRST: Have any bomlines been left with PromptCustomer values or Custprovide with mountType as unknown?
        if (bomLines.filterBy('sourcingAction', 'PromptCustomer').length > 0 || bomLines.filterBy('mountType', "Unknown").filterBy('sourcingAction', 'CustProvided').length > 0) {
          return false; //STOP there are parts that need to be resolved.
        } else {
            return bomLines;
          }
      }).then(function (bomLines) {
        bomLines.forEach(function (line) {
          data["projectBOMInputModel"]["bomLines"].pushObject({ "bmlId": line.get('id'), "sourcingAction": line.get('sourcingAction'), "mountType": line.get('mountType'), "lineNumber": line.get("lineNumber") });
        });

        buildDataDeferred.resolve(data);
      })['catch'](function () {
        self.sendAction('toggleNeedToResolveErrors', true); // Show error prompting user to resolve parts.
        self.set('showSpinner', false);
      });

      buildDataDeferred.promise.then(function (data) {
        var project = self.get('project');
        var uploadUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + project.get('id') + '/bom?Api-Key=SCWEB';
        var tokenString = '';
        if (self.get('session.isAuthenticated')) {
          tokenString = 'Bearer ' + self.get('session.data.authenticated.access_token');
        }
        uploadUrl = uploadUrl + "&isKittedBom=" + project.get('bomDetails.isKittedBom') + '&SessionGUID=' + $.cookie('sessionGUID');

        var oauthToken = { 'Authorization': tokenString };
        var saveBomDeferred = new _ember['default'].RSVP.defer('saveBom');
        self.saveBom(uploadUrl, oauthToken, data, project, saveBomDeferred);

        saveBomDeferred.promise.then(function (component) {
          // reload for association purposes...
          project.reload().then(function (project) {
            //totalPlacements, uniquePartCount, and thruHoleCount will be updated with the calculated values
            //from bom file. This is done to update those values in quoteAssemblyPricing model.
            var quoteAssemblyPricing = self.bom.store.peekRecord('quoteAssemblyPricing', project.get('id'));
            quoteAssemblyPricing.set('totalPlacements', project.get('assemblyDetails').get('totalPlacements'));
            quoteAssemblyPricing.set('uniquePartCount', project.get('assemblyDetails').get('uniquePartCount'));
            quoteAssemblyPricing.set('thruHoleCount', project.get('assemblyDetails').get('thruHoleCount'));

            // Parts will not be included if all parts are marked as "I will Provide".
            // No parts association is kept on the backed.
            if (project.get('partsIncludedBoolean')) {
              component.get('controller').sendAction('triggerSummary', bom); //confirmed
            }

            self.set('showSpinner', false);
            component.sendAction('closeModal');
          });
        });
      });
    },

    saveBom: function saveBom(uploadUrl, oauthToken, data, project, deferred) {
      var self = this;

      $.ajax({
        url: uploadUrl,
        type: 'PUT',
        headers: oauthToken,
        data: JSON.stringify(data),
        contentType: 'application/json; charset=UTF-8',
        cache: false,
        dataType: 'json'
      }).done(function () {
        //Upload done, close modal - bubbles up to application route
        deferred.resolve(self);
      }).fail(function (data, textStatus, errorThrown) {
        // Upload done, but errors encountered.
        console.log("Failed to  update Bom: ", errorThrown);
        console.log("Failed to  update Bom, text status: ", textStatus);
        console.log("Failed to  update Bom, data: ", data);
        self.set('showSpinner', false);

        if (data.status === 401) {
          var applicationController = _ember['default'].getOwner(self).lookup('controller:application');
          applicationController.send('authorizationFailed');
        }
      });
    }
  });
});