define('web-app-frontend/routes/user', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    headTags: [{
      type: 'meta',
      tagId: 'meta-prerender-status-code',
      attrs: {
        name: 'prerender-status-code',
        content: '401'
      }
    }, {
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Manage Quotes, Orders and Account from a single dashboard.'
      }
    }],

    titleToken: 'Project Box',

    activate: function activate() {
      _ember['default'].$('body').addClass('user');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('user');
    },

    beforeModel: function beforeModel(transition) {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
      // prevent enter quoting on API down
      if (this.controllerFor('application').get('isAPIDown')) {
        this.controllerFor('application').send('alertAPIDown');
        transition.abort();
      }
      // prevent from entering user route on old browsers
      else if (this.controllerFor('application').get('browserOld')) {
          this.controllerFor('application').send('alertOldBrowser');
          transition.abort();
        }
        // prevent enter quoting if cookies are disabled
        else if (this.controllerFor('application').get('isCookieDisabled')) {
            this.controllerFor('application').send('alertCookieDisabled');
            transition.abort();
          }
    }
  });
});