define('web-app-frontend/initializers/trackjs-service', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(container, application) {
    application.inject('route', 'trackjs', 'service:trackjs');
    application.inject('controller', 'trackjs', 'service:trackjs');
  }

  exports['default'] = {
    name: 'trackjs-service',
    initialize: initialize
  };
});