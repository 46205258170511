define('web-app-frontend/controllers/mixins/quoting-errors', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    showFooterError: false,
    assemblyError: false, //can we get these sent up from the form fields?
    fabricationError: false,

    updateFooterError: (function () {
      //add partsError, customQuoteError, pricingError as needed
      if (this.get('assemblyError') || this.get('fabricationError')) {
        this.set('showFooterError', true);
      } else {
        this.set('showFooterError', false);
      }
    }).observes('assemblyError', 'fabricationError'),

    updateAssemblyErrorState: (function () {
      var self = this;
      var assemblyPricing = this.assemblyPricing;
      if (assemblyPricing) {
        this.set('assemblyFormErrors', null);
        var errors = assemblyPricing.get('errors');

        if (errors && errors.get('length')) {
          var newErrors = "";

          errors.forEach(function (error) {
            var fieldHasBeenTouched = assemblyPricing.get('userAlteredAttributes').indexOf(error.attribute) > -1;
            var isBaseError = !assemblyPricing.get('constructor.attributes._keys.list').contains(error.attribute);

            if (fieldHasBeenTouched || isBaseError) {
              // This is to ensure that if an error is painted below a form field, we don't also display
              // at bottom of form, unless its assemblySubtotal which has no field input.
              if (!fieldHasBeenTouched || error.attribute === 'assemblySubtotal') {
                newErrors += error.message;
                self.set('assemblyFormErrors', newErrors);
              }
              self.set('assemblyError', true);
            } else {
              self.set('assemblyError', false);
            }
          });
        } else {
          this.set('assemblyError', false);
        }
      }
    }).observes('assemblyPricing.errors.length'),

    updateFabricationErrorState: (function () {
      var self = this;
      var fabricationPricing = this.fabricationPricing;
      if (fabricationPricing) {
        this.set('fabricationFormErrors', null);
        var errors = fabricationPricing.get('errors');

        if (errors && errors.get('length')) {
          var newErrors = "";

          errors.forEach(function (error) {
            var fieldHasBeenTouched = fabricationPricing.get('userAlteredAttributes').indexOf(error.attribute) > -1;
            var isBaseError = !fabricationPricing.get('constructor.attributes._keys.list').contains(error.attribute);
            var assemblyServiceAlert = errors.get('assemblyServiceAlert') && (error.attribute === 'height' || error.attribute === 'assemblyServiceAlert');

            if ((fieldHasBeenTouched || isBaseError) && !assemblyServiceAlert) {
              if (isBaseError) {
                newErrors += error.message;
                self.set('fabricationFormErrors', newErrors);
              }
              self.set('fabricationError', true);
            } else {
              self.set('fabricationError', false);
            }
          });
        } else {
          this.set('fabricationError', false);
        }
      }
    }).observes('fabricationPricing.errors.length')
  });
});