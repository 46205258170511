define('web-app-frontend/models/question', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attributes
    svqQuestionTitle: _emberData['default'].attr('string'),
    svqQuestionText: _emberData['default'].attr('string'),
    svqCommentText: _emberData['default'].attr('string'),
    svqThreshold: _emberData['default'].attr('string'),
    svqScale: _emberData['default'].attr('number'),
    svqRating: _emberData['default'].attr('number'),
    svfFeedbackId: _emberData['default'].attr('number'),
    svqQuestionId: _emberData['default'].attr('number'),
    svfUserComments: _emberData['default'].attr('string'),
    svfIsAnswered: _emberData['default'].attr("boolean"),
    commitOnSuccess: _emberData['default'].attr("boolean"),
    svqType: _emberData['default'].attr("string"),
    surveyId: _emberData['default'].attr('number'),
    displayRatings: _emberData['default'].attr('array'),
    showSavingSpinner: _emberData['default'].attr('boolean', { defaultValue: false }),
    isEditing: _emberData['default'].attr('boolean', { defaultValue: false }),
    displayUserComments: _emberData['default'].attr('boolean', { defaultValue: false }),
    updateDisplayUserComments: (function () {
      //Rating is below threshold
      var threshold = eval(this.get('svqRating') + this.get('svqThreshold'));
      //Rating is below threshold
      //OR comment is not empty
      //OR question is in edit mode
      //AND question has been answered
      var result = (threshold || !this.get('isCommentEmpty') || this.get('isEditing')) && this.get('svfIsAnswered');
      this.set('displayUserComments', result);
    }).observes('svqRating', 'isCommentEmpty', 'isEditing', 'svfIsAnswered'),

    updateDisplayRatings: (function () {
      var ratings = [];
      var fullStars = this.starRange(1, this.get('svqRating'), 'full');
      var emptyStars = this.starRange(this.get('svqRating') + 1, this.get('svqScale'), 'empty');
      Array.prototype.push.apply(ratings, fullStars);
      Array.prototype.push.apply(ratings, emptyStars);
      this.set('displayRatings', ratings);
    }).observes('svqRating'),

    starRange: function starRange(start, end, type) {
      var starsData = [];
      for (var i = start; i <= end; i++) {
        starsData.push({ rating: i, full: type === 'full' });
      }
      return starsData;
    },

    isCommentEmpty: (function () {
      return _ember['default'].isEmpty(this.get('svfUserComments'));
    }).property('svfUserComments'),

    isQuestionTextEmpty: (function () {
      return _ember['default'].isEmpty(this.get('svqQuestionText'));
    }).property('svqQuestionText'),

    showCommentReadOnly: (function () {
      if (this.get('isCommentEmpty')) {
        return false;
      } else {
        return !this.get('isEditing');
      }
    }).property('isCommentEmpty', 'isEditing')

  });
});