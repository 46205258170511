define('web-app-frontend/components/loadmore-pagination', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        openModal: 'openModal',
        transitionAndPrint: 'transitionAndPrint',
        duplicateProject: 'duplicateProject',

        hasNextPage: (function () {
            if (this.get('shouldPaginate')) {
                return true;
            } else {
                return false;
            }
        }).property('shouldPaginate'),

        actions: {
            loadMore: function loadMore() {
                var self = this;
                this.send('addSpinner');
                var deferred = new _ember['default'].RSVP.defer('Pagination');
                this.sendAction('loadMore', deferred);
                deferred.promise.then(function () {
                    self.send('removeSpinner');
                });
            },
            addSpinner: function addSpinner() {
                var icon = this.$().find('i');
                icon.removeClass();
                icon.addClass('fa fa-spinner fa-pulse mr5');
            },
            removeSpinner: function removeSpinner() {
                var icon = this.$().find('i');
                icon.removeClass('fa-spinner fa-pulse');
                icon.addClass('fa fa-angle-down mr5');
            },
            openModal: function openModal(modal, model) {
                this.sendAction('openModal', modal, model);
            },
            duplicateProject: function duplicateProject(projectGUID) {
                this.sendAction('duplicateProject', projectGUID);
            },
            transitionAndPrint: function transitionAndPrint(model) {
                this.sendAction('transitionAndPrint', model);
            }
        }

    });
});