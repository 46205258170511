define('web-app-frontend/serializers/bom-line', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeQueryResponse: function normalizeQueryResponse(store, typeClass, payload) {
      var id = payload.projectBOMModel.projGUID;
      var bomData = payload.projectBOMModel;
      var bomLineData = bomData.allBomLines.data;
      //let allLineIds = bomData.bomLines.data.map(x => x.bmlId);
      var bomLineIds = [];
      var bomLines = [];
      bomData.id = id;
      bomData.project = id;
      bomLineData.forEach(function (line) {
        var lineId = line.bmlId;
        bomLineIds.push(lineId);
        line.id = lineId;
        line.project = id;
        line.bom = id;
        // move reference data into top level
        Object.keys(line.resultReferenceData).forEach(function (key) {
          var value = line.resultReferenceData[key];
          line[key] = value;
        });
        // remove nested reference data
        delete line.resultReferenceData;
        bomLines.push(line);
        // store.pushPayload('bom-line', line);
      });

      bomData.bomLines = bomLineIds;
      payload = { bomLines: bomLines };
      return this._super(store, typeClass, payload, id);
    }
  });
});