define("web-app-frontend/adapters/quote", ["exports", "web-app-frontend/adapters/application"], function (exports, _webAppFrontendAdaptersApplication) {
  exports["default"] = _webAppFrontendAdaptersApplication["default"].extend({
    pathForType: function pathForType(type) {
      return type;
    },

    buildURL: function buildURL(userID, parameters) {
      var url = "user/" + userID + "/quotes";
      var keys = Object.keys(parameters);
      var queryURL = "";
      keys.forEach(function (key) {
        if (queryURL !== "") {
          queryURL = queryURL + "&";
        }
        queryURL = queryURL + key + '=' + parameters[key];
      });
      queryURL = url + "?" + queryURL;
      return this._super(queryURL);
    },

    query: function query(store, type, parameters) {
      var user = store.peekAll('user').get('firstObject');
      var userID = user.get('id');
      return this.ajax(this.buildURL(userID, parameters), 'GET');
    }

  });
});