define('web-app-frontend/components/custom-quote', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    collapse: 'collapse',
    openModal: 'openModal',
    saveForLater: 'saveForLater',
    setProjectFile: 'setProjectFile',
    toggleComponent: 'toggleComponent',
    toggleLockQuote: 'toggleLockQuote',
    transitionToRoute: 'transitionToRoute',

    checkout: function checkout(project) {
      var self = this;
      var store = self.get('targetObject.store');
      var checkoutObject = store.createRecord('project-checkout', { projectGUID: project.get('id'),
        commitOnSuccess: true });

      checkoutObject.save().then(function () {
        self.sendAction('collapse');
        self.sendAction('toggleLockQuote', true);
        //TODO: could create projectCheckout serializer to update project record with checkoutObject response instead of reloading
        project.reload();
      });
    },

    actions: {
      setProjectFile: function setProjectFile(projectFile) {
        this.sendAction('setProjectFile', projectFile);
      },

      saveForLater: function saveForLater(project) {
        this.sendAction('saveForLater', project);
        this.sendAction('transitionToRoute', 'user.quotes');
      },

      submitCustomQuote: function submitCustomQuote(project) {
        var self = this;
        var projectFile = self.get('projectFile');

        if (projectFile && !_ember['default'].isBlank(projectFile.get('fileName'))) {
          var confirmDeferred = new _ember['default'].RSVP.defer('custom quote confirmation');
          this.sendAction('openModal', 'modals/confirmation-modal', { text: 'Are you sure you are ready to submit this custom quote?',
            subtext: 'Once you submit your quote will be locked for further editing as our Customer Service Representatives work on pricing your quote',
            customSubmitText: 'Yes, submit custom quote',
            deferredObject: confirmDeferred
          });
          confirmDeferred.promise.then(function () {
            project.save().then(function () {
              self.checkout(project);
            });
          });
        } else {
          self.sendAction('openModal', 'modals/alert-modal', { text: 'In order to submit your quote, we will need a .ZIP copy of your project drawings.' });
        }
      },

      updateUploadText: function updateUploadText(fileName) {
        this.$().find('.faux-button-upload span.fileName')[0].innerHTML = fileName;
      }
    }
  });
});