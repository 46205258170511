define('web-app-frontend/components/shipping-label-link', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
		exports['default'] = _ember['default'].Component.extend({
				showLink: false,
				tagName: 'li',
				session: _ember['default'].inject.service(),

				updateShowLabel: (function () {
						if (!this.get('hasShippingAddress')) {
								return;
						}
						if (this.get('requiresKit') !== true) {
								return;
						} else {
								this.set('showLink', true);
						}
				}).observes('project').on('init'),

				actions: {
						printLabel: function printLabel(projectGUID) {
								var self = this;
								console.log('printing label');
								var windowHandle = window.open();
								var tokenString = '';
								tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
								var oauthToken = { 'Authorization': tokenString };
								var labelUrl = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/shippinglabel?Api-Key=SCWEB';
								$.ajax({
										url: labelUrl,
										type: 'POST',
										headers: oauthToken,
										cache: false,
										dataType: 'json',
										processData: false, // Don't process the files
										contentType: false }). // Set content type to false as jQuery will tell the server its a query string request
								done(function (data) {
										console.log('Generated shipping label PDF');
										console.log(data.projectShippingLabelModel.shippingLabelPDFLink);
										labelUrl = labelUrl + "&filename=" + data.projectShippingLabelModel.shippingLabelPDFLink;
										windowHandle.location.href = labelUrl;
								}).fail(function (data) {
										console.log('Shipping label PDF creation failed');
										console.log(data);
										windowHandle.close();

										if (data.status === 401) {
												var applicationController = _ember['default'].getOwner(self).lookup('controller:application');
												applicationController.send('authorizationFailed');
										}
								});
						}
				}
		});
});