define('web-app-frontend/serializers/shipping-address', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeResponse: function normalizeResponse(store, typeClass, payload) {
      var shippingAddresses = [];
      var shippingAddress = payload.shippingAddress;

      delete shippingAddress.type;
      delete shippingAddress.errors;

      payload = { shippingAddress: [shippingAddress] };
      store.pushPayload('shippingAddress', payload);
      shippingAddresses.push(shippingAddress);

      return shippingAddresses;
    }
  });
});