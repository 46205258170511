define('web-app-frontend/initializers/custom-elements', ['exports'], function (exports) {
  exports['default'] = {
    name: "custom-elements",
    initialize: function initialize(application) {
      application.deferReadiness();
      document.createElement('sc-order');
      $('body').on('click', 'sc-order', function () {
        window.location.href = '/user/order/' + $(this).data('id');
        //alert('Not yet implemented');
      });
      document.createElement('sc-quote');
      $('body').on('click', 'sc-quote', function () {
        window.location.href = '/quote/' + $(this).data('id') + '/edit';
      });
      application.advanceReadiness();
    }
  };
});