define('web-app-frontend/models/assembly-price-difference', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblySubtotal: _emberData['default'].attr('string'),
    hasBGA: _emberData['default'].attr('boolean'),
    hasClass3: _emberData['default'].attr('boolean'),
    hasDoubleSidedSMT: _emberData['default'].attr('boolean'),
    hasDoubleSidedThruHole: _emberData['default'].attr('boolean'),
    hasLeadFree: _emberData['default'].attr('boolean'),
    isITAR: _emberData['default'].attr('boolean'),
    productLifecycle: _emberData['default'].attr('string'),
    projectGUID: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    totalPlacements: _emberData['default'].attr('number'),
    thruHoleCount: _emberData['default'].attr('number'),
    turnTimeCode: _emberData['default'].attr('string'),
    uniquePartCount: _emberData['default'].attr('number'),

    keysToSerialize: ["hasBGA", "hasClass3", "hasDoubleSidedSMT", "hasDoubleSidedThruHole", "hasLeadFree", "isITAR", "productLifecycle", "projectGUID", "quantity", "thruHoleCount", "totalPlacements", "turnTimeCode", "uniquePartCount"]
  });
});