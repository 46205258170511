define("web-app-frontend/adapters/shipping-address", ["exports", "web-app-frontend/adapters/application"], function (exports, _webAppFrontendAdaptersApplication) {
  exports["default"] = _webAppFrontendAdaptersApplication["default"].extend({
    pathForType: function pathForType() {
      var userID = JSON.parse(localStorage['ember_simple_auth-session']).authenticated.UserGUID;
      return "user/" + userID + "/shippingaddress";
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var payload = {};
      var serializer = store.serializerFor(type.modelName);

      serializer.serializeIntoHash(payload, type, record, { includeId: true });

      return this.ajax(this.buildURL(type.modelName), 'PUT', { payload: payload });
    },

    ajax: function ajax(url, type, hash) {
      url = url + "?Api-Key=SCWEB";
      return this._super(url, type, hash);
    }
  });
});