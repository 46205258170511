define('web-app-frontend/routes/user/account/contacts', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Contacts',

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    model: function model() {
      var store = this.get('store');
      store.unloadAll('userEmailAddress');
      return store.findAll('userEmailAddress');
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var contacts = model;

      controller.setProperties({
        contacts: contacts
      });
    }
  });
});