define('web-app-frontend/components/project-notes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    toggleComponent: 'toggleComponent',
    collapse: 'collapse',
    close: 'close',
    setClientStateOnProject: 'setClientStateOnProject',
    setSectionErrorState: 'setSectionErrorState',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    actions: {
      performAction: function performAction(action, actionParam) {
        this.sendAction('setClientStateOnProject', actionParam);
        this.sendAction(action, actionParam);
      },

      preventValidationOnToggle: function preventValidationOnToggle() {
        this.set('preventValidationOnToggle', true);
      },

      saveAndPerformAction: function saveAndPerformAction(action, actionParam) {
        var projectNotes = this.model;
        var self = this;
        var quoteSubmitButton = this.get('quoteSubmitButton');

        projectNotes.setupForCommit();
        projectNotes.save().then(function () {
          self.send('performAction', action, actionParam);
        })['catch'](function (errors) {
          console.log('saveAndPerformAction Failed', errors);
        })['finally'](function () {
          quoteSubmitButton.send('canSubmit');
          projectNotes.set('commitOnSuccess', false);
        });
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      },

      validateProjectNotes: function validateProjectNotes() {
        if (!this.get('preventValidationOnToggle')) {
          var projectNotes = this.model;
          projectNotes.alterAttributes();
          projectNotes.save().then(function () {})['catch'](function (error) {
            console.log('validate project notes failed', error);
          });
        } else {
          this.set('preventValidationOnToggle', false);
        }
      }
    }
  });
});