define('web-app-frontend/models/project-turn-time', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyServiceName: _emberData['default'].attr('string'),
    assemblyTotal: _emberData['default'].attr('number'),
    assemblySubTotal: _emberData['default'].attr('number'),
    assemblyTurnDays: _emberData['default'].attr('number'),
    assemblyTurnTimeCode: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    hasAssembly: _emberData['default'].attr('boolean'),
    fabExpedite: _emberData['default'].attr('string'),
    fabServiceName: _emberData['default'].attr('string'),
    fabSystemLiveStatus: _emberData['default'].attr('boolean'),
    fabTotal: _emberData['default'].attr('number'),
    fabSubTotal: _emberData['default'].attr('number'),
    fabTurnDays: _emberData['default'].attr('number'),
    fabTurnTimeCode: _emberData['default'].attr('string'),
    hasFab: _emberData['default'].attr('boolean'),
    hasParts: _emberData['default'].attr('boolean'),
    isSelected: _emberData['default'].attr('boolean'),
    partsCount: _emberData['default'].attr('number'),
    partsTotal: _emberData['default'].attr('number'),
    perBoardPrice: _emberData['default'].attr('number'),
    projectGUID: _emberData['default'].attr('string'),
    projectTotal: _emberData['default'].attr('number'),
    quantity: _emberData['default'].attr('number'),
    totalTurnDays: _emberData['default'].attr('number'),
    type: _emberData['default'].attr('string'),
    upgradeFabRouting: _emberData['default'].attr('boolean', { defaultValue: false }),

    taxShippingEstimate: _emberData['default'].belongsTo('tax-shipping-estimate', { async: true }),
    userAlertMessage: _emberData['default'].belongsTo('user-alert-message', { async: false }),
    customFabQuote: _emberData['default'].attr('boolean'),
    showCustomTurnTimeHeader: _emberData['default'].attr('boolean'),

    project: _emberData['default'].belongsTo('project', { async: true }),

    hasUserAlert: (function () {
      return !!this.get('userAlertMessage');
    }).property('userAlertMessage'),

    isSourcingParts: (function () {
      return this.get('partsCount') > 0;
    }).property('partsCount'),

    fabSkipped: (function () {
      return !this.get('hasFab') || false;
    }).property('hasFab'),

    isFullProto: (function () {
      return this.get('assemblyServiceName') === 'Full Proto';
    }).property('assemblyServiceName'),

    isShortRun: (function () {
      return this.get('assemblyServiceName') === 'Short-Run';
    }).property('assemblyServiceName'),

    isShortRunAndCustProvidedParts: (function () {
      return this.get('isShortRun') && this.get('custProvidedParts');
    }).property('assemblyServiceName', 'custProvidedParts'),

    custProvidedParts: (function () {
      return this.get('partsSkipped') || this.get('project.bomDetails.customerWillProvideParts');
    }).property('partsSkipped', 'project.bomDetails.customerWillProvideParts'),

    partsSkipped: (function () {
      return !this.get('hasParts') || false;
    }).property('hasParts'),

    hasPartsAndNoFab: (function () {
      return !this.get('partsSkipped') && this.get('fabSkipped');
    }).property('partsSkipped', 'fabSkipped'),

    requiresPricingConfirmation: function requiresPricingConfirmation() {
      return this.get('fabSkipped') || this.get('isShortRunAndCustProvidedParts');
    }
  });
});