define("web-app-frontend/controllers/quoting/parts", ["exports", "ember", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend({
    quotingController: _ember["default"].inject.controller('quoting'),
    session: _ember["default"].inject.service(),

    deletePartsAndClearBOM: function deletePartsAndClearBOM(partsRecord, controller, parentDeferred) {
      var self = this;
      var store = this.store;
      var projectGUID = partsRecord.get('id');
      var deleteDeferred = new _ember["default"].RSVP.defer('deleteParts');
      var clearBOMDeferred = new _ember["default"].RSVP.defer('Clear BOM');
      var reloadDeferred = new _ember["default"].RSVP.defer('reloadProject');

      //newly created record is in created.uncommitted state, and destroyRecord doesn't
      //initiate a DELETE request from that state, so faking it, because record
      //bom exists on the server after
      partsRecord.transitionTo('loaded.saved');

      partsRecord.destroyRecord().then(function () {
        console.log('parts record has been destroyed');
        store.unloadAll('bom');
        store.unloadAll('bom-line');

        // remove bom from quoting controller.
        if (controller) {
          controller.set('bom', null);
        }
        deleteDeferred.resolve();
      })["catch"](function (errors) {
        console.log('deletePartsAndClearBOM Failed', errors);
        if (parentDeferred) {
          parentDeferred.reject();
        }
      });

      deleteDeferred.promise.then(function () {
        self.postToClearBomEndpoint(projectGUID, clearBOMDeferred);
      });

      clearBOMDeferred.promise.then(function () {
        console.log('BOM HAS BEEN CLEARED');

        var project = store.peekRecord('project', projectGUID);
        project.set('clientState', 'parts');
        project.save().then(function () {
          reloadDeferred.resolve();

          //partsIncluded gets set to false though user has selected to upload new bom.
          //temp: state maintained server side so shouldn't be setting directly here
          //hoping for way to set this on the endpoint, SC-698
          //project.set('partsIncluded', 'Unknown');

          // resolve parent deferred after project has been reloaded.
          if (parentDeferred) {
            parentDeferred.resolve();
          }
        });
      });
    },

    postToSkipBomEndpoint: function postToSkipBomEndpoint(projectGUID, customValue, deferred) {
      var self = this;
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/skipbom?custom=' + customValue + '&Api-Key=SCWEB';
      var authorization;
      if (this.get('session.isAuthenticated')) {
        authorization = { 'Authorization': 'Bearer ' + this.get('session.data.authenticated.access_token') };
      }
      url = url + '&SessionGUID=' + $.cookie('sessionGUID');

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'text',
        headers: authorization
      }).done(function () {
        //available params data, textStatus, jqXHR
        console.log('skip bom ajax succeed');
        if (deferred) {
          deferred.resolve();
        }
      }).fail(function (data) {
        //available params jqXHR, textStatus, errorThrown
        console.log('skip bom ajax fail');
        if (deferred) {
          deferred.reject();
        }

        if (data.status === 401) {
          var applicationController = _ember["default"].getOwner(self).lookup('controller:application');
          applicationController.send('authorizationFailed');
        }
      });
    },

    postToClearBomEndpoint: function postToClearBomEndpoint(projectGUID, deferred) {
      var self = this;
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/' + projectGUID + '/clearbom?&Api-Key=SCWEB';
      var authorization;
      if (this.get('session.isAuthenticated')) {
        authorization = { 'Authorization': 'Bearer ' + this.get('session.data.authenticated.access_token') };
      }
      url = url + '&SessionGUID=' + $.cookie('sessionGUID');

      $.ajax({
        url: url,
        type: 'POST',
        dataType: 'text',
        headers: authorization
      }).done(function () {
        //available params data, textStatus, jqXHR
        console.log('Clear bom ajax succeed');
        if (deferred) {
          deferred.resolve();
        }
      }).fail(function (data) {
        //available params jqXHR, textStatus, errorThrown
        console.log('Clear bom ajax fail');
        if (deferred) {
          deferred.reject();
        }

        if (data.status === 401) {
          var applicationController = _ember["default"].getOwner(self).lookup('controller:application');
          applicationController.send('authorizationFailed');
        }
      });
    }

  });
});