define('web-app-frontend/routes/application', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'web-app-frontend/mixins/analytics', 'web-app-frontend/config/environment', 'web-app-frontend/utils/hubspot-utils'], function (exports, _ember, _emberSimpleAuthMixinsApplicationRouteMixin, _webAppFrontendMixinsAnalytics, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsHubspotUtils) {
		exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsAnalytics['default'], _emberSimpleAuthMixinsApplicationRouteMixin['default'], {
				session: _ember['default'].inject.service(),

				headTags: [{
						type: 'meta',
						tagId: 'meta-description',
						attrs: {
								name: 'description',
								content: 'Electronic PCB assembly and prototyping service is now easy and fast with Screaming Circuits. Get quotes for SMT, BGA, QFN and Fine Pitch PCB prototypes and assemblies now and order online.'
						}
				}, {
						type: 'meta',
						tagId: 'meta-robots',
						attrs: {
								name: 'robots',
								content: _webAppFrontendConfigEnvironment['default'].environmentIsProduction ? 'index, follow' : 'noindex, nofollow'
						}
				}],

				chunkSubstr: function chunkSubstr(str, size) {
						var numChunks = Math.ceil(str.length / size);
						var chunks = new Array(numChunks);
						for (var i = 0, o = 0; i < numChunks; ++i, o += size) {
								chunks[i] = str.substr(o, size);
						}
						return chunks;
				},

				sendDataToTrackJS: function sendDataToTrackJS(key, error, data) {
						if (window.trackJs) {
								window.trackJs.track("heartbeat API failed: " + error);
								if (!_ember['default'].isEmpty(data)) {
										var bodyStart, bodyEnd, bodyData;
										bodyStart = data.indexOf('<body');

										if (bodyStart === -1) {
												bodyData = data;
										} else {
												bodyEnd = data.indexOf('</body>');
												if (bodyStart === -1) {
														bodyData = data.substring(bodyStart);
												} else {
														bodyData = data.substring(bodyStart, bodyEnd + 7);
												}
										}
										var chunks = this.chunkSubstr(bodyData, 40000);
										for (var i = 0; i < chunks.length; i++) {
												window.trackJs.track("BLOCKED API ERROR: " + key + " Chunk " + (i + 1) + " of " + chunks.length + ":" + chunks[i]);
										}
								}
						}
				},

				setupController: function setupController(controller) {
						console.log('in application setup controller');

						var self = this;
						var random = Math.random().toString(36).substring(2);
						var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/system/heartbeat?Api-Key=SCWEB&query=' + random;
						$.ajax({
								url: url,
								type: 'GET',
								dataType: 'text',
								timeout: 10000
						}).done(function (data) {
								if (data.indexOf(random) === -1) {
										_ember['default'].run(function () {
												controller.set('isAPIDown', true);
												self.sendDataToTrackJS(random, "mismatched key", data);
										});
								}
						}).fail(function ($xhr) {
								_ember['default'].run(function () {
										self.sendDataToTrackJS(random, $xhr.statusText, $xhr.responseText);
										controller.set('isAPIDown', true);
								});
						});
				},

				model: function model() {
						//Called only once on the setup of the App.
						//This code is useful if, when the app is started, the user's login credentials have not yet timed out

						//Let's check to see if we can bother the customer with an Inspectlet invite...
						this._runInspectletOptin();

						var userGUID = this.get('session.data.authenticated.UserGUID');
						if (typeof userGUID !== 'undefined') {
								var self = this;
								this.store.findRecord('user', userGUID).then(function () {
										self._updateTagManagerWithLogin();
								});
						}
				},

				//beforeTransition: function() {
				//	console.log("******************************** beforeTransition: routes/application/beforeTransition *********************************");		
				//},

				//beforeModel: function () {
				//	console.log("******************************** beforeModel: routes/application/beforeModel *********************************");

				//LOOKS LIKE THIS ONLY GETS HIT ON THE FIRST LOAD? Yes, indeed, being the master route it is never hit again. The deepest child
				// route is the only one which fires when there is a transition.
				// But this still might be useful, as it is only outside deep links that are going to bring us to into the app...
				// for now... but down the road we want to show activity and let folks click on the activity and log in if needed. So that is
				// an internal deep link that would need to be handled at that point in time.

				//One option is to have secure routes inherit from a different base than route. Something like this: http://jsfiddle.net/NQKvy/670/
				//Another option is to use a mixin to check the authentication
				// See discussion here: (http://discuss.emberjs.com/t/specifying-whether-or-not-routes-should-check-for-authentication/4097)
				//I think only the user/ and project/ routes will be checked, as those will be fired once while navigating into them. Once inside,
				//navigating between will be fine as we'll already be checked. Deep links into the app will fire these no matter what the sub-route
				//as it will route through these leaves.
				//This application route is too high up as it contains both public and private pages.
				//

				//Note here is an article which includes an AuthenticatedRoute which can be inherited rather than Route. https://webcloud.info/blog/2014/04/07/emberjs-authentication-the-right-way-javascript-version/

				//So... In each of those routes, check for authentication, if it isn't authenticated, store the URL then forward to an
				//authentication page (preferrably a blank page with a spinner and a pop-up login dialogue so it looks like we're just
				//waiting on authentication before loading the page), then transition back to the originally stored URL. If the pop-up is
				//closed by the user we should transition to the logged-out project box page.

				//We are using the Ember Simple Auth add on, which technically just needs to add a mixin to each route that should be secure. It
				//should reroute to the login page and then back to the original page after login (at least that is the default behavior)  

				//Need to figure out how to determine if I am in the Project Box while in beforeModel...and how do I pop a dialogue on a page
				//which hasn't loaded yet? Do I need to transition to a dialogue only page first, then back again?
				//var control = this.get('controller'); //Undefined at this point...
				//var foo = this.get('controller.currentPath'); //Undefined at this point...
				//var goo = this.get('controller.currentRouteName'); //Undefined at this point....
				//var moo = this.get('router.url'); //works.... "/user/activity" or "/project/a20bb568-5ef1-4a17-a906-eff44dd8fc64/quote/edit"

				//var foo = this.currentRouteInProjectBox();
				//var bar = this.get('session.isAuthenticated'); //This works! Returns true or false

				//var currentURL = this.get('router.url')
				//if (currentURL.match(/^\/user\/|^\/project\//i)) { //TODO: Keeps /quote/ open for anonymous quoting
				//	console.log("******************************** We are in a secure area *********************************")
				//var authenticated = bar ? "We are autenticated" : "We are not authenticated";
				//consolelog(authenticated);
				//Can I transition out of here?
				//Can I route back to the URL later? We don't have a full route object yet. That needs to come from higher up the chain.
				//}

				//},

				_updateTagManagerWithLogin: function _updateTagManagerWithLogin() {
						//User info push to Google Analytics , inspectlet and TrackJs, fires when model is first loaded if user is previously logged in,
						//or on login

						//Skip entirely if this is not production
						if (!_webAppFrontendConfigEnvironment['default'].environmentIsProduction) {
								return;
						}

						//If GAUserID already exists on the dataLayer then we don't need to do anything here, it has already been done.
						if (window.dataLayer && window.dataLayer.findBy('GAUserID')) {
								return;
						}

						//Load UUID, check that it is legit, if not return.
						var thisCustomerUUID = this.get('session.data.authenticated.UserGUID');
						if (typeof thisCustomerUUID === 'undefined') {
								return;
						}

						this.store.findRecord('user', thisCustomerUUID).then(function (user) {

								var userEmail = user.get('emailAddress');
								var userLogin = user.get('loginName');
								var userName = user.get('firstName') + ' ' + user.get('lastName');
								//Customer's UUID is avalable through closure via thisCustomerUUID

								//Push to Google Tag Manager (for mapping to Google Analytics)
								window.dataLayer.push({
										'GAUserID': thisCustomerUUID,
										'GAdimension1': thisCustomerUUID
								});

								//Push directly to Inspectlet if loaded (not a simple way to do this in Tag Manager)
								if (typeof __insp === "object") {
										__insp.push(['tagSession', { login: userLogin, email: userEmail, name: userName }]); // jshint ignore:line
										__insp.push(['identify', userName]); // jshint ignore:line
								}

								//Push user details to TracJs (Later move this to Tag Manager)
								if (window.trackJs) {
										window.trackJs.configure({
												userId: userLogin
										});
								}
						});
				},

				title: function title(tokens) {
						//Sets page title automatically based on the route name
						//To override create a titleToken property on the route you want explicitly named
						var basic = 'Screaming Circuits';
						var hasTokens = tokens && tokens.length;

						var titleText = '';

						if (hasTokens) {
								var indexOfCurrentRouteTitle = 0;

								if (tokens.length > 1) {
										indexOfCurrentRouteTitle = tokens.length - 1;
								}

								titleText = tokens[indexOfCurrentRouteTitle] + ' - ' + basic;
						} else {
								titleText = basic;
						}

						return titleText;
				},

				associateUserWithSession: function associateUserWithSession() {
						var userGUID = this.get('session.data.authenticated.UserGUID');
						var self = this;
						this.store.findRecord('user', userGUID).then(function (user) {
								self.get('session').set('data.user', user);
						});
				},

				postAssociationUserSessionToApi: function postAssociationUserSessionToApi(deferred) {
						// deferred sent from sessionAuthenticationSucceeded();
						var userSession = this.store.createRecord('userSession', {
								id: this.get('session.data.authenticated.UserGUID'),
								sessionGUID: $.cookie('sessionGUID')
						});

						var self = this;
						userSession.save().then(function () {

								if (deferred) {
										deferred.resolve();
								}
								self._updateTagManagerWithLogin();
								console.log('session associated with user.');
						})['catch'](function (errors) {
								console.log('session creation failed: ', errors);
								if (deferred) {
										deferred.reject(errors);
								}
						});
				},

				currentRouteInProjectBox: function currentRouteInProjectBox() {
						var currentRoute = this.controller.get('currentPath');
						if (currentRoute.match(/user|quote|quoting|order/)) {
								return true;
						}
				},

				// _runInspectletOptin throttles our Inspectlet opt-in popup so only a few customers will see it,
				// and after they have seen it they won't be bother by it for awhile again.
				_runInspectletOptin: function _runInspectletOptin() {

						//Uncomment these lines to skip the checks and force Inspectlet to show...
						//this.controllerFor('application').set('showInspectletOptin', true);
						//return;

						var sessionsBeforeEligible = 4; //Minimum sessions before opt-in is shown again
						var daysBeforeEligible = 14; //Minimum days before opt-in is shown again
						var oneInXChance = 3; //Chance that an elegible customer will see the opt-in, a 10 would equate to a one-in-ten chance.

						//Get our opt-in cookie:
						var optInCookie = null;
						try {
								optInCookie = JSON.parse($.cookie('optIn_Inspectlet'));
						} catch (err) {}

						//Typecheck - If there isn't a properly formatted cookie we'll set it here...
						if (optInCookie == null || typeof optInCookie !== "object" || typeof optInCookie.sessionsSinceOptIn !== "number" || typeof optInCookie.dateLastAsked !== "string" || typeof optInCookie.dateLastChecked !== "string") {
								optInCookie = {
										sessionsSinceOptIn: sessionsBeforeEligible,
										dateLastAsked: '1976-10-09',
										dateLastChecked: '1976-10-09'
								};
						}

						//If the last checked date matches today's date then we'll simply return out of here.
						//This prevents evaluation on an application reset, such as from logging out.
						if (moment().isSame(optInCookie.dateLastChecked, 'day')) {
								return;
						}

						optInCookie.sessionsSinceOptIn = optInCookie.sessionsSinceOptIn + 1;
						optInCookie.dateLastChecked = moment().format("YYYY-MM-DD");

						//Lucky winner, chicken dinner...
						var eligibleBySessionCount = optInCookie.sessionsSinceOptIn > sessionsBeforeEligible;
						var eligibleByDate = moment().isAfter(moment(optInCookie.dateLastAsked).add(daysBeforeEligible, 'days'));
						var elegibleByChance = Math.floor(Math.random() * oneInXChance) === 0;
						if (eligibleBySessionCount && eligibleByDate && elegibleByChance) {
								this.controllerFor('application').set('showInspectletOptin', true);
								optInCookie.dateLastAsked = moment().format("YYYY-MM-DD");
								optInCookie.sessionsSinceOptIn = 0;
						}

						//Update the cookie for the next time around...
						$.cookie('optIn_Inspectlet', JSON.stringify(optInCookie), { path: '/', expires: 365 });
				},

				sessionAuthenticated: function sessionAuthenticated() {
						console.log("************************** In sessionAuthenticated ******************************");
						var attemptedTransition = this.get('session').get('attemptedTransition');
						var self = this;

						var associateUserAndSessionDeferred = new _ember['default'].RSVP.defer('associate User And Session');
						self.postAssociationUserSessionToApi(associateUserAndSessionDeferred); //Using an RSVP defer object as there is code below that will wait for this transaction to complete
						this.controllerFor('application').set('currentTransition', null);

						if (this.controllerFor('application').get('signInTriggered')) {
								//This closes the sign in pop up, not the code in the pop up itself...should we remove the 'observes' in the sign-in-or-register code? 
								this.controllerFor('application').send('toggleSignIn');
						}

						associateUserAndSessionDeferred.promise.then(function () {
								// waits for the self.postAssociationUserSessionToApi(associateUserAndSessionDeferred) called above to complete
								self.associateUserWithSession();
								if ((self.controller.currentPath === 'index' || self.controller.currentPath === 'register' || self.controller.currentPath === 'project-sign-in' || self.controller.currentPath === 'passthrough-sign-in') && !attemptedTransition) {
										self.transitionTo('user.activity');
								} else {
										if (attemptedTransition) {
												attemptedTransition.retry();
										}
								}
						})['catch'](function (errors) {
								self.transitionTo('user.activity');console.log('associateUserAndSession failed', errors);
						}); //TODO: Handle this
				},

				sessionInvalidated: function sessionInvalidated() {
						// TODO: Unload user is throwing error preventing logout without refresh
						// Uncaught TypeError: Cannot read property 'clearRelationships' of undefined
						// this.store.unloadAll('user');
						//this.store.unloadAll();

						//Clear user data from simple auth session.. upgraded version does not do that by default.
						this.get('session').set('data.user', null);

						this.flashMessage({
								content: 'You have been logged out',
								duration: 2000,
								type: 'success'
						});
						(0, _webAppFrontendUtilsHubspotUtils.clearHubspotUtkCookie)();

						if (this.currentRouteInProjectBox()) {
								this.transitionTo('/project-sign-in');
						}
						_ember['default'].run.later(function () {
								console.log('reseting application');
								window.SCEmberApp.reset();
						}, 500);
				},

				actions: {
						invalidateSession: function invalidateSession() {
								console.log('in the invalidateSession function call');
								this.get('session').invalidate();
						},

						error: function error(reason) {
								// if user is forbidden
								//TODO: We need to support deep linking into a logged out account. This will involve capturing where the customer
								//wanted to go, allowing them to log in, and when login is successful, checking if there is a deep linked route stored and
								//attempting to go to it. If it is still not a valid route for this user then we either need to show a message or
								//redirect to their activity feed
								if (reason.status) {
										if (reason.status === 403) {
												this.transitionTo('/404');
										} else if (reason.status === 404) {
												this.transitionTo('/404');
										} else if (reason.status === 401) {
												console.log('401 error');
										} else {
												this.handleApplicationError(reason.status, reason, false, "");
										}
								} else if (reason.errors) {
										if (reason.errors[0].status === '403') {
												this.transitionTo('/404');
										} else if (reason.errors[0].status === '404') {
												this.transitionTo('/404');
										} else if (reason.errors[0].status === '401') {
												console.log('401 error');
										} else {
												this.handleApplicationError(reason.errors[0].status, reason, false, "");
										}
								} else {
										this.handleApplicationError("Appliction Global", reason, false, "");
								}
						},

						willTransition: function willTransition() {
								this.controllerFor('application').set('dropdownActive', false);
						},

						alertError: function alertError(message) {
								this.send('openModal', 'modals/alert-modal', { text: message });
						},

						openModal: function openModal(modalName, model, deferred) {

								if (_ember['default'].isEmpty(modalName)) {
										console.error("openModal: Received request to open a modal, but no modalName passed");
										return;
								}

								//deferred used with project-name-modal
								if (deferred) {
										this.controllerFor(modalName).set('deferred', deferred);
								}

								//can't find way of seting deferred/options other than model/controller/view/outlet via the render method
								this.render(modalName, {
										into: 'application',
										outlet: 'modal',
										model: model
								});
						},

						closeModal: function closeModal(deferred) {
								this.disconnectOutlet({
										outlet: 'modal',
										parentView: 'application'
								});
								//deferred is being used with project-name-modal
								if (deferred) {
										deferred.resolve();
								}
								// Bug to fix backdrop staying in dom
								_ember['default'].$('.modal-backdrop').remove();
								_ember['default'].$('body').removeClass('modal-open');
						},

						// use this action to link to a section of a page. Assign a unique DOM id
						transitionAndScrollTo: function transitionAndScrollTo(route, id) {
								this.transitionTo(route);
								var self = this;
								_ember['default'].run.schedule('afterRender', function () {
										self.send('scrollToId', id);
								});
						},

						scrollToId: function scrollToId(target) {
								$('html, body').animate({ scrollTop: $('#' + target).offset().top - 10 }, 200);
						},

						openTaxShippingEstimateModal: function openTaxShippingEstimateModal(model) {
								var taxShippingEstimate;
								var taxShippingEstimateController = this.controller.get('taxController');

								if (model.get('constructor.modelName') === 'project-turn-time') {
										taxShippingEstimate = taxShippingEstimateController.createWithCustomTurnTime(model);
								} else {
										taxShippingEstimate = taxShippingEstimateController.createNewTaxShippingEstimate(model);
								}

								this.send('openModal', 'quoting.tax-shipping-estimate-modal', taxShippingEstimate);
						},

						startNewQuote: function startNewQuote() {
								this.controllerFor('project').send('startNewQuote');
						},

						//enableInspectlet action is triggered by the inspectlet-optin component, enabling session recording, see _runInspectletOptin above.
						enableInspectlet: function enableInspectlet() {

								window.dataLayer.push({ 'event': 'loadInspectlet' }); //enable inspectlet

								//We'll wait five seconds for inspectlet to load before pushing login information...
								var self = this;
								setTimeout(function () {

										if (typeof __insp !== "object") {
												return;
										} //Check that Inspectlet is loaded, bail out if not

										//Let's see if the user is already logged in so we can send details to Inspectlet...
										var userGUID = self.get('session.data.authenticated.UserGUID');
										if (typeof userGUID !== 'undefined') {

												self.store.findRecord('user', userGUID).then(function (user) {
														var userEmail = user.get('emailAddress');
														var userLogin = user.get('loginName');
														var userName = user.get('firstName') + ' ' + user.get('lastName');
														__insp.push(['tagSession', { login: userLogin, email: userEmail, name: userName }]); // jshint ignore:line
														__insp.push(['identify', userName]); // jshint ignore:line
												});
										}
								}, 5000);
						}

				}
		});
});