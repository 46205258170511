define('web-app-frontend/controllers/quoting/confirm-pricing-selection-modal', ['exports', 'ember', 'web-app-frontend/mixins/analytics'], function (exports, _ember, _webAppFrontendMixinsAnalytics) {
  exports['default'] = _ember['default'].Controller.extend(_webAppFrontendMixinsAnalytics['default'], {
    applicationController: _ember['default'].inject.controller('application'),
    quotingController: _ember['default'].inject.controller('quoting'),

    transitionToOrder: 'transitionToOrder',

    saveModel: function saveModel() {
      var model = this.model.turnTime;
      var self = this;
      model.save().then(function () {
        if (self.model.deferred) {
          self.model.deferred.resolve();
        }
        self.send('closeModal');
        self.get('quotingController').send('transitionToOrder', model);
      })['catch'](function (errors) {
        console.log('selectProjectTurnTime errors', errors);
        self.send('closeModal');
      });
    },

    actions: {
      cancelModal: function cancelModal() {
        var deferred = this.model.deferred;
        deferred.reject();
        this.send('closeModal');
      },

      closeModal: function closeModal() {
        this.get('applicationController').send('closeModal');
      },

      confirm: function confirm() {
        this.saveModel();
      }
    }

  });
});