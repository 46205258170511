define('web-app-frontend/components/fabrication-turn-time', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',
    classNames: ['relative', 'ml5', 'turn-time-toggle'],
    classNameBindings: ['isActive:extended'],
    attributeBindings: ['formName'],

    isActive: false,
    showSpinner: false,

    saveAndValidateForm: 'saveAndValidateForm',

    isITAR: _ember['default'].computed.equal('model.isITAR', true),

    didRender: function didRender() {
      this._super();
      var serviceType = this.model.get("serviceType");
      $("#serviceType li").removeClass("active");
      $("#serviceType li i").removeClass("fa-check-circle");

      if (serviceType === "ValueProto") {
        $('#serviceType li').not('#ValueProto').addClass('grayed');
        $("#ValueProto").addClass("active");
        $("#ValueProto i").addClass("fa-check-circle");
      } else if (serviceType === "PCBpro") {
        $('#serviceType li').not('#PCBpro').addClass('grayed');
        $("#PCBpro ").addClass("active");
        $("#PCBpro i").addClass("fa-check-circle");
      } else if (serviceType === "PCBExpress") {
        $('#serviceType li').not('#PCBExpress').addClass('grayed');
        $("#PCBExpress").addClass("active");
        $("#PCBExpress i").addClass("fa-check-circle");
      } else if (serviceType === "Custom") {
        $('#serviceType li').not('#Custom').addClass('grayed');
        $("#Custom ").addClass("active");
        $("#Custom i").addClass("fa-check-circle");
      }
    },

    changeRadioButtonSelection: function changeRadioButtonSelection() {
      var serviceType = this.model.get('serviceType');
      var turnTime = this.model.get('turnTime');
      $('.' + serviceType).find("input[type=radio][value='" + turnTime + "']").prop('checked', true);
    },

    click: function click(e) {
      // stop bubbling to parent view
      e.stopPropagation();
    },

    itChanged: (function () {
      if (!this.get('parentView.parentView.turnTime')) {
        this.set('isActive', false);
      }
    }).observes('parentView.parentView.turnTime').on('init'),

    actions: {
      toggle: function toggle() {
        var self = this;
        //retry the toggle until state is nolonger inFlight.
        // This was added due to the situation when a user clicks directly from a form input to the turnTimes dropdown.
        // The turntimes would all be disabled until the user clicked out and tried again.
        // This will recursively call until state is nolonger inFlight.
        if (this.model.get('currentState.stateName') === "root.loaded.updated.inFlight") {
          this.set('showSpinner', true);
          _ember['default'].run.later(this, function () {
            self.set('showSpinner', false);
            self.send('toggle');
          }, 100);
        } else {
          if (!this.get('isActive')) {
            this.set('parentView.parentView.turnTime', false);
            this.set('parentView.parentView.turnTime', true);
            this.model.toggleProperty('updateTurnTimeSelectionComponent');
            this.changeRadioButtonSelection();
          }
          this.set('parentView.parentView.turnTime', true);
          this.toggleProperty('isActive');
        }
      },

      closeAndSave: function closeAndSave() {
        var self = this;
        var deferred = new _ember['default'].RSVP.defer('Fabrication closeAndSave deferred');
        this.sendAction('saveAndValidateForm', deferred);
        this.send('toggle');

        this.set('showSpinner', true);
        deferred.promise.then(function () {
          self.set('showSpinner', false);
        });
      },

      showServiceTypeWindow: function showServiceTypeWindow() {
        // send the showServiceTypeWindow action from the quote-snippet context.
        // mostly to avoid having to put an explicit action chain between here & quoteSnippet/
        this.get('parentView').sendAction('showServiceTypeWindow'); //send action
        this.toggleProperty('isActive'); //close dropdown
      }
    }
  });
});