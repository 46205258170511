define('web-app-frontend/mixins/reset-scroll', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    // include this mixin if you'd like a route to jump to the top of the page when route activates
    // if you are using activate in the route, you must call this._super.apply(this, arguments);
    // more info: http://guides.emberjs.com/v1.10.0/cookbook/user_interface_and_interaction/resetting_scroll_on_route_changes/
    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    }
  });
});