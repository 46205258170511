define('web-app-frontend/components/survey-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    showSurvey: false,
    saveFeedback: 'saveFeedback',

    updateShowSurvey: (function () {
      if (!_ember['default'].isEmpty(this.get('survey'))) {
        var questions = this.get('survey.questions');
        questions.forEach(function (question) {
          var ratings = [];
          var fullStars = question.starRange(1, question.get('svqRating'), 'full');
          var emptyStars = question.starRange(question.get('svqRating') + 1, question.get('svqScale'), 'empty');
          Array.prototype.push.apply(ratings, fullStars);
          Array.prototype.push.apply(ratings, emptyStars);
          question.set('displayRatings', ratings);
          question.updateDisplayUserComments();
        });
        this.set('showSurvey', true);
      }
    }).observes('survey').on('init'),

    attachHoverEvent: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.$().find('span.star-rating').each(function () {
          $(this).mouseover(function () {
            $(this).siblings().addBack().removeClass('glyphicon-star glyphicon-star-empty blue');
            $(this).siblings().addBack().addClass('glyphicon-star-empty');
            $(this).prevAll().addBack().removeClass('glyphicon-star-empty');
            $(this).prevAll().addBack().addClass('glyphicon-star green');
          }).mouseout(function () {
            $(this).siblings().addBack().removeClass('glyphicon-star glyphicon-star-empty green');
            $(this).siblings('.selected').addBack('.selected').addClass('glyphicon-star blue');
            $(this).siblings(':not(.selected)').addBack(':not(.selected)').addClass('glyphicon-star-empty');
          });
        });
      });
    }).observes('showSurvey'),

    actions: {
      saveFeedback: function saveFeedback(question, mode) {
        console.log("Saving saveFeedback");
        var self = this;
        if (mode === "button" && _ember['default'].isEmpty(question.get('svfUserComments'))) {
          return;
        }
        question.set('showSavingSpinner', true);
        if (typeof $(event.target).data('rating') !== 'undefined') {
          question.set('svqRating', $(event.target).data('rating'));
        }
        question.save().then(function () {
          var threshold = eval(question.get('svqRating') + question.get('svqThreshold'));
          if (mode === "star" && threshold) {
            question.set('isEditing', true);
          } else {
            question.set('isEditing', false);
          }
          question.set('showSavingSpinner', false);
          self.attachHoverEvent();
        })['catch'](function (errors) {
          console.log('saving errors', errors);
          question.set('showSavingSpinner', false);
          self.attachHoverEvent();
        });
      },

      editFeedback: function editFeedback(question) {
        console.log("editing saveFeedback");
        question.set('isEditing', true);
      }
    }

  });
});