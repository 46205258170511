define('web-app-frontend/adapters/quote-assembly-pricing', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/assembly/pricing';
      return this._super(url);
    },

    //payload's default root key is overwritten with payloadKeyFromModelName in the QAP serializer
    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var id = snapshot.record.get('projectGUID');
      var serializer = store.serializerFor(type.modelName);
      snapshot.id = id;

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      return this.ajax(this.buildURL(type.modelName, id), "POST", { data: data });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var designViolations, designViolationProperties, errors, matchingResponseItem;
      if (this.isSuccess(status, headers, payload)) {
        var turnTimeOptions = payload.assemblyPricing.data;
        var projectGUID = payload.assemblyPricing.url.match(/project\/(.*?)\/assembly/)[1];

        //Retrieve current QAP object currently in form & it's associated turnTime code.
        var currentModelStoreObject = this.store.peekRecord('quote-assembly-pricing', projectGUID);
        var currentModelTurnTime = currentModelStoreObject.get('userSelectedTurnTime');

        //Does a matching turntime exist in the response?
        var matchingTurnTimeExists = turnTimeOptions.filterBy('turnTimeCode', currentModelTurnTime).get('length') > 0;
        if (matchingTurnTimeExists) {
          //Yes? Use it.
          matchingResponseItem = turnTimeOptions.filterBy('turnTimeCode', currentModelTurnTime).get('firstObject');
        } else {
          //No? Use default assembly turntime.
          matchingResponseItem = turnTimeOptions.filterBy('turnTimeCode', 'FP5').get('firstObject');
        }

        //Does matching turntime have designViolations?
        if (matchingResponseItem.designViolations.length && !currentModelStoreObject.get('customAssemblyQuote')) {
          designViolations = matchingResponseItem.designViolations;
          designViolationProperties = [].concat.apply([], designViolations.mapBy('designViolationProperties'));
          //If designViolations are present build errors object and return errors to form.
          if (!designViolationProperties.contains("AssemblySubtotal")) {
            //then reject assemblysubtotal from array
            errors = _webAppFrontendUtilsErrorFormatting['default'].generateErrorsFromDesignViolations(designViolations);
            return new _emberData['default'].InvalidError(errors);
          } else {
            return payload;
          } //assemblySubotal error will be generated in model's rubberBandTurnTimes method
        } else {
            return payload;
          }
      } else if (this.isInvalid(status, headers, payload)) {
        //is 400
        if (payload.assemblyPricing.errors !== null) {
          errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(payload.assemblyPricing.errors);
        }
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});