define('web-app-frontend/models/address-suggestion', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    addr1: _emberData['default'].attr('string'),
    addr2: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),
    postalCode: _emberData['default'].attr('string'),
    country: _emberData['default'].attr('string'),
    type: _emberData['default'].attr('string'),
    showSpinner: _emberData['default'].attr('boolean', false),

    // Associations
    addressValidationResult: _emberData['default'].belongsTo('address-validation-result', { async: true })
  });
});