define('web-app-frontend/adapters/quoting-parent', ['exports', 'web-app-frontend/adapters/application'], function (exports, _webAppFrontendAdaptersApplication) {
  exports['default'] = _webAppFrontendAdaptersApplication['default'].extend({
    pathForType: function pathForType(type) {
      return type;
    },

    //will append SessionGUID= query param to the end of the URL.
    //Also allows for optional hash to be passed to this.ajax() which will be appended to end of URL.
    //example this.ajax(this.buildURL('project'), 'GET', {}, { UserName: 'Test' }) -> /project?UserName=Test
    ajax: function ajax(url, type, hash, queryHash) {
      var querys = [];
      if (queryHash) {
        $.each(queryHash, function (key, value) {
          querys.push(key + '=' + value);
        });
      }

      if (type !== 'GET') {
        querys.push('Api-Key=SCWEB');
      }
      querys.push('SessionGUID=' + $.cookie('sessionGUID'));
      url = url + '?' + querys.join('&');

      return this._super(url, type, hash);
    }
  });
});