define('web-app-frontend/routes/user/orders', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Orders',
    queryParams: { sortField: { refreshModel: true } },

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);

      this.get("store").unloadAll('order');
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        openedOrdersPromise: this.get("store").query('order', { pageNumber: params.pageNumber,
          sortField: params.sortField,
          sortAscending: params.sortAsc,
          orderStatusFilter: 'Order Placed' }).then(function (results) {
          return {
            openedOrders: results,
            hasMoreData: results.get('meta.hasMoreData'),
            totalCount: results.get('meta.totalCount')
          };
        })['catch'](function (errors) {
          console.log('GET orders failed', errors);
        }),

        shippedOrdersPromise: this.get("store").query('order', { pageNumber: params.pageNumber,
          sortField: params.sortField,
          sortAscending: params.sortAsc,
          orderStatusFilter: 'Order Shipped' }).then(function (results) {
          return {
            shippedOrders: results,
            hasMoreData: results.get('meta.hasMoreData'),
            totalCount: results.get('meta.totalCount')
          };
        })['catch'](function (errors) {
          console.log('GET orders failed', errors);
        })
      });
    },

    setupController: function setupController(controller, promises) {
      var orders = promises.openedOrdersPromise.openedOrders;
      orders.addObjects(promises.shippedOrdersPromise.shippedOrders.content);

      this._super(controller, orders);
      controller.setProperties({});

      controller.setPaginationDetails(promises.openedOrdersPromise.hasMoreData, 'open', promises.openedOrdersPromise.totalCount);
      controller.setPaginationDetails(promises.shippedOrdersPromise.hasMoreData, 'shipped', promises.shippedOrdersPromise.totalCount);
      controller.setShouldPaginateState();
      controller.sortOrders(); // sort needs to retrigger when additional orders have been added before a page refresh
    },

    resetController: function resetController(controller) {
      controller.resetPageNumber();
    }
  });
});