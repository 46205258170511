define('web-app-frontend/models/contact', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    emailAddress: _emberData['default'].attr('string'),
    phoneNumber: _emberData['default'].attr('string'),
    company: _emberData['default'].attr('string'),
    message: _emberData['default'].attr('string'),
    receiveInfo: _emberData['default'].attr('boolean', { defaultValue: false }),
    commitOnSuccess: _emberData['default'].attr('boolean', { defaultValue: false }),
    validationToken: _emberData['default'].attr('string'),
    ContactFormSource: _emberData['default'].attr('string', { defaultValue: 'userContact' }),
    hubspotUtkCookie: _emberData['default'].attr('string'),
    recaptchaToken: _emberData['default'].attr('string')
  });
});