define('web-app-frontend/routes/user/account/billing', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Billing Info',
    session: _ember['default'].inject.service(),

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    model: function model() {
      var store = this.get('store');
      var userGUID = this.get('session.data.authenticated.UserGUID');
      //reload on a record won't work if the record is presently invalid (the request occurs, but the local
      //record won't be updated with the return data) so in lieu of a reload, we will fully unload and re-fetch.
      //store.unloadAll('userBillingAddress');

      return store.findRecord('userBillingAddress', userGUID).then(function (address) {
        return address;
      })['catch'](function () {
        return store.createRecord('userBillingAddress', { userGUID: userGUID, id: userGUID });
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('billingAddress', model);
      controller.set('showSpinner', true);
      var deferred = new _ember['default'].RSVP.defer('Find Credit Card');
      this.findCreditCard(deferred);
      deferred.promise.then(function (userCreditDetail) {
        controller.set('creditCardOnFile', true);
        controller.set('availableCards', userCreditDetail.get('availableCards'));
        controller.set('showSpinner', false);
      })['catch'](function () {
        controller.set('creditCardOnFile', false);
        controller.set('showSpinner', false);
        controller.set('showNoCardsMessage', true);
      });
    },

    resetController: function resetController() {
      // The primary use of this resetController block will be to make sure we
      // do not hold onto any users credit card longer than necessary.
      var store = this.get('store');
      store.unloadAll('userCreditDetail');
    },

    findCreditCard: function findCreditCard(deferred) {
      var store = this.store;
      var userID = this.get('session.data.authenticated.UserGUID');

      store.findRecord('userCreditDetail', userID).then(function (creditCard) {
        deferred.resolve(creditCard);
      })['catch'](function () {
        deferred.reject('No Card Found');
      });
    }
  });
});