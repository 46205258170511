define('web-app-frontend/components/bom-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['bomLineComponent'],
    classNameBindings: ['bomLine.shouldDisplayErrorIcon:error-row', 'disableBomRow:row-disable'],
    refreshData: 'refreshData',
    openModal: 'openModal',

    isAlternativePartAdded: false,
    alternativeParts: [],
    isMountTypeUnknown: false,
    disableBomRow: _ember['default'].computed('isKittedBom', 'bomLine.provideOrDNP', function () {
      return !this.get('isKittedBom') && this.get('bomLine.provideOrDNP');
    }),

    setIsAlternativePartAdded: (function () {
      var currentBomLine = this.get('bomLine');
      var alternativeParts = this.get('bom.bomLines').filter(function (bomline) {
        return bomline.get('lineNumber') === currentBomLine.get('lineNumber');
      });
      this.set('alternativeParts', alternativeParts);
      this.set("isAlternativePartAdded", alternativeParts.length > 1);
    }).observes('bomLine').on('init'),

    setIsMountTypeUnknown: (function () {
      var currentBomLine = this.get('bomLine');
      var isUnknown = this.bomLine.get('mountType').toLowerCase() === "unknown";
      this.set('isMountTypeUnknown', isUnknown);
    }).observes('bomLine').on('init'),

    registerUserInteractionWithParent: (function () {
      if (!this.userInteraction) {
        this.set('targetObject.userInteraction', true);
      }
    }).observes('bomLine.sourcingAction'),

    observeSourcingActionChanges: (function () {
      var parentFilter = this.parentFilterValue;
      if (this.bomLine.get('sourcingAction') !== 'parentFilter' && parentFilter !== 'all') {
        this.sendAction('refreshData');
      }
    }).observes('bomLine.sourcingAction'),

    disableChooseActionValue: (function () {
      //disable selection of the "Choose Action" value in the available sourcing actions
      this.$('.x-bom-action option[value="PromptCustomer"]').prop('disabled', true);
      this.$().popover({
        selector: '[rel="popover"]',
        trigger: 'hover focus',
        placement: 'bottom',
        html: true,
        content: function content() {
          return $(this).next('.popover-wrapper').html();
        },
        viewport: {
          selector: 'body',
          padding: 20
        }
      });
      console.log('in did insert didInsertElement');
    }).on('didInsertElement'),

    actions: {
      openAlternativeBomModal: function openAlternativeBomModal() {
        var currentBomLine = this.get('bomLine');
        var selectedLineId = this.get('bomLine.id');
        var alternativeParts = this.get('bomLine.bom.bomLines').filter(function (bomline) {
          return bomline.get('lineNumber') === currentBomLine.get('lineNumber');
        });

        this.sendAction('openModal', 'quoting.bom-alternative-modal', {
          alternativeParts: alternativeParts,
          selectedLineId: selectedLineId,
          selectedMfgNumber: this.get('bomLine.manufactureNumber'),
          selectedSourcingAction: this.get('bomLine.sourcingAction'),
          project: this.get('bomLine.bom.project'),
          bom: this.get('bomLine.bom')
        });
      }
    }
  });
});