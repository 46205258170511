define('web-app-frontend/mixins/analytics', ['exports', 'ember', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Mixin.create({

    location: _webAppFrontendConfigEnvironment['default'].locationType,

    //Example: sendGoogleTagEvent({action: 'fooButtonClick'})
    //Will by default send the page title as the category but can be overridden by passing an 'eventCategory' key/value pair
    //Optionally assign strings or numbers to eventLabel and eventValue key/value pairs, numbers will be converted to strings
    sendGoogleTagEvent: function sendGoogleTagEvent(params) {
      if (!_webAppFrontendConfigEnvironment['default'].environmentIsProduction || typeof params.action !== 'string') {
        return;
      }
      var eventCategory = typeof params.category === 'string' ? params.category : $(document).attr('title'); //Default to page title
      var eventLabel = typeof params.label === 'string' || typeof params.label === 'number' ? params.label.toString() : '';
      var eventValue = typeof params.value === 'string' || typeof params.value === 'number' ? params.value.toString() : '';
      window.dataLayer.push({
        'event': 'interactionEvent',
        'eventCategory': eventCategory,
        'eventAction': params.action,
        'eventLabel': eventLabel,
        'eventValue': eventValue
      });
    },

    sendGA4Event: function sendGA4Event(params) {
      if (!_webAppFrontendConfigEnvironment['default'].environmentIsProduction || typeof params.action !== "string") {
        return;
      }

      var eventAction = typeof params.action === 'string' ? params.action : "";
      var eventCategory = typeof params.category === 'string' ? params.category : "";
      var eventValue = typeof params.value === 'string' || typeof params.value === 'number' ? params.value.toString() : '';

      var orderNumber = typeof params.orderNumber === "string" || typeof params.orderNumber === "number" ? params.orderNumber.toString() : "";

      var eventPayload = {};
      if (eventAction) {
        if (eventAction === "begin_checkout") {
          eventPayload = {
            'event': params.action,
            'currency': "USD",
            'value': eventValue,
            'items': [{
              'item_id': orderNumber,
              'item_name': orderNumber,
              'price': eventValue
            }]
          };
        } else if (eventAction === "purchase") {
          eventPayload = {
            'event': params.action,
            'transaction_id': orderNumber,
            'currency': "USD",
            'value': eventValue,
            'items': [{
              'item_id': orderNumber,
              'item_name': orderNumber,
              'item_category': eventCategory,
              'price': eventValue
            }]
          };
        }
        window.dataLayer.push(eventPayload);
      }
    },

    sendMSAnalyticsEvent: function sendMSAnalyticsEvent(params) {
      if (!_webAppFrontendConfigEnvironment['default'].environmentIsProduction || typeof params.action !== "string") {
        return;
      }

      // window.uetq = window.uetq || [];

      var orderNumber = params.orderNumber;

      var eventCategory = params.category;

      if (params.action === "begin_checkout") {
        //For begin_checkout event we need the orderNumber and ordertotal
        window.uetq.push('event', 'begin_checkout', { 'event_label': orderNumber, 'revenue_value': params.value, 'currency': 'USD' });
      } else if (params.action === "purchase") {
        //For purchase event we need, orderNumber, ordertotal and category. Category values can be web order or formal quote
        window.uetq.push('event', 'purchase', { 'event_label': orderNumber, 'event_category': eventCategory, 'revenue_value': params.value, 'currency': "USD" });
      }
    },

    sendTaboolaAnalyticsEvent: function sendTaboolaAnalyticsEvent(params) {
      if (!_webAppFrontendConfigEnvironment['default'].environmentIsProduction || typeof params.action !== "string") {
        return;
      }

      var orderNumber = params.orderNumber;

      var eventCategory = params.category;

      var TABOOLA_ACCOUNTID = 1666989;

      if (params.action === "begin_checkout") {
        //For begin_checkout event we need the orderNumber and ordertotal

        _tfa.push({ notify: 'event', name: 'begin_checkout', id: TABOOLA_ACCOUNTID, orderid: orderNumber, revenue: params.value, currency: 'USD' });
      } else if (params.action === "purchase") {
        //For purchase event we need, orderNumber, ordertotal and category. Category values can be web order or formal quote
        _tfa.push({ notify: 'event', name: 'make_purchase', id: TABOOLA_ACCOUNTID, orderid: orderNumber, revenue: params.value, event_category: eventCategory, currency: 'USD' });
        //category is missing here
      }
    }

  });
});
//Base level page transition event tag is setup in app/router.js