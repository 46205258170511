define('web-app-frontend/models/project-shipping', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    //inupt model has int id
    city: _emberData['default'].attr('string'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    companyName: _emberData['default'].attr('string'),
    country: _emberData['default'].attr('string', { defaultValue: 'USA' }),
    description: _emberData['default'].attr('string'),
    email: _emberData['default'].attr('string'),
    firstName: _emberData['default'].attr('string'),
    ignoreTaxAlert: _emberData['default'].attr('boolean'),
    isTaxExempt: _emberData['default'].attr('boolean'),
    hasTaxExemptionOnFile: _emberData['default'].attr('boolean'),
    isWhiteList: _emberData['default'].attr('boolean'),
    lastName: _emberData['default'].attr('string'),
    phone: _emberData['default'].attr('string'),
    postalCode: _emberData['default'].attr('string'),
    purchaseOrderNumber: _emberData['default'].attr('string'),
    shippingAccountNumber: _emberData['default'].attr('string'),
    shippingChargeDollars: _emberData['default'].attr('number'),
    shippingMethod: _emberData['default'].attr('string', { defaultValue: 'Ground' }),
    shippingNotes: _emberData['default'].attr('string'),
    shippingService: _emberData['default'].attr('string', { defaultValue: 'UPS' }),
    shippingTerms: _emberData['default'].attr('string'),
    state: _emberData['default'].attr('string'),
    streetAddress1: _emberData['default'].attr('string'),
    streetAddress2: _emberData['default'].attr('string'),
    streetAddress3: _emberData['default'].attr('string'),
    individualWrap: _emberData['default'].attr('boolean'),

    // Associations
    project: _emberData['default'].belongsTo('project', { async: true }),
    addressValidationResult: _emberData['default'].belongsTo('addressValidationResult', { async: false }),

    clearTermsAndService: function clearTermsAndService() {
      this.set('shippingTerms', 'UPS'); //defaults on select box
      this.set('shippingService', 'UPS 2nd Day Air');
    },

    hasAddressSuggestions: (function () {
      if (this.get('addressValidationResult')) {
        return this.get('addressValidationResult').get('suggestions').get('length') > 0;
      }
    }).property().volatile(),

    unconfirmedNoSuggestions: (function () {
      if (this.get('addressValidationResult')) {
        return this.get('addressValidationResult').get('addressValidationFlag') === 'NoCandidates';
      }
    }).property().volatile(),

    resetDefaults: function resetDefaults() {
      this.setProperties({ ignoreTaxAlert: false,
        isTaxExempt: false,
        isWhiteList: false
      });
    },

    updateWithSuggestion: function updateWithSuggestion(suggestion) {
      this.set('streetAddress1', suggestion.get('addr1'));
      if (suggestion.get('addr2') !== 'string') {
        this.set('streetAddress2', suggestion.get('addr2'));
      } else {
        this.set('streetAddress2', '');
      }
      this.set('city', suggestion.get('city'));
      this.set('state', suggestion.get('state'));
      this.set('postalCode', suggestion.get('postalCode'));
    },

    // Whitelisting an address will prevent address suggestions from being returned until a different zipcode has been applied.
    whiteListAddress: function whiteListAddress() {
      this.set('isWhiteList', true);
    },

    displayName: (function () {
      var companyName = this.get('companyName');
      if (!_ember['default'].isBlank(companyName)) {
        return companyName;
      } else {
        return this.get('firstName') + ' ' + this.get('lastName');
      }
    }).property('companyName', 'firstName', 'lastName')
  });
});