define('web-app-frontend/controllers/user/orders', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    orderingController: _ember['default'].inject.controller('ordering'),
    filter: 'open',
    filterOrders: [{ text: 'Last Updated', value: 'modificationDate', sortAscending: false }, { text: 'Created Date', value: 'creationDate', sortAscending: false }, { text: 'Order Number', value: 'orderNumber', sortAscending: true }, { text: 'Project Name', value: 'projectName', sortAscending: true }],
    queryParams: ['pageNumber', 'sortField', 'sortAsc', 'orderStatusFilter'],
    isFilterShipped: false,
    sortField: 'modificationDate',
    pageNumber: 1,
    shouldPaginate: false,
    sortAsc: false,
    orderStatusFilter: 'Order Placed',
    numberOfOpenOrders: 0,
    numberOfShippedOrders: 0,
    pageNumForOpened: 1,
    pageNumForShipped: 1,
    loadMoreOpend: false,
    loadMoreShipped: false,

    setPaginationDetails: function setPaginationDetails(hasNextPage, filter, totalCount) {
      if (filter === 'open') {
        if (hasNextPage) {
          this.incrementProperty('pageNumForOpened');
        }
        this.set('loadMoreOpend', hasNextPage);
        this.set('numberOfOpenOrders', totalCount);
      } else {
        if (hasNextPage) {
          this.incrementProperty('pageNumForShipped');
        }
        this.set('loadMoreShipped', hasNextPage);
        this.set('numberOfShippedOrders', totalCount);
      }
    },

    setShouldPaginateState: function setShouldPaginateState() {
      if (this.get('filter') === 'open') {
        this.set('shouldPaginate', this.get('loadMoreOpend'));
      } else {
        this.set('shouldPaginate', this.get('loadMoreShipped'));
      }
    },

    filterOpenOrders: function filterOpenOrders() {
      return this.get('model').filter(function (order) {
        return !order.get('status').match('Order Shipped');
      });
    },

    filterShippedOrders: function filterShippedOrders() {
      return this.get('model').filter(function (order) {
        return order.get('status').match('Order Shipped');
      });
    },

    filterByOpenOrShip: function filterByOpenOrShip() {
      var filter = this.get('filter');
      if (filter === 'open') {
        return this.filterOpenOrders();
      } else if (filter === 'shipped') {
        return this.filterShippedOrders();
      } else {
        //all orders, not presently an option
        return this.get('model');
      }
    },

    resetPageNumber: function resetPageNumber() {
      this.set('pageNumForShipped', 1);
      this.set('pageNumForOpened', 1);
    },

    refreshModelBeforeSorting: (function () {
      this.set('sortField', this.get('selectedSort.value'));
      this.set('sortAsc', this.get('selectedSort.sortAscending'));
      this.set('filter', this.get('filter'));
      this.set('isFilterShipped', this.get('filter') === 'shipped' ? true : false);
    }).observes('selectedSort'),

    sortOrders: function sortOrders() {
      var filteredOrders = this.filterByOpenOrShip();
      this.set('orders', filteredOrders);
    },

    orders: (function () {
      console.log('orders triggered');
      return this.filterByOpenOrShip();
    }).property('selectedSort', 'filter'),

    getNextResults: function getNextResults(deferred) {
      var self = this;
      var filter = this.get('filter');

      this.store.query('order', { pageNumber: filter === 'open' ? this.get('pageNumForOpened') : this.get('pageNumForShipped'),
        sortField: this.get('sortField'),
        sortAscending: this.get('sortAsc'),
        orderStatusFilter: filter === 'open' ? 'Order Placed' : 'Order Shipped'
      }).then(function (rawPayload) {

        var hasMoreData = rawPayload.get('meta.hasMoreData');
        var totalCount = rawPayload.get('meta.totalCount');

        self.setPaginationDetails(hasMoreData, self.filter, totalCount);
        self.setShouldPaginateState();
        self.get('model').addObjects(rawPayload.content);
        self.sortOrders();

        if (deferred) {
          deferred.resolve();
        }
      })['catch'](function (errors) {
        console.log('Load More failed', errors);
        if (deferred) {
          deferred.reject(errors);
        }
      });
    },

    actions: {
      duplicateProject: function duplicateProject(projectGUID) {
        var orderingController = this.get('orderingController');
        orderingController.send('duplicateProject', projectGUID);
      },

      filterOrders: function filterOrders(filter) {
        this.set('filter', filter);
        // controls active class on filter links
        if (this.get('filter') === 'shipped') {
          this.set('isFilterShipped', true);
        } else {
          this.set('isFilterShipped', false);
        }
        this.setShouldPaginateState();
        this.sortOrders();
      },

      loadMore: function loadMore(deferred) {
        this.getNextResults(deferred);
      },

      transitionAndPrint: function transitionAndPrint(model) {
        var orderingController = this.get('orderingController');
        orderingController.set('printTransition', true);
        orderingController.transitionToRoute('order.edit', model.get('id'));
      }
    }
  });
});