define('web-app-frontend/adapters/project-checkout', ['exports', 'ember', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _ember, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/checkout';
      return this._super(url);
    },

    createRecord: function createRecord(store, typeClass, snapshot) {
      var id = snapshot.attr('projectGUID');
      var commitOnSuccess = snapshot.attr('commitOnSuccess');
      var formalQuoteRequest = snapshot.attr('formalQuote');
      var hubSpotConversationId = snapshot.attr('hubSpotConversationId');

      return this.ajax(this.buildURL(typeClass.modelName, id), 'POST', {}, { CommitOnSuccess: commitOnSuccess, FormalQuoteRequest: formalQuoteRequest, HubSpotConversationId: hubSpotConversationId });
    },

    handleResponse: function handleResponse(status, headers, payload, requestData) {
      var errors, errorsToReport, response, jsonErrors;
      errors = {};
      errorsToReport = {};
      response = payload;
      var orderingController = _ember['default'].getOwner(this).lookup('controller:ordering');
      orderingController.set('checkoutAssemblyError', false);
      orderingController.set('checkoutFabError', false);
      orderingController.set('checkoutPartsError', false);

      if (status === 500) {
        if (response.projectValidationResult.assemblyErrors != null && Object.keys(response.projectValidationResult.assemblyErrors).length > 0) {
          jsonErrors = response.projectValidationResult.assemblyErrors;
          orderingController.set('checkoutAssemblyError', true);
        }

        if (response.projectValidationResult.fabricationErrors != null && Object.keys(response.projectValidationResult.fabricationErrors).length > 0) {
          jsonErrors = response.projectValidationResult.fabricationErrors;
          orderingController.set('checkoutFabError', true);
        }
        if (response.projectValidationResult.partErrors != null && Object.keys(response.projectValidationResult.partErrors).length > 0) {
          jsonErrors = response.projectValidationResult.partErrors;
          orderingController.set('checkoutPartsError', true);
        }
        if (response.projectValidationResult.paymentErrors != null && Object.keys(response.projectValidationResult.paymentErrors).length > 0) {
          //Get shipping model and add errors to it
          var billingObject = this.store.peekRecord('project-billing', response.projectValidationResult.projectGUID);

          jsonErrors = response.projectValidationResult.paymentErrors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            billingObject.get('errors').add(key, jsonErrors[key][0].userFriendlyMessage);
            billingObject.set(_ember['default'].String.camelize(key), null);
            orderingController.get('billingComponent').raiseValidateAddressAction(true);
          });
          //Display error
          errors["billingSection"] = [];
          errors["billingSection"].push({ "userFriendlyMessage": "Billing section has some errors" });
        }
        if (response.projectValidationResult.shippingErrors != null && Object.keys(response.projectValidationResult.shippingErrors).length > 0) {
          //Get shipping model and add errors to it
          var shippingObject = this.store.peekRecord('project-shipping', response.projectValidationResult.projectGUID);
          jsonErrors = response.projectValidationResult.shippingErrors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            shippingObject.get('errors').add(key, jsonErrors[key][0].userFriendlyMessage);
            shippingObject.set(_ember['default'].String.camelize(key), null);
            orderingController.get('shippingComponent').validateOrderShipping(true);
          });
          //Display error
          errors["shippingSection"] = [];
          errors["shippingSection"].push({ "userFriendlyMessage": "Shipping section has some errors" });
        }
        if (response.projectValidationResult.kitErrors != null && Object.keys(response.projectValidationResult.kitErrors).length > 0) {
          //Get kit model and add errors to it

          var kitObject = this.store.peekRecord('orderKit', response.projectValidationResult.projectGUID);
          jsonErrors = response.projectValidationResult.kitErrors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            kitObject.get('errors').add(key, jsonErrors[key][0].userFriendlyMessage);
            kitObject.set(_ember['default'].String.camelize(key), null);
            orderingController.get('kittedComponent').send('validateOrderKit', true);
          });
          //Display error
          errors["kitSection"] = [];
          errors["kitSection"].push({ "userFriendlyMessage": "Kit section has some errors" });
        }
        if (response.projectValidationResult.contactErrors != null && Object.keys(response.projectValidationResult.contactErrors).length > 0) {
          //Get contact model and add errors to it
          var contactObject = this.store.peekRecord('order-contact', response.projectValidationResult.projectGUID);
          console.log('contact object');
          console.log(contactObject);
          jsonErrors = response.projectValidationResult.contactErrors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            contactObject.send('becomeDirty');
            contactObject.get('errors').add(key, jsonErrors[key][0].userFriendlyMessage);
            contactObject.set(_ember['default'].String.camelize(key), null);
            orderingController.get('contactsComponent').send('validateOrderContact', true);
          });
          //Display error
          errors["contactsSection"] = [];
          errors["contactsSection"].push({ "userFriendlyMessage": "Contact section has some errors" });
        }
        if (response.projectValidationResult.projectErrors != null && Object.keys(response.projectValidationResult.projectErrors).length > 0) {
          jsonErrors = response.projectValidationResult.projectErrors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            if (!errors[_ember['default'].String.camelize(key)]) {
              errors[_ember['default'].String.camelize(key)] = [];
            }
            errors[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
          });
        }
        if (response.projectValidationResult.errors != null && Object.keys(response.projectValidationResult.errors).length > 0) {
          jsonErrors = response.projectValidationResult.errors;
          Object.keys(jsonErrors).forEach(function (key) {
            if (!errorsToReport[_ember['default'].String.camelize(key)]) {
              errorsToReport[_ember['default'].String.camelize(key)] = [];
            }
            errorsToReport[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
            if (!errors[_ember['default'].String.camelize(key)]) {
              errors[_ember['default'].String.camelize(key)] = [];
            }
            errors[_ember['default'].String.camelize(key)].push(jsonErrors[key][0].userFriendlyMessage);
          });
        }
        errors = _webAppFrontendUtilsErrorFormatting['default'].getJsonApiErrorObject(errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload, requestData);
      }
    }
  });
});