define('web-app-frontend/routes/resource-center/process', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'Our Process',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'How Screaming Circuits processes your quick-turn prototype and on-demand electronics manufacturing orders.'
      }
    }]

  });
});