define('web-app-frontend/routes/quoting', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'Quoting',

    renderTemplate: function renderTemplate() {
      this.render();

      this.render('quoting.footer', {
        outlet: 'footer',
        into: 'application'
      });
    },

    setupController: function setupController(controller) {
      //Clear Sunstone flag
      controller.set('sunstoneUnavailable', false);
    }
  });
});
// Consider this the application route for the quoting ("project") process.