define('web-app-frontend/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'ember', 'web-app-frontend/config/environment'], function (exports, _emberSimpleAuthAuthenticatorsOauth2PasswordGrant, _ember, _webAppFrontendConfigEnvironment) {
  exports['default'] = _emberSimpleAuthAuthenticatorsOauth2PasswordGrant['default'].extend({
    serverTokenEndpoint: _webAppFrontendConfigEnvironment['default'].serverTokenEndpoint,

    makeRequest: function makeRequest(url, data) {
      data.client_id = _ember['default'].ENV.client_id;
      data.client_secret = _ember['default'].ENV.client_secret;
      return this._super(url, data);
    },

    refreshAccessToken: function refreshAccessToken(expiresIn, refreshToken) {
      this._super(expiresIn, refreshToken).then(function () {
        console.log('token refresh  succeeded');
      }, function (error) {
        console.log('token refresh failed with message:', error);
      });
    }

  });
});