define('web-app-frontend/components/submit-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['submitBtnComponent'],
    loading: false,

    //http://www.samselikoff.com/blog/getting-ember-components-to-respond-to-actions/
    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    buttonInline: (function () {
      if (this.get('inline')) {
        return true;
      }
    }).property('buttonInline'),

    actions: {
      showLoading: function showLoading(model) {
        var $submitBtn = this.$('.submit-btn');
        var spinner = this.$('.loading-spinner');

        if ($submitBtn.hasClass('disabled')) {
          return false;
        } else {
          spinner.toggleClass('none');
          this.sendAction('action', model);
        }
      },

      loadingInline: function loadingInline(model) {
        this.set('loading', true);
        this.sendAction('action', model);
      },

      hasSucceeded: function hasSucceeded() {
        var successLabel = this.$('.success-label');
        $(successLabel).css('display', 'inline-block');
        _ember['default'].run.later(this, function () {
          $(successLabel).fadeOut();
        }, 1000);
        // this.$('.submit-btn').addClass('disabled');
      },

      hideSpinner: function hideSpinner() {
        //when submit button is in modal, modal closes/spinner disappears before hide is called
        if (this.$('.loading-spinner')) {
          this.$('.loading-spinner').addClass('none');
        }
      },

      canSubmit: function canSubmit() {
        var btn = this.$('.submit-btn');
        btn.removeClass('disabled');
      },

      forceDisableButton: function forceDisableButton() {
        var btn = this.$('.submit-btn');
        btn.addClass('disabled');
      },

      showError: function showError() {
        var spinner = this.$('.loading-spinner');
        var error = this.$('.error-label');

        this.set('loading', false);

        spinner.addClass('none');
        error.css('display', 'inline-block');
        _ember['default'].run.later(this, function () {
          $(error).fadeOut();
        }, 2000);
        // this.$('.submit-btn').addClass('disabled');
      },

      showCustomError: function showCustomError(errorMssg) {
        var spinner = this.$('.loading-spinner');
        var error = this.$('.error-label');

        this.set('loading', false);

        spinner.addClass('none');
        error.css('display', 'inline-block');
        error.html('<i class="fa fa-exclamation-triangle orange"></i> ' + errorMssg);
        _ember['default'].run.later(this, function () {
          $(error).fadeOut();
        }, 2000);
        // this.$('.submit-btn').addClass('disabled');
      }
    }
  });
});