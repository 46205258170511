define('web-app-frontend/components/user-address-form', ['exports', 'ember', 'web-app-frontend/controllers/mixins/countries'], function (exports, _ember, _webAppFrontendControllersMixinsCountries) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendControllersMixinsCountries['default'], {

    actions: {
      updateAddress: function updateAddress(address) {
        var submitBtnComponent = this.get('submitButton');

        // Defines attributes that will display input errors
        // also sets 'commitOnSuccess' to true
        address.setupForCommit();

        address.save().then(function () {
          // when successful
          submitBtnComponent.send('hasSucceeded');
        })['catch'](function (errors) {
          //when not successful
          console.log('userAddressComponent', errors);
          submitBtnComponent.send('showError');
        })['finally'](function () {
          submitBtnComponent.send('hideSpinner');
        });
      },

      validateAddress: function validateAddress() {
        var baseAddress = this.get('address');
        baseAddress.alterAttributes();
        baseAddress.generateHash();

        var matchingRecord = baseAddress.findMatchingRecord();
        if (matchingRecord) {
          baseAddress.mapMatchToBaseRecord(matchingRecord);
        } else {
          var cacheCopy = baseAddress.createCacheCopy();
          cacheCopy.save().then(function () {})['catch'](function () {})['finally'](function () {
            baseAddress.mapFromCopyOnRequestFulfillment(cacheCopy);
          });
        }
        return false;
      }
    }
  });
});