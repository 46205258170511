define('web-app-frontend/components/timeline-node', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['timeline-node', 'text-center'],
    classNameBindings: ['calendarShifted:shifted', 'status', 'nodeClass'],
    shiftCalendar: 'shiftCalendar',

    setStatus: (function () {
      if (this.get('node.completionDate')) {
        this.set('status', 'complete');
      } else if (this.get('node.inProgress') && !this.project.get('onHold')) {
        this.set('status', 'in-progress');
      } else if (this.get('node.inProgress') && this.project.get('onHold')) {
        this.set('status', 'on-hold');
      }
      // other statuses available
      // 'in-progress' 'on-hold'
    }).observes('node').on('init'),

    setNodeWidth: (function () {
      var self = this;
      self.$().css('width', self.get('nodeWidth').toString() + '%');
      if (window.outerWidth > 768) {
        self.$('.node-line').css('width', self.$().outerWidth() + 'px');
      } else {
        self.$('.node-line').css('width', '1px');
      }
    }).observes('nodeWidth'),

    formattedMonth: (function () {
      if (this.get('node.month') && !this.project.get('isFormalQuote')) {
        return this.get('node.month').substring(0, 3);
      } else {
        return "TBD";
      }
    }).property('formattedMonth'),

    formattedDate: (function () {
      if (this.get('node.date') && !this.project.get('isFormalQuote')) {
        return this.get('node.date');
      } else {
        return "";
      }
    }).property('formattedDate'),

    showScrollToContinue: (function () {
      if (this.get('status') === 'in-progress' && this.project.get('isUserEditable')) {
        return true;
      } else {
        return false;
      }
    }).property('showScrollToContinue'),

    didInsertElement: function didInsertElement() {
      if (this.get('nodeWidth')) {
        this.$().css('width', this.get('nodeWidth').toString() + '%');
        if (this.get('nodeWidth') !== 100) {
          $('.node-line').css('width', this.$().width() + 'px');
        }
      }
    },

    willDestroyElement: function willDestroyElement() {
      // removes event listener for resize if component is not on page
      _ember['default'].$(window).off('resize');
    },

    actions: {
      shiftCalendar: function shiftCalendar(shiftContent) {
        this.sendAction('shiftCalendar', shiftContent);
      }
    }
  });
});