define('web-app-frontend/routes/resource-center/services', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    titleToken: 'PCB Assembly Services & Capabilities',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Read about electronics manufacturing service offerings from Screaming Circuits.'
      }
    }]

  });
});