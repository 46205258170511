define('web-app-frontend/components/timeline-content-node', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    showContent: false,
    shiftCalendar: 'shiftCalendar',
    classNames: ['timeline-content'],

    click: function click() {
      this.toggleProperty('showContent');
      this.sendAction('shiftCalendar', this.get('showContent'));
    }
  });
});