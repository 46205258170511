define("web-app-frontend/controllers/register", ["exports", "ember", "web-app-frontend/config/environment", "web-app-frontend/utils/hubspot-utils"], function (exports, _ember, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsHubspotUtils) {
  exports["default"] = _ember["default"].Controller.extend({
    showHearAboutUsDescTemp: false,
    showHearAboutUsSubTemp: false,
    registerPageCaptchaWidgetId: undefined,
    hearAboutUsOptions: [{
      label: "Search Engine",
      value: "SEARCH_ENGINE",
      showHearAboutUsSub: true,
      searchEngine: [{ label: "Google", value: "GOOGLE", showHearAboutUsDesc: false }, { label: "Bing", value: "BING", showHearAboutUsDesc: false }, {
        label: "Duck Duck Go",
        value: "DUCK_DUCK_GO",
        showHearAboutUsDesc: false
      }, {
        label: "Other",
        value: "OTHER",
        showHearAboutUsDesc: true,
        needMoreLabel: "Which Search Engine?"
      }]
    }, {
      label: "Social Media",
      value: "SOCIAL_MEDIA",
      showHearAboutUsSub: true,
      socialMedia: [{ label: "Facebook", value: "FACEBOOK", showHearAboutUsDesc: false }, { label: "Instagram", value: "INSTAGRAM", showHearAboutUsDesc: false }, { label: "LinkedIn", value: "LINKEDIN", showHearAboutUsDesc: false }, { label: "Twitter", value: "TWITTER", showHearAboutUsDesc: false }, {
        label: "Other",
        value: "OTHER",
        showHearAboutUsDesc: true,
        needMoreLabel: "Which Social Media?"
      }]
    }, {
      label: "Screaming Circuits Newsletter",
      value: "SCREAMING_CIRCUITS_NEWSLETTER",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: false
    }, {
      label: "Circuit Talk Publication",
      value: "CIRCUIT_TALK_PUBLICATION",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: false
    }, {
      label: "PCB Fab House",
      value: "PCB_FAB_HOUSE",
      showHearAboutUsSub: true,
      PCBFabHouse: [{ label: "Sunstone", value: "SUNSTONE", showHearAboutUsDesc: false }, {
        label: "Other",
        value: "OTHER",
        showHearAboutUsDesc: true,
        needMoreLabel: "Which PCB Fab House?"
      }]
    }, {
      label: "Parts Vendor",
      value: "PARTS_VENDOR",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Who's Parts Vender?"
    }, {
      label: "Other Website",
      value: "OTHER_WEBSITE",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Which Other Website?"
    }, {
      label: "Sales Rep",
      value: "SALES_REP",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Who's Sales Rep?"
    }, {
      label: "Other Email Newsletter",
      value: "OTHER_EMAIL_NEWSLETTER",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Which Email Newsletter?"
    }, {
      label: "Trade Show",
      value: "TRADE_SHOW",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Which Trade Show?"
    }, {
      label: "Printed Material",
      value: "PRINTED_MATERIAL",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "What Printed Material?"
    }, {
      label: "Personal Or Co-Worker Or Self Referral",
      value: "PERSONAL_OR_COWORKER_REFERRAL",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Who Referred You?"
    }, {
      label: "Milwaukee Electronics",
      value: "MILWAUKEE_ELECTRONICS",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: false
    }, {
      label: "San Diego PCB Design",
      value: "SAN_DIEGO_PCB_DESIGN",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: false
    }, {
      label: "Other Source",
      value: "OTHER_SOURCE",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Please Describe:"
    }, {
      label: "Internet Ad",
      value: "INTERNET_AD",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Which Internet Ad?:"
    }, {
      label: "Other Partner",
      value: "OTHER_PARTNER",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: true,
      needMoreLabel: "Which Partner?:"
    }, {
      label: "Existing Customer",
      value: "EXISTING_CUSTOMER",
      showHearAboutUsSub: false,
      showHearAboutUsDesc: false
    }],
    hearAboutUsSubOptions: [],
    session: _ember["default"].inject.service(),
    passwordsMatch: false,
    applicationController: _ember["default"].inject.controller("application"),
    needMoreLabel: "More Info",

    showHearAboutUsDesc: (function () {
      var showOther = false;
      if (this.get("model.hearAboutUsSub")) {
        var selectedSubOption = this.get("hearAboutUsSubOptions").findBy("value", this.get("model.hearAboutUsSub"));
        if (selectedSubOption) {
          showOther = selectedSubOption.showHearAboutUsDesc;
          if (selectedSubOption.showHearAboutUsDesc === true) {
            //Only change on true to avoid ackward label change while field is hiding
            this.set("needMoreLabel", selectedSubOption.needMoreLabel);
          }
        }
      } else {
        var selectedOption = this.get("hearAboutUsOptions").findBy("value", this.get("model.hearAboutUs"));
        if (selectedOption) {
          showOther = selectedOption.showHearAboutUsDesc;
          if (selectedOption.showHearAboutUsDesc === true) {
            //Only change on true to avoid ackward label change while field is hiding
            this.set("needMoreLabel", selectedOption.needMoreLabel);
            this.set("showHearAboutUsDescTemp", true);
          }
        }
      }
      if (!showOther) {
        if (this.get("model.hearAboutUsDescription")) {
          this.set("model.hearAboutUsDescription", null);
        }
        this.set("needMoreLabel", "");
      }
      this.set("showHearAboutUsDescTemp", showOther);
    }).observes("model.hearAboutUsSub", "model.hearAboutUs"),

    showHearAboutUsSub: (function () {
      var showOther = false;
      var selectedOption;
      if (this.get("model.hearAboutUs")) {
        selectedOption = this.get("hearAboutUsOptions").findBy("value", this.get("model.hearAboutUs"));
        if (selectedOption) {
          showOther = selectedOption.showHearAboutUsSub;
          if (selectedOption.showHearAboutUsSub === true) {
            //Only change on true to avoid ackward label change while field is hiding
            if (selectedOption.searchEngine) {
              this.set("hearAboutUsSubOptions", selectedOption.searchEngine);
              this.set("model.hearAboutUsSub", selectedOption.searchEngine[0].value);
            } else if (selectedOption.socialMedia) {
              this.set("hearAboutUsSubOptions", selectedOption.socialMedia);
              this.set("model.hearAboutUsSub", selectedOption.socialMedia[0].value);
            } else if (selectedOption.PCBFabHouse) {
              this.set("hearAboutUsSubOptions", selectedOption.PCBFabHouse);
              this.set("model.hearAboutUsSub", selectedOption.PCBFabHouse[0].value);
            }
          } else if (selectedOption.showHearAboutUsDesc === true) {
            this.set("showHearAboutUsDescTemp", true);
            this.set("needMoreLabel", selectedOption.needMoreLabel);
            this.set("model.hearAboutUsSub", null);
            this.set("hearAboutUsSubOptions", []);
          }
        }
      }
      if (!showOther && !selectedOption.showHearAboutUsDesc) {
        if (this.get("model.hearAboutUsDescription")) {
          this.set("model.hearAboutUsDescription", null);
        }
        this.set("showHearAboutUsDescTemp", false);
        this.set("showHearAboutUsSubTemp", false);
        this.set("model.hearAboutUsSub", null);
        this.set("hearAboutUsSubOptions", []);
      }
      this.set("showHearAboutUsSubTemp", showOther);
    }).observes("model.hearAboutUs"),

    actions: {
      createUser: function createUser() {
        var grecaptcha = window.grecaptcha;
        var captchaId = this.get("registerPageCaptchaWidgetId");
        var token = grecaptcha.getResponse(captchaId);
        var submitBtnComponent = this.get("submitButton");
        submitBtnComponent.send("forceDisableButton");
        var applicationController = this.get("applicationController");
        var newUser = this.get("model");
        var store = this.get("store");
        var userEmailAddress = newUser.get("emailAddress");
        var userfirstName = newUser.get("firstName");
        var userLastName = newUser.get("lastName");
        var userCompany = newUser.get("companyName");
        var userPhoneNumber = newUser.get("contactPhone");
        var userHearAboutUs = this.get("hearAboutUsOptions").findBy("value", newUser.get("hearAboutUs")).label;
        var userHearAboutUsSub = this.get("hearAboutUsSubOptions").length ? this.hearAboutUsSubOptions.findBy("value", newUser.get("hearAboutUsSub")).label : null;
        var userHearAboutUsDesc = newUser.get("hearAboutUsDescription");
        // These two fields are needed to authenticate user after creation.
        applicationController.set("identification", newUser.get("loginName"));
        applicationController.set("password", newUser.get("passwordUnencrypted"));

        var self = this;
        // Defines attributes that will display input errors
        // also sets 'commitOnSuccess' to true
        newUser.setupForCommit();
        newUser.set("validationToken", this.get("validationToken"));
        newUser.set("recaptchaToken", token);
        newUser.save().then(function () {
          submitBtnComponent.send("hasSucceeded");
          (0, _webAppFrontendUtilsHubspotUtils.callHubspotIdentifyAPI)(userEmailAddress, userfirstName, userLastName, userCompany, userPhoneNumber, userHearAboutUs, userHearAboutUsSub, userHearAboutUsDesc, _webAppFrontendConfigEnvironment["default"].hubspotScreamingCircuitsBusinessUnitId);
          (0, _webAppFrontendUtilsHubspotUtils.callHubspotEventAPI)(_webAppFrontendConfigEnvironment["default"].hubspotRegisterEventId);
        }).then(function () {
          // The code is to route user to the prior screen post user registration.
          // Prior screen's transition is held in application controller's property as
          // current transition. Post registration it is set to attemptedTransition which
          // is re-tried on successful authentication thus routing user back to prior screen.
          var currentTransition = applicationController.get("currentTransition");
          if (currentTransition) {
            if (currentTransition.targetName === "quoting.new" && !$.cookie("currentProjectGUID")) {
              //Attempting transition to quoting.new without projectGuid cookie ends up creating a
              //new project. Ignore setting attemptedTransition in this case.
            } else {
                //Setting attemptedTransition to redirect on the prior screen after successful registration.
                self.get("session").set("attemptedTransition", currentTransition);
              }
          }
          applicationController.send("authenticate");
        }).then(function () {
          store.unloadRecord(newUser);
        })["catch"](function (error) {
          var captchaError = error.errors.find(function (err) {
            return err.source.pointer === "data/attributes/recaptchaToken";
          });

          if (captchaError) {
            // this.set('captchaError', captchaError);
            submitBtnComponent.send("showCustomError", captchaError.title);
          } else {
            submitBtnComponent.send("showError");
          }
          //Handled errors, need this empty function to keep Ember from bubbling them further.
          console.log("user register errors", error);
          // To disable button on error
          // submitBtnComponent.send('forceDisableButton');
        })["finally"](function () {
          submitBtnComponent.send("hideSpinner");
          submitBtnComponent.send("canSubmit");
        });
      },

      matchPassword: function matchPassword() {
        var password = this.get("model");

        if (password.get("passwordUnencryptedConfirm") === password.get("passwordUnencrypted") && password.get("passwordUnencryptedConfirm") !== "") {
          this.set("passwordsMatch", true);
          this.send("validateUser");
        } else {
          this.set("passwordsMatch", false);
        }
      },
      //validateUser is the default focusOut action for fields on the register form
      //takes param triggerElement if the spinner is to be used
      validateUser: function validateUser() {

        var self = this;
        var baseUser = self.get("model"); //Handle to the row in the data store that represents this form
        baseUser.set("validationToken", this.get("validationToken"));
        //Note: I attempted to test for data model change and break out of this if there is none,
        //for example, if the user simply clicks out of the field there would be no need to validate and map data/errors back from cache.
        //But, ember clears the error of an attribute when it is touched. So it IS necessary to refresh the errors when focus leaves
        //a field, otherwise errors are erroneously cleared by clicking in and out of fields.

        //Modifications to the baseUser model
        //Add touched fields(attributes) to the list of attribute errors that are shown on form.baseUser.alterAttributes();
        baseUser.alterAttributes(); //Remove touched fields(attributes) from the list of attribute errors that are hidden on form.
        baseUser.generateHash(); //Create and store a hash that represents the current state of this model.

        //Now, check to see if there are any other user-register-model records in the data store that share this hash...
        var matchingRecord = baseUser.findMatchingRecord();
        if (matchingRecord) {
          //There was a matching cached record...
          baseUser.mapMatchToBaseRecord(matchingRecord);
        } else {
          //There was no matching cached record, we'll clone a new record and send it off to the server
          // self.addSpinner(triggerElement); //Add spinner to the element that triggered this validateUser call
          //Create and setup a clone record of the baseUser record
          var cacheCopy = baseUser.createCacheCopy();
          cacheCopy.save().then(function () {
            console.log("clone user validate success!");
          })["catch"](function () {
            console.log("clone user validate fail!");
          })["finally"](function () {
            //To filter out-of-order returns from the server, we only map to the baseUser when the requestIDs still match
            baseUser.mapFromCopyOnRequestFulfillment(cacheCopy);
            // self.clearSpinners();
          });
        }
        return false; //Stop bubbling the action further up the chain
      },

      validatePassword: function validatePassword() {
        var newUser = this.get("model");

        if (newUser.get("passwordUnencryptedConfirm") && newUser.get("passwordUnencrypted")) {
          this.send("validateUser");
        }
      }
    }
  });
});