define("web-app-frontend/templates/resource-center/privacy-policy", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 72,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/resource-center/privacy-policy.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row mt60 mb40");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ib mr20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "icon-compass-orange font-65");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ib");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h6");
        dom.setAttribute(el5, "class", "underline");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        var el6 = dom.createTextNode("Privacy Policy");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row mt20 mb40");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-md-10");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Your Privacy On ScreamingCircuits.com");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Screaming Circuits® protects the privacy of our employees, our customers and the users of our web sites. We also strongly advocate privacy as a right for all. The following provides an overview of how we protect your privacy during your visit to ScreamingCircuits.com.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Information Collected By ScreamingCircuits.com");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("The purpose of ScreamingCircuits.com is to serve as an informational resource that assists in learning more about Screaming Circuits products and services. It allows users to create quotes, check prices, and monitor orders placed. We use the information collected during the ordering process and when subscribing to our newsletter to send users information about our company, as well as to troubleshoot technical problems. Users may opt out of receiving future mailings; see the \"Opting Out\" section (below) for more information.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("In addition, our site also collects non-personal information through our Internet access logs. When you access ScreamingCircuits.com, non-personal data such as your IP address is automatically collected and stored in our Internet access logs. Typically, an IP address—and the other information stored in the logs—is not considered personally identifiable information.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Data Security");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Screaming Circuits takes every precaution to protect our users' information. When you submit sensitive information via our web site, your information is protected both online and offline. When our forms ask you to enter your sensitive information (credit card number), that information is encrypted and is protected with the best encryption software in the industry: SSL. When you are on a secure page, such as our order form, you will notice that the padlock icon on your browser (Chrome, Firefox, or Microsoft Internet Explorer) becomes locked.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Cookies");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Cookies are small browser files required by the Screaming Circuits web site to temporarily store data during the account creation and quoting processes. Cookies can be used to retrieve certain information previously provided by you to ScreamingCircuits.com, so that you will not need to re-enter it each time you visit the site. Accepting the cookie does not compromise your privacy or the security of your computer; it is simply a way to facilitate your use of our site. Unless you specifically tell us, we will never know who you are, even though we may assign the browser on your computer a cookie. Please remember that you may clear out your browser's cookies at any time after completing your quote on our site (see Help for your browser's settings).");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("How We Use The Information");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We analyze the information that we collect in order to determine what is most effective about our site and to help us identify ways to improve it. We do not sell, rent, loan, trade, or lease any personally identifiable information to any other third party, unless we are legally required to do so.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("However, as with any business, Screaming Circuits, or parts of it, can be sold or transferred. Such sale or transfer would ordinarily include a sale of our customer list, which is an asset of our business. The customer list includes personal information of our customers. In the event of a proposed change of ownership, ScreamingCircuits.com will inform customers via email at least 30 days immediately preceding the date scheduled for the transfer of assets. That notice will inform our customers that they may request removal from our database. On receipt of such request prior to the change of ownership date, the customer will be removed from the database prior to the ownership change.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Children");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("ScreamingCircuits.com's intended audience does not include children. We do not knowingly archive any personal information pertaining to children younger than 13 years of age.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Opting Out");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("This site gives users the option to change or modify information that they previously provided to Screaming Circuits. If you have specific concerns or questions, contact Screaming Circuits customer service.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Correcting/updating Your Information");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("This site gives users the option to change or modify information that they previously provided to Screaming Circuits. Please send an email to ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "mailto:privacy@screamingcircuits.com");
        var el6 = dom.createTextNode("privacy@screamingcircuits.com");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(". for further instructions about updating your information.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Links To Other Sites");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("Our web site contains links to other sites. This privacy statement is for the sole use and purpose of ScreamingCircuits.com. Please be aware that Screaming Circuits is not responsible for the privacy practices or the content on other web sites. We encourage you to read the privacy statements of every web site that collects your personal information.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Notification Of Changes");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("This policy may be revised over time as new features are added to ScreamingCircuits.com. We will post those changes prominently so that you will always be informed of the information that we gather, how we might use it, and whether we will disclose it to third parties.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "article-subhead");
        var el5 = dom.createTextNode("Email, But I Don't Like Spam");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We don't like SPAM any more than you do so we do our best to limit our emails to useful or interesting information. When you register with our site, you are given the opportunity to decline emailings from us. However, you will receive an initial \"Welcome\" email regardless. Further, if you place an order with us, we will communicate with you via email several times throughout the process. This is considered part of the business relationship and can not be opted out of.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "article-p");
        var el5 = dom.createTextNode("We do periodically send out emails of other sorts - newsletters, special offers, announcements or notifications. If you have opted to not receive email from us when registering, or by sending an email to ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "href", "mailto:privacy@screamingcircuits.com");
        var el6 = dom.createTextNode("privacy@screamingcircuits.com");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(", you will not receive newsletters, special offers or general announcements. In some cases, the notifications will cover information about your account or your ability to continue to use the site. In these limited cases, you may still receive email communications from us.");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1, 1, 3, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        return morphs;
      },
      statements: [["inline", "link-to", ["Back to Resource Center", "resource-center"], [], ["loc", [null, [8, 30], [8, 85]]]], ["content", "marketing-footer", ["loc", [null, [71, 0], [71, 20]]]]],
      locals: [],
      templates: []
    };
  })());
});