define("web-app-frontend/adapters/bom-line", ["exports", "web-app-frontend/adapters/quoting-parent", "web-app-frontend/config/environment"], function (exports, _webAppFrontendAdaptersQuotingParent, _webAppFrontendConfigEnvironment) {
  exports["default"] = _webAppFrontendAdaptersQuotingParent["default"].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },

    createRecord: function createRecord() {
      return true;
    },

    updateRecord: function updateRecord() {
      return true;
    },

    query: function query(store, typeClass, _query) {
      var bmhId = _query.bomheaderId;
      var projectguid = _query.projectGUID;
      var lineNumber = _query.lineNumber;

      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + '/api/project/bomheader/' + bmhId + '/' + projectguid + '/' + lineNumber;

      return this.ajax(url, "GET");
    }
  });
});