define('web-app-frontend/components/sticky-faq-topics', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    scrollToTitle: 'scrollToTitle',

    didInsertElement: function didInsertElement() {
      var self = this;

      if ($(window).width() >= 992) {
        this.initializeSticky();

        _ember['default'].$(window).resize(function () {
          if ($(window).width() <= 992) {
            $('.sticky-column').trigger('sticky_kit:detach');
          } else {
            self.initializeSticky();
          }
        });
      }
    },

    willDestroyElement: function willDestroyElement() {
      // removes event listener for resize if component is not on page
      _ember['default'].$(window).off('resize');
    },

    initializeSticky: function initializeSticky() {
      $('.sticky-column').stick_in_parent({
        offset_top: 20,
        disable_spacer_position: true
      });
    },

    actions: {
      scrollToTitle: function scrollToTitle(titleName) {
        this.sendAction('scrollToTitle', titleName);
      }
    }
  });
});