define('web-app-frontend/components/quote-pricing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    openModal: 'openModal',
    openTaxShippingEstimateModal: 'openTaxShippingEstimateModal',
    toggleComponent: 'toggleComponent',
    transitionToOrder: 'transitionToOrder',
    transitionToRoute: 'transitionToRoute',
    toggleSkip: 'toggleSkip',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    actions: {
      priceOptionsFromCarousel: function priceOptionsFromCarousel(priceOptions) {
        this.set('priceOptions', priceOptions);
      },

      openModal: function openModal(modal, model) {
        this.sendAction('openModal', modal, model);
      },

      openTaxShippingEstimateModal: function openTaxShippingEstimateModal(model) {
        this.sendAction('openTaxShippingEstimateModal', model);
      },

      transitionToOrder: function transitionToOrder(model) {
        this.sendAction('transitionToOrder', model);
      },

      transitionToRoute: function transitionToRoute(route) {
        this.sendAction('transitionToRoute', route);
      },

      toggleSkip: function toggleSkip(section, removal) {
        this.sendAction('toggleSkip', section, removal);
      }
    }
  });
});