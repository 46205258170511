define('web-app-frontend/adapters/project-checkout-message', ['exports', 'web-app-frontend/adapters/quoting-parent'], function (exports, _webAppFrontendAdaptersQuotingParent) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },

    buildURL: function buildURL(type, id) {
      var url = 'project/' + id + '/checkoutmessage';
      return this._super(url);
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id), "GET");
    }
  });
});