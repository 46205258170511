define('web-app-frontend/components/credit-card', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        cardSelectionHandler: 'cardSelectionHandler',

        isSelected: false,

        //flags for showing Different Logo for differet card types
        isMasterCard: false,
        isVisaCard: false,
        isAmericanExpressCard: false,
        isDiscoverCard: false,
        isJCBCard: false,
        isEnRouteCard: false,
        isDinersClubCard: false,

        isCardSelected: (function () {
            if (this.get('availableCard.paymentId') === this.get('selectedPaymentId')) {
                this.set('isSelected', true);
            } else {
                this.set('isSelected', false);
            }
        }).observes('selectedPaymentId', 'availableCard').on('init'),

        setCardType: (function () {
            if (this.get('availableCard.cardType') !== null || this.get('availableCard.cardType') !== "") {

                if (this.get('availableCard.cardType') === "M") {
                    this.set('isMasterCard', true);
                } else if (this.get('availableCard.cardType') === "V") {
                    this.set('isVisaCard', true);
                } else if (this.get('availableCard.cardType') === "A") {
                    this.set('isAmericanExpressCard', true);
                } else if (this.get('availableCard.cardType') === "DS") {
                    this.set('isDiscoverCard', true);
                } else if (this.get('availableCard.cardType') === "J") {
                    this.set('isJCBCard', true);
                } else if (this.get('availableCard.cardType') === "E") {
                    this.set('isEnRouteCard', true);
                } else if (this.get('availableCard.cardType') === "DN") {
                    this.set('isDinersClubCard', true);
                }
            }
        }).observes('availableCard').on('init'),

        actions: {
            cardSelectionHandler: function cardSelectionHandler(paymentId) {
                this.get('targetObject').send("handleCardSelection", paymentId);
            }
        }
    });
});