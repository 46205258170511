define('web-app-frontend/components/homepage-alert', ['exports', 'web-app-frontend/config/environment', 'ember'], function (exports, _webAppFrontendConfigEnvironment, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['homepage-alert', 'mt10', 'full-width'],
    classNameBindings: ['alertActive::inactive'],
    alertActive: false,

    lookForFlashMessage: (function () {
      if ($.cookie('userDismissedHomePageAlert')) {
        return;
      }
      var self = this;
      var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/system/flashMessage?Api-Key=' + _ember['default'].ENV.client_id;

      $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json'
      }).done(function (data) {
        if (!_ember['default'].isEmpty(data.messageModel.flashMessage)) {
          self.set('flashMessage', data.messageModel.flashMessage);
          self.set('alertActive', true);
        }
        // }).fail(function(data, textStatus, errorThrown) {
      }).fail(function () {
        self.set('alertActive', false);
      });
    }).on('didInsertElement'),

    actions: {
      closeAlert: function closeAlert() {
        this.set('alertActive', false);
        $.cookie('userDismissedHomePageAlert', true, { path: '/', expires: 1 });
      }
    }
  });
});