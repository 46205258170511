define('web-app-frontend/serializers/user-session', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, typeClass, payload, id) {
      //this is present if a user has started a quote anonymously and then logs in
      if (payload.userOrder.id) {
        payload = payload.userOrder;
        var serializer = store.serializerFor('quote');
        var json = serializer.normalizeCreateRecordResponse(store, 'order', payload);
        store.push(json);
      }
      payload = { userSession: { id: id } };
      return this._super(store, 'userSession', payload);
    }
  });
});