define('web-app-frontend/serializers/order-kit', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      kitBoxes: { embedded: 'always' }
    },

    serialize: function serialize(snapshot, options) {
      var json = this._super(snapshot, options);
      json.boxes = json.kitBoxes;
      delete json.kitBoxes;
      return json;
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload, id) {
      var baseRecord = store.peekRecord('order-kit', id);
      var cacheCopy = baseRecord.findMatchingRecord();
      var orderKit = payload.kitModel;

      if (orderKit.commitOnSuccess === false) {
        orderKit.id = cacheCopy.get('requestID');
      } else {
        orderKit.id = id;
      }

      delete orderKit.boxes;

      payload = { orderKit: orderKit };
      console.log('payload: ', payload);
      return this._super(store, typeClass, payload, id);
    },

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, id, requestType) {
      var orderKit = payload.kitModel;
      var boxesData = [];
      orderKit.kitBoxes = [];
      orderKit.id = id;
      orderKit.projectGUID = id;
      if (orderKit.boxes) {
        boxesData = orderKit.boxes.data;
        boxesData.forEach(function (box) {
          orderKit.kitBoxes.push(box['id']);
        });
      }
      delete orderKit.boxes;

      payload = { orderKit: orderKit, kitBoxes: boxesData };
      return this._super(store, primaryModelClass, payload, id, requestType);
    }
  });
});