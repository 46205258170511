define('web-app-frontend/controllers/project', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    createNewProject: function createNewProject(deferred) {
      var controller = this;
      this.store.createRecord('project', { sessionGUID: $.cookie('sessionGUID'), hubSpotConversationId: $.cookie('hubSpotConversationId') }).save().then(function (projectResponse) {
        projectResponse.set('hubSpotConversationId', '');
        controller.createProjectGUIDCookie(projectResponse.get('projectGUID'), deferred);
      });
    },

    findOrCreateProject: function findOrCreateProject(deferred) {
      var store = this.store;
      var self = this;

      if ($.cookie('currentProjectGUID')) {

        console.log('current project cookie present');
        var currentProjectGUID = $.cookie('currentProjectGUID');

        store.findRecord('project', currentProjectGUID).then(function (project) {
          console.log('project found', project);
          var projectGUID = project.get('id');
          self.resolveDeferred(deferred, projectGUID);
        });
      } else {
        self.createNewProject(deferred);
      }
    },

    createProjectGUIDCookie: function createProjectGUIDCookie(projectGUID, deferred) {
      // any pre-existing cookie should be removed by the resetController function in quote.new route.
      $.removeCookie('currentProjectGUID', { path: '/' });
      $.cookie('currentProjectGUID', projectGUID, { expires: 1, path: '/' });
      this.resolveDeferred(deferred, projectGUID);
    },

    resolveDeferred: function resolveDeferred(deferred, projectGUID) {
      deferred.resolve(projectGUID);
    },

    setClientState: function setClientState(projectGUID, section) {
      var project = this.store.peekRecord('project', projectGUID);
      project.set('clientState', section);
      project.save().then(function () {
        console.log('clientState successfully set');
      })['catch'](function (errors) {
        console.log('clientState failed', errors);
      });
    },

    actions: {
      // This logic is duplicated in the empty-box view as well
      startNewQuote: function startNewQuote() {
        $.removeCookie('currentProjectGUID', { path: '/' });
        this.transitionToRoute('quoting.new');
      }
    }
  });
});