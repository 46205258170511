define('web-app-frontend/controllers/modals/search-results', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    pageCount: 1,

    getSearchResults: function getSearchResults(baseSearchUrl, index) {
      $('.modal').animate({ scrollTop: 0 }, 200);
      var self = this;
      var searchUrl = baseSearchUrl + '&start=' + index;

      $.ajax({
        url: searchUrl,
        type: 'GET',
        dataType: 'json'
      }).done(function (data) {
        self.showSearchResults(data, baseSearchUrl);
      });
    },

    showSearchResults: function showSearchResults(data, baseSearchUrl) {
      var title = this.get('model').title;
      var hasNextPage = _ember['default'].isPresent(data.queries.nextPage);
      var hasPreviousPage = _ember['default'].isPresent(data.queries.previousPage);

      var model = { searchResults: data.items,
        title: title,
        hasNextPage: hasNextPage,
        hasPreviousPage: hasPreviousPage,
        baseSearchUrl: baseSearchUrl,
        pageCount: this.get('pageCount'),
        data: data
      };

      this.set('model', model);
    },

    actions: {
      closeSearchModal: function closeSearchModal() {
        this.set('pageCount', 1);
        this.get('applicationController').send('closeModal');
      },

      previousPage: function previousPage(baseSearchUrl, data) {
        var nextPageIndex = data.queries.previousPage.get('firstObject.startIndex');
        this.decrementProperty('pageCount');
        this.getSearchResults(baseSearchUrl, nextPageIndex);
      },

      nextPage: function nextPage(baseSearchUrl, data) {
        var nextPageIndex = data.queries.nextPage.get('firstObject.startIndex');
        this.incrementProperty('pageCount');
        this.getSearchResults(baseSearchUrl, nextPageIndex);
      }
    }
  });
});