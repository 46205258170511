define('web-app-frontend/routes/about/sponsorship', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'about',
    titleToken: 'Sponsorship',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'The Screaming Sponsorship program helps college and non-profit teams push the technology envelope by providing professional assembly expertise.'
      }
    }],

    setupController: function setupController(controller) {
      controller.set('indexName', 'sponsorship');
    }
  });
});