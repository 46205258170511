define('web-app-frontend/serializers/user', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, typeClass, payload, id) {
      var baseUser = store.peekRecord('user', id);
      var cloneUser = baseUser.findMatchingRecord();
      payload.user.id = cloneUser.get('requestID');

      return this._super(store, typeClass, payload);
    }
  });
});