define('web-app-frontend/serializers/tax-shipping-estimate', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var taxShippingEstimate = payload.projectTaxAndShipModel;
      taxShippingEstimate.id = taxShippingEstimate.projectGUID;
      var data = { taxShippingEstimate: taxShippingEstimate };
      return this._super(store, typeClass, data);
    }
  });
});