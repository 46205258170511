define("web-app-frontend/models/user-billing-address", ["exports", "ember-data"], function (exports, _emberData) {
  exports["default"] = _emberData["default"].Model.extend({
    // Attributes
    city: _emberData["default"].attr("string"),
    commitOnSuccess: _emberData["default"].attr("boolean"),
    companyName: _emberData["default"].attr("string"),
    country: _emberData["default"].attr("string", { defaultValue: 'USA' }),
    description: _emberData["default"].attr("string"),
    email: _emberData["default"].attr("string"),
    firstName: _emberData["default"].attr("string"),
    lastName: _emberData["default"].attr("string"),
    phone: _emberData["default"].attr("string"),
    postalCode: _emberData["default"].attr("string"),
    province: _emberData["default"].attr("string"),
    state: _emberData["default"].attr("string"),
    streetAddress1: _emberData["default"].attr("string"),
    streetAddress2: _emberData["default"].attr("string"),
    streetAddress3: _emberData["default"].attr("string"),
    userGUID: _emberData["default"].attr('string'),
    type: _emberData["default"].attr('string'),

    keysToSerialize: ["commitOnSuccess", "city", "companyName", "country", "description", "email", "firstName", "lastName", "phone", "postalCode", "province", "state", "streetAddress1", "streetAddress2", "streetAddress3"]
  });
});