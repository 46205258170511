define("web-app-frontend/adapters/job-file", ["exports", "web-app-frontend/adapters/application", "ember", "web-app-frontend/config/environment"], function (exports, _webAppFrontendAdaptersApplication, _ember, _webAppFrontendConfigEnvironment) {
  exports["default"] = _webAppFrontendAdaptersApplication["default"].extend({
    validationToken: "",

    headers: _ember["default"].computed(function () {
      if (localStorage['ember_simple_auth-session']) {
        return {
          'SCValidationToken': this.get('validationToken'),
          'Authorization': 'Bearer ' + JSON.parse(localStorage['ember_simple_auth-session']).authenticated.access_token
        };
      } else {
        return {
          'SCValidationToken': this.get('validationToken')
        };
      }
    }).property('validationToken', 'session.data.authenticated.access_token').volatile(),

    pathForType: function pathForType() {
      return 'system/jobfiles?Api-Key=' + _webAppFrontendConfigEnvironment["default"].EmberENV.client_id;
    },

    createRecord: function createRecord(store, typeClass, snapshot) {
      var record = snapshot.serialize();
      var data = { 'jobFile': record };
      this.set('validationToken', record.validationToken);
      return this.ajax(this.buildURL(typeClass.modelName), 'PUT', { data: data });
    },

    updateRecord: function updateRecord(store, typeClass, snapshot) {
      var record = snapshot.serialize();
      var data = { 'jobFile': record };
      this.set('validationToken', record.validationToken);
      return this.ajax(this.buildURL(typeClass.modelName), 'PUT', { data: data });
    }
  });
});