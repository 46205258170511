define('web-app-frontend/models/kit-box', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    contents: _emberData['default'].attr('string'),
    from: _emberData['default'].attr('string'),
    received: _emberData['default'].attr('boolean'),

    // Associations
    orderKit: _emberData['default'].belongsTo('order-kit', { async: false }),

    keysToSerialize: ['from', 'contents'],

    boxError: (function () {
      //box error is only returned once, for whole parent model orderKit, so here we
      //determine if this particular box should show the "all details are required for kit boxes" error
      var hasEmptyField = _ember['default'].isEmpty(this.get('from')) || _ember['default'].isEmpty(this.get('contents'));
      var userTouchedFrom = this.get('userAlteredAttributes').contains('from');
      var userTouchedContents = this.get('userAlteredAttributes').contains('contents');
      return hasEmptyField && (userTouchedFrom || userTouchedContents);
    }).property('contents', 'from', 'userAlteredAttributes')
  });
});