define('web-app-frontend/models/user-register-model', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    // Attribtues
    commitOnSuccess: _emberData['default'].attr('boolean'),
    companyName: _emberData['default'].attr('string'),
    contactPhone: _emberData['default'].attr('string'),
    emailAddress: _emberData['default'].attr('string'),
    firstName: _emberData['default'].attr('string'),
    lastName: _emberData['default'].attr('string'),
    loginName: _emberData['default'].attr('string'),
    mobile: _emberData['default'].attr('string'),
    passwordUnencrypted: _emberData['default'].attr('string'),
    passwordUnencryptedConfirm: _emberData['default'].attr('string'),
    title: _emberData['default'].attr('string'),
    hearAboutUs: _emberData['default'].attr('string', { defaultValue: "SEARCH_ENGINE" }),
    hearAboutUsSub: _emberData['default'].attr('string', { defaultValue: "GOOGLE" }),
    hearAboutUsDescription: _emberData['default'].attr('string'),
    validationToken: _emberData['default'].attr('string'),
    recaptchaToken: _emberData['default'].attr('string'),

    keysToSerialize: ["commitOnSuccess", "companyName", "contactPhone", "emailAddress", "firstName", "lastName", "loginName", "mobile", "passwordUnencrypted", "passwordUnencryptedConfirm", "title", "hearAboutUs", "hearAboutUsSub", "hearAboutUsDescription", "recaptchaToken"]
  });
});