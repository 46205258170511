define('web-app-frontend/components/price-difference', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'span',

    modelType: _ember['default'].computed.alias('model.constructor.modelName'),
    basePrice: null,

    calculatePriceDifference: (function () {
      if (this.checkForValidity()) {
        this.manageSpinner(true);
        this.checkForPriceDifference();
      } else {
        this.manageSpinner(false);
        this.set('calculatedPriceDifference', '');
      }
    }).observes('model.triggerPriceDifference'),

    checkForValidity: function checkForValidity() {
      // first check if model is present and section is visible.
      if (!this.model || this.model.get('skipCheckPriceDifferences') || !this.model.get('isValid')) {
        return false;
      }

      if (this.model.get('constructor.modelName') === 'quote-fabrication-pricing') {

        return this.get('model.hasValidTurnTime') && this.model.get('quoteTotal') > 0 && this.get('targetObject.showFabrication');
      } else if (this.model.get('constructor.modelName') === 'quote-assembly-pricing') {

        return this.model.get('assemblySubtotal') > 0 && this.get('targetObject.showAssembly');
      }
    },

    manageSpinner: function manageSpinner(status) {
      //Check to ensure that component is still visible before setting value
      if (!this.get('removedFromDOM')) {
        this.set('showSpinner', status); // set value of spinner alongside price difference
      }
    },

    // We need to create a new record instead of take base price off of this.model to ensure that
    // price differences still calculate correctly when the upgrade option has already been chosen.
    getBasePrice: function getBasePrice(deferred) {
      var self = this;
      var basePrice;
      var comparisonRecord = this.createComparisonRecord();
      comparisonRecord.set(this.get('attr'), this.get('baseValue'));
      comparisonRecord.generateHash();
      var matchingRecord = comparisonRecord.findMatchingRecord();
      if (matchingRecord) {
        comparisonRecord.mapMatchToBaseRecord(matchingRecord);
        basePrice = matchingRecord.get('quoteTotal') || matchingRecord.get('assemblySubtotal');
        self.set('basePrice', basePrice);
        deferred.resolve(comparisonRecord);
      } else {
        var cacheCopy = comparisonRecord.createCacheCopy(false, false);
        cacheCopy.save().then(function (cacheCopy) {
          basePrice = cacheCopy.get('quoteTotal') || cacheCopy.get('assemblySubtotal');
          self.set('basePrice', basePrice);
          comparisonRecord.mapFromCopyOnRequestFulfillment(cacheCopy);
          deferred.resolve(comparisonRecord);
        })['catch'](function (errors) {
          console.log('price-difference failed', errors);
          comparisonRecord.mapFromCopyOnRequestFulfillment(cacheCopy);
          self.manageSpinner(false);
        });
      }
    },

    createComparisonRecord: function createComparisonRecord() {
      var comparisonRecord;
      var store = this.get('parentView.targetObject.store');

      if (this.get('modelType') === 'quote-assembly-pricing') {
        comparisonRecord = store.createRecord('assembly-price-difference', this.get('model').toJSON());
      } else {
        comparisonRecord = store.createRecord('fabrication-price-difference', this.get('model').toJSON());

        // Needed due to settings of parent model in quote-fab-pricing adapter needed to retrieve all turntimes at once.
        if (!comparisonRecord.get('turnTime')) {
          comparisonRecord.set('turnTime', this.model.get('userSelectedTurnTime'));
        }
      }
      return comparisonRecord;
    },

    checkForPriceDifference: function checkForPriceDifference() {
      var self = this;
      var basePriceDeferred = new _ember['default'].RSVP.defer('get price-difference base price');
      this.getBasePrice(basePriceDeferred);

      basePriceDeferred.promise.then(function (comparisonRecord) {
        comparisonRecord.set(self.get('attr'), self.get('alternateValue'));
        comparisonRecord.generateHash();
        var matchingRecord = comparisonRecord.findMatchingRecord();
        if (matchingRecord) {
          var upgradePrice = matchingRecord.get('quoteTotal') || matchingRecord.get('assemblySubtotal');
          self.send('setPriceDifference', self.get('basePrice'), upgradePrice);
          comparisonRecord.mapMatchToBaseRecord(matchingRecord);
        } else {
          var cacheCopy = comparisonRecord.createCacheCopy(false, false);
          cacheCopy.save().then(function (cacheCopy) {
            var upgradePrice = cacheCopy.get('quoteTotal') || cacheCopy.get('assemblySubtotal');
            self.send('setPriceDifference', self.get('basePrice'), upgradePrice);
          })['catch'](function (errors) {
            console.log('price-difference failed', errors);
            self.manageSpinner(false);
          })['finally'](function () {
            comparisonRecord.mapFromCopyOnRequestFulfillment(cacheCopy);
          });
        }
      });
    },

    actions: {

      setPriceDifference: function setPriceDifference(basePrice, upgradePrice) {
        // only set price difference if component is visible in DOM.
        if (this.get('isVisible')) {
          var priceDifference = (upgradePrice - basePrice).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          if (priceDifference !== 'NaN' && priceDifference > "0") {
            this.set('calculatedPriceDifference', '[ ' + 'Adds $' + priceDifference + ' ]');
            this.manageSpinner(false);
          } else {
            this.manageSpinner(false);
            this.set('calculatedPriceDifference', '');
          }
        }
      }
    }
  });
});