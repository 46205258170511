define('web-app-frontend/components/order-billing', ['exports', 'ember', 'web-app-frontend/controllers/mixins/countries', 'web-app-frontend/mixins/analytics', 'web-app-frontend/config/environment'], function (exports, _ember, _webAppFrontendControllersMixinsCountries, _webAppFrontendMixinsAnalytics, _webAppFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendControllersMixinsCountries['default'], _webAppFrontendMixinsAnalytics['default'], {
    checkoutComplete: 'checkoutComplete',
    close: 'close',
    openModal: 'openModal',
    setClientStateOnProject: 'setClientStateOnProject',
    setSectionErrorState: 'setSectionErrorState',
    toggleComponent: 'toggleComponent',
    transitionToQuote: 'transitionToQuote',
    showSpinnerForPaymentForm: false,

    session: _ember['default'].inject.service(),

    fetchOauthToken: function fetchOauthToken() {
      var tokenString = '';
      if (this.get('session.isAuthenticated')) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    setBillingSameAsShipping: (function () {
      console.log("USE SAME ADDRESS AS SHIPPING!");
      var store = this.get('targetObject.store');
      var shippingAddress = store.peekRecord('project-shipping', this.get('project.id'));

      this.get('model').set("streetAddress1", shippingAddress.get('streetAddress1'));
      this.get('model').set("streetAddress2", shippingAddress.get('streetAddress2'));
      this.get('model').set("streetAddress3", shippingAddress.get('streetAddress3'));
      this.get('model').set("city", shippingAddress.get('city'));
      this.get('model').set("state", shippingAddress.get('state'));
      this.get('model').set("province", shippingAddress.get('province'));
      this.get('model').set("country", shippingAddress.get('country'));
      this.get('model').set("postalCode", shippingAddress.get('postalCode'));
    }).observes('sameBillingAddress'),

    //a promotion record must be submitted but cannot be fetched, so if a user is returning to an order form
    //with a previously set promo code, we must pull it off of the project
    setExistingPromotion: (function () {
      if (this.project && this.project.get('promoCode') && this.promotion && !this.promotion.get('promotionCode')) {
        this.promotion.set('promotionCode', this.project.get('promoCode'));
      }
    }).observes('project.promoCode'),

    raiseValidateAddressAction: function raiseValidateAddressAction() {
      var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      this.send('validateAddress', all);
    },

    actions: {
      addNewCard: function addNewCard() {
        var _this = this;

        if (this.get('model.paymentType') === 'CreditCard') {
          (function () {
            //TODO: handle NEW CARD scenario using a button instead
            var self = _this;

            self.set('showSpinnerForPaymentForm', true);

            var oauthToken = _this.fetchOauthToken();

            var url = _webAppFrontendConfigEnvironment['default'].screamingCircuitsApiUrl + '/api/project/' + _this.project.get('id') + '/getEbizForm?Api-Key=SCWEB&SessionGUID=' + $.cookie('sessionGUID');
            $.ajax({
              url: url,
              type: 'GET',
              contentType: 'application/json; charset=UTF-8',
              cache: false,
              headers: oauthToken,
              dataType: 'json'
            }).done(function (data) {
              self.sendAction('openModal', 'modals/payment-modal', { paymentUrl: data.model, project: self.project, orderBilling: self.get('model') });
            }).fail(function () {
              self.sendAction('openModal', 'modals/alert-modal', { text: 'Our online payment system is currently unavailable. Please select "Contact me about payment" option to proceed further.',
                customTitle: 'Payment-Service Down Alert' });
            }).always(function () {
              self.set('showSpinnerForPaymentForm', false);
            });
          })();
        }
      },

      handleCardSelection: function handleCardSelection(paymentId) {
        var self = this;
        //calling GET:PaymentForm here and opens Payment Modal
        if (self.get('model.paymentType') === 'CreditCard') {
          (function () {
            //TODO: handle NEW CARD scenario using a button instead
            //updating model with the selectedCard details
            var selectedPaymentId = paymentId;
            var selectedCard = self.get('model.availableCards').filter(function (x) {
              return x.paymentId === selectedPaymentId;
            })[0];
            self.set('model.creditCardExpirationMonth', selectedCard.cardExpirationMonth);
            self.set('model.selectedPaymentId', selectedPaymentId);
            self.set('model.creditCardExpirationYear', selectedCard.cardExpirationYear);
            self.set('model.creditCardNumberObfuscated', selectedCard.cardNumberObfuscated);
            self.set('model.creditCardNumberPlain', selectedCard.cardNumberObfuscated);
            self.set('model.creditCardType', selectedCard.cardType);
            self.set('model.nameOnCard', selectedCard.nameOnCard);
            self.set('model.streetAddress1', selectedCard.streetAddress);
            self.set('model.postalCode', selectedCard.postalCode);
          })();
        }
      },

      performAction: function performAction(action, actionParam) {
        this.sendAction('setClientStateOnProject', actionParam);
        this.sendAction(action, actionParam);
      },

      preventValidationOnToggle: function preventValidationOnToggle() {
        this.set('preventValidationOnToggle', true);
      },

      saveAndPerformAction: function saveAndPerformAction(action, actionParam) {
        var billingInfo = this.model;
        var self = this;
        var quoteSubmitButton = this.get('quoteSubmitButton');

        billingInfo.setupForCommit();
        billingInfo.save().then(function () {
          self.send('performAction', action, actionParam);
          billingInfo.reload();
        })['catch'](function (errors) {
          console.log('saveAndPerformAction Failed', errors);
        })['finally'](function () {
          quoteSubmitButton.send('canSubmit');
          billingInfo.set('commitOnSuccess', false);
        });
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      },

      validateOrderBilling: function validateOrderBilling() {
        if (!this.get('preventValidationOnToggle')) {
          var billingInfo = this.model;
          billingInfo.alterAttributes();
          billingInfo.save().then(function () {})['catch'](function (error) {
            console.log('validate order billing failed', error);
          });
        } else {
          this.set('preventValidationOnToggle', false);
        }
      },

      //Parts quorting changes above

      addPurchaseOrderNumber: function addPurchaseOrderNumber(spinnerDeferred) {

        if (this.get('model.purchaseOrderNumber')) {
          this.set('hasPurchaseOrderNumber', true);
        }

        if (spinnerDeferred) {
          spinnerDeferred.resolve();
        }
      },

      applyPromoCode: function applyPromoCode(spinnerDeferred) {
        var promotion = this.get('promotion');
        var self = this;
        promotion.alterAttributes();
        promotion.save().then(function () {
          self.send('hasSucceeded');

          if (spinnerDeferred) {
            spinnerDeferred.resolve();
          }
        })['catch'](function (error) {
          console.log('promotion save something went wrong', error);
          if (spinnerDeferred) {
            spinnerDeferred.reject();
          }
        });
      },

      cancelChange: function cancelChange() {
        //rollback to last persisted state
        if (this.project.get('promoCode')) {
          this.promotion.set('promotionCode', this.project.get('promoCode'));
        } else {
          this.promotion.set('promotionCode', '');
        }
      },

      cancelPurchaseOrderChange: function cancelPurchaseOrderChange() {
        if (!this.get('hasPurchaseOrderNumber')) {
          this.get('model').set('purchaseOrderNumber', '');
        }
      },

      hasSucceeded: function hasSucceeded() {
        var successLabel = this.$('.success-label');
        $(successLabel).css('display', 'inline-block');
        _ember['default'].run.later(this, function () {
          $(successLabel).fadeOut();
        }, 2000);
        // this.$('.submit-btn').addClass('disabled');
      },

      linkToCancelAction: function linkToCancelAction(action) {
        this.send(action);
      },

      linkToInputButtonAction: function linkToInputButtonAction(action, spinnerDeferred) {
        this.send(action, spinnerDeferred);
      },

      openModal: function openModal(modalTemplate, model) {
        this.sendAction('openModal', modalTemplate, model);
      },

      /*
      performAction: function(action, actionParam, deferred) {
        this.send(action, actionParam, deferred);
      },
        performAction: function(action, actionParam) {
        this.sendAction('setClientStateOnProject', actionParam);
        this.sendAction(action, actionParam);
      },
        preventValidationOnToggle: function() {
        this.set('preventValidationOnToggle', true);
      },
        */

      removePurchaseOrderNumber: function removePurchaseOrderNumber() {
        this.get('model').set('purchaseOrderNumber', '');
        this.set('hasPurchaseOrderNumber', false);
      },

      removePromoCode: function removePromoCode() {
        var promotion = this.get('promotion');
        // We need to POST this first in order to avoid Ember's default of just removing
        // any unsaved records from the store w/out triggering the adapter response.
        var deferred = new _ember['default'].RSVP.defer('remove Promo');
        this.send('openModal', 'modals/confirmation-modal', { text: 'Are you sure you want to remove this promo?',
          customSubmitText: 'Yes, continue to remove',
          deferredObject: deferred });
        deferred.promise.then(function () {
          promotion.save().then(function () {
            promotion.destroyRecord().then(function () {
              console.log('promotion destroyed');
              //once it's been reported that the record was deleted remotely, the local record
              //is technically no longer in an editable state. Since no remote ids are in place for
              //these records, there's not reason to not reuse it. So we transition the record back
              //into an editable state, instead of unloading and recreating a new one.
              promotion.transitionTo('loaded.saved');
            })['catch'](function (error) {
              console.log('promotion delete something went wrong', error);
            });
          })['catch'](function (error) {
            //promotion code in post probably wasn't valid, and was thus not saved server side, so
            //not possible to delete server side, but we still want to clear out the input
            promotion.set('promotionCode', '');
            console.log('promotion save something went wrong', error);
          });
        });
      },

      validateAddress: function validateAddress() {
        var all = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

        if (!this.get('preventValidationOnToggle')) {
          var billingInfo = this.get('model');
          billingInfo.setCreditCardType();
          billingInfo.alterAttributes(all);
          billingInfo.save().then(function () {})['catch'](function (error) {
            console.log('validate billing address failed', error);
          });
        } else {
          this.set('preventValidationOnToggle', false);
        }
      },

      transitionToQuote: function transitionToQuote() {
        this.sendAction('transitionToQuote');
      }
    }
  });
});