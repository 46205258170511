define('web-app-frontend/models/quote-assembly-pricing', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    assemblyNotes: _emberData['default'].attr('string'),
    assemblyServiceCode: _emberData['default'].attr('string', { defaultValue: '' }),
    assemblyServiceName: _emberData['default'].attr('string'),
    assemblySubtotal: _emberData['default'].attr('number'),
    assemblySubtotalDisplay: _emberData['default'].attr('string', { defaultValue: '$0.00' }),
    assemblyTotal: _emberData['default'].attr('number'),
    assemblyCostPerBoard: _emberData['default'].attr('number'),
    bgaCount: _emberData['default'].attr('number'),
    customAssemblyNotes: _emberData['default'].attr('string'),
    customAssemblyQuote: _emberData['default'].attr('boolean', { defaultValue: false }),
    invalidatedQuoteTotal: _emberData['default'].attr('number'),
    finePitchCount: _emberData['default'].attr('number'),
    hasBGA: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasDoubleSidedSMT: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasDoubleSidedThruHole: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasLeadFree: _emberData['default'].attr('boolean', { defaultValue: true }),
    hasClass3: _emberData['default'].attr('boolean', { defaultValue: false }),
    isClone: _emberData['default'].attr('boolean', { defaultValue: false }),
    isITAR: _emberData['default'].attr('boolean', { defaultValue: false }),
    isFlexBoard: _emberData['default'].attr('boolean', { defaultValue: false }),
    isReorder: _emberData['default'].attr('boolean'),
    projectGUID: _emberData['default'].attr('string'),
    quantity: _emberData['default'].attr('number'),
    smtCount: _emberData['default'].attr('number'),
    thruHoleCount: _emberData['default'].attr('number', { defaultValue: 0 }),
    totalPlacements: _emberData['default'].attr('number', { defaultValue: 1 }),
    turnDays: _emberData['default'].attr('number'),
    turnDaysDisplay: _emberData['default'].attr('string'),
    turnTimeCode: _emberData['default'].attr('string'),
    uniquePartCount: _emberData['default'].attr('number', { defaultValue: 1 }),

    userHasSelectedTurnTime: _emberData['default'].attr('boolean'),
    turnTimeDropdownEnabled: _emberData['default'].attr('boolean', { defaultValue: true }),
    userSelectedTurnTime: _emberData['default'].attr('string'),
    triggerPriceDifference: _emberData['default'].attr('boolean', { defaultValue: false }),
    skipCheckPriceDifferences: _emberData['default'].attr('boolean', { defaultValue: false }),
    triggerTurnTimeReload: _emberData['default'].attr('boolean', { defaultValue: false }),
    priceDifferenceReferenceID: _emberData['default'].attr('string'),
    productLifecycle: _emberData['default'].attr('string'),

    keysToSerialize: ["hasBGA", "hasClass3", "hasDoubleSidedSMT", "hasDoubleSidedThruHole", "hasLeadFree", "isITAR", "projectGUID", "quantity", "thruHoleCount", "totalPlacements", "turnTimeCode", "uniquePartCount", "customAssemblyNotes", "customAssemblyQuote", "productLifecycle"],

    // Associations
    // designViolations: DS.hasMany('design-violation', { async: true }),
    quoteAssembly: _emberData['default'].belongsTo('quote-assembly', { async: true }),
    project: _emberData['default'].belongsTo('project', { async: true }),
    turnTimes: _emberData['default'].hasMany('assembly-turn-time', { async: true }),

    zeroThruHole: (function () {
      return this.get("thruHoleCount") === "0" || !this.get("thruHoleCount");
    }).property('thruHoleCount'),

    updatePerBoardPrice: _emberData['default'].attr('boolean', { defaultValue: false }),
    perBoardPrice: (function () {
      return this.get('assemblyCostPerBoard');
      //return 77.55;
      /*
      var subtotal = this.get('assemblySubtotal');
      var quantity = this.get('quantity');
      if (quantity === "0" || Ember.isBlank(quantity)) {
        return 0;
      } else {
        return (subtotal/quantity).toFixed();
      } */
    }).property('assemblyCostPerBoard'),

    isSquare: (function () {
      var height = this.get('height');
      var width = this.get('width');
      return height === width;
    }).property('height', 'width'),

    boardArea: (function () {
      var height = this.get('height');
      var width = this.get('width');
      return height * width;
    }).property('height', 'width'),

    isDoubleSided: (function () {
      return this.get('hasDoubleSidedSMT') || this.get('hasDoubleSidedThruHole');
    }).property('hasDoubleSidedSMT', 'hasDoubleSidedThruHole'),

    overOneHundredBoards: (function () {
      return this.get('quantity') > 100;
    }).property('quantity'),

    //while this only returns quantity, we needed a property that was only updated when the perBoardPrice
    //changed. Otherwise, quantity would update in the quote footer before the pricing request completed
    quantityOnSubtotalChange: (function () {
      return this.get('quantity');
    }).property('perBoardPrice'),

    // monitorDoubleSidedChildAttributes && monitorDoubleSidedThruHoleBasedUponQuanity are used to control the child
    // radio button values for DoubleSided selections in the quote assembly component.
    monitorDoubleSidedChildAttributes: (function () {
      if (!this.get('isDoubleSided')) {
        this.setProperties({ 'hasDoubleSidedSMT': false,
          'hasDoubleSidedThruHole': false });
      } else {
        this.set('hasDoubleSidedSMT', true);
      }
    }).observes('isDoubleSided'),

    monitorDoubleSidedThruHoleBasedUponQuanity: (function () {
      if (this.get('thruHoleCount') < 1) {
        this.set('hasDoubleSidedThruHole', false);
      }
    }).observes('thruHoleCount'),

    configureToRequestAllTurnTimes: function configureToRequestAllTurnTimes() {
      // setting all of these values to empty strings ensures that we get ALL available turntimes.
      // Selection and association to the correct turntime will take place in the serializer.
      if (this.get('turnTimeCode')) {
        this.setProperties({ userSelectedTurnTime: this.get('turnTimeCode'),
          turnTimeCode: '',
          assemblyServiceCode: '',
          turnDays: '' });
      }
    },

    setupForCommit: function setupForCommit() {
      this.configureToRequestAllTurnTimes();
      this.set('skipCheckPriceDifferences', true);
      this._super();
    },

    checkForOverpriceError: function checkForOverpriceError(turnTimes, selectedTurnTime) {
      if (!_ember['default'].isEmpty(selectedTurnTime)) {
        var designViolations = selectedTurnTime.get('designViolations');
        //combine all of the returned DV attributes into single array.
        var designViolationProperties = [].concat.apply([], designViolations.mapBy('designViolationProperties'));
        //Is there a violation for AssemblySubtotal?
        //This is the only instance when we will 'RubberBand' turntimes for Assembly.
        return designViolationProperties.contains("AssemblySubtotal");
      }
      return true;
    },

    // rubberBandTurnTimes: function(turnTimes) {
    //   //Sort returned turntime options HIGHEST -> LOWEST price;
    //   turnTimes = turnTimes.sortBy('assemblySubtotal').reverse();
    //   var self = this;
    //   var turnTimeFound = turnTimes.any(function(turnTime) {
    //   // Since turnTimes are sorted most expensive -> least expensive, we can iterate through the array until we find a
    //   // turntime that is cheaper than 15K.  This will be our best possible turnTime.
    //     if (turnTime.get('assemblySubtotal') < 15000 && turnTime.get('assemblySubtotal') !== 0) { // if turnTime found
    //       self.updateWithTurnTime(turnTime);
    //       return true;
    //     }
    //   });

    //   //No turntimes are below $15K
    //   if (!turnTimeFound) {
    //     //Select longest (last) turntime from options array and display it's pricepoint to user.
    //     var turnTime = turnTimes.get('lastObject');
    //     turnTime.get('designViolations').any(function(designViolation) {
    //       if (designViolation.designViolationProperties.contains("AssemblySubtotal")) {
    //         self.get('errors').add('AssemblySubtotal', designViolation.designViolationMessage);
    //         return true;
    //       }
    //     });
    //     this.updateWithTurnTime(turnTime);
    //   }
    // },

    selectTurnTime: function selectTurnTime() {
      var selectedTurnTime;
      var turnTimes = this.get('turnTimes');

      this.set('turnTimeCode', this.get('userSelectedTurnTime'));
      var turnTimeCode = this.get('turnTimeCode');

      if (!this.get('userSelectedTurnTime') && !turnTimeCode) {
        selectedTurnTime = turnTimes.filterBy('turnTimeCode', 'FP5').get('firstObject');
      } else {
        var userSelectedTurnTime = this.get('userSelectedTurnTime');
        selectedTurnTime = turnTimes.filterBy('turnTimeCode', userSelectedTurnTime).get('firstObject');
      }

      // var hasOverpriceError = this.checkForOverpriceError(turnTimes, selectedTurnTime);

      // if (hasOverpriceError) {
      //   this.rubberBandTurnTimes(turnTimes);
      // } else {
      this.updateWithTurnTime(selectedTurnTime);

      // A user has selected a different turntime if their quotes turntime code is not default & default is less than 15K which is the only reason it would change automatically.
      if (turnTimeCode !== 'FP5') {
        this.setProperties({ userSelectedTurnTime: selectedTurnTime.get('turnTimeCode'),
          userHasSelectedTurnTime: true });
      }
      // }
    },

    updateWithTurnTime: function updateWithTurnTime(newTurnTime) {
      this.setProperties({ assemblyServiceName: newTurnTime.get('assemblyServiceName'),
        assemblyServiceCode: newTurnTime.get('assemblyServiceCode'),
        turnTimeCode: newTurnTime.get('turnTimeCode'),
        turnDays: newTurnTime.get('turnDays'),
        turnDaysDisplay: newTurnTime.get('turnDaysDisplay'),
        assemblySubtotal: newTurnTime.get('assemblySubtotal'),
        assemblyCostPerBoard: newTurnTime.get('assemblyCostPerBoard'),
        assemblySubtotalDisplay: newTurnTime.get('assemblySubtotalDisplay') });
    },

    validationSuccessEvents: function validationSuccessEvents() {
      this.toggleProperty('triggerTurnTimeReload');
      this.toggleProperty('triggerPriceDifference');
      this.toggleProperty('updatePerBoardPrice');
      this.set('skipCheckPriceDifferences', false);
    },

    hasStandardTurnTime: (function () {
      return this.get('userSelectedTurnTime') === 'FP5';
    }).property('userSelectedTurnTime'),

    //called on a cache copy - to reuse turn times from last valid request so that placeholders appear in the turn time dropdown, but not of them are available
    invalidateTurnTimes: function invalidateTurnTimes(quoteAssemblyPricing) {
      this.set('turnTimes', quoteAssemblyPricing.get('turnTimes'));
      this.get('turnTimes').setEach('designViolations', [{ attribute: "generic", message: "Design violation to invalidate turn time." }]);
    },

    hasValidTurnTimes: function hasValidTurnTimes() {
      var turnTimes = this.get('turnTimes');
      var turnTimeFound = turnTimes.any(function (turnTime) {
        if (turnTime.get('designViolations').length === 0) {
          return true;
        }
      });
      return turnTimeFound;
    }
  });
});