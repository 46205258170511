define('web-app-frontend/serializers/user-credit-detail', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, typeClass, payload, id) {
      var userGUID = id;
      var creditDetails = payload.userCreditDetails;

      // var serverID = creditDetails.id;

      // delete creditDetails.id;

      creditDetails.id = userGUID;
      creditDetails.userGUID = userGUID;
      creditDetails.availableCards = payload.userCreditDetails.availableCards.data;

      payload = { userCreditDetail: creditDetails };

      return this._super(store, typeClass, payload, id);
    },

    normalizeSaveResponse: function normalizeSaveResponse(store, typeClass, payload) {
      var userCard;

      if (payload.userCreditUpdate) {
        userCard = payload.userCreditUpdate;
      } else if (payload.userCreditDetails) {
        userCard = payload.userCreditDetails;
        delete userCard.type;
      }

      // Only records with commitOnSuccess === true will have associated ID attrs.
      if (userCard.commitOnSuccess) {
        payload = { userCreditDetail: userCard };
      } else {
        payload = {};
      }

      return this._super(store, typeClass, payload);
    }
  });
});