define('web-app-frontend/components/sign-in-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    toggleSignIn: 'toggleSignIn',
    openModal: 'openModal',
    signInAndAuthenticate: 'signInAndAuthenticate',
    forgotPassword: 'forgotPassword',
    session: _ember['default'].inject.service(),

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    didInsertElement: function didInsertElement() {
      // make sure that errors and fields are empty
      // when the modal and dropdown sign in enter DOM
      this.$('.sign-in-user').parents('.sign-in').find('.sign-in-user').focus();
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.send('resetFields');
    },

    resolveDeferred: function resolveDeferred() {
      if (this.deferred) {
        this.deferred.resolve();
      }
    },

    //This observer has been set up to resolve deferred that will be responsible for triggering the click of checkout
    // that a user has selected, but needed to login to proceed.
    observeSessionAuthentication: (function () {
      if (this.deferred) {
        this.deferred.resolve();
      }
      if (this.spinnerDeferred) {
        this.spinnerDeferred.resolve();
      }
    }).observes('targetObject.session.isAuthenticated'),

    observeSessionAuthenticationFailed: function observeSessionAuthenticationFailed() {
      if (this.deferred) {
        this.deferred.reject();
      }
      if (this.spinnerDeferred) {
        this.spinnerDeferred.resolve();
      }
    },

    actions: {
      createAccount: function createAccount() {
        this.sendAction('toggleSignIn', 'createAccount');
      },

      signInAndAuthenticate: function signInAndAuthenticate() {
        var self = this;
        var deferred = new _ember['default'].RSVP.defer('login spinner deferred');
        var id = this.get('identification');
        var password = this.get('password');

        this.set('showSpinner', true);
        this.set('spinnerDeferred', deferred);
        this.sendAction('signInAndAuthenticate', id, password, deferred);

        deferred.promise.then(function () {
          self.set('showSpinner', false);
        })['catch'](function () {
          self.set('showSpinner', false);
        });
      },

      forgotPassword: function forgotPassword() {
        this.sendAction('forgotPassword');
      },

      resetFields: function resetFields() {
        this.set('identification', null);
        this.set('password', null);
        this.set('errorMessage', null);
      }
    }

  });
});