define("web-app-frontend/templates/components/timeline-node", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.0",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 1
              },
              "end": {
                "line": 24,
                "column": 1
              }
            },
            "moduleName": "web-app-frontend/templates/components/timeline-node.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "in-progress small lh1 scrollToContinueDesktop");
            var el2 = dom.createTextNode("Scroll to continue checkout");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "in-progress small lh1 scrollToContinueMobile");
            var el2 = dom.createTextNode("(Scroll beyond timeline to continue checkout)");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n		");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "fa fa-arrow-down in-progress mb10");
            dom.setAttribute(el1, "aria-hidden", "true");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/timeline-node.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 0, 0);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["uppercase semi-bold mt5 ", ["get", "status", ["loc", [null, [19, 38], [19, 44]]]]]]], ["inline", "remove-dashes", [["get", "status", ["loc", [null, [19, 64], [19, 70]]]]], [], ["loc", [null, [19, 48], [19, 72]]]], ["block", "if", [["get", "showScrollToContinue", ["loc", [null, [20, 7], [20, 27]]]]], [], 0, null, ["loc", [null, [20, 1], [24, 8]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/timeline-node.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "timeline-content-node", [], ["content", ["subexpr", "@mut", [["get", "node.notes", ["loc", [null, [27, 34], [27, 44]]]]], [], []], "class", "mt20"], ["loc", [null, [27, 2], [27, 59]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/timeline-node.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("p");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "full-width sm-hide");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tableView");
        var el3 = dom.createTextNode("\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "data-toggle", "tooltip");
        dom.setAttribute(el3, "data-placement", "bottom");
        dom.setAttribute(el3, "title", "");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "none sm-show");
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "small muted pb10");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "node-background");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "node");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "semi-bold");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "node-line");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2, 1, 1]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(fragment, [6, 1]);
        var element4 = dom.childAt(element3, [1]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createAttrMorph(element1, 'class');
        morphs[2] = dom.createAttrMorph(element1, 'data-original-title');
        morphs[3] = dom.createMorphAt(element1, 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[6] = dom.createAttrMorph(element4, 'class');
        morphs[7] = dom.createMorphAt(element4, 0, 0);
        morphs[8] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
        morphs[9] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[10] = dom.createMorphAt(fragment, 9, 9, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["content", "node.subTitle", ["loc", [null, [1, 3], [1, 20]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "node.toolTip", ["loc", [null, [4, 18], [4, 30]]]], "mb5 tooltip-hover", "mb5 tooltip-hover decoration-None"], [], ["loc", [null, [4, 13], [4, 88]]]]]]], ["attribute", "data-original-title", ["get", "node.toolTip", ["loc", [null, [4, 167], [4, 179]]]]], ["content", "node.description", ["loc", [null, [4, 182], [4, 202]]]], ["content", "node.description", ["loc", [null, [8, 5], [8, 25]]]], ["content", "node.toolTip", ["loc", [null, [9, 29], [9, 45]]]], ["attribute", "class", ["concat", ["small uppercase semi-bold ", ["subexpr", "if", [["get", "project.isFormalQuote", ["loc", [null, [13, 42], [13, 63]]]], "mt10", ""], [], ["loc", [null, [13, 37], [13, 75]]]]]]], ["content", "formattedMonth", ["loc", [null, [13, 77], [13, 95]]]], ["content", "formattedDate", ["loc", [null, [14, 26], [14, 43]]]], ["block", "if", [["get", "status", ["loc", [null, [18, 6], [18, 12]]]]], [], 0, null, ["loc", [null, [18, 0], [25, 7]]]], ["block", "if", [["get", "node.notes", ["loc", [null, [26, 6], [26, 16]]]]], [], 1, null, ["loc", [null, [26, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});