define("web-app-frontend/templates/components/sign-in-dropdown", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/sign-in-dropdown.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "sign-in-form", [], ["inputPassId", "userPassword-Dropdown", "inputUsernameId", "sign-in-user-Dropdown", "needsRegister", true, "errorMessage", ["subexpr", "@mut", [["get", "errorMessage", ["loc", [null, [1, 123], [1, 135]]]]], [], []], "targetObject", ["subexpr", "@mut", [["get", "controller", ["loc", [null, [1, 149], [1, 159]]]]], [], []], "class", "mb20 mt20"], ["loc", [null, [1, 0], [1, 179]]]]],
      locals: [],
      templates: []
    };
  })());
});