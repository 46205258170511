define('web-app-frontend/controllers/ordering', ['exports', 'ember', 'web-app-frontend/controllers/mixins/activities'], function (exports, _ember, _webAppFrontendControllersMixinsActivities) {
  exports['default'] = _ember['default'].Controller.extend(_webAppFrontendControllersMixinsActivities['default'], {
    contactController: _ember['default'].inject.controller('order/order-contact'),
    kitController: _ember['default'].inject.controller('order/order-kit'),
    kitBoxController: _ember['default'].inject.controller('order/kit-box'),
    notesController: _ember['default'].inject.controller('order/project-notes'),
    fileController: _ember['default'].inject.controller('order/project-file'),
    billingController: _ember['default'].inject.controller('order/billing'),
    shippingController: _ember['default'].inject.controller('order/shipping'),
    promotionController: _ember['default'].inject.controller('order/promotion'),

    showFiles: true,
    showContacts: false,
    showKitted: false,
    showShipping: false,
    showBilling: false,
    showNotes: false,
    showCheckout: false,

    filesEditable: true,
    contactsEditable: false,
    kittedEditable: false,
    shippingEditable: false,
    billingEditable: false,
    notesEditable: false,
    checkoutEditable: false,

    filesError: false,
    contactsError: false,
    kitError: false,
    shippingError: false,
    billingError: false,
    notesError: false,

    isShowController: false,
    reloadTimeLine: false,
    estimatedShipDate: null, //time-line will set this via passEstimatedShipDate action
    showDuplicateSpinner: false,
    showSpinnerForSummary: false,

    //set and used in edit route
    autoTransitionToContacts: false,

    checkoutAssemblyError: false,
    checkoutFabError: false,
    checkoutPartsError: false,
    checkoutQuotingError: _ember['default'].computed.or('checkoutAssemblyError', 'checkoutFabError', 'checkoutPartsError'),
    internalServerError: '',
    anyFormErrors: _ember['default'].computed.or('filesError', 'contactsError', 'kitError', 'shippingError', 'billingError', 'notesError'),
    ownShippingAccount: _ember['default'].computed.equal('shippingAddress.shippingMethod', 'Own Account'),

    openProjectNameModal: (function () {
      if (this.project && !this.project.get('projectName')) {
        this.send('openModal', 'order/project-name-modal', this.project);
      }
    }).observes('project'),

    showPendingTaxExemption: (function () {
      if (this.project) {
        return this.project.get('selfExempt');
      } else {
        return false;
      }
    }).property('project.status', 'project.taxAmountString'),

    openSection: function openSection() {
      if (this.get('showFiles')) {
        return 'files';
      }
      if (this.get('showContacts')) {
        return 'contacts';
      }
      if (this.get('showKitted')) {
        return 'kitted';
      }
      if (this.get('showShipping')) {
        return 'shipping';
      }
      if (this.get('showBilling')) {
        return 'billing';
      }
      if (this.get('showNotes')) {
        return 'notes';
      }
      if (this.get('showCheckout')) {
        return 'checkout';
      }
    },

    printOnTransition: function printOnTransition() {
      if (this.get('printTransition')) {
        this.send('collapseAndPrint');
      }
      this.set('printTransition', false);
    },

    checkoutTitle: (function () {
      if (this.project.get('isFormalQuote')) {
        return "Request a Formal Quote";
      } else {
        return "Starting Your Order";
      }
    }).property('project.isFormalQuote'),

    saveClosingSection: function saveClosingSection(section) {
      switch (section) {
        case 'files':
          return this.saveFilesOnToggleShut();
        case 'contacts':
          return this.saveContactsOnToggleShut();
        case 'kitted':
          return this.saveKitOnToggleShut();
        case 'shipping':
          return this.saveShippingOnToggleShut();
        case 'billing':
          return this.saveBillingOnToggleShut();
        case 'notes':
          return this.saveNotesOnToggleShut();
        default:
          return false;
      }
    },

    saveFilesOnToggleShut: function saveFilesOnToggleShut() {
      var self = this;
      var projectFile = this.get('projectFile');
      if (projectFile.get('showFile') && !projectFile.get('fileName')) {

        this.set('projectFile.filesMissingError', true);
        this.send('setSectionErrorState', 'filesError', true);
      } else if (projectFile.get('showDate')) {
        //projectFile record gets saved on file upload, so this save only needs to be called if the user
        //has opted to provide the file at a future date, and it's saving the date to the record
        projectFile.alterAttributes();
        projectFile.formatDateForSave();
        projectFile.save().then(function () {})['catch'](function (errors) {
          console.log('order-files save failed', errors);
          //Log error but do not notify user.
          self.handleApplicationError("ordering.js --> saveFilesOnToggleShut", errors, false);
        });
      }
    },

    saveContactsOnToggleShut: function saveContactsOnToggleShut() {
      var self = this;
      var orderContact = this.get('orderContact');
      orderContact.setupForCommit();
      orderContact.save().then(function () {})['catch'](function (errors) {
        console.log('save orderContact failed', errors);
        //else validation errors might show in locked section
        if (orderContact.get('nightSameAsDay')) {
          orderContact.clearNighttimeContactErrors();
        }
        //Log error but do not notify user.
        self.handleApplicationError("ordering.js --> saveContactsOnToggleShut", errors, false);
      })['finally'](function () {
        orderContact.set('commitOnSuccess', false);
      });
    },

    saveKitOnToggleShut: function saveKitOnToggleShut() {
      var self = this;
      var orderKit = this.get('orderKit');
      orderKit.setupForCommit();
      orderKit.save(function () {
        self.send('setSectionErrorState', 'kitError', false);
      })['catch'](function (errors) {
        self.send('setSectionErrorState', 'kitError', true);
        console.log('save orderKit failed', errors);
        //Log error but do not notify user.
        self.handleApplicationError("ordering.js --> saveKitOnToggleShut", errors, false);
      })['finally'](function () {
        orderKit.set('commitOnSuccess', false);
      });
    },

    saveShippingOnToggleShut: function saveShippingOnToggleShut() {
      var self = this;
      var shippingAddress = this.get('shippingAddress');
      shippingAddress.setupForCommit();
      shippingAddress.save(function () {})['catch'](function (errors) {
        console.log('save shippingAddress failed', errors);
        //Log error but do not notify user.
        self.handleApplicationError("ordering.js --> saveShippingOnToggleShut", errors, false);
      })['finally'](function () {
        shippingAddress.set('commitOnSuccess', false);
      });
    },

    saveBillingOnToggleShut: function saveBillingOnToggleShut() {
      var self = this;
      var billingInfo = this.get('billingInfo');
      billingInfo.setupForCommit();
      billingInfo.save(function () {})['catch'](function (errors) {
        console.log('save billingInfo failed: ', errors);
        //Log error but do not notify user.
        self.handleApplicationError("ordering.js --> saveBillingOnToggleShut", errors, false);
      })['finally'](function () {
        billingInfo.set('commitOnSuccess', false);
      });
    },

    saveNotesOnToggleShut: function saveNotesOnToggleShut() {
      var self = this;
      var projectNotes = this.get('projectNotes');
      projectNotes.setupForCommit();
      projectNotes.save().then(function () {})['catch'](function (errors) {
        console.log('save projectNotes failed', errors);
        self.handleApplicationError("ordering.js --> saveNotesOnToggleShut", errors, false);
      })['finally'](function () {
        projectNotes.set('commitOnSuccess', false);
      });
    },

    actions: {
      passEstimatedShipDate: function passEstimatedShipDate(date) {
        //Receives estimated ship date from time-line component, for display in the summary box component 
        this.set('estimatedShipDate', date);
      },
      cancel: function cancel(closeThis, orderModel, clientState) {
        var self = this;
        this.set('show' + closeThis.capitalize(), false);

        //reload won't take if record is in dirty state
        orderModel.rollbackAttributes();
        if (closeThis === 'kitted') {
          orderModel.get('kitBoxes').forEach(function (box) {
            box.rollbackAttributes();
          });
        }

        //'rollback record'
        orderModel.reload()['finally'](function () {
          orderModel.set('commitOnSuccess', false);
          if (closeThis === 'billing') {
            self.set('hasPurchaseOrderNumber', !_ember['default'].isEmpty(orderModel.get('purchaseOrderNumber')));
          }
          if (closeThis === 'kitted') {
            if (!orderModel.get('kitBoxes.length')) {
              self.store.unloadAll('kitBox');
              var kitBox = self.store.createRecord('kitBox');
              orderModel.get('kitBoxes').pushObject(kitBox);
            }
          }
        });
        if (!_ember['default'].isEmpty(clientState)) {
          var openSection = clientState;
          if (closeThis !== clientState) {
            this.set('show' + clientState.capitalize(), true);
          } else {
            openSection = 'files';
            this.set('showFiles', true);
          }
          this.send('scrollToSnippet', openSection);
        }
      },

      close: function close() {
        this.transitionToRoute('user.quotes');
      },

      collapse: function collapse() {
        this.set('showFiles', false);
        this.set('showContacts', false);
        this.set('showKitted', false);
        this.set('showShipping', false);
        this.set('showBilling', false);
        this.set('showProject', false);
        this.set('showNotes', false);
        this.set('showCheckout', false);
      },

      collapseAndPrint: function collapseAndPrint() {
        this.send('collapse');

        $('.header')[0].scrollIntoView();

        _ember['default'].run.later(function () {
          window.print();
        }, 500);
      },

      disableFileEditability: function disableFileEditability() {
        this.set('filesEditable', false);
      },

      duplicateProject: function duplicateProject(projectGUID) {
        var self = this;
        self.set('showDuplicateSpinner', true);
        var deferredProjectName = new _ember['default'].RSVP.defer('Project Name Deferred');

        var duplicate = this.store.createRecord('project', { guidForDuplication: projectGUID,
          commitOnSuccess: true,
          sessionGUID: $.cookie('sessionGUID'),
          hubSpotConversationId: $.cookie('hubSpotConversationId') });
        duplicate.save().then(function (duplicate) {
          duplicate.set('hubSpotConversationId', '');
          duplicate.reload().then(function () {
            self.send('openModal', 'order/project-name-modal', duplicate, deferredProjectName);
          });

          deferredProjectName.promise.then(function () {
            self.transitionToRoute('quote.edit', duplicate.get('projectGUID'));
          })['finally'](function () {
            self.set('showDuplicateSpinner', false);
          });
        })['catch'](function (errors) {
          console.log(errors);
          self.set('showDuplicateSpinner', false);
          //Log error but do not notify user.
          self.handleApplicationError("ordering.js --> duplicateProject", errors, false);
        });
      },

      preventValidationOnToggle: function preventValidationOnToggle(section) {
        //prevent validations triggered by non-cancelable focusOut event when section is being toggled shut

        //unless files (no validations triggered by focusOut to prevent in the files section)
        if (section !== 'files') {
          this.get(section + 'Component').send('preventValidationOnToggle');
        }
      },

      scrollToSnippet: function scrollToSnippet(snippetName) {
        var targetSnippet = this.get('order' + snippetName.capitalize() + 'Snippet');
        // add a bit of delay so the component height can expand all the way
        if (targetSnippet) {
          _ember['default'].run.later(function () {
            $('html, body').animate({ scrollTop: targetSnippet.$().offset().top - 10 }, 200);
          }, 500);
        }
      },

      setClientStateOnProject: function setClientStateOnProject(section, deferredSummarySpinner) {
        var self = this;
        console.log('setClientStateOnProject section', section);
        this.get('project').set('clientState', section);
        this.get('project').save().then(function () {
          if (deferredSummarySpinner) {
            deferredSummarySpinner.resolve();
          }
          console.log('clientState successfully set on project');
        })['catch'](function (errors) {
          console.log('clientState failed', errors);
          //Log error but do not notify user.
          self.handleApplicationError("ordering.js --> setClientStateOnProject", errors, false);
        });
      },

      setProjectFile: function setProjectFile(projectFile) {
        this.set('projectFile', projectFile);
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.set(sectionErrorStateName, state);
      },

      transitionToQuote: function transitionToQuote() {
        var projectGUID = this.project.get('id');
        this.transitionToRoute('quote.edit', projectGUID);
      },

      resetReloadTimeLine: function resetReloadTimeLine() {
        this.set('reloadTimeLine', false);
      },

      //second param so save doesn't occur on green button click?
      toggleComponent: function toggleComponent(showThis) {
        var fromSnippet = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

        //Clear all error flags here
        this.set('internalServerError', '');
        this.set('checkoutAssemblyError', false);
        this.set('checkoutFabError', false);
        this.set('checkoutPartsError', false);

        var targetSnippet = this.get('order' + showThis.capitalize() + 'Snippet');
        var openSection = this.openSection();
        //fromSnippet tells us if the toggle came from a click on the order-snippet title, as opposed to from
        //save via the green button in an indivual form section. Prevent duplicate save on section collapse.
        if (fromSnippet) {
          this.saveClosingSection(openSection);
        }
        this.send('collapse');

        this.set('show' + showThis.capitalize(), true);
        // add a bit of delay so the component height can expand all the way
        _ember['default'].run.later(function () {
          $('html, body').animate({ scrollTop: targetSnippet.$().offset().top - 10 }, 200);
        }, 350);
        this.set(showThis + 'Editable', true);
      }
    }
  });
});