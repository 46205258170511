define('web-app-frontend/controllers/user/account/contact-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    contactController: _ember['default'].inject.controller('user/account/contacts'),
    validateContact: 'validateContact',

    rollbackContact: function rollbackContact(contact) {
      var rollbackState = this.get('rollbackState');
      var serverId = contact.get('serverId');
      this.get('applicationController').updateChangedValues(contact, rollbackState);
      contact.set('serverId', serverId);
      this.store.unloadRecord(rollbackState);
    },

    actions: {
      closeContactModal: function closeContactModal(action, contact) {
        if (action === "edit") {
          this.rollbackContact(contact);
        } else {
          //"new"
          //manual state transition might not be necessary in future ember-data release, but for now,
          //without it here one is prevented from unloading the unsaved record, which is in 'inFlight'
          //status from having been validated
          contact.transitionTo('loaded.saved');
          this.store.unloadRecord(contact);
        }
        this.send('closeModal');
      },

      saveContact: function saveContact(contact) {
        var self = this;
        var submitBtnComponent;
        submitBtnComponent = this.get('submitButton');
        // Defines attributes that will display input errors
        // also sets 'commitOnSuccess' to true
        contact.setupForCommit();
        contact.save().then(function () {
          contact.set('committedToServer', true);
          self.send('closeModal');
          submitBtnComponent.send('hasSucceeded');
        })['catch'](function (errors) {
          submitBtnComponent.send('showError', errors);
        })['finally'](function () {
          submitBtnComponent.send('hideSpinner');
        });
      },

      validateContact: function validateContact() {
        var contact = this.model;
        contact.set('commitOnSuccess', false);
        contact.alterAttributes();
        contact.save().then(function () {})['catch'](function (errors) {
          console.log('validate contact failed: ', errors);
        });
      }
    }
  });
});