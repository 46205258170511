define("web-app-frontend/templates/components/error-display", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 0
          }
        },
        "moduleName": "web-app-frontend/templates/components/error-display.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "error-message", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [1, 22], [1, 27]]]]], [], []], "attribute", ["subexpr", "@mut", [["get", "attribute", ["loc", [null, [1, 38], [1, 47]]]]], [], []], "sectionErrorStateName", ["subexpr", "@mut", [["get", "sectionErrorStateName", ["loc", [null, [1, 70], [1, 91]]]]], [], []], "hideForSoftAlert", ["subexpr", "@mut", [["get", "hideForSoftAlert", ["loc", [null, [1, 109], [1, 125]]]]], [], []]], ["loc", [null, [1, 0], [1, 127]]]], ["inline", "soft-alert", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 19], [2, 24]]]]], [], []], "attribute", ["subexpr", "@mut", [["get", "attribute", ["loc", [null, [2, 35], [2, 44]]]]], [], []]], ["loc", [null, [2, 0], [2, 46]]]]],
      locals: [],
      templates: []
    };
  })());
});