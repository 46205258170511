define('web-app-frontend/components/form-inline-inputs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['errorActive:error-active'],
    classNames: ['inline-inputs'],
    errorActive: false,
    firstAttributeWithErrors: null,
    scrollToFullFeature: 'scrollToFullFeature',

    setAttribute: (function () {
      this.set('firstAttributeWithErrors', this.firstAttribute);
    }).on('init'),

    updateFirstAttributeWithErrors: (function () {
      if (!this.model) {
        return;
      }
      if (this.model.get('errors').get(this.secondAttribute)) {
        this.set('firstAttributeWithErrors', this.firstAttribute);
      } else {
        this.set('firstAttributeWithErrors', this.firstAttribute);
      }
    }).observes('firstValue', 'secondValue'),

    focusOut: function focusOut() {

      if (this.get('secondValue') !== null) {
        var firstAttribute = this.get('firstAttribute');
        var userAlteredAttributes = this.model.get('userAlteredAttributes');
        if (this.model.get('userAlteredAttributes').indexOf(firstAttribute) === -1) {
          userAlteredAttributes.push(firstAttribute);
        }
        this.sendAction();
      }
    },

    actions: {
      scrollToFullFeature: function scrollToFullFeature() {
        this.sendAction('scrollToFullFeature');
      },

      setFieldErrorState: function setFieldErrorState(state) {
        this.set('errorActive', state);
      }
    }
  });
});