define('web-app-frontend/routes/resource-center/faq', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'FAQ',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'Listed below are the most common questions we have received over the years, categorized by the topics on the left.'
      }
    }],

    setupController: function setupController(controller) {
      // just to render 3 links in marketing-section-links
      controller.set('indexName', 'process');
    }
  });
});