define('web-app-frontend/controllers/user/account/forgot-password-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),

    actions: {
      resetPassword: function resetPassword(model) {
        var self = this;
        this.set('showError', false);
        this.set('showSpinner', true);
        model.save().then(function () {
          console.log('password successfully reset');
          self.send('openModal', 'user.account.forgot-password-confirmation-modal');
          self.set('showSpinner', false);
          self.set('showError', false);
        })['catch'](function (error) {
          console.log('password reset failed', error);
          self.set('showError', true);
          self.set('showSpinner', false);
        });
      },

      closeModal: function closeModal() {
        if (this.get('applicationController.currentPath') === 'passthrough-sign-in') {
          this.send('openModal', 'modals.sign-in-or-register-modal');
        } else {
          return true;
        }
      }

    }
  });
});