define('web-app-frontend/routes/project-sign-in', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'application',
    titleToken: 'Sign In or Sign Up',
    session: _ember['default'].inject.service(),

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'If you are new, you can get started by creating a quote or by creating a Screaming Circuits account. If you have an account, use the sign in form to the right.'
      }
    }],

    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('user.activity');
      }
    },

    activate: function activate() {
      _ember['default'].$('body').addClass('project-box-active');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('project-box-active');
    },

    setupController: function setupController(controller) {
      // theoretically there should only be one project here.
      var projects = this.store.peekAll('project');
      var projectCount = projects.get('length');
      var projectPresent = false;
      var projectGUID;

      if (projectCount > 0) {
        projectPresent = true;
        projectGUID = projects.get('firstObject.id');
        controller.set('redirectProject', projectGUID);
      }

      controller.set('projectPresent', projectPresent);

      this.send('closeModal');
    }
  });
});