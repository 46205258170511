define('web-app-frontend/controllers/order/project-name-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    applicationController: _ember['default'].inject.controller('application'),
    nameError: false,
    revisionError: false,

    // should only be able to close the modal in the quoting section
    canCloseModal: (function () {
      var currentPath = this.get('applicationController.currentPath');
      if (currentPath === 'user.orders' || currentPath === 'user.quotes') {
        return false;
      }
      if (currentPath !== 'project.order.edit' || this.get('model.projectName')) {
        return true;
      } else {
        return false;
      }
    }).property('applicationController.currentPath'),

    actions: {
      resetAndCloseModal: function resetAndCloseModal() {
        if (this.model) {
          this.model.rollbackAttributes();
          this.model.save();
        }
        if (this.deferred) {
          this.deferred.reject();
        }
        this.send('closeModal');
      },

      updateModel: function updateModel(model) {
        this.get('applicationController').send('updateModel', model, this, this.deferred);
        this.set('canCloseModal', true);
      },

      validateAndUpdateProject: function validateAndUpdateProject() {
        var submitButton = this.get('submitButton');
        this.send('validateProjectName');
        if (this.get('nameError')) {
          submitButton.send('showError');
        } else {
          this.send('updateModel', this.model);
        }
      },

      validateProjectName: function validateProjectName() {
        if (!this.model.get('projectName')) {
          this.set('nameError', true);
        } else {
          this.set('nameError', false);
        }
      }
    }
  });
});