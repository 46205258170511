define("web-app-frontend/routes/contact", ["exports", "ember", "web-app-frontend/mixins/reset-scroll", "web-app-frontend/config/environment", "web-app-frontend/utils/caesar-shift"], function (exports, _ember, _webAppFrontendMixinsResetScroll, _webAppFrontendConfigEnvironment, _webAppFrontendUtilsCaesarShift) {
  exports["default"] = _ember["default"].Route.extend(_webAppFrontendMixinsResetScroll["default"], {
    // created to extend ResetScrollMixin
    titleToken: "Contact",

    headTags: [{
      type: "meta",
      tagId: "meta-description",
      attrs: {
        name: "description",
        content: "We make it easy to get all those SMT and thru-hole parts soldered on to your prototype PCBs, fast - in as little as 24-hours! Just quote and order online."
      }
    }, {
      type: "meta",
      tagId: "og:title",
      attrs: {
        name: "og:title",
        content: "Contact Screaming Circuits about PCB Assembly and on-demand electronics manufacturing"
      }
    }, {
      type: "meta",
      tagId: "twitter:title",
      attrs: {
        name: "twitter:title",
        content: "Contact Screaming Circuits about PCB Assembly and on-demand electronics manufacturing"
      }
    }],

    beforeModel: function beforeModel(transition) {
      // prevent enter quoting on API down
      if (this.controllerFor("application").get("isAPIDown")) {
        this.controllerFor("application").send("alertAPIDown");
        transition.abort();
      }
    },

    setupController: function setupController(controller) {
      var contact = this.store.createRecord("contact");
      var tokenDeferred = new _ember["default"].RSVP.defer("validation token");
      this.setSCContactValidationToken(tokenDeferred);
      tokenDeferred.promise.then(function (token) {
        contact.set("validationToken", token);
      });
      controller.set("model", contact);
      controller.set("isSubmitted", false);
    },

    setSCContactValidationToken: function setSCContactValidationToken(deferred) {
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + "/api/system/validationtoken?Api-Key=SCWEB";

      $.ajax({
        url: url,
        type: "GET",
        dataType: "json"
      }).done(function (data) {
        //Generate caesarShift cipher before storing token in the cookie
        var cipherText = (0, _webAppFrontendUtilsCaesarShift["default"])(data.model);
        deferred.resolve(cipherText);
      }).fail(function (errors) {
        console.log("sc validation token for contact failed.", errors);
        deferred.reject();
      });
    },

    resetController: function resetController() {
      this.store.unloadAll("contact");
    },

    actions: {
      didTransition: function didTransition() {
        var self = this;
        //Since ember js is a SPA 'afterRender' ember hook was configured to render the grecaptcha widget
        _ember["default"].run.scheduleOnce('afterRender', this, function () {
          var grecaptcha = window.grecaptcha;

          grecaptcha.ready(function () {
            var widgetId = grecaptcha.render('contact-us-form-captcha', {
              'sitekey': _webAppFrontendConfigEnvironment["default"].grecaptchaSiteKey
            });

            self.controller.set('contactUsPageCaptchaWidgetId', widgetId);
          });
        });
      }
    }
  });
});