define('web-app-frontend/controllers/modals/order-confirmation-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isChecked: false,

    actions: {
      approve: function approve(deferred) {
        deferred.resolve();
        this.set('isChecked', true);
        this.send('closeModal');
      },

      reject: function reject(deferred) {
        deferred.reject();
        this.set('isChecked', false);
        this.send('closeModal');
      }
    }
  });
});