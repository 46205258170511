define('web-app-frontend/components/faq-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    questionShown: false,

    actions: {
      toggleQuestion: function toggleQuestion() {
        this.toggleProperty('questionShown');
      }
    }
  });
});