define('web-app-frontend/models/quote-parts', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    bmhFileName: _emberData['default'].attr('string'),
    bomHeaderId: _emberData['default'].attr('number'),
    commitOnSuccess: _emberData['default'].attr('boolean'),
    lowSuccessWarning: _emberData['default'].attr('boolean'),
    isKittedParts: _emberData['default'].attr('boolean', { defaultValue: true }),

    // associations.
    bomLines: _emberData['default'].hasMany('bom-lines')
  });
});