define('web-app-frontend/serializers/activity', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    normalizeQueryResponse: function normalizeQueryResponse(store, typeClass, payload) {
      var activities = payload.activityModel.data;
      // This is to avoid dropping more activities than the user really cares about
      // Arbitrarily set at the last 300 activities.
      var filteredActivities = activities.filter(function (item, index) {
        if (index < 300) {
          return true;
        }
      });

      payload = { activity: filteredActivities };
      return this._super(store, typeClass, payload);
    }
  });
});