define('web-app-frontend/components/file-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    updateUploadText: 'updateUploadText',
    url: '',
    setProjectFile: 'setProjectFile',
    session: _ember['default'].inject.service(),

    click: function click() {
      // clears the value of the text-input so the same file can be reuploaded twice.
      var input = this.$().parents('.faux-button-upload').find('input:file')[0];
      input.value = null;
    },

    closeBOMModal: function closeBOMModal(component, modalName) {
      component.sendAction('closeModal', modalName);
    },

    fetchOauthToken: function fetchOauthToken() {
      var tokenString = '';
      if (this.userPresent()) {
        tokenString = 'Bearer ' + this.get('session.data.authenticated.access_token');
      }
      return { 'Authorization': tokenString };
    },

    fileIsNotTooLarge: function fileIsNotTooLarge() {
      var file = this.get('files')[0];
      // file size / 1,000,000
      return file.size / 1000000 <= 100.0;
    },

    filesEmpty: function filesEmpty() {
      return _ember['default'].isEmpty(this.get('files'));
    },

    getFileExtension: function getFileExtension(filename) {
      var parts = filename.split('.');
      return parts[parts.length - 1];
    },

    openBOMModal: function openBOMModal(component, modalName, model) {
      component.get('targetObject.bomController').send('resetModalVariables');
      component.sendAction('openModal', modalName, model);
    },

    setCompleteState: function setCompleteState() {
      var component = this.get('component');
      component.setProperties({ 'showSpinner': false,
        'showComplete': true
      });
      _ember['default'].run.later(this, function () {
        component.set('showComplete', false);
      }, 3000);
    },

    setErrorState: function setErrorState() {
      this.get('component').setProperties({ 'showSpinner': false,
        'showError': true
      });
    },

    setFileNameInView: function setFileNameInView() {
      // Send filename to parent component to update text
      var fileName = this.get('files')[0].name;
      this.sendAction('updateUploadText', fileName);
    },

    userPresent: function userPresent() {
      return this.get('session.isAuthenticated');
    },
    actions: {}
  });
});