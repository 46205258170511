define("web-app-frontend/routes/register", ["exports", "ember", "web-app-frontend/utils/caesar-shift", "web-app-frontend/config/environment"], function (exports, _ember, _webAppFrontendUtilsCaesarShift, _webAppFrontendConfigEnvironment) {
  exports["default"] = _ember["default"].Route.extend({
    titleToken: "Register",

    headTags: [{
      type: "meta",
      tagId: "meta-description",
      attrs: {
        name: "description",
        content: "Create an account with Screaming Circuits for the quickest route from PCB Assembly quote to order to delivery."
      }
    }],

    model: function model() {
      return this.get("store").createRecord("UserRegisterModel");
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var projects = this.store.peekAll("project");
      // var userRegisterModel = this.store.createRecord('user-register-model');
      if (projects.get("length") > 0) {
        controller.set("redirectGUID", projects.get("firstObject.id"));
      }
      var tokenDeferred = new _ember["default"].RSVP.defer("validation token");
      this.setSCContactValidationToken(tokenDeferred);
      tokenDeferred.promise.then(function (token) {
        // userRegisterModel.set('validationToken', token);
        controller.set("validationToken", token);
      });
      controller.set("model", model);
    },

    activate: function activate() {
      _ember["default"].$("body").addClass("project-box-active");
      _ember["default"].run.schedule("afterRender", function () {
        $("html, body").animate({ scrollTop: 0 }, 200);
      });
    },

    deactivate: function deactivate() {
      _ember["default"].$("body").removeClass("project-box-active");
    },

    setSCContactValidationToken: function setSCContactValidationToken(deferred) {
      var url = _webAppFrontendConfigEnvironment["default"].screamingCircuitsApiUrl + "/api/system/validationtoken?Api-Key=SCWEB";
      $.ajax({
        url: url,
        type: "GET",
        dataType: "json"
      }).done(function (data) {
        //Generate caesarShift cipher before storing token in the cookie
        var cipherText = (0, _webAppFrontendUtilsCaesarShift["default"])(data.model);
        deferred.resolve(cipherText);
      }).fail(function (errors) {
        console.log("sc validation token  failed.", errors);
        deferred.reject();
      });
    },

    actions: {
      didTransition: function didTransition() {
        var self = this;
        //Since ember js is a SPA 'afterRender' ember hook was configured to render the grecaptcha widget
        _ember["default"].run.scheduleOnce('afterRender', this, function () {
          var grecaptcha = window.grecaptcha;

          grecaptcha.ready(function () {
            var widgetId = grecaptcha.render('register-user-form-captcha', {
              'sitekey': _webAppFrontendConfigEnvironment["default"].grecaptchaSiteKey
            });

            self.controller.set('registerPageCaptchaWidgetId', widgetId);
          });
        });
      }
    }
  });
});