define('web-app-frontend/components/sign-in-dropdown', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sign-in'],
    signInHovered: 'signInHovered',
    toggleSignIn: 'toggleSignIn',
    openModal: 'openModal',
    signInAndAuthenticate: 'signInAndAuthenticate',
    forgotPassword: 'forgotPassword',

    mouseLeave: function mouseLeave() {
      this.sendAction('signInHovered', false);
    },

    mouseEnter: function mouseEnter() {
      this.sendAction('signInHovered', true);
    },

    actions: {
      signInAndAuthenticate: function signInAndAuthenticate(id, password, deferred) {
        this.sendAction('signInAndAuthenticate', id, password, deferred);
      },

      toggleSignIn: function toggleSignIn(signInParam) {
        this.sendAction('toggleSignIn', signInParam);
      },

      forgotPassword: function forgotPassword() {
        this.sendAction('forgotPassword');
        this.send('toggleSignIn');
      }
    }
  });
});