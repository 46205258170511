define('web-app-frontend/components/kit-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['kitBoxPartial'],
    firstBox: _ember['default'].computed.equal('index', 0),

    todayDate: moment().toDate(),
    twoMonths: moment().add(60, 'days').toDate(),

    setSectionErrorState: 'setSectionErrorState',
    validateOrderKit: 'validateOrderKit',

    setKitArrivalDateOnInit: (function () {
      var orderKit = this.get('model.orderKit');
      var kitBox = this.get('model');
      var previouslySelectedDate, firstValidDate;
      if (kitBox && orderKit) {
        if (this.get('project.canUploadKits') === true) {
          if (orderKit.get('kitArrivalDate')) {
            if (orderKit.uploadDateHasPassed()) {
              //based upon functionality triggered by test, this appears to loop indefinetly...
              firstValidDate = orderKit.firstValidDate(); //today OR next weekday if weekend
              orderKit.set('kitArrivalDate', firstValidDate);
              this.saveOrderKit(orderKit);
            } else {
              previouslySelectedDate = orderKit.get('kitArrivalDate');
              orderKit.set('kitArrivalDate', previouslySelectedDate);
            }
          } else {
            firstValidDate = orderKit.firstValidDate();
            orderKit.set('kitArrivalDate', firstValidDate);
          }
        } else {
          console.log('Kits are not editable');
        }
      }
      //This observer causes order-kit-test update saved information to loop infinitely.
    }).observes('model.orderKit', 'project.canUploadKits').on('init'),

    saveOrderKit: function saveOrderKit(orderKit) {
      var self = this;
      orderKit.setupForCommit();
      orderKit.formatDateForSave();
      orderKit.save().then(function () {
        console.log('save order kit succeeded');
        //Trigger timeline reload
        self.set('reloadTimeLine', true);
      })['catch'](function (errors) {
        console.log('save order kit failed', errors);
      })['finally'](function () {
        orderKit.set('commitOnSuccess', false);
      });
    },

    actions: {
      removeBox: function removeBox() {
        this.get('model').unloadRecord();
      },

      setSectionErrorState: function setSectionErrorState(sectionErrorStateName, state) {
        this.sendAction('setSectionErrorState', sectionErrorStateName, state);
      },

      validateOrderKit: function validateOrderKit() {
        this.get('model').alterAttributes();
        this.sendAction('validateOrderKit');
      }
    }
  });
});