define('web-app-frontend/routes/about/partners', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll'], function (exports, _ember, _webAppFrontendMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webAppFrontendMixinsResetScroll['default'], {
    controllerName: 'about',
    titleToken: 'Partners',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'We carefully choose partners who are committed to your success. These are companies that add value to our services and integrate into your order experience.'
      }
    }],

    setupController: function setupController(controller) {
      controller.set('indexName', 'partners');
    }
  });
});