define("web-app-frontend/initializers/custom-session", ["exports", "ember-simple-auth/services/session"], function (exports, _emberSimpleAuthServicesSession) {
  exports["default"] = {
    name: "current-user",
    before: "ember-simple-auth",
    initialize: function initialize(application) {
      application.deferReadiness();

      _emberSimpleAuthServicesSession["default"].reopen({

        getSessionGuid: (function () {
          if ($.cookie('sessionGUID') !== undefined) {
            return $.cookie('sessionGUID');
          }
          var d = new Date().getTime();
          var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : r & 0x3 | 0x8).toString(16);
          });
          $.cookie('sessionGUID', uuid, { path: '/', expires: 30 });
          return uuid;
        }).on('init')
      });

      application.advanceReadiness();
    }
  };
});