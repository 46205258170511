define('web-app-frontend/components/marketing-footer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['marketingFooter'],
    siteMapOpen: false,
    startNewQuote: 'startNewQuote',
    transitionAndScrollTo: 'transitionAndScrollTo',

    actions: {
      startNewQuote: function startNewQuote() {
        this.sendAction('startNewQuote');
      },

      toggleSiteMap: function toggleSiteMap() {
        this.toggleProperty('siteMapOpen');
      },

      transitionAndScrollTo: function transitionAndScrollTo(route, id) {
        this.sendAction('transitionAndScrollTo', route, id);
      }
    }
  });
});