define('web-app-frontend/components/price-option', ['exports', 'ember', 'web-app-frontend/mixins/analytics'], function (exports, _ember, _webAppFrontendMixinsAnalytics) {
  exports['default'] = _ember['default'].Component.extend(_webAppFrontendMixinsAnalytics['default'], {
    classNames: ['price-option bordered text-center'],
    classNameBindings: ['model.isSelected:chosen-price'],
    openModal: 'openModal',
    buildCarousel: 'buildCarousel',
    goToSlide: 'goToSlide',
    removeActiveClass: 'removeActiveClass',
    transitionToOrder: 'transitionToOrder',
    transitionToRoute: 'transitionToRoute',
    openTaxShippingEstimateModal: 'openTaxShippingEstimateModal',
    prepCarousel: "prepCarousel",

    showSpinner: false,
    showSuccess: false,

    bgType: (function () {
      var type = this.get('protoOrShort');
      if (this.get('model.showCustomTurnTimeHeader')) {
        this.set('protoOrShort', this.get('protoOrShort') + ' (Custom)');
        return 'custom-color';
      } else if (type === 'Short-Run') {
        return 'bg-light-grey';
      } else {
        return 'bg-blue';
      }
    }).property('type'),

    saveTurnTime: function saveTurnTime(projectTurnTime, deferred) {
      var store = this.get('parentView.parentView.targetObject.store');
      var project = store.peekRecord('project', projectTurnTime.get('projectGUID'));
      var self = this;
      projectTurnTime.save().then(function () {
        project.reload().then(function () {
          deferred.resolve();
          self.sendAction('transitionToOrder');
        });
      })['catch'](function (errors) {
        console.log('selectProjectTurnTime errors', errors);
        deferred.reject(errors);
      });
    },

    projectBomIsValid: function projectBomIsValid(projectGUID, parentDeferred) {
      var store = this.get('parentView.parentView.targetObject.store');
      var bom = store.peekRecord('bom', projectGUID);
      if (bom) {
        var bomValidDeferred = new _ember['default'].RSVP.defer('check if bom is valid');
        bom.checkIfValid(bomValidDeferred);
        bomValidDeferred.promise.then(function (result) {
          parentDeferred.resolve(result);
        });
      } else {
        parentDeferred.resolve(true);
      }
    },

    checkIfFabricationServiceIsAvailable: function checkIfFabricationServiceIsAvailable(projectTurnTime) {
      if (projectTurnTime.get('hasFab') && !projectTurnTime.get('fabSystemLiveStatus')) {
        return false;
      } else {
        return true;
      }
    },

    isFormalQuote: (function () {
      return this.get('model.project.isFormalQuote') || this.get('model.customFabQuote') || this.get('model.customAssemblyQuote');
    }).property('isFormalQuote'),

    actions: {
      openModal: function openModal(controller, model) {
        this.sendAction('openModal', controller, model);
      },

      openTaxShippingEstimateModal: function openTaxShippingEstimateModal(model) {
        this.sendAction('openTaxShippingEstimateModal', model);
      },

      selectProjectTurnTime: function selectProjectTurnTime(projectTurnTime) {
        var self = this;
        var store = this.get('parentView.parentView.targetObject.store');
        var projectGUID = projectTurnTime.get('projectGUID');
        var project = store.peekRecord('project', projectGUID);
        var bom = store.peekRecord('bom', projectGUID);
        var fabricationIsAvailable = self.checkIfFabricationServiceIsAvailable(projectTurnTime);

        self.set('showSpinner', true);
        var bomDeferred = new _ember['default'].RSVP.defer('bom validity parent');
        if (bom) {
          //won't be if user opted to remove
          self.projectBomIsValid(projectGUID, bomDeferred);
        } else {
          bomDeferred.resolve(true);
        }

        var alertDeferred = new _ember['default'].RSVP.defer('pricing selection deferred');
        bomDeferred.promise.then(function (bomIsValid) {
          if (projectTurnTime.requiresPricingConfirmation() && bomIsValid && fabricationIsAvailable) {
            self.send('openModal', 'quoting.confirm-pricing-selection-modal', { turnTime: self.model, deferred: alertDeferred });
          } else if (!bomIsValid) {
            self.send('openModal', 'modals/alert-modal', { text: 'BOM is showing errors for certain parts, please correct them before proceeding.', deferred: alertDeferred });
          } else {
            self.saveTurnTime(projectTurnTime, alertDeferred);
          }
        });

        alertDeferred.promise.then(function () {
          self.set('showSpinner', false);
          self.set('showSuccess', true);

          var analyticsEventPayload = {
            action: 'begin_checkout',
            category: project.get('isFormalQuote') ? 'Formal Quote' : 'Web Order',
            value: project.get('orderTotal'),
            orderNumber: project.get('orderNumber')
          };

          self.sendGA4Event(analyticsEventPayload); //Analytics Mixin

          self.sendMSAnalyticsEvent(analyticsEventPayload);

          self.sendTaboolaAnalyticsEvent(analyticsEventPayload);
        })['catch'](function () {
          self.set('showSpinner', false);
        });
      }
    }
  });
});