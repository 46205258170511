define('web-app-frontend/adapters/question', ['exports', 'ember-data', 'web-app-frontend/adapters/quoting-parent', 'web-app-frontend/utils/error-formatting'], function (exports, _emberData, _webAppFrontendAdaptersQuotingParent, _webAppFrontendUtilsErrorFormatting) {
  exports['default'] = _webAppFrontendAdaptersQuotingParent['default'].extend({
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return true;
    },

    buildURL: function buildURL(type, id) {
      var url = 'survey/feedback/' + id;
      return this._super(url);
    },

    findRecord: function findRecord(store, type, id) {
      return this.ajax(this.buildURL(type.modelName, id, null), "GET");
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var record = snapshot.record;
      var feedbackId = record.get('svfFeedbackId');
      var payload = { question: record };
      return this.ajax(this.buildURL(type.modelName, feedbackId), "PUT", { data: payload });
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var errors, response;
      errors = [];
      response = payload;
      delete payload.questions;
      if (status === 500) {
        _webAppFrontendUtilsErrorFormatting['default'].addJsonApiError("base", "An error has occurred. Please give us a call at 1-866-784-5887 for assistance.", errors);
        return new _emberData['default'].InvalidError(errors);
      } else {
        return this._super(status, headers, payload);
      }
    }

  });
});