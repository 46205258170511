define("web-app-frontend/templates/components/user-password-form", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "modifiers",
            "modifiers": ["action"]
          },
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 41,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/user-password-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("form");
          dom.setAttribute(el1, "class", "userPasswordForm");
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "group-form");
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-md-11");
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          dom.setAttribute(el5, "class", "text-right");
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "small");
          var el7 = dom.createTextNode("*");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" Required");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5, "for", "currentpw-input");
          dom.setAttribute(el5, "class", "block");
          var el6 = dom.createTextNode("Current Password");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "small");
          var el7 = dom.createTextNode("*");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5, "for", "newpw-input");
          dom.setAttribute(el5, "class", "block");
          var el6 = dom.createTextNode("New Password");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "small");
          var el7 = dom.createTextNode("*");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("label");
          dom.setAttribute(el5, "for", "confirmpw-input");
          dom.setAttribute(el5, "class", "block");
          var el6 = dom.createTextNode("Confirm New Password");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("span");
          dom.setAttribute(el6, "class", "small");
          var el7 = dom.createTextNode("*");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n      ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-md-1");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var element1 = dom.childAt(element0, [1, 1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element1, 5, 5);
          morphs[2] = dom.createMorphAt(element1, 9, 9);
          morphs[3] = dom.createMorphAt(element1, 13, 13);
          morphs[4] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["element", "action", ["changePassword", ["get", "password", ["loc", [null, [2, 57], [2, 65]]]]], ["on", "submit"], ["loc", [null, [2, 31], [2, 79]]]], ["inline", "form-field-display", [], ["model", ["subexpr", "@mut", [["get", "password", ["loc", [null, [11, 18], [11, 26]]]]], [], []], "attribute", "passwordOld", "cssClasses", "inspectletIgnore", "action", "validatePassword", "value", ["subexpr", "@mut", [["get", "password.passwordOld", ["loc", [null, [15, 18], [15, 38]]]]], [], []], "type", "password"], ["loc", [null, [10, 8], [16, 29]]]], ["inline", "form-passwordfield-display", [], ["model", ["subexpr", "@mut", [["get", "password", ["loc", [null, [20, 18], [20, 26]]]]], [], []], "attribute", "passwordNew", "cssClasses", "inspectletIgnore", "action", "matchPassword", "value", ["subexpr", "@mut", [["get", "password.passwordNew", ["loc", [null, [24, 18], [24, 38]]]]], [], []]], ["loc", [null, [19, 8], [24, 40]]]], ["inline", "form-passwordfield-display", [], ["model", ["subexpr", "@mut", [["get", "password", ["loc", [null, [28, 18], [28, 26]]]]], [], []], "attribute", "passwordConf", "cssClasses", "inspectletIgnore", "action", "matchPassword", "value", ["subexpr", "@mut", [["get", "password.passwordConf", ["loc", [null, [32, 18], [32, 39]]]]], [], []], "passwordsMatch", ["subexpr", "@mut", [["get", "passwordsMatch", ["loc", [null, [33, 27], [33, 41]]]]], [], []]], ["loc", [null, [27, 8], [33, 43]]]], ["inline", "submit-button", [], ["text", "Change Password", "action", "changePassword", "model", ["subexpr", "@mut", [["get", "password", ["loc", [null, [39, 71], [39, 79]]]]], [], []], "register-as", ["subexpr", "@mut", [["get", "submitButton", ["loc", [null, [39, 92], [39, 104]]]]], [], []]], ["loc", [null, [39, 2], [39, 106]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.0",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "web-app-frontend/templates/components/user-password-form.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-11 mt20 h6");
          var el3 = dom.createTextNode("\n      Success! Your Password has been changed!\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 18
          }
        },
        "moduleName": "web-app-frontend/templates/components/user-password-form.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "liquid-unless", [["get", "isSubmitted", ["loc", [null, [1, 17], [1, 28]]]]], [], 0, 1, ["loc", [null, [1, 0], [47, 18]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});