define('web-app-frontend/serializers/project-turn-time', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      userAlertMessage: { embedded: 'always' }
    },

    //alias for normalizeArrayResponse
    normalizeQueryResponse: function normalizeQueryResponse(store, typeClass, payload) {
      store.unloadAll('project-turn-time');
      var turnTimes = payload.projectTurnTimeModel.data;
      var userAlertMessages = [];
      turnTimes.forEach(function (turnTime, index) {
        turnTime.id = index + 1;
        turnTime.project = turnTime.projectGUID;
        if (turnTime.userAlertMessage) {
          turnTime.userAlertMessage.id = index + 1;
          userAlertMessages.push(turnTime.userAlertMessage);
        }
      });
      payload = { projectTurnTimes: turnTimes, userAlertMessages: userAlertMessages };
      return this._super(store, typeClass, payload);
    }
  });
});