define('web-app-frontend/components/quote-footer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['partsTouched:show', 'assembly.customAssemblyQuote:show'],
    classNames: ['footer-contain'],
    collapseAndPrint: 'collapseAndPrint',
    toggleSkip: 'toggleSkip',
    openModal: 'openModal',
    openTaxShippingEstimateModal: 'openTaxShippingEstimateModal',
    saveForLater: 'saveForLater',
    toggleComponent: 'toggleComponent',
    transitionToRoute: 'transitionToRoute',

    SCRegister: (function () {
      //With later ember upgrades this needs to be moved to didInitAttrs event
      if (this.get('attrs.register-as')) {
        this.get('attrs.register-as.update')(this);
      }
    }).on('didInitAttrs'),

    // saveAndTransitionToQuoting: function(saveDeferred) {
    saveAndTransitionToQuoting: function saveAndTransitionToQuoting() {
      var self = this;
      var saveDeferred = new _ember['default'].RSVP.defer('footer saveForLater');

      this.sendAction('saveForLater', this.project, saveDeferred, true);

      saveDeferred.promise.then(function () {
        self.sendAction('transitionToRoute', 'user.quotes');
        self.set('showSpinner', false);
        _ember['default'].run.later(function () {
          $('html, body').animate({ scrollTop: 0 }, 200);
        }, 350);
      })['catch'](function () {
        //saveForLater failed;
        self.set('showSpinner', false);
      });
    },

    combinedTotalEstimatePerBoard: (function () {
      if (this.get('combinedTotalEstimate')) {
        var perBoard = this.get('combinedTotalEstimate') / this.assembly.get('quantityOnSubtotalChange');
        return perBoard;
      }
    }).property('combinedTotalEstimate', 'assembly.quantityOnSubtotalChange'),

    combinedTotalEstimate: (function () {
      if (this.project) {
        var assemblyTotal = 0;
        var fabTotal = 0;
        var partsTotal = 0;

        if (this.assembly) {
          assemblyTotal = this.assembly.get('assemblySubtotal');
        }
        if (this.fabrication && this.fabricationTouched && !this.get('project.userRemovedFab')) {
          fabTotal = this.fabrication.get('quoteTotal') || 0;
        }

        if (this.project && this.project.get('bomDetails') && this.partsTouched && !this.skipParts) {
          partsTotal = this.project.get('bomDetails.partsTotal') || 0;
        } else if (this.project && this.partsTouched && !this.skipParts) {
          partsTotal = this.project.get('materialsPrice') || 0;
        }

        return assemblyTotal + fabTotal + partsTotal;
      }
    }).property('assembly.assemblySubtotal', 'fabrication.quoteTotal', 'project.bomDetails.partsTotal'),

    actions: {
      collapseAndPrint: function collapseAndPrint() {
        this.sendAction('collapseAndPrint');
      },

      focusInput: function focusInput() {
        var element = $('#quantity').find('input');
        this.sendAction('focusInput', element);
      },

      openModal: function openModal(template, model, deferred) {
        this.sendAction('openModal', template, model, deferred);
      },

      openTaxShippingEstimateModal: function openTaxShippingEstimateModal(model) {
        this.sendAction('openTaxShippingEstimateModal', model);
      },

      saveForLater: function saveForLater() {
        this.set('showSpinner', true);
        var self = this;
        if (!this.project.get('projectName')) {
          var nameProjectDeferred = new _ember['default'].RSVP.defer('footer nameProject');
          this.send('openModal', 'quoting/project-name-modal', this.project, nameProjectDeferred);
          nameProjectDeferred.promise.then(function () {
            self.saveAndTransitionToQuoting();
          })['catch'](function () {
            self.set('showSpinner', false);
          });
        } else {
          self.saveAndTransitionToQuoting();
        }
      },

      toggleComponent: function toggleComponent(param) {
        this.sendAction('toggleComponent', param);
      },

      toggleSkip: function toggleSkip(section, removal) {
        this.sendAction('toggleSkip', section, removal);
        this.sendAction('toggleComponent', section);
      }
    }
  });
});