define('web-app-frontend/components/dismissable-alert', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    dismissed: false,

    actions: {
      dismiss: function dismiss() {
        this.set('dismissed', true);
      }
    }
  });
});