define('web-app-frontend/components/bom-alternative-row', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'tr',
        classNames: ['amlComponent'],
        deleteAlternativePart: 'deleteAlternativePart',

        actions: {
            deleteRow: function deleteRow() {
                this.deleteAlternativePart(this.get('bomline.id'), this.get('bomline.lineNumber'), this.get('bomline.manufactureNumber'));
            }
        }
    });
});