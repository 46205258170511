define('web-app-frontend/routes/resource-center/services/parts-sourcing', ['exports', 'ember', 'web-app-frontend/mixins/reset-scroll', 'ember-cli-meta-tags/mixins/route-meta'], function (exports, _ember, _webAppFrontendMixinsResetScroll, _emberCliMetaTagsMixinsRouteMeta) {
  exports['default'] = _ember['default'].Route.extend(_emberCliMetaTagsMixinsRouteMeta['default'], _webAppFrontendMixinsResetScroll['default'], {
    titleToken: 'Parts Sourcing',

    headTags: [{
      type: 'meta',
      tagId: 'meta-description',
      attrs: {
        name: 'description',
        content: 'It takes 16 hours to source parts for an average BOM. We can do it for you, and we can build sourcing into the schedule so you get your boards back faster.'
      }
    }]
  });
});