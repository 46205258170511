define('web-app-frontend/routes/user/account/password', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Change Password',
    session: _ember['default'].inject.service(),

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);
    },

    model: function model() {
      var user = this.store.findRecord('user', this.get('session.data.authenticated.UserGUID'));
      return user;
    },

    setupController: function setupController(controller, user) {
      var password = this.store.createRecord('user-password-reset', { userGUID: this.get('session.data.authenticated.UserGUID'),
        commitOnSuccess: true
      });

      controller.setProperties({
        user: user,
        password: password,
        isSubmitted: false
      });
    }
  });
});