define('web-app-frontend/routes/user/quotes', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    titleToken: 'Quotes',
    queryParams: { sortField: { refreshModel: true } },

    activate: function activate() {
      this._super();
      window.scrollTo(0, 0);
    },

    beforeModel: function beforeModel() {
      //Fire ember-simple-auth's beforeModel hook to check for an authenticated session
      this._super.apply(this, arguments);

      this.get("store").unloadAll('quote');
    },

    model: function model(params) {
      return this.get("store").query('quote', { PageNumber: params.PageNumber,
        SortField: params.sortField,
        SortAscending: params.sortAsc }).then(function (results) {
        return {
          quotes: results,
          hasMoreData: results.get('meta.hasMoreData')
        };
      })['catch'](function (errors) {
        console.log('GET quotes failed', errors);
      });
    },

    setupController: function setupController(controller, _ref) {
      var quotes = _ref.quotes;
      var hasMoreData = _ref.hasMoreData;

      this._super(controller, quotes);
      controller.set('model', quotes);
      // sort needs to retrigger when additional quotes have been added before a page refresh
      controller.sortQuotes();
      this.controller.setPageNumber(hasMoreData);
    }
  });
});